import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { PromoAction } from '../../../redux/actions'
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

function PromoExport({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const csvLink = useRef();

    /** Redux actions and state */
    const { export_promo_loading: isLoading, export_promo } = useSelector((state) => state.PromoReducer);
    const exportPromoCsv = (params) => dispatch(PromoAction.exportPromoCsv(params));

    /** Initialize values from props */
    const { payload } = props;

    /** Initialize and declare state */
    const [isExporting, setIsExporting] = useState(false)
    const [exportDisabled, setExportDisabled] = useState(true)

    const headers = [
        { label: "#", key: "pI" },
        { label: "Name", key: "name" },
        { label: "Promo type", key: "promo_type" },
        { label: "Number of promos", key: "number_of_promos" },
        { label: "Number of Stories", key: "storyCount" },
        { label: "Insta handle", key: "insta_handle" },
        { label: "Brand name", key: "brand_details.name" },
        { label: "Email", key: "brand_details.email" },
        { label: "Mobile number", key: "brand_details.mobile_number" },
        { label: "Payment method", key: "payment_method" },
        { label: "Coupon codes", key: "coupon_codes" },
        { label: "Used coupon count", key: "used_coupon_count" },
        { label: "Posted On", key: "created_at" },
        { label: "Status", key: "status" },
        { label: "Description", key: "description" },
    ];

    /**
     * function to export csv file
     * @param {string} null
     * @author Naveen
     * @created_at 20 June 2022 
     */
    const getPromo = () => {
        if (!exportDisabled) {
            setExportDisabled(true)
            setIsExporting(true);
            exportPromoCsv(payload);
        }
    }

    useEffect(() => {
        if (isExporting && !isLoading) {
            csvLink.current.link.click();
            setIsExporting(false);
            setExportDisabled(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [export_promo])

    useEffect(() => {
        if (csvLink.current) {
            setExportDisabled(false)
        }
    }, [csvLink])

    return (

        <React.Fragment>
            <MenuItem onClick={(e) => {e.stopPropagation(); getPromo()}} disabled={exportDisabled} key={1}>
                <ListItemIcon>
                    <Download fontSize="small" />
                </ListItemIcon>
                Export to CSV
            </MenuItem>
            <MenuItem key={2} style={{ display: `none` }}>
                <CSVLink data={export_promo} filename="promo.csv" asyncOnClick={true} headers={headers} ref={csvLink}>Download</CSVLink>
            </MenuItem>
        </React.Fragment>
        
    )

}

export { PromoExport }