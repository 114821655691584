import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import { CardHeader } from '../../../../_components/card';
import { FormSearchInput } from '../../../../_components/form';
import { GeneralButton } from '../../../../_components/controls';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoData } from '../../../../_components/layout';
import { BrandAction } from "../../../../redux/actions";
import { BrandMenuCard, BrandMenuCardLoader, BrandMenuFilter } from '.';

function BrandMenu() {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
    const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
    const brandId = searchParams.get('brandId'); // plan id from url


    /** Redux reducer state */
	const { per_page, page, brand_menu_loading: isLoading, brand_menu } = useSelector((state) => state.BrandReducer);
	const listBrandMenu = (params) => dispatch(BrandAction.listBrandMenu(params));

    /** Initialize and declare state */
	const [showBrandMenuFilterModal, setShowBrandMenuFilterModal] = useState(false);
	const [payload, setPayload] = useState({ limit: per_page, page: page, filter: { category: searchParams.get('category') ?? '' }, search: searchParams.get('q') ?? '', brand_id: brandId });

    useEffect(() => {

        /* Read the payload and append the parameters to the url for proper reload */
		const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
		if (payload.filter.category) queryParam.category = payload.filter.category; else delete queryParam.category;
        getNext()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

	/**
	 * function to open/close brand menu filter modal
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const brandMenuFilterModal = () => {
		setShowBrandMenuFilterModal(!showBrandMenuFilterModal);
	};

	/**
	 * function to apply the selected filter options on the restaurant menu list
	 * @param {Object} filterData
	 * @param {String} filterData.category Selected category from the restaurant menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 26 Oct 2023
	 */
	const applyFilter = (filterData) => {
		setPayload({ ...payload, page: 1, filter: filterData });
	};

    /**
	 * function to apply the search key words on the brand menu list
	 * @param {String} text Search keyword used
	 * @return view
	 * @author Naveen K
	 * @created_at 26 Oct 2023
	 */
	const applySearch = (text) => {
		setPayload({ ...payload, page: 1, search: text });
	};

	const getNext = async () => {
		const result = await listBrandMenu(payload);
		if (result.status) {
			setPayload({ ...payload, page: payload.page + 1 }); // If result success, update payload state
		}
	};

	return (
		<React.Fragment>
			<CardHeader
				action={
					<Stack spacing={1} direction="row">
						<FormSearchInput getSearchText={applySearch} searchText={payload.search} />
						<GeneralButton label={<FilterAlt />} onClick={() => brandMenuFilterModal()} />
					</Stack>
				}
			/>
			<BrandMenuFilter show={showBrandMenuFilterModal} closeModal={brandMenuFilterModal} applyFilter={applyFilter} brandMenuFilter={payload.filter} />
			<InfiniteScroll dataLength={brand_menu.length} next={getNext} hasMore={true}>
				<Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch">
					{brand_menu.length > 0 &&
						brand_menu.map((brandMenu, i) => {
							return (
								<Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={i}>
									<BrandMenuCard brandMenu={brandMenu}/>
								</Grid>
							);
						})}
					{isLoading && <BrandMenuCardLoader />}
					{!isLoading && brand_menu.length === 0 && <NoData content1={`Brand Menu`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />}
				</Grid>
			</InfiniteScroll>
		</React.Fragment>
	);
}

export { BrandMenu };
