import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PostAuthRoutes } from "../../../routes";
import { CardHeader, CardHeader as PageHeader } from "../../../_components/card";
import { BrandAction } from "../../../redux/actions";
import { BrandCategoryAction } from '../../../redux/actions';
import { BrandCard, BrandCardLoader } from "../brand-card";
import { UpdateStatusModal, UpdateWhatsAppModal, UpdateSmsModal } from "../../../_components/modal";
import { NoData } from "../../../_components/layout/no-data";
import { Tabs } from "../../../_components/tabs";
import { BrandPromos, DeleteBrand } from ".";
import { CreateWallet } from "../brand-wallet-create";
import { GeneralButton } from "../../../_components/controls";
import {BrandWallet} from './brand-wallet';
import { BrandMenu } from './brand-menu'
import { BrandInfluencerWelcomeMessage } from "./brand-influencer-welcome-message";
import { AbilityCan } from "../../../_helpers/permission/AbilityCan";
import {ChangeBrandPassword} from './change-password/ChangeBrandPassword';

function BrandDetails({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const brandId = params.get('brandId'); // plan id from url

    /** Redux actions and state */
    const { brand_details_loading: isLoading, brand_details } = useSelector((state) => state.BrandReducer);
    const { brand_category_details } = useSelector((state) => state.BrandCategoryReducer);
    const brandDetails = (params) => dispatch(BrandAction.brandDetails(params));
    const changeStatus = (params) => dispatch(BrandAction.changeStatus(params));
    const brandCategoryDetails = (params) => dispatch(BrandCategoryAction.brandCategoryDetails(params));
    const changeBrandWhatsApp = (params) => dispatch(BrandAction.changeBrandWhatsApp(params));
    const changeBrandSms = (params) => dispatch(BrandAction.changeBrandSms(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [showWhatsAppUpdateModal, setShowWhatsAppUpdateModal] = useState(false);
    const [showSmsUpdateModal, setShowSmsUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [whatsAppData, setWhatsAppData] = useState({ id: '', whatsApp: '', item: '' });
    const [smsData, setSmsData] = useState({ id: '', sms: '', item: '' });
    const [showCreateWalletModal, setShowCreateWalletModal] = useState(false);
    const [showDeleteBrandModal, setShowDeleteBrandModal] = useState(false);
    const [showBrandChangePasswordModal, setShowBrandChangePasswordModal] = useState(false);

    useEffect(() => {
        brandDetails({ brand_id: brandId }).then((result) => {
            if (result.status === 1) {
                dispatch({ type: 'BRAND_WALLET_TOTAL', data: (result && result.data && result.data.wallet_current_balance )? result.data.wallet_current_balance : 0 });
            }
        });

        //eslint-disable-next-line
    }, [brandId])

    useEffect(() => {
		if (brand_details.brand_category) {
			brandCategoryDetails({ brand_id: brand_details.brand_category });
		}

		//eslint-disable-next-line
	}, [brand_details])

    /**
    * function to open/close status Update modal
    * @return view
    * @author
    * @created_at 14 June 2022
    */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update Brand
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'brand_id': id });
    }

    /**
     * function to open/close whats app Update modal
     * @return view
     * @author Naveen
     * @created_at 13 June 2024
     */
    const whatsAppUpdateModal = ({ id, whatsApp, item }) => {
        setWhatsAppData({ id: id, whatsApp: whatsApp, item: '' });
        setShowWhatsAppUpdateModal(!showWhatsAppUpdateModal);
    }

    /**
     * function to update whats app Brand
     * @return view
     * @author
     * @created_at 13 June 2024
     */
    const updateWhatsAppAction = async (id) => {
        setShowWhatsAppUpdateModal(!showWhatsAppUpdateModal);
        await changeBrandWhatsApp({ 'brand_id': id });
    }

    /**
     * function to open/close sms Update modal
     * @return view
     * @author Naveen
     * @created_at 19 June 2024
     */
    const smsUpdateModal = ({ id, sms, item }) => {
        setSmsData({ id: id, sms: sms, item: '' });
        setShowSmsUpdateModal(!showSmsUpdateModal);
    }

    /**
     * function to update whats app Brand
     * @return view
     * @author
     * @created_at 19 June 2024
     */
    const updateSmsAction = async (id) => {
        setShowSmsUpdateModal(!showSmsUpdateModal);
        await changeBrandSms({ 'brand_id': id });
    }

    /**
     * function to close plans update modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const closeModal = () => {
        setShowCreateWalletModal(!showCreateWalletModal);
    }

    /**
     * function to create wallet
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const createWalletModal = () => {
        setShowCreateWalletModal(!showCreateWalletModal);
    }

    /**
     * function to create wallet
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const deleteBrandModal = () => {
        setShowDeleteBrandModal(!showDeleteBrandModal);
    }

    /**
     * function to open/close Brand change password modal
     * @param {string} null
     * @return view
     * @author Naveen
     * @created_at 10 July 2023
     */
    const brandChangePasswordModal = () => {
        setShowBrandChangePasswordModal(!showBrandChangePasswordModal);
    }


    function BrandDetailTabPanel() {
        return (
            <React.Fragment>
                <CardHeader action={
                    <Stack spacing={1} direction="row">
                        <GeneralButton label={'Add Money To Wallet'} onClick={createWalletModal}/>
                        <GeneralButton label={'Change Password'} onClick={brandChangePasswordModal}/>
                        <AbilityCan I='admin_delete_brand'>
                            <GeneralButton label={'Delete Brand'} onClick={deleteBrandModal}/>
                        </AbilityCan>
                    </Stack>
                } />
                <BrandInfluencerWelcomeMessage />
                {
                    (!isLoading && Object.keys(brand_details).length > 0) &&
                    <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                        <Box sx={{ maxWidth: `1000px` }}>
                            {!isLoading && <BrandCard
                                brand={brand_details}
                                redirectUrl={`${PostAuthRoutes('brand_details').path}?brandId=${brand_details._id}`}
                                actions={{ detail: false, update: true, change_status: true, change_whats_app: true, change_sms: true }} statusUpdateModal={statusUpdateModal} whatsAppUpdateModal={whatsAppUpdateModal} smsUpdateModal={smsUpdateModal} height="80vh"
                            />}
                            {isLoading && <BrandCardLoader count={1} />}
                        </Box>
                    </Box>
                }
                {(isLoading) && (
                    <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                        <Box sx={{ maxWidth: `1000px` }}>
                            <BrandCardLoader count={1} />
                        </Box>
                    </Box>
                )}
                <ChangeBrandPassword show={showBrandChangePasswordModal} closeModal={brandChangePasswordModal} />
                <CreateWallet show={showCreateWalletModal} closeModal={closeModal} currency={brand_details.currency}/>

            </React.Fragment>
        )
    }


    return (
        <React.Fragment>
            <PageHeader title={PostAuthRoutes('brand_details').name}/>
            {!isLoading && Object.keys(brand_details).length === 0 && <NoData content1={`No`} content2={`Brands available`} minHeight={`55vh`} ></NoData>}
            <Tabs
                groupName={'bdTab'}
                data={[
                    { slug: "brand", label: "Brand", component: <BrandDetailTabPanel /> },
                    { slug: "promos", label: "Promos", component: <BrandPromos /> },
                    { slug: "wallet", label: "Wallet", component: <BrandWallet balance={brand_details.wallet_current_balance} currency={brand_details.currency} /> },
                    (brand_details.brand_category && brand_category_details.brand_category === 'Restaurant') ? { slug: 'brand_menu', label: 'Menus', component: <BrandMenu /> } : '',
                ]}
            />
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
            <DeleteBrand show={showDeleteBrandModal} closeModal={deleteBrandModal} brand_details={brand_details}/>
            <UpdateWhatsAppModal show={showWhatsAppUpdateModal} closeModal={whatsAppUpdateModal} data={whatsAppData} updateWhatsApp={updateWhatsAppAction} />
            <UpdateSmsModal show={showSmsUpdateModal} closeModal={smsUpdateModal} data={smsData} updateSms={updateSmsAction} />

        </React.Fragment>
    );
}

export { BrandDetails }