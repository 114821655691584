module.exports = {
    /**
     * function to validate the giveaway create or update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 28 May 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter your giveaway name.';
                break;
            } case 'description': {
                errors.description = '';
                if (value === '')
                    errors.description = 'Enter description for the giveaway.';
                break;
            } case 'sample_story': {
                errors.sample_story = '';
                if (value === '' || value === [] || typeof value === 'undefined') {
                    errors.sample_story = 'Select giveaway sample story. ';
                } else if (typeof value !== 'string') {
                    let extension = (value.name).split('.').pop();
                    extension = extension.toLowerCase();
                    let size = value.size;

                    if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                        errors.sample_story = 'Please choose a valid file format.';
                    } else if (size >= 2000000) {
                        errors.sample_story = 'Selected giveaway sample story size exceeded.File size must be below 2 MB. ';
                    }
                }
                break;
            } case 'giveaway_date': {
                errors.giveaway_date = '';
                if (!value || value.length === 0)
                    errors.giveaway_date = 'Select giveaway date.';
                break;
            } case 'thank_you_message': {
                errors.thank_you_message = '';
                if (value === '')
                    errors.thank_you_message = 'Enter thank you message';
                break;
            } case 'send_coupon_code': {
                errors.send_coupon_code = '';
                if (value === '')
                    errors.send_coupon_code = 'Select whether you want to send a coupon code or not.';
                break;
            } case 'coupon_file': {
                errors.coupon_file = '';
                if (data.send_coupon_code === 'yes') {
                    if (value === '' || value === [] || typeof value === 'undefined') {
                        errors.coupon_file = 'Select giveaway coupon code csv. ';
                    } else if (typeof value !== 'string') {
                        let extension = (value.name).split('.').pop();
                        extension = extension.toLowerCase();
                        let size = value.size;
                        if (extension !== 'csv') {
                            errors.coupon_file = 'Please choose a valid file format.';
                        } else if (size >= 2000000) {
                            errors.coupon_file = 'Selected coupon code csv file size exceeded. ';
                        }
                    }
                }
                break;
            } case 'brand_id': {
                errors.brand_id = '';
                if (value === '')
                    errors.brand_id = 'Select brand.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}