import React from 'react'
import { Alert as MuiAlert } from '@mui/material'
import { styled } from '@mui/material/styles';

const StyledAlert = styled(({ ...props }) => (<MuiAlert {...props} />))`
        marginBottom: ${props => props.theme.spacing(1)};
        & .MuiPaper-root {
            display: flex;
            alignItems: center;
            justifyContent: center;
        }
`;

function Alert({ message, type = "error", variant = "filled", ...props }) {

    if (!message) {
        return '';
    }

    return (
        <StyledAlert severity={type} variant={variant} {...props}>{message}</StyledAlert>
    )
}

export { Alert }
