import { TestimonialServices } from "../services";
import { GlobalAction } from ".";

export const TESTIMONIAL_REQUEST = 'TESTIMONIAL_REQUEST';
export const TESTIMONIAL_SUCCESS = 'TESTIMONIAL_SUCCESS';
export const TESTIMONIAL_FAILURE = 'TESTIMONIAL_FAILURE';

export const TESTIMONIAL_CREATE_REQUEST = 'TESTIMONIAL_CREATE_REQUEST';
export const TESTIMONIAL_CREATE_SUCCESS = 'TESTIMONIAL_CREATE_SUCCESS';
export const TESTIMONIAL_CREATE_FAILURE = 'TESTIMONIAL_CREATE_FAILURE';

export const TestimonialAction = {

    /**
     * Action for list testimonials
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 15 June 2022 
     */
    listTestimonial: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TestimonialServices.listTestimonial(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: TESTIMONIAL_REQUEST, request: request } }
        function success(request, response) { return { type: TESTIMONIAL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: TESTIMONIAL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating Testimonial
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    createTestimonial: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TestimonialServices.createTestimonial(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: TESTIMONIAL_CREATE_REQUEST, request: request } }
        function success(request, response) { return { type: TESTIMONIAL_CREATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: TESTIMONIAL_CREATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

}
