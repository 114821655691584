import { Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { PreAuthMainComponents, PrivateRoute } from './routes';
import './App.scss';
import { GlobalAction } from './redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppNameContext } from './_contexts';
import { PageLoader } from './_layouts/loader/page-loader/PageLoader';
import { InitialLoader } from './_layouts/loader/initial-loader/InitialLoader';
import { Toast } from './_layouts/toast';
import { useEffect, useMemo, useState } from 'react';
import { PostSignIn } from './_layouts/post-signin';
import { APP_NAME } from './_helpers';
import { ThemeProvider } from '@mui/system';
import themeLight from './_theme/theme-light'
import themeDark from './_theme/theme-dark'
import { CssBaseline, useMediaQuery } from '@mui/material';
import { NotFound } from './_components/layout/not-found';

function App() {
	const menuRoutes = Object.values(PreAuthMainComponents());

	const dispatch = useDispatch();
	const { pageLoader, initialLoader, toastMessage: toast, theme } = useSelector((state) => state.GlobalReducer);
	const [isDarkMode, setIsDarkMode] = useState(false)

	useEffect(() => {
		dispatch(GlobalAction.initialLoader(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');


	useMemo(() => setIsDarkMode(theme === 'dark' ? true : ((theme === 'light') ? (false) : prefersDarkMode)), [prefersDarkMode, theme]);


	return (
		<AppNameContext.Provider value={APP_NAME}>
			<HelmetProvider>
				<ThemeProvider theme={isDarkMode ? themeDark : themeLight}>
					<CssBaseline />
					<Switch>

						{menuRoutes.map((route, index) => (
							<Route exact key={index} path={route.path} component={route.component} />
						))}

						<PrivateRoute path='/' component={PostSignIn}></PrivateRoute>
						<Route component={NotFound} />
					</Switch>
					<PageLoader loader={pageLoader} />
					<InitialLoader loader={initialLoader} />
					<Toast message={toast} />
				</ThemeProvider>
			</HelmetProvider>
		</AppNameContext.Provider>
	);
}

export { App };
