import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import { FaqAction } from "../../../redux/actions";
import { Box, Grid, Stack } from '@mui/material';
import { SortFaqCard } from './sort-faq-card';
import { CardHeader } from '../../../_components/card/card-header/CardHeader';
import { PostAuthRoutes } from '../../../routes';
import { NoData } from '../../../_components/layout/no-data';
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory } from 'react-router-dom';

function SortFaq() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { faq_loading: isLoading, faq } = useSelector((state) => state.FaqReducer);
    const listFaq = (params) => dispatch(FaqAction.listFaq(params));
    const sortFaq = (params) => dispatch(FaqAction.sortFaq(params));

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ limit: 100, page: 1 });
    const [sortOrder, setSortOrder] = useState(faq);

    useEffect(() => {
        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to list FAQ
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 13 Jan 2023
     */
    const getNext = async () => {
        const result = await listFaq(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(sortOrder);
        const [reOrderedItems] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reOrderedItems);

        setSortOrder(items);
    }

    /**
     * function to handle sort submit action
     * @author Naveen
     * @created_at 16 Jan 2023
     */
    function handleSortSubmit() {
        let sortData = [];
        // eslint-disable-next-line array-callback-return
        sortOrder.map((element, index) => {
            sortData.push({
                "faq_id": element._id,
                "oldSortIndex": element.sort_index,
                "newSortIndex": index,
            })
        });

        addSortList(sortData);
    }

    /**
     * function to add sort FAQ
     * @author Naveen
     * @created_at 16 Jan 2023
     */
    async function addSortList(sortData) {
        const isCreated = await sortFaq({ sortData: sortData })
        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('faq').path;
            history.push(redirectPath);
        }
    }

    /**
     * function to cancel sort operation
     * @param Null
     * @return view
     * @author Naveen
     * @created_at 16 Jan 2023
     */
    function cancelSort() {
        let redirectPath = PostAuthRoutes('faq').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Box>
                    {faq.length > 0 && <CardHeader title={PostAuthRoutes('sort_faq').name} />}
                    <InfiniteScroll dataLength={faq.length} next={getNext} hasMore={true}>
                        <Droppable droppableId="tbody">
                            {(provided) => (
                                <Grid container spacing={1} ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        sortOrder.length > 0 && sortOrder.map((faqs, i) => {
                                            return (
                                                <Draggable draggableId={faqs._id} index={i} key={i}>
                                                    {(provided) => (
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            {/* <div draggable> */}
                                                            <SortFaqCard faqs={faqs} />
                                                            {/* </div> */}
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                    {provided.placeholder}
                                    {!isLoading && faq.length === 0 && <NoData content1={`No`} content2={`FAQ available`} minHeight={`55vh`} description={`Stay tuned! All FAQ will show up here.`} ></NoData>}
                                </Grid>
                            )}
                        </Droppable>
                    </InfiniteScroll>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} paddingTop={2}>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                            <SubmitButton label='Sort' loading={isLoading} onClick={handleSortSubmit} tabIndex={6} />
                            <CancelButton onClick={cancelSort} tabIndex={12} />
                        </Stack>
                    </Grid>
                </Box>
            </DragDropContext>
        </React.Fragment>
    )
}

export { SortFaq };