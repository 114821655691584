import { apiServices } from '../../_helpers';

export const ContactUsServices = {

    /**
     * Service for List Contacts
     * @param {Object} params - The params which are used for the api
     * @author Sarath
     * @created_at 10 June 2022
     */

    listContactUs: (params) => {
        return apiServices.post('admin/contact-us/list', params)
            .then((response) => { return response; })
    },   

    /**
     * Service for change contact us status
     * @param {Object} params - The params which are used for the api
     * @author Sarath
     * @created_at 15 June 2022
     */

    changeStatus: (params) => {
        return apiServices.put('admin/contact-us/change-status', params)
            .then((response) => { return response; })
    },

    /**
     * Service for get contact us details
     * @param {Object} params - The params which are used for the api
     * @author Sarath 
     * @created_at 07 July 2022
     */
    contactUsDetails: (params) => {
        return apiServices.post('admin/contact-us/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service to reply
     * @param {Object} params - The params which are used for the api
     * @author Sarath 
     * @created_at 07 July 2022
     */
    addReply: (params) => {
        return apiServices.put('admin/contact-us/reply/add', params)
            .then((response) => { return response; })
    },
    
}
