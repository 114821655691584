import React, { useEffect } from "react";
import { PromoAction } from "../../../redux/actions";
import { CardHeader as PageHeader } from "../../../_components/card";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PostAuthRoutes } from "../../../routes";
import { PromoCard, PromoCardLoader } from "../promo-card";
import { Box } from "@mui/material";
import { NoData } from "../../../_components/layout/no-data";
import { Tabs } from "../../../_components/tabs";
import { PromoStories } from "../promo-stories";
import { PromosCouponCodes } from "../promos-coupon-codes";
import { PromosPosterDetails } from "../promos-poster-details";

function PromoDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const promoId = params.get('pId'); // plan promo from url

    /** Redux actions and state */
    const { promo_details_loading: isLoading, promo_details } = useSelector((state) => state.PromoReducer);
    const promoDetails = (params) => dispatch(PromoAction.promoDetails(params));
    const getRunningPromos = (params) => dispatch(PromoAction.getRunningPromos(params));

    useEffect(() => {
        promoDetails({ promo_id: promoId });

        //eslint-disable-next-line
    }, [promoId])

    useEffect(() => {

        getRunningPromos() // Initially load participants list

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function PromoDetailTabPanel() {
        return (
            <React.Fragment>
                {(!isLoading && Object.keys(promo_details).length > 0) &&
                    <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                        <Box sx={{ maxWidth: `1000px` }}>
                            <PromoCard promo={promo_details} actions={{ detail: false }} />
                        </Box>
                    </Box>
                }
                {(isLoading) &&
                    <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                        <Box sx={{ maxWidth: `1000px` }}>
                            <PromoCardLoader count={1} />
                        </Box>
                    </Box>
                }
            </React.Fragment>


        )
    }

    return (
        <React.Fragment>
            <PageHeader title={PostAuthRoutes('promo_details').name} />
            {!isLoading && Object.keys(promo_details).length === 0 && <NoData content1={`No`} content2={`Promo available`} minHeight={`55vh`} ></NoData>}
            <Tabs
                groupName={'pdTab'}
                data={[
                    { slug: "promo", label: "Promo", component: <PromoDetailTabPanel /> },
                    { slug: "stories", label: "Stories", component: <PromoStories /> },
                    { slug: "coupons", label: "Coupon Codes", component: <PromosCouponCodes /> },
                    { slug: "poster", label: "Poster", component: <PromosPosterDetails /> }
                ]}
            />
        </React.Fragment>
    );
}

export { PromoDetails }