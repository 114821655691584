import {
    SEND_PWD_RST_LINK_REQUEST, SEND_PWD_RST_LINK_SUCCESS, SEND_PWD_RST_LINK_FAILURE,
    RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE
} from "../actions";

const initialState = { send_pwd_rst_link_loading: false, send_pwd_rst_link_request: {}, send_pwd_rst_link: {}, send_pwd_rst_link_errors: {},
reset_password_loading: false, reset_password_errors: {}};

export function ForgotPasswordReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Send password reset link in redux */
        case SEND_PWD_RST_LINK_REQUEST: {
            return {
                send_pwd_rst_link_loading: true,
                send_pwd_rst_link_request: action.request,
                send_pwd_rst_link: {}
            };
        }
        case SEND_PWD_RST_LINK_SUCCESS: {
            return {
                send_pwd_rst_link_loading: false,
                send_pwd_rst_link: action.data,
            };
        }
        case SEND_PWD_RST_LINK_FAILURE: {
            return {
                send_pwd_rst_link_loading: false,
                send_pwd_rst_link: {},
                send_pwd_rst_link_errors: action.errors
            };
        }
        /** end::Send password reset link in redux */

        /** begin::Reset password redux */
        case RESET_PASSWORD_REQUEST: {
            return {
                reset_password_loading: true,
            };
        }
        case RESET_PASSWORD_SUCCESS: {
            return {
                reset_password_loading: false,
            };
        }
        case RESET_PASSWORD_FAILURE: {
            return {
                reset_password_loading: false,
                reset_password_errors: action.errors
            };
        }
        /** end::Reset password redux */

        default:
            return state;
    }
}
