import { DashboardServices } from "../services";

export const INFLUENCER_COUNT_REQUEST = 'INFLUENCER_COUNT_REQUEST';
export const INFLUENCER_COUNT_SUCCESS = 'INFLUENCER_COUNT_SUCCESS';
export const INFLUENCER_COUNT_FAILURE = 'INFLUENCER_COUNT_FAILURE';

export const BRAND_COUNT_REQUEST = 'BRAND_COUNT_REQUEST';
export const BRAND_COUNT_SUCCESS = 'BRAND_COUNT_SUCCESS';
export const BRAND_COUNT_FAILURE = 'BRAND_COUNT_FAILURE';

export const PROMO_COUNT_REQUEST = 'PROMO_COUNT_REQUEST';
export const PROMO_COUNT_SUCCESS = 'PROMO_COUNT_SUCCESS';
export const PROMO_COUNT_FAILURE = 'PROMO_COUNT_FAILURE';

export const STORY_COUNT_REQUEST = 'STORY_COUNT_REQUEST';
export const STORY_COUNT_SUCCESS = 'STORY_COUNT_SUCCESS';
export const STORY_COUNT_FAILURE = 'STORY_COUNT_FAILURE';

export const TOP_INFLUENCERS_BY_FOLLOWERS_REQUEST = 'TOP_INFLUENCERS_BY_FOLLOWERS_REQUEST';
export const TOP_INFLUENCERS_BY_FOLLOWERS_SUCCESS = 'TOP_INFLUENCERS_BY_FOLLOWERS_SUCCESS';
export const TOP_INFLUENCERS_BY_FOLLOWERS_FAILURE = 'TOP_INFLUENCERS_BY_FOLLOWERS_FAILURE';

export const TOP_INFLUENCERS_BY_STORIES_REQUEST = 'TOP_INFLUENCERS_BY_STORIES_REQUEST';
export const TOP_INFLUENCERS_BY_STORIES_SUCCESS = 'TOP_INFLUENCERS_BY_STORIES_SUCCESS';
export const TOP_INFLUENCERS_BY_STORIES_FAILURE = 'TOP_INFLUENCERS_BY_STORIES_FAILURE';

export const TOP_BRANDS_BY_FOLLOWERS_REQUEST = 'TOP_BRANDS_BY_FOLLOWERS_REQUEST';
export const TOP_BRANDS_BY_FOLLOWERS_SUCCESS = 'TOP_BRANDS_BY_FOLLOWERS_SUCCESS';
export const TOP_BRANDS_BY_FOLLOWERS_FAILURE = 'TOP_BRANDS_BY_FOLLOWERS_FAILURE';

export const TOP_BRANDS_BY_STORIES_REQUEST = 'TOP_BRANDS_BY_STORIES_REQUEST';
export const TOP_BRANDS_BY_STORIES_SUCCESS = 'TOP_BRANDS_BY_STORIES_SUCCESS';
export const TOP_BRANDS_BY_STORIES_FAILURE = 'TOP_BRANDS_BY_STORIES_FAILURE';


export const DashboardAction = {
    /**
     * Action for influencer count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    influencerCount: (params) => {

        return dispatch => {
            dispatch(request(params));
            return DashboardServices.influencerCount(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: INFLUENCER_COUNT_REQUEST, request: request } }
        function success(request, response) { return { type: INFLUENCER_COUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INFLUENCER_COUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Brand count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    brandCount: (params) => {

        return dispatch => {
            dispatch(request(params));
            return DashboardServices.brandCount(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: BRAND_COUNT_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_COUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_COUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Promo count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    promoCount: (params) => {

        return dispatch => {
            dispatch(request(params));
            return DashboardServices.promoCount(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMO_COUNT_REQUEST, request: request } }
        function success(request, response) { return { type: PROMO_COUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMO_COUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Story count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    storyCount: (params) => {

        return dispatch => {
            dispatch(request(params));
            return DashboardServices.storyCount(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: STORY_COUNT_REQUEST, request: request } }
        function success(request, response) { return { type: STORY_COUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORY_COUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action to get top influencers by followers count
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
     getTopInfluencersByFollowers: (params) => {
        return dispatch => {
            dispatch(request(params));
            return DashboardServices.getTopInfluencersByFollowers(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: TOP_INFLUENCERS_BY_FOLLOWERS_REQUEST, request: request } }
        function success(request, response) { return { type: TOP_INFLUENCERS_BY_FOLLOWERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: TOP_INFLUENCERS_BY_FOLLOWERS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get top influencers by stories count
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    getTopInfluencersByStories: (params) => {
        return dispatch => {
            dispatch(request(params));
            return DashboardServices.getTopInfluencersByStories(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: TOP_INFLUENCERS_BY_STORIES_REQUEST, request: request } }
        function success(request, response) { return { type: TOP_INFLUENCERS_BY_STORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: TOP_INFLUENCERS_BY_STORIES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get top brands by followers count
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    getTopBrandsByFollowers: (params) => {
        return dispatch => {
            dispatch(request(params));
            return DashboardServices.getTopBrandsByFollowers(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error =>  { return  dispatch(failure(request, error)); }
                );
        };
        
        function request(request) {  return { type: TOP_BRANDS_BY_FOLLOWERS_REQUEST, request: request }}
        function success(request, response) { return { type: TOP_BRANDS_BY_FOLLOWERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }}
        function failure(request, error) { return { type: TOP_BRANDS_BY_FOLLOWERS_FAILURE, request: request, errors: error.errors, message: error.message, data: error.data, status: error.status,status_code: error.status_code }};
    },

    /**
     * Action to get top brands by stories count
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    getTopBrandsByStories: (params) => {
        return dispatch => {
            dispatch(request(params));
            return DashboardServices.getTopBrandsByStories(params)
            .then(
                response => { return dispatch(success(request, response)); },
                error => { return dispatch(failure(request, error)); }
            );
        };
        function request(request) { return { type: TOP_BRANDS_BY_STORIES_REQUEST, request: request }}
        function success(request, response) { return { type: TOP_BRANDS_BY_STORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code}}
        function failure(request, error) { return { type: TOP_BRANDS_BY_STORIES_FAILURE, request: request, message: error.message, errors: error.errors, data: error.data, status: error.status, status_code: error.status_code}}
    }
}