import { GlobalAction } from ".";
import { WalletServices } from "../services";

export const CREATE_WALLET_REQUEST = 'CREATE_WALLET_REQUEST';
export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';
export const CREATE_WALLET_FAILURE = 'CREATE_WALLET_FAILURE';

export const WALLET_HISTORY_REQUEST = 'WALLET_HISTORY_REQUEST';
export const WALLET_HISTORY_SUCCESS = 'WALLET_HISTORY_SUCCESS';
export const WALLET_HISTORY_FAILURE = 'WALLET_HISTORY_FAILURE';

export const WALLET_TOTAL_REQUEST = 'WALLET_TOTAL_REQUEST';
export const WALLET_TOTAL_SUCCESS = 'WALLET_TOTAL_SUCCESS';
export const WALLET_TOTAL_FAILURE = 'WALLET_TOTAL_FAILURE';

export const WalletAction = {

    /**
     * Action for list plans
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    createBrandWallet: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.createBrandWallet(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };
        function request(request) { return { type: CREATE_WALLET_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_WALLET_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_WALLET_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list wallet history
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 09 May 2023
     */
    listWalletHistory: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.listWalletHistory(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: WALLET_HISTORY_REQUEST, request: request } }
        function success(request, response) { return { type: WALLET_HISTORY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: WALLET_HISTORY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for total month in wallet
     * @author Naveen
     * @created_at 10 May 2023
     */
    totalWallet: () => {
        return dispatch => {
            dispatch(request());
            return WalletServices.totalWallet()
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: WALLET_TOTAL_REQUEST, request: request } }
        function success(request, response) { return { type: WALLET_TOTAL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: WALLET_TOTAL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}
