import React, { useEffect } from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardAction } from '../../../redux/actions';


function TopBrandsByFollowers() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { top_brands_by_followers } = useSelector((state) => state.DashboardReducer);
    const getTopBrandsByFollowers = (params) => dispatch(DashboardAction.getTopBrandsByFollowers(params));

    const options = {

        chart: {
            type: 'column',
            renderTo: 'container',
            backgroundColor: 'rgba(0,0,0,0)',
            style: {
                fontFamily: 'unset'
            }
        },
        title: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Followers'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.0f}'
                }
            }
        },

        tooltip: {
            formatter: function () {
                return '<span style="font-size:11px">' + this.series.name + ':' + this.point.brand_name + '</span> <br><span style="color: ' + this.point.color + '">' + this.point.name + '</span>: <b>' + this.point.y + '<b/> followers </br>'
            }
        },

        series: [
            {
                name: "Brand",
                colorByPoint: true,
                data: top_brands_by_followers
            }
        ],
        credits: {
            enabled: false
        }
    };

    useEffect(() => {
        getTopBrandsByFollowers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Card>
            <CardHeader title="Brands with most followers" />
            <CardContent>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </CardContent>
        </Card>
    )
}

export { TopBrandsByFollowers }
