import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormSelect, FormDateRangePicker } from "../../../_components/form";
import { ConfzModal, ConfzModalBody, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from "../../../_components/modal";
import { handleInputChange } from "../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { BrandAction } from "../../../redux/actions";
import { InfluencerAction } from "../../../redux/actions";

// Initialize form input values to null
const inputs = { status: '', startDate: '', endDate: '', date: '', influencer: '', brand: '' };

// Declare filter status select options
const filterStatus = [
    { value: 'done', label: 'Done' },
    { value: 'processing', label: 'Processing' }
]

function StoryFilter({ hideFilter = [], ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { brands_select_list } = useSelector((state) => state.BrandReducer);
    const brandSelectList = (params) => dispatch(BrandAction.brandSelectList(params));

    const { influencer_select_list } = useSelector((state) => state.InfluencerReducer);
    const influencerSelectList = (params) => dispatch(InfluencerAction.influencerSelectList(params));

    /** Initialize and declare state */
    const { show, closeModal, applyFilter, storyFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        brandSelectList();
        influencerSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setData({ status: storyFilter.status, startDate: storyFilter.startDate, endDate: storyFilter.endDate, date: storyFilter.date, influencer: storyFilter.influencer, brand: storyFilter.brand })

        let filtered = {};
        Object.keys(storyFilter).forEach(function (item, i) {
            if (storyFilter[item])
                filtered[item] = storyFilter[item];
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storyFilter])

    /**
     * function to close Story filter modal
     * @param {string} status Entered status on Story filter modal
     * @param {string} month Entered month on Story filter modal 
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const onClose = () => {
        setData({ status: '', startDate: '', endDate: '', date: '', influencer: '', brand: '' });
        closeModal(false);
    }

    /**
     * function to clear Story filter modal
     * @param {string} status Entered status on Story filter modal
     * @param {string} month Entered month on Story filter modal 
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const onClear = () => {
        setData({ status: '', startDate: '', endDate: '', date: '', influencer: '', brand: '' });
        applyFilter({ status: '', startDate: '', endDate: '', date: '', influencer: '', brand: '' });
        closeModal(false);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author 
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  
     * @created_at 14 June 2022 
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);

    }

    /**
     * function to clear all data entered on Story filter modal
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });
    }

    return (
        <React.Fragment>

            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={1}>
                                {
                                    // eslint-disable-next-line array-callback-return
                                    Object.keys(filteredData).map((item, i) => {
                                        if (!(hideFilter.length > 0 && (hideFilter.includes(item)))) {
                                            if (item === 'status') {
                                                const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                                return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />
                                            } else if (item === 'brand') {
                                                const brand = brands_select_list.find(function (brand) { return brand.value === filteredData[item]; });
                                                return <Chip key={i} label={brand.label} onDelete={() => clearFilterItem(item)} />
                                            } else if (item === 'influencer') {
                                                const influence = influencer_select_list.find(function (influence) { return influence.value === filteredData[item]; });
                                                return <Chip key={i} label={influence.label} onDelete={() => clearFilterItem(item)} />
                                            }
                                            return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />
                                        }
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }

            <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="storyFilter" onClose={onClose} title='Filter' />
                    <ConfzModalBody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    className="col-md-6"
                                    value={data.status}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label={`Brand`}
                                    name={`brand`}
                                    placeholder={`Select brand`}
                                    onChange={handleChange}
                                    data={brands_select_list}
                                    className="col-md-6"
                                    value={data.brand}
                                />
                            </Grid>
                            {!(hideFilter.length > 0 && (hideFilter.includes('influencer'))) &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormSelect
                                        tabIndex={1}
                                        label={`Influencer`}
                                        name={`influencer`}
                                        placeholder={`Select influencer`}
                                        onChange={handleChange}
                                        data={influencer_select_list}
                                        className="col-md-6"
                                        value={data.influencer}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormDateRangePicker
                                    tabIndex={1}
                                    label='Posted Between'
                                    name='date'
                                    value={data.date}
                                    placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                    <ConfzModalFooterSaveBtn tabIndex={4} text='Filter' />
                                    <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClear} text='Clear' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ConfzModalBody>
                </form>
            </ConfzModal>
        </React.Fragment>
    );

}

export { StoryFilter }