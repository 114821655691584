import { Box, Tab, Tabs as MuiTabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

function TabPanel({ ...props }) {

    const { children, value, index, groupName } = props;

    let indexInt = parseInt(index);

    return (
        <Box role="tabpanel" hidden={value !== indexInt} id={`${groupName}-tabpanel-${index}`} aria-labelledby={`${groupName}-tab-${index}`} pt={4}>
            {value === indexInt && (<Box>{children}</Box>)}
        </Box>
    );

}


function Tabs({ groupName, onChange, data, ...props }) {

    const { search } = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(search);

    const [value, setValue] = useState(searchParams.get(groupName) ? parseInt(searchParams.get(groupName)) : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    useEffect(() => {
        const queryParam = {}

        for (var key of searchParams.keys()) {
            const element = searchParams.get(key);

            if (element === "" || element === null) {
                delete queryParam[key];
            }
            else {
                queryParam[key] = element;
            }
        }
        delete queryParam[groupName];

        if (value)
            queryParam[groupName] = data[value].slug;

        history.push({ search: queryString.stringify(queryParam) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <MuiTabs value={value} onChange={handleChange} aria-label="basic tabs example"  >
                    {data.map((tabData, index) => {
                        return <Tab label={tabData.label} id={`${groupName}-tab-${index}`} aria-controls={`${groupName}-tabpanel-${index}`} key={index} disabled={tabData.disabled}/>
                    })}
                </MuiTabs>
            </Box>
            {data.map((tabData, index) => {
                return (
                    <TabPanel value={value} key={index} index={`${index}`} groupName={groupName}>
                        {tabData.component}
                    </TabPanel>
                )
            })}
        </Box>
    )
}

export { Tabs }