import { apiServices } from '../../_helpers';


export const PermissionServices = {

    /**
     * Service to add permission
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    addPermission: (params) => {
        return apiServices.post('/admin/permission/add', params)
            .then((response) => { return response; })
    },

    /**
     * Service to update permission
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    updatePermission: (params) => {
        return apiServices.put('/admin/permission/update', params)
            .then((response) => { return response; })
    },

    /**
     * Service to change permission status
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    changePermissionStatus: (params) => {
        return apiServices.put('/admin/permission/change-status', params)
            .then((response) => { return response; })
    },

    /**
     * Service for permission select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
    */
    permissionSelectList: (params) => {
        return apiServices.post('admin/permission/permission-select-list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for permissions list
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
    */
    getPermissionsList: (params) => {
        return apiServices.post('admin/permission/list', params)
            .then((response) => { return response; })
    },

    /**
	 * Service for brand category select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 17 Jan 2024
	 */
	brandCategorySelectList: (params) => {
		return apiServices.post('admin/permission/brand-category-select-list', params).then((response) => {
			return response;
		});
	},
};
