import { FilterAlt } from '@mui/icons-material'
import { Box, Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PostAuthRoutes } from '../../routes'
import { CardHeader, Card, CardContent } from '../../_components/card'
import { FormSearchInput } from '../../_components/form'
import { GeneralButton } from '../../_components/controls'
import { ApprovalsDataTable } from './'
import { ApprovalsFilter } from './approvals-filter'
import { AbilityCan } from '../../_helpers/permission/AbilityCan'

function Approvals() {

    /** Initialize plugins and variables */
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [showApprovalsFilterModal, setShowApprovalsFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: {
            status: searchParams.get('status') ?? '',
            filter_brand: searchParams.get('filter_brand') ?? '',
            filter_month: searchParams.get('filter_month') ?? '',
            requested_by: 'brand'
        },
        search: searchParams.get('q') ?? ''
    });

    /**
     * function to open/close Approval filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const approvalsFilterModal = () => {
        setShowApprovalsFilterModal(!showApprovalsFilterModal);
    }

    /**
     * function to apply the selected filter options on the approval list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the approval filter modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the approval list
     * @param {String} text Search keyword used
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('approvals').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='admin_approval_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='admin_approval_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment><FilterAlt /></React.Fragment>} onClick={() => approvalsFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <ApprovalsFilter show={showApprovalsFilterModal} closeModal={approvalsFilterModal} applyFilter={applyFilter} approvalsFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <ApprovalsDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </React.Fragment>

    )
}

export { Approvals }