import React, { useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { FilterAlt } from '@mui/icons-material'
import { GeneralButton } from '../../_components/controls'
import { CardHeader, Card, CardContent } from '../../_components/card'
import { FormSearchInput } from '../../_components/form'
import { PostAuthRoutes } from '../../routes'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { EmailLogsDataTable } from './'
import { EmailLogFilter } from './email-log-filter'
import { AbilityCan } from '../../_helpers/permission/AbilityCan'

function EmailLogs() {

    /** Initialize plugins and variables */
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [showEmailLogFilterModal, setShowEmailLogFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { status: searchParams.get('status') ?? '' },
        search: searchParams.get('q') ?? ''
    });

    /**
     * function to open/close EmailLog filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const emailLogFilterModal = () => {
        setShowEmailLogFilterModal(!showEmailLogFilterModal);
    }

    /**
     * function to apply the selected filter options on the emailLog list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the emailLog filter modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the emailLog list
     * @param {String} text Search keyword used
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('email_log').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='admin_email_log_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='admin_email_log_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => emailLogFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <EmailLogFilter show={showEmailLogFilterModal} closeModal={emailLogFilterModal} applyFilter={applyFilter} emailLogFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <EmailLogsDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </React.Fragment>

    )
}

export { EmailLogs }