/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import React from "react";
import { Card } from "../card";
import Box from "../box";
import Typography from "../typography";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components

function StatisticsWidget({ color, title, count, percentage = null, icon }) {
    return (
        <Card sx={{ boxShadow: 1 }}>
            <Box display="flex" justifyContent="space-between" pt={1} px={2} pb={percentage ? 0 : 2}>
                <Box
                    variant="gradient"
                    bgColor={color}
                    color={color === "light" ? "dark" : "white"}
                    coloredShadow={color}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3}
                >
                    <Icon fontSize="medium" color="inherit" style={{ width: `1.7rem`, height: `1.7rem` }}>
                        {icon}
                    </Icon>
                </Box>
                <Box textAlign="right" lineHeight={1.25}>
                    <Typography variant="button" fontWeight="light" color="text">
                        {title}
                    </Typography>
                    <Typography variant="h4" color="text">{count}</Typography>
                </Box>
            </Box>
            {percentage &&
                <React.Fragment>
                    <Divider />
                    <Box pb={2} px={2}>
                        <Typography component="p" variant="button" color="text" display="flex">
                            <Typography
                                component="span"
                                variant="button"
                                fontWeight="bold"
                                color={percentage.color}
                            >
                                {percentage.amount}
                            </Typography>
                            &nbsp;{percentage.label}
                        </Typography>
                    </Box>
                </React.Fragment>
            }
        </Card>
    );
}

// Setting default values for the props of StatisticsWidget
StatisticsWidget.defaultProps = {
    color: "info"
};

// Typechecking props for the StatisticsWidget
StatisticsWidget.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    title: PropTypes.string.isRequired,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    percentage: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "white",
        ]),
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    }),
    icon: PropTypes.node.isRequired,
};

export { StatisticsWidget };
