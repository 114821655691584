import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";
import { FormRadioStyle as useStyles } from '.'
import React from "react";

/**
 * Declare default value of input properties 
 */
const defaultProps = {
    type: 'text',
    isReadOnly: false,
    className: '',
};

const FormRadio = ({ name, label, data, className, value, onChange, isReadOnly, tabIndex, error, info, ...props }) => {

    const classes = useStyles();

    const handleChange = (e) => {
        onChange(e);
    }

    return (
        <FormControl fullWidth className={classes.formControl}  >
            <FormLabel htmlFor={name} className={classes.label}>{label}</FormLabel>
            <RadioGroup row aria-labelledby={label} name={name} value={value} onChange={handleChange}>
                {data.map((radio, index) => {
                    return <FormControlLabel key={index} value={radio.value} className={classes.inputLabel} disabled={isReadOnly} control={<Radio size="small" />} label={radio.label} />
                })}
            </RadioGroup>
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>
    );
};

FormRadio.defaultProps = defaultProps;

export { FormRadio };
