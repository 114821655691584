import { Box, Chip, Divider, Stack, Card, CardContent, Typography, Grid, CardMedia, CardHeader, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { ApprovalAction } from '../../../../redux/actions';
import { PostAuthRoutes } from '../../../../routes';
import { useHistory } from 'react-router';

function ApprovalActionDetailCard({ approval, ...props }) {

    /** Initialize and declare state */
    const { request_type, user_type, user, description, created_at, status } = approval;
    const requestId = props.requestId;

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();

    /** Redux actions and state */
    const { plan_approve_or_reject_loading: isLoading } = useSelector((state) => state.ApprovalReducer);
    const planUpdateApprove = (params) => dispatch(ApprovalAction.planUpdateApprove(params));
    const planUpdateReject = (params) => dispatch(ApprovalAction.planUpdateReject(params));

    /**
     * function to Approve
     * @return json
     * @author
     * @created_at 27 June 2022
     */
    async function updateApprove(type) {
        const isCreated = await planUpdateApprove({ request_id: requestId });
        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('approvals').path;
            history.push(redirectPath);
        }
    }

    /**
     * function to Reject
     * @return json
     * @author
     * @created_at 27 June 2022
     */
    async function updateReject(type) {
        const isCreated = await planUpdateReject({ request_id: requestId });
        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('approvals').path;
            history.push(redirectPath);
        }
    }

    return (
        <Card>
            <CardHeader action={
                <Box>
                    <Stack direction="row" display={`flex`} alignItems={`center`} justifyContent={`center`} spacing={1}>
                        <Chip size='small' label={status === 'approved' ? 'Approved' : (status === 'rejected' ? 'Rejected' : 'Requested')} color={status === 'approved' ? 'success' : (status === 'rejected' ? 'error' : 'info')} />
                    </Stack>
                </Box>
            }></CardHeader>
            {approval.user && <CardContent>
                <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '12px' }}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `200px` }}>
                            <CardMedia height={`100%`} component={"img"} src={user.insta_profile.profile_picture_url} onError={e => { e.target.src = user.insta_profile.profile_picture_url_error }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems={`center`} justifyContent={`center`}>
                        <Typography variant="h5" align='center' mb={1}>{`${user.insta_profile.name}`}</Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Stack spacing={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`${description}`}</Typography>
                    </Box>
                </Stack>
                <Divider />
                <Stack spacing={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Category : `}</Typography>
                        <Chip label={`${request_type}`} size="small" />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Requested By : `}</Typography>
                        <Chip label={`${user_type}`} size="small" />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Created On : `}</Typography>
                        <Chip label={`${created_at}`} size="small" />
                    </Box>
                </Stack>
                <Divider />
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    {status === 'requested' && <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                        <Button variant="contained" color={"primary"} tabIndex={1} onClick={() => updateApprove()} disabled={isLoading} >
                            Approve
                        </Button>
                        <Button variant="contained" color={"error"} tabIndex={2} disabled={isLoading} onClick={() => updateReject()}>
                            Reject
                        </Button>
                    </Stack>}
                </Grid>
            </CardContent>}
        </Card>
    )
}

export { ApprovalActionDetailCard }