import { BrandCategoryServices } from "../services";
import { GlobalAction } from "./GlobalAction";


export const BRAND_CATEGORY_REQUEST = 'BRAND_CATEGORY_REQUEST';
export const BRAND_CATEGORY_SUCCESS = 'BRAND_CATEGORY_SUCCESS';
export const BRAND_CATEGORY_FAILURE = 'BRAND_CATEGORY_FAILURE';

export const ADD_BRAND_CATEGORY_REQUEST = 'ADD_BRAND_CATEGORY_REQUEST';
export const ADD_BRAND_CATEGORY_SUCCESS = 'ADD_BRAND_CATEGORY_SUCCESS';
export const ADD_BRAND_CATEGORY_FAILURE = 'ADD_BRAND_CATEGORY_FAILURE';

export const UPDATE_BRAND_CATEGORY_REQUEST = 'UPDATE_BRAND_CATEGORY_REQUEST';
export const UPDATE_BRAND_CATEGORY_SUCCESS = 'UPDATE_BRAND_CATEGORY_SUCCESS';
export const UPDATE_BRAND_CATEGORY_FAILURE = 'UPDATE_BRAND_CATEGORY_FAILURE';

export const CHANGE_BRAND_CATEGORY_STATUS_REQUEST = 'CHANGE_BRAND_CATEGORY_STATUS_REQUEST';
export const CHANGE_BRAND_CATEGORY_STATUS_SUCCESS = 'CHANGE_BRAND_CATEGORY_STATUS_SUCCESS';
export const CHANGE_BRAND_CATEGORY_STATUS_FAILURE = 'CHANGE_BRAND_CATEGORY_STATUS_FAILURE';

export const BRAND_CATEGORY_SELECT_LIST_REQUEST = 'BRAND_CATEGORY_SELECT_LIST_REQUEST';
export const BRAND_CATEGORY_SELECT_LIST_SUCCESS = 'BRAND_CATEGORY_SELECT_LIST_SUCCESS';
export const BRAND_CATEGORY_SELECT_LIST_FAILURE = 'BRAND_CATEGORY_SELECT_LIST_FAILURE';

export const BRAND_CATEGORY_DETAILS_REQUEST = 'BRAND_CATEGORY_DETAILS_REQUEST';
export const BRAND_CATEGORY_DETAILS_SUCCESS = 'BRAND_CATEGORY_DETAILS_SUCCESS';
export const BRAND_CATEGORY_DETAILS_FAILURE = 'BRAND_CATEGORY_DETAILS_FAILURE';

export const BrandCategoryAction = {

    /**
     * Action for list brand Category
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */

    listBrandCategory: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCategoryServices.listBrandCategory(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: BRAND_CATEGORY_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_CATEGORY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_CATEGORY_FAILURE, request: request, data: error.data, message: error.message, errors: error.errors, status: error.status, status_code: error.status_code } }
    },

    /**
     * Action to add brand Category
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    addBrandCategory: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCategoryServices.addBrandCategory(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_BRAND_CATEGORY_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_BRAND_CATEGORY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_BRAND_CATEGORY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to update brand Category
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    updateBrandCategory: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCategoryServices.updateBrandCategory(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_BRAND_CATEGORY_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_BRAND_CATEGORY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_BRAND_CATEGORY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for change brand Category status
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCategoryServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_BRAND_CATEGORY_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_BRAND_CATEGORY_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_BRAND_CATEGORY_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

     /**
     * Action for brand category select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
     brandCategorySelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCategoryServices.brandCategorySelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_CATEGORY_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_CATEGORY_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_CATEGORY_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get brand category details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 26 Oct 2023
    */
    brandCategoryDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCategoryServices.brandCategoryDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_CATEGORY_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_CATEGORY_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_CATEGORY_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}