import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormSelect } from "../../../_components/form";
import { ConfzModal, ConfzModalBody, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from "../../../_components/modal";
import { handleInputChange } from "../../../_helpers";

// Initialize form input values to null
const inputs = { filter_status: '' };

// Declare filter status select options
const filterStatus = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
]

function TestimonialFilter({ ...props }) {

    /** Initialize and declare state */
    const { show, closeModal, applyFilter, testimonialFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    /**
     * function to close testimonial filter modal
     * @param {string} status Entered status on testimonial filter modal
     * @param {string} month Entered month on testimonial filter modal 
     * @return view
     * @author Naveen
     * @created_at 15 June 2022 
     */
    const onClose = () => {
        setData({ filter_status: '' });
        closeModal(false);
    }

    /**
    * function to clear testimonial filter modal
    * @param {string} status Entered status on testimonial filter modal
    * @param {string} month Entered month on testimonial filter modal 
    * @return view
    * @author  Naveen
    * @created_at 15 June 2022 
    */
    const onClear = () => {
        setData({ filter_status: '' });
        applyFilter({ filter_status: '' });
        closeModal(false);
    }

    useEffect(() => {

        setData({ filter_status: testimonialFilter.filter_status })

        let filtered = {};
        Object.keys(testimonialFilter).forEach(function (item, i) {
            if (testimonialFilter[item]) {
                filtered[item] = testimonialFilter[item];
                if (item === 'filter_status') {
                    let status = filterStatus.filter(function (obj) { return obj.value === testimonialFilter[item] })
                    filtered[item] = status[0].label
                }
                return filtered[item];
            }
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testimonialFilter])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 15 June 2022 
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Naveen
     * @created_at 15 June 2022 
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);
    }

    /**
     * function to clear all data entered on testimonial filter modal
     * @return view
     * @author Naveen
     * @created_at 15 June 2022 
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem })
    }

    return (

        <React.Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2}>
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <Stack direction='row' spacing={1} >
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        if (item === 'status') {
                                            const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                            return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />
                                        }
                                        return <Chip keys={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />

                                    })
                                }

                            </Stack>
                        </Grid>
                    </Grid>
                )
            }

            <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="testimonialFilter" onClose={onClose} title='Filter' />
                    <ConfzModalBody>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`filter_status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    className="col-md-6"
                                    value={data.filter_status}
                                />
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                    <ConfzModalFooterSaveBtn tabIndex={4} text='Filter' />
                                    <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClear} text='Clear' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ConfzModalBody>
                </form>
            </ConfzModal>
        </React.Fragment>

    )
}

export { TestimonialFilter }