import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from ".";
import { Card, CardMedia, Grid, Stack, Typography } from "@mui/material";
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn } from "../../../../_components/modal";
import { InfluencerAction } from "../../../../redux/actions";
import { FormInput } from "../../../../_components/form";
import { CardContent } from "../../../../_components/card";
import questinMarkSvg from './question-mark.svg'
import { PostAuthRoutes } from "../../../../routes";


function DeleteInfluencer({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    let history = useHistory();

    const params = new URLSearchParams(search);
    const influencerId = params.get('id'); // story id from url

    const inputs = { confirmation_text: '', influencer_id: influencerId };

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /** Initialize values from props */
    const { show, closeModal, influencer_details } = props;

    /** Redux actions and state */
    const { delete_influencer_profile_loading: isLoading } = useSelector((state) => state.InfluencerReducer);
    const deleteInfluencerProfile = (params) => dispatch(InfluencerAction.deleteInfluencerProfile(params));

    /**
     * function to close wallet model
     * @author
     * @created_at 08 Mar 2024
     */
    const onClose = () => {
        setData({ ...inputs });
        closeModal(false);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 08 Mar 2024
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author
     * @created_at 08 Mar 2024
     */
    function handleSubmit(e) {
        e.preventDefault();

        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        const formData = {
            "influencer_id": data.influencer_id,
        }

        dispatchAction(formData);

    }

    const dispatchAction = async (formData) => {
        deleteInfluencerProfile(formData).then((result) => {
            if (result.status) {
                history.push({
                    pathname: PostAuthRoutes('influencer').path,
                });
            }
        });
    }

    return (
        <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ConfzModalBody style={{ padding: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormInput
                                    tabIndex={2}
                                    label='Type DELETE to confirm'
                                    name='confirmation_text'
                                    value={data.confirmation_text}
                                    error={action.isSubmitted && errors.confirmation_text ? errors.confirmation_text : ''}
                                    onChange={handleChange}
                                    autoComplete='off'
                                />
                            </Grid>
                        </Grid>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>

                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `165px` }}>
                                <Typography variant='h3'>Are You Sure?</Typography>
                                <Typography variant='body2'>{`Do you want to delete influencer ${Object.keys(influencer_details).length > 0 ? influencer_details.name : ''}`}?</Typography>
                            </Stack>
                        </CardContent>
                    </ConfzModalBody>
                    <ConfzModalFooter style={{ padding: 0 }}>
                        <ConfzModalFooterSaveBtn tabIndex={3} text='Delete' isProcessing={action.isProcessing} disabled={isLoading} />
                        <ConfzModalFooterCloseBtn tabIndex={4} onClose={onClose} />
                    </ConfzModalFooter>
                </form>
            </Card>
        </ConfzModal>
    )
}

export { DeleteInfluencer };
