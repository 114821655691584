import React, { useEffect } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card, CardContent, CardHeader } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { DashboardAction } from '../../../../src/redux/actions';


function TopBrandsGraph() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { top_brands_by_stories } = useSelector((state) => state.DashboardReducer);
    const getTopBrandsByStories = (params) => dispatch(DashboardAction.getTopBrandsByStories(params));

    const options = {
        chart: {
            type: 'column',
            renderTo: 'container',
            backgroundColor: 'rgba(0,0,0,0)',
            style: {
                fontFamily: 'unset'
            }
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y:.of})</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%'],
                size: '110%'
            }
        },
        series: [{
            type: 'pie',
            name: 'Stories',
            innerSize: '50%',
            data: top_brands_by_stories
        }],
        credits: {
            enabled: false
        }
    };

    useEffect(() => {
        getTopBrandsByStories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Card>
            <CardHeader title="Brands with most stories" />
            <CardContent>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </CardContent>
        </Card>
    )
}

export { TopBrandsGraph }