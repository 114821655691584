import { SignIn, ForgotPassword, ConfirmEmail, ResetPassword } from "../app/pre-signin";

const allRoutes = {
    'login': {
        path: "/login",
        name: "Log in",
        component: SignIn,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'forgot_password': {
        path: "/forgot-password",
        name: "Forgot password",
        component: ForgotPassword,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'confirm_email': {
        path: "/confirm-email/:key",
        name: "Confirm email address",
        component: ConfirmEmail,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'reset_password': {
        path: "/reset-password",
        name: "Reset password",
        component: ResetPassword,
        layout: "/pre-auth",
        isMainComponent: true
    },
};


function PreAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PreAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PreAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PreAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}

export { PreAuthRoutes, PreAuthMenuRoutes, PreAuthModuleMenuRoutes, PreAuthMainComponents, getComponentNameByPath };