import { GlobalAction } from ".";
import { BrandServices } from "../services";

export const BRANDS_REQUEST = 'BRANDS_REQUEST';
export const BRANDS_SUCCESS = 'BRANDS_SUCCESS';
export const BRANDS_FAILURE = 'BRANDS_FAILURE';

export const CHANGE_BRAND_STATUS_REQUEST = 'CHANGE_BRAND_STATUS_REQUEST';
export const CHANGE_BRAND_STATUS_SUCCESS = 'CHANGE_BRAND_STATUS_SUCCESS';
export const CHANGE_BRAND_STATUS_FAILURE = 'CHANGE_BRAND_STATUS_FAILURE';

export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAILURE = 'UPDATE_BRAND_FAILURE';

export const BRANDS_SELECT_LIST_REQUEST = 'BRANDS_SELECT_LIST_REQUEST';
export const BRANDS_SELECT_LIST_SUCCESS = 'BRANDS_SELECT_LIST_SUCCESS';
export const BRANDS_SELECT_LIST_FAILURE = 'BRANDS_SELECT_LIST_FAILURE';

export const BRAND_DETAILS_REQUEST = 'BRAND_DETAILS_REQUEST';
export const BRAND_DETAILS_SUCCESS = 'BRAND_DETAILS_SUCCESS';
export const BRAND_DETAILS_FAILURE = 'BRAND_DETAILS_FAILURE';

export const BRAND_CSV_REQUEST = 'BRAND_CSV_REQUEST';
export const BRAND_CSV_SUCCESS = 'BRAND_CSV_SUCCESS';
export const BRAND_CSV_FAILURE = 'BRAND_CSV_FAILURE';

export const BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_REQUEST = 'BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_REQUEST';
export const BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_SUCCESS = 'BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_SUCCESS';
export const BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_FAILURE = 'BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_FAILURE';

export const CHANGE_BRAND_PASSWORD_REQUEST = 'CHANGE_BRAND_PASSWORD_REQUEST';
export const CHANGE_BRAND_PASSWORD_SUCCESS = 'CHANGE_BRAND_PASSWORD_SUCCESS';
export const CHANGE_BRAND_PASSWORD_FAILURE = 'CHANGE_BRAND_PASSWORD_FAILURE';

export const DELETE_BRAND_PROFILE_REQUEST = 'DELETE_BRAND_PROFILE_REQUEST';
export const DELETE_BRAND_PROFILE_SUCCESS = 'DELETE_BRAND_PROFILE_SUCCESS';
export const DELETE_BRAND_PROFILE_FAILURE = 'DELETE_BRAND_PROFILE_FAILURE';

export const UPDATE_BRAND_LOGO_REQUEST = 'UPDATE_BRAND_LOGO_REQUEST';
export const UPDATE_BRAND_LOGO_SUCCESS = 'UPDATE_BRAND_LOGO_SUCCESS';
export const UPDATE_BRAND_LOGO_FAILURE = 'UPDATE_BRAND_LOGO_FAILURE';

export const BRAND_MENU_REQUEST = 'BRAND_MENU_REQUEST';
export const BRAND_MENU_SUCCESS = 'BRAND_MENU_SUCCESS';
export const BRAND_MENU_FAILURE = 'BRAND_MENU_FAILURE';

export const BRAND_MENU_SELECT_LIST_REQUEST = 'BRAND_MENU_SELECT_LIST_REQUEST';
export const BRAND_MENU_SELECT_LIST_SUCCESS = 'BRAND_MENU_SELECT_LIST_SUCCESS';
export const BRAND_MENU_SELECT_LIST_FAILURE = 'BRAND_MENU_SELECT_LIST_FAILURE';

export const CHANGE_BRAND_WHATS_APP_REQUEST = 'CHANGE_BRAND_WHATS_APP_REQUEST';
export const CHANGE_BRAND_WHATS_APP_SUCCESS = 'CHANGE_BRAND_WHATS_APP_SUCCESS';
export const CHANGE_BRAND_WHATS_APP_FAILURE = 'CHANGE_BRAND_WHATS_APP_FAILURE';

export const CHANGE_BRAND_SMS_REQUEST = 'CHANGE_BRAND_SMS_REQUEST';
export const CHANGE_BRAND_SMS_SUCCESS = 'CHANGE_BRAND_SMS_SUCCESS';
export const CHANGE_BRAND_SMS_FAILURE = 'CHANGE_BRAND_SMS_FAILURE';

export const BrandAction = {
    /**
     * Action for list brands
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    listBrands: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.listBrands(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: BRANDS_REQUEST, request: request } }
        function success(request, response) { return { type: BRANDS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRANDS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change brand status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @author Jasin
     * @created_at 15 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_BRAND_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_BRAND_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_BRAND_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update brand
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @param {String} params.name
     * @param {String} params.insta_handle
     * @param {String} params.email
     * @param {String} params.mobile_number
     * @author Jasin
     * @created_at 15 June 2022
    */
    updateBrand: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.updateBrand(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_BRAND_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_BRAND_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_BRAND_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for brand select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    brandSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.brandSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRANDS_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: BRANDS_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRANDS_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get brand details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin
     * @created_at 15 June 2022
    */
    brandDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.brandDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get brand csv
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 11 April 2022
    */
    brandCsv: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.brandCsv(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_CSV_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_CSV_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_CSV_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get brand csv
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 11 April 2022
    */
    brandSendInfluencerWelcomeMessage: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.brandSendInfluencerWelcomeMessage(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change brand password
     * @param {Object} params - The params which are used for change password api.
     * @author
     * @created_at 10 July 2023
     */
    changeBrandPassword: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.changeBrandPassword(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_BRAND_PASSWORD_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_BRAND_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_BRAND_PASSWORD_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to get brand csv
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 11 April 2022
    */
    deleteBrandProfile: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.deleteBrandProfile(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_BRAND_PROFILE_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_BRAND_PROFILE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_BRAND_PROFILE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to update brand logo
     * @param {Object} - null
     * @author Naveen
     * @created_at 21 Aug 2023
    */
    updateBrandLogo: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.updateBrandLogo(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_BRAND_LOGO_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_BRAND_LOGO_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_BRAND_LOGO_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list brand menu
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 26 Oct 2023
     */
    listBrandMenu: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.listBrandMenu(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: BRAND_MENU_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_MENU_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_MENU_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Category select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 11 Oct 2023
    */
    brandCategorySelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.brandCategorySelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_MENU_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_MENU_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_MENU_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to enable whats app
     * @param {Object} params - The params which are used for the api
     * @author Naveen k
     * @created_at 13 Jun 2024
     */
    changeBrandWhatsApp: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.changeBrandWhatsApp(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_BRAND_WHATS_APP_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_BRAND_WHATS_APP_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_BRAND_WHATS_APP_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to enable whats app
     * @param {Object} params - The params which are used for the api
     * @author Naveen k
     * @created_at 19 Jun 2024
     */
    changeBrandSms: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.changeBrandSms(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_BRAND_SMS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_BRAND_SMS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_BRAND_SMS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

};
