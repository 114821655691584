import { apiServices } from '../../_helpers';


export const FaqServices = {

    /**
     * Service for list FAQ
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 10 Jan 2023
     */
    listFaq: (params) => {
        return apiServices.post('/admin/faq', params)
            .then((response) => { return response; })

    },

    /**
     * Service for Creating FAQ
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 10 Jan 2023
     */
    createFaq: (params) => {
        return apiServices.post('/admin/faq/create', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /**
     * Service for update FAQ
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 10 Jan 2023
    */
    updateFaq: (params) => {
        return apiServices.put('admin/faq/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

    /**
     * Service for deleting FAQ
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 10 Jan 2023
    */
    deleteFaq: (params) => {
        return apiServices.put('admin/faq/delete', params)
            .then((response) => { return response; })
    },

    /**
     * Service to change FAQ status
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 12 Jan 2023
     */
    changeFaqStatus: (params) => {
        return apiServices.put('/admin/faq/change-status', params)
            .then((response) => { return response; })
    },

    /**
     * Service for Sort FAQ
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 16 Jan 2023
    */
    sortFaq: (params) => {
        return apiServices.put('admin/faq/sort', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /** 
     * Service for FAQ details
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 13 Jan 2023
    */
    faqDetails: (params) => {
        return apiServices.post('/admin/faq/details', params)
            .then((response) => { return response; })

    },

}