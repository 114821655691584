import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { BrandAction } from '../../../redux/actions'
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

function BrandExport({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const csvLink = useRef();

    /** Redux actions and state */
    const { brand_csv_loading: csvLoading, brand_csv } = useSelector((state) => state.BrandReducer);
    const brandCsv = (params) => dispatch(BrandAction.brandCsv(params));

    /** Initialize values from props */
    const { payload } = props;

    /** Initialize and declare state */
    const [isExporting, setIsExporting] = useState(false);
    const [exportDisabled, setExportDisabled] = useState(true);

    const headers = [
        { label: "#", key: "allCounts.bI" },
        { label: "Brand", key: "brands.insta_profile.name" },
        { label: "email", key: "brands.email" },
        { label: 'Mobile Number', key: 'brands.mobile_number' },
        { label: 'Instagram Id', key: 'brands.insta_handle' },
        { label: 'Followers', key: 'brands.insta_profile.followers_count' },
        { label: 'Plan', key: 'brands.plan' },
        { label: 'Status', key: 'brands.status' },
        { label: 'Total Promo', key: 'allCounts.totalPromoCount' },
        { label: 'Total Story', key: 'allCounts.totalStoryCount' },
        { label: 'Total Influencer', key: 'allCounts.totalInfluencerCount' },
        { label: 'Promo This Month', key: 'allCounts.promoCountThisMonth' },
        { label: 'Story This Month', key: 'allCounts.storyCountThisMonth' },
        { label: 'Influencer This Month', key: 'allCounts.influencerCountThisMonth' },
    ];

    /**
     * function to export csv file
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    const exportCsvFile = () => {
        if (!exportDisabled) {
            setExportDisabled(true);
            setIsExporting(true);
            brandCsv(payload);
        }
    }

    useEffect(() => {
        if (isExporting && !csvLoading) {
            csvLink.current.link.click();
            setIsExporting(false);
            setExportDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand_csv])

    useEffect(() => {
        if (csvLink.current) {
            setExportDisabled(false)
        }
    }, [csvLink])

    return (

        <React.Fragment>
            <MenuItem onClick={(e) => { e.stopPropagation(); exportCsvFile() }} disabled={exportDisabled} key={1}>
                <ListItemIcon>
                    <Download fontSize="small" />
                </ListItemIcon>
                Export to CSV
            </MenuItem>
            <MenuItem key={2} style={{ display: `none` }}>
                <CSVLink data={brand_csv} filename="brand.csv" asyncOnClick={true} headers={headers} ref={csvLink}>Download</CSVLink>
            </MenuItem>
        </React.Fragment>
    )
}

export { BrandExport }