import {
    FAQ_REQUEST, FAQ_SUCCESS, FAQ_FAILURE,
    FAQ_CREATE_REQUEST, FAQ_CREATE_SUCCESS, FAQ_CREATE_FAILURE,
    UPDATE_FAQ_REQUEST, UPDATE_FAQ_SUCCESS, UPDATE_FAQ_FAILURE,
    CHANGE_FAQ_STATUS_REQUEST, CHANGE_FAQ_STATUS_SUCCESS, CHANGE_FAQ_STATUS_FAILURE,
    FAQ_DETAILS_REQUEST, FAQ_DETAILS_SUCCESS, FAQ_DETAILS_FAILURE,
    DELETE_FAQ_REQUEST, DELETE_FAQ_SUCCESS, DELETE_FAQ_FAILURE,
    SORT_FAQ_REQUEST, SORT_FAQ_SUCCESS, SORT_FAQ_FAILURE,
} from "../actions";

const initialState = {
    faq_loading: false, faq: [], faq_errors: {},
    create_faq_loading: false, create_faq_errors: {},
    change_faq_status_loading: false, change_faq_status_errors: {},
    faq_details_loading: false, faq_details: {}, faq_details_errors: {},
};

export function FaqReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::FAQ list redux */
        case FAQ_REQUEST: {
            return {
                ...state,
                faq_loading: true,
                faq_errors: {}
            };
        }
        case FAQ_SUCCESS: {

            let newArray = [...state.faq]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.faq.forEach((payload) => {
                const faqIndex = newArray.findIndex(faqs => faqs._id === payload._id);
                if (faqIndex !== -1) {
                    newArray[faqIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                faq_loading: false,
                faq: action.data.faq,
                faq_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case FAQ_FAILURE: {
            return {
                ...state,
                faq_loading: false,
                faq_errors: action.errors,
                faq: [],
            };
        }
        /** end::FAQ list redux */

        /** begin::Create FAQ redux */
        case FAQ_CREATE_REQUEST: {
            return {
                ...state,
                create_faq_loading: true,
                create_faq_errors: {}
            };
        }
        case FAQ_CREATE_SUCCESS: {

            let faqArray = [...state.faq]; //making a new array
            const faqIndex = faqArray.findIndex(faq => faq._id === action.data._id);

            if (faqIndex !== -1) {
                faqArray[faqIndex].status = action.data.status;
            }

            return {
                ...state,
                create_faq_loading: false,
                faq: faqArray,
                create_faq_errors: {}
            };

        }
        case FAQ_CREATE_FAILURE: {
            return {
                ...state,
                create_faq_loading: false,
                create_faq_errors: action.errors
            };
        }

        /** end::Create FAQ redux */

        /** begin::Update FAQ redux */
        case UPDATE_FAQ_REQUEST: {
            return {
                ...state,
                update_faq_loading: true,
                update_faq_errors: {}
            };
        }
        case UPDATE_FAQ_SUCCESS: {
            let faqArray = [...state.faq];
            const faqIndex = faqArray.findIndex(faqs => faqs._id === action.data._id);
            if (faqIndex !== -1) {
                faqArray[faqIndex] = action.data;
            }

            return {
                ...state,
                faq: faqArray,
                update_faq_loading: false,
                update_faq: action.data,
                update_faq_errors: {}
            };
        }
        case UPDATE_FAQ_FAILURE: {
            return {
                ...state,
                update_faq_loading: false,
                update_faq_errors: action.errors
            };
        }
        /** end::Update FAQ redux */

        /** begin::Delete FAQ redux */
        case DELETE_FAQ_REQUEST: {
            return {
                ...state,
                delete_faq_loading: true,
                delete_faq_errors: {}
            };
        }
        case DELETE_FAQ_SUCCESS: {
            let faqArray = [...state.faq];

            const FaqFilter = faqArray.filter(faqs => faqs._id !== action.data._id)

            return {
                ...state,
                faq: FaqFilter,
                delete_faq_loading: false,
                delete_faq: action.data,
                delete_faq_errors: {},
            };
        }
        case DELETE_FAQ_FAILURE: {
            return {
                ...state,
                delete_faq_loading: false,
                delete_faq_errors: action.errors
            };
        }
        /** end::Delete FAQ redux */

        /** begin::Change FAQ status redux */
        case CHANGE_FAQ_STATUS_REQUEST: {
            return {
                ...state,
                change_faq_status_loading: true,
                change_faq_status_errors: {}
            };
        }
        case CHANGE_FAQ_STATUS_SUCCESS: {
            let faqArray = [...state.faq];
            const faqIndex = faqArray.findIndex(faq => faq._id === action.data._id);

            if (faqIndex !== -1) {
                faqArray[faqIndex].status = action.data.status;
            }

            return {
                ...state,
                change_faq_status_loading: false,
                change_faq_status_errors: {},
                faq: faqArray
            };
        }
        case CHANGE_FAQ_STATUS_FAILURE: {
            return {
                ...state,
                change_faq_status_loading: false,
                change_faq_status_errors: action.errors
            };
        }
        /** end::Change FAQ status redux */

        /** begin::Sort FAQ redux */
        case SORT_FAQ_REQUEST: {
            return {
                ...state,
                sort_faq_loading: true,
                sort_faq_errors: {}
            };
        }
        case SORT_FAQ_SUCCESS: {
            let faqArray = [...state.faq];
            const faqIndex = faqArray.findIndex(faqs => faqs._id === action.data._id);
            if (faqIndex !== -1) {
                faqArray[faqIndex] = action.data;
            }

            return {
                ...state,
                faq: faqArray,
                sort_faq_loading: false,
                sort_faq: action.data,
                sort_faq_errors: {}
            };
        }
        case SORT_FAQ_FAILURE: {
            return {
                ...state,
                sort_faq_loading: false,
                sort_faq_errors: action.errors
            };
        }
        /** end::Sort FAQ redux */
        /** begin::FAQ details redux */
        case FAQ_DETAILS_REQUEST: {
            return {
                ...state,
                faq_details_loading: true,
                faq_details_errors: {}
            };
        }
        case FAQ_DETAILS_SUCCESS: {
            return {
                ...state,
                faq_details_loading: false,
                faq_details: action.data,
                faq_details_errors: {}
            };
        }
        case FAQ_DETAILS_FAILURE: {
            return {
                ...state,
                faq_details_loading: false,
                faq_details_errors: action.errors
            };
        }
        /** end::FAQ details redux */

        default:
            return state;
    }
}