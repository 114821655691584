import React, {useState, useEffect} from 'react'
import { Grid, Stack, Typography, Button } from '@mui/material'
import { Card, CardContent, CardHeader } from '../../../_components/card'
import { FormTelePhoneInput, FormInput } from '../../../_components/form';
import { handleInputChange} from '../../../_helpers';
import { PostAuthRoutes } from '../../../routes';
import { UserAction } from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';

const inputs = { whatsapp_mobile: '', email: '', sms_mobile: ''}

function CommunicationServices() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { test_communication_errors } = useSelector((state) => state.UserReducer);
    const testCommunication = (params) => dispatch(UserAction.testCommunication(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });

    useEffect(() => {
        setErrors({ ...test_communication_errors });

    }, [test_communication_errors]);

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen K
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    async function handleEmailCommunication () {
        const testEmail = await testCommunication({ email: data.email });
        if (testEmail.status === 1) {
            setData({ ...data, email: '' });
        }
    };
    async function handleWhatsAppCommunication () {
        const testWhatsApp = await testCommunication({ whatsapp_mobile: data.whatsapp_mobile });
        if (testWhatsApp.status === 1) {
            setData({ ...data, whatsapp_mobile: '' });
        }
    };
    async function handleSmsCommunication () {
        const testSms = await testCommunication({ sms_mobile: data.sms_mobile });
        if (testSms.status === 1) {
            setData({ ...data, sms_mobile: '' });
        }
    };
        return (
            <React.Fragment>
                <Card sx={{ p: `80px !important` }}>
                    <CardHeader title={PostAuthRoutes('communication_services').name} />
                    <CardContent>
                        <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                            <Grid item xs={12} md={12}>
                                <Typography variant="h6">Whats App Test Service</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" color="textSecondary">A test message will be sent to the entered WhatsApp mobile number.</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                    <FormTelePhoneInput tabIndex={13} label='Whats App Mobile Number' name='whatsapp_mobile' value={data.whatsapp_mobile} error={errors.whatsapp_mobile ? errors.whatsapp_mobile : ''} onChange={handleChange} maskType={`digit-only`} inputProps={{ maxLength: 12 }} autoComplete={`whatsapp_mobile`} placeholder="Whats App Mobile number" disabled={true}/>
                                    <Button variant='contained' size="large" color='secondary' onClick={() => handleWhatsAppCommunication()}>Sent a test Whats App</Button>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Typography variant="h6">Email Test Service</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" color="textSecondary">A test message will be sent to the entered email address.</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                    <FormInput tabIndex={1} label='Email' name='email' value={data.email} error={errors.email ? errors.email : ''} onChange={handleChange} placeholder="Enter Email" autoFocus={true}/>
                                    <Button variant='contained' size="large" color='secondary' onClick={() => handleEmailCommunication()}>Sent a test Email</Button>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Typography variant="h6">Sms Test Service</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" color="textSecondary">A test message will be sent to the entered sms number.</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                    <FormTelePhoneInput tabIndex={13} label='SMS Mobile Number' name='sms_mobile' value={data.sms_mobile} error={errors.sms_mobile ? errors.sms_mobile : ''} onChange={handleChange} maskType={`digit-only`} inputProps={{ maxLength: 12 }} autoComplete={`sms_mobile`} placeholder="SMS Mobile number" disabled={true}/>
                                    <Button variant='contained' size="large" color='secondary' onClick={() => handleSmsCommunication()}>Sent a test Sms</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
}

export { CommunicationServices };
