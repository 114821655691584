import { Grid } from "@mui/material";
import React from "react";
import { InfluencerCard } from "../../influencer";
import { RibbonContainer } from "react-ribbons";
// import { RibbonContainer, LeftCornerRibbon } from "react-ribbons";



function GiveawayWinnerCard({ isLoading,  giveaway_winner}) {

    /** Initialize plugins and variables */

    return (
        <React.Fragment>
            {(!isLoading && giveaway_winner && Object.keys(giveaway_winner).length > 0) &&
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                    <RibbonContainer className="custom-class">
                        <InfluencerCard influencer={giveaway_winner.influencer} actions = {{ detail: true, change_status: false }}/>
                        {/* <LeftCornerRibbon backgroundColor="#EB102F" color="#f0f0f0" fontFamily="Arial">
                            Winner
                        </LeftCornerRibbon> */}
                    </RibbonContainer>
                </Grid>
            }
        </React.Fragment>
    );
}

export { GiveawayWinnerCard }