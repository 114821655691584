import { Card, CardContent, CardMedia, Stack, Typography, Grid } from '@mui/material';
import React, { useState } from 'react'
import { FormInput } from '../../../_components/form'
import questinMarkSvg from './question-mark.svg'
import { handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn } from '../../../_components/modal';

// Initialize form input values to null
const inputs = { remark: '' };

function RedeemCouponModal({ show, showLoader, data, closeModal, redeemCoupon }) {

    /** Initialize and declare state */
    const [redeemCouponData, setRedeemCouponData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const { redeem_coupon_loading: isLoading, redeem_coupon_errors: redeemCouponCodesErrors } = useSelector((state) => state.PromoReducer);
    const { giveaway_redeem_coupon_loading: isGiveawayRedeemLoading, giveaway_redeem_coupon_errors: giveawayRedeemCouponCodesErrors } = useSelector((state) => state.GiveawayReducer);


    /**
     * function to close update status modal
     * @param {string} status Entered status on ticket update status modal
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const onClose = () => {
        setRedeemCouponData({ ...inputs })
        closeModal({ coupon_id: '', promo_id: '', remark: '' });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author 
     * @created_at 30 May 2022
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(redeemCouponData)) {
            const inputError = validate(name, value, redeemCouponData);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        redeemCoupon({ coupon_id: data.coupon_id, promo_id: data.promo_id, remark: redeemCouponData.remark });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, redeemCouponData)) })
        data[name] = value;
        setRedeemCouponData({ ...data });
    }

    useEffect(() => {
        if (redeemCouponCodesErrors && Object.keys(redeemCouponCodesErrors).length > 0) {
            setErrors({ ...redeemCouponCodesErrors });
        } else {
            setErrors({ ...giveawayRedeemCouponCodesErrors });
        }
    }, [redeemCouponCodesErrors, giveawayRedeemCouponCodesErrors])

    return (
        <ConfzModal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ConfzModalBody style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>

                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `10px`, minWidth: `100%` }} spacing={3}>
                                <Typography variant='h3'>Are You Sure?</Typography>
                                <Typography variant='body2'>Do you want to redeem this coupon?</Typography>

                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `100px`, minWidth: `60%` }} >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Remark'
                                                name='remark'
                                                value={redeemCouponData.remark}
                                                error={(action.isSubmitted && (errors.remark)) ? (errors.remark) : ''}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={25}
                                                minRows={1}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>

                            </Stack>

                        </CardContent>
                    </ConfzModalBody>
                    <ConfzModalFooter style={{ padding: 0 }}>
                        <ConfzModalFooterSaveBtn tabIndex={2} text={'Redeem'} isProcessing={action.isProcessing || isLoading || isGiveawayRedeemLoading} disabled={action.isProcessing || isLoading ||isGiveawayRedeemLoading}/>
                        <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClose} />
                    </ConfzModalFooter>
                </form>
            </Card>
        </ConfzModal>
    )
}

export { RedeemCouponModal }
