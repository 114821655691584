
const returnDigitWithOneDot = (e) => {
    var keyCodeEntered = e.which
        ? e.which
        : window.e.keyCode
            ? window.e.keyCode
            : -1;
    if (keyCodeEntered >= 48 && keyCodeEntered <= 57) {
        return true;
    } else if (keyCodeEntered === 43) {
        if (e.value && e.value.indexOf('+') >= 0) {
            e.preventDefault();
            return false;
        } else return true;
    } else if (keyCodeEntered === 45) {
        if (e.value && e.value.indexOf('-') >= 0) {
            e.preventDefault();
            return false;
        } else return true;
    } else if (keyCodeEntered === 46) {
        if (e.value && e.value.indexOf('.') >= 0) {
            e.preventDefault();
            return false;
        } else return true;
    }
    e.preventDefault();
    return false;
}

export { returnDigitWithOneDot }
