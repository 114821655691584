import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";
import Box from "../../../_components/box";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormDateRangePicker, FormInput, FormSelect } from "../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { BrandAction, BrandCostAction } from "../../../redux/actions";
import { PostAuthRoutes } from "../../../routes";

// Initialize form input values to null
const inputs = { brand_id: '', date_range: '', cost: '', giveaway_coupon_cost: '', cost_id: '' };

function CreateBrandCost() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Brand id and details on update page */
    const location = useLocation();
    const { brand_cost } = location.state ?? {};

    /** Redux actions and state */
    const { add_brand_cost_loading: isLoading, add_brand_cost_errors: addBrandCostErrors } = useSelector((state) => state.BrandCostReducer);
    const { brands_select_list, brands_select_list_loading: isBrandLoading } = useSelector((state) => state.BrandReducer);
    const addBrandCost = (params) => dispatch(BrandCostAction.addBrandCost(params));
    const updateBrandCost = (params) => dispatch(BrandCostAction.updateBrandCost(params));
    const brandSelectList = (params) => dispatch(BrandAction.brandSelectList(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetBrandCostLoading, setBrandCostLoading] = useState(true);

    useEffect(() => {
        brandSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setErrors({ ...addBrandCostErrors });
    }, [addBrandCostErrors])

    useEffect(() => {
        if (brand_cost && brand_cost._id) {
            setData({
                brand_id: brand_cost._id,
                date_range: `${brand_cost.cost.start_date} - ${brand_cost.cost.end_date}`,
                cost: brand_cost.cost.cost,
                giveaway_coupon_cost: brand_cost.cost.giveaway_coupon_cost ? brand_cost.cost.giveaway_coupon_cost : '',
                cost_id: brand_cost.cost._id ?? ''
            });
            setBrandCostLoading(false);
        } else {
            setBrandCostLoading(false);
        }
    }, [brand_cost])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle update brand form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchAddBrandCostAction(data);
    }

    /**
     * function to dispatch update brand api
     * @author Jasin
     * @created_at 14 June 2022
     */
    async function dispatchAddBrandCostAction(formData) {
        const isCreated = brand_cost && brand_cost._id ? await updateBrandCost(formData) : await addBrandCost(formData);

        if (isCreated && isCreated.status === 1) {
            let redirectPath = `${PostAuthRoutes('brand_cost').path}`;
            history.push(redirectPath);
        }
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function cancelBrandCost() {
        let redirectPath = `${PostAuthRoutes('brand_cost').path}`;
        history.push(redirectPath);
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${brand_cost && brand_cost._id ? PostAuthRoutes('update_brand_cost').name : PostAuthRoutes('create_brand_cost').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={brand_cost && brand_cost._id ? PostAuthRoutes('update_brand_cost').name : PostAuthRoutes('create_brand_cost').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" display={`flex`} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card >
                                {!isSetBrandCostLoading && !isBrandLoading && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={1}
                                                label={`Brand`}
                                                name={`brand_id`}
                                                placeholder={`Select brand`}
                                                onChange={handleChange}
                                                data={brands_select_list}
                                                className="col-md-6"
                                                readOnly={(brand_cost && brand_cost._id) ? true : false}
                                                error={action.isSubmitted && errors.brand_id ? errors.brand_id : ''}
                                                value={data.brand_id}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={2}
                                                label='Cost (RS)'
                                                name='cost'
                                                value={data.cost}
                                                error={action.isSubmitted && errors.cost ? errors.cost : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={2}
                                                label='Giveaway Coupon Cost (RS)'
                                                name='giveaway_coupon_cost'
                                                value={data.giveaway_coupon_cost}
                                                error={action.isSubmitted && errors.giveaway_coupon_cost ? errors.giveaway_coupon_cost : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormDateRangePicker
                                                tabIndex={3}
                                                label='Date Range'
                                                name='date_range'
                                                value={data.date_range}
                                                placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                                error={action.isSubmitted && errors.date_range ? errors.date_range : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={brand_cost && brand_cost._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={4} />
                                                <CancelButton onClick={cancelBrandCost} tabIndex={4} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreateBrandCost };
