import { FileDownload } from '@mui/icons-material';
import { Box, Chip, Stack, Card, CardContent, Typography, CardHeader } from '@mui/material'

function LastInvoiceDetailCard({ brandLastInvoice, ...props }) {

    /** Initialize and declare state */
    const { invoice_number, invoice_start_date, invoice_end_date, total_coupons, total_amount, created_at, status, invoice_pdf } = brandLastInvoice;

    return (
        <Card>
            <CardHeader action={
                <Box>
                    <Stack direction="row" display={`flex`} alignItems={`center`} justifyContent={`center`} spacing={1}>
                        <Chip size='small' label={status === 'paid' ? 'Paid' : (status === 'not_paid' ? 'Not Paid' : 'Requested')} color={status === 'paid' ? 'success' : (status === 'not_paid' ? 'error' : 'info')} />
                    </Stack>
                </Box>
            }></CardHeader>
            {brandLastInvoice && <CardContent>
                <Stack spacing={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Invoice Number : `}</Typography>
                        <Chip component="a" href={invoice_pdf} label={`${invoice_number}`} clickable size="small" icon={<FileDownload />} />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Period : `}</Typography>
                        <Chip label={`${invoice_start_date} - ${invoice_end_date}`} size="small" />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Coupons : `}</Typography>
                        <Chip label={`${total_coupons}`} size="small" />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Amount (Rs) : `}</Typography>
                        <Chip label={`${total_amount}`} size="small" />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Created Date : `}</Typography>
                        <Chip label={`${created_at}`} size="small" />
                    </Box>
                </Stack>
            </CardContent>}
        </Card>
    )
}

export { LastInvoiceDetailCard }