module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 14 June 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = {};
        switch (name) {
            case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter user name.';
                break;
            } case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid.';
                if (value === '')
                    errors.email = 'Enter an email address.';
                break;
            } case 'password': {
                const confirmPassword = data.confirm_password ?? '';
                errors.password = value.length < 6 ? 'Password must be 6 characters long.' : '';
                if (confirmPassword !== '' && confirmPassword === value)
                    errors.confirm_password = '';
                if (value === '')
                    errors.password = 'Enter password.';
                break;
            } case 'confirm_password': {
                const password = data.password ?? '';
                errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long.' : '';
                if (password !== '' && password !== value)
                    errors.confirm_password = 'Confirm password should match with the password.';
                if (value === '')
                    errors.confirm_password = 'Re-enter your password.';
                break;
            } case 'role_id': {
                errors.role_id = '';
                if (value === '')
                    errors.role_id = 'Select role.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}