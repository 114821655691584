import { Avatar, Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';

function ContactUsReplies() {

    /** Redux actions and state */
    const { contact_us_details_loading: isLoading, contact_us_details } = useSelector((state) => state.ContactUsReducer);


    const replyEndRef = useRef(null)

    const scrollToBottom = () => {
        replyEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [contact_us_details.replies]);


    if (isLoading)
        return ''

    return (
        <React.Fragment >
            {!isLoading && Object.keys(contact_us_details).length > 0 &&
                <Box sx={{ height: `710px`, overflow: `scroll` }} >
                    <Grid container spacing={2} display={`flex`} alignItems={`center`}>
                        {contact_us_details && contact_us_details.replies.length > 0 && contact_us_details.replies.map((reply, i) => {

                            return (reply.replied_by_type === 'admins') ?
                                (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
                                        <Box display={`flex`} alignItems={`center`} justifyContent={`right`} sx={{ p: 2, backgroundColor: `#262626`, borderRadius: 2 }}>
                                            <Box mr={2} pl={2} >
                                                <Typography variant="body2" textAlign={`right`}>{reply.reply}</Typography>
                                                <Typography variant="body2" textAlign={`right`} sx={{ fontSize: `9px` }}> {reply.created_at}</Typography>
                                            </Box>
                                            <Avatar />
                                        </Box>
                                    </Grid>
                                ) :
                                (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
                                        <Box display={`flex`} alignItems={`center`} justifyContent={`left`} sx={{ p: 2, backgroundColor: `#1b1b1b`, borderRadius: 2 }}>
                                            <Avatar />
                                            <Box ml={2} sx={{ width: `100%` }}>
                                                <Typography variant="body2">{reply.reply}</Typography>
                                                <Typography variant="body2" sx={{ fontSize: `9px` }}> {reply.created_at}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                        })}
                    </Grid>
                    <Box ref={replyEndRef}></Box>
                </Box>
            }
        </React.Fragment>
    )
}

export { ContactUsReplies }