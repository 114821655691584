import {
    NEWSLETTER_SUBSCRIBERS_REQUEST, NEWSLETTER_SUBSCRIBERS_SUCCESS, NEWSLETTER_SUBSCRIBERS_FAILURE,
} from "../actions";

const initialState = {
    newsletter_subscribers_loading: false, newsletter_subscribers: [], newsletter_subscribers_errors: {},
    page: 1, per_page: 100, total: 0, total_pages: 0,
};

export function NewsletterSubscribersReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::newsletter subscribers list redux */
        case NEWSLETTER_SUBSCRIBERS_REQUEST: {
            return {
                ...state,
                newsletter_subscribers_loading: true,
                newsletter_subscribers_errors: {}
            };
        }
        case NEWSLETTER_SUBSCRIBERS_SUCCESS: {
            return {
                ...state,
                newsletter_subscribers_loading: false,
                newsletter_subscribers: action.data.newsletter,
                per_page: action.data.newsletter.length,
                newsletter_subscribers_errors: {}
            };
        }
        case NEWSLETTER_SUBSCRIBERS_FAILURE: {
            return {
                ...state,
                newsletter_subscribers_loading: false,
                newsletter_subscribers_errors: action.errors
            };
        }
        /** end::newsletter subscribers list redux */

        default:
            return state;
    }
}
