import { makeStyles } from "@mui/styles";
import { DRAWER_WIDTH } from "../../_helpers";

const useStyles = makeStyles((theme) => ({
    appLayout: {
        display: `flex`
    },
    mainComponent: {
        // backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        height: '90vh',
        marginLeft: `60px`,
        padding: `25px`,
    },
    drawerOpen: {
        marginLeft: `${DRAWER_WIDTH}px !important`,
    }
}))

export { useStyles };
