import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router'
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import { FilterAlt } from '@mui/icons-material'
import { AdvertisementAction } from '../../redux/actions'
import { UpdateStatusModal } from '../../_components/modal/modals/UpdateStatusModal';
import { DeleteModal } from '../../_components/modal/modals/DeleteModal';
import Box from '../../_components/box'
import { CardHeader } from "../../_components/card";
import { FormSearchInput } from '../../_components/form';
import { Grid, Stack } from '@mui/material'
import { AdvertisementCard, AdvertisementCardLoader } from ".";
import { PostAuthRoutes } from "../../routes";
import { GeneralButton } from "../../_components/controls";
import { AdvertisementFilter } from "./advertisement-filter";
import { NoData } from "../../_components/layout/no-data";
import { AbilityCan } from "../../_helpers/permission/AbilityCan";

function Advertisement() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    let history = useHistory();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { advertisements_loading: isLoading, advertisements } = useSelector((state) => state.AdvertisementReducer);
    const listAdvertisement = (params) => dispatch(AdvertisementAction.listAdvertisement(params));
    const changeStatus = (params) => dispatch(AdvertisementAction.changeStatus(params));
    const deleteAdvertisement = (params) => dispatch(AdvertisementAction.deleteAdvertisement(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '' });
    const [advertisementDeleteData, setAdvertisementDeleteData] = useState({ id: '' });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: {
            status: searchParams.get('status') ?? ''
        },
        search: searchParams.get('q') ?? ''
    });
    const [showAdvertisementFilterModal, setShowAdvertisementFilterModal] = useState(false);

    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        let pageUrl = `${PostAuthRoutes('advertisement').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        getNext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter.status, payload.search])

    useEffect(() => {
        getNext();
        //eslint-disable-next-line
    }, [])


    /**
     * function to list Advertisement
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {
        const result = await listAdvertisement(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to create Advertisement
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    function createAdvertisement() {
        history.push({
            pathname: PostAuthRoutes('create_advertisement').path,
            state: { advertisements: advertisements }
        });
    }

    /**
     * function to open/close status Update modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status }) => {
        setStatusData({ id: id, status: status });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update Advertisement
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'advertisement_id': id });
    }

    /**
     * function to open/close advertisement delete modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const advertisementDeleteModal = async ({ id }) => {
        setAdvertisementDeleteData({ id: id })
        setShowDeleteModal(!showDeleteModal)
    }

    /**
     * function to delete Advertisement
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const advertisementDeleteAction = async (id) => {
        setShowDeleteModal(!showDeleteModal)
        await deleteAdvertisement({ 'advertisement_id': id });
    }

    /**
     * function to apply the selected filter options on the advertisement list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the advertisement filter modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to apply the search key words on the advertisement list
     * @param {String} text Search keyword used
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    /**
     * function to open/close advertisement filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const advertisementFilterModal = () => {
        setShowAdvertisementFilterModal(!showAdvertisementFilterModal);
    }

    return (
        <React.Fragment>
            <Box >
                <CardHeader title={PostAuthRoutes('advertisement').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='admin_advertisement_create' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment>Create</React.Fragment>} onClick={() => createAdvertisement()} />
                        </AbilityCan>
                        <AbilityCan I='admin_advertisement_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='admin_advertisement_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => advertisementFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <Box>
                <AdvertisementFilter show={showAdvertisementFilterModal} applyFilter={applyFilter} closeModal={advertisementFilterModal} advertisementFilter={payload.filter} />
                <InfiniteScroll dataLength={advertisements.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            advertisements.length > 0 && advertisements.map((advertisement, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={i}>
                                        <AdvertisementCard advertisement={advertisement} statusUpdateModal={statusUpdateModal} advertisementDeleteModal={advertisementDeleteModal} />
                                    </Grid>
                                );
                            })
                        }
                        {isLoading && <AdvertisementCardLoader />}
                        {!isLoading && advertisements.length === 0 && <NoData content1={`No`} content2={`Advertisement available`} description={`Please click below button to create new advertisement.`} minHeight={`55vh`} ><GeneralButton label={<React.Fragment>Create</React.Fragment>} onClick={() => createAdvertisement()} /></NoData>}
                    </Grid>
                </InfiniteScroll>
            </Box>

            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
            <DeleteModal show={showDeleteModal} closeModal={advertisementDeleteModal} data={advertisementDeleteData} deleteModal={advertisementDeleteAction} />
        </React.Fragment>

    )

}

export { Advertisement }