import { ContactUsServices } from '../services';
import { GlobalAction } from '.';

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';

export const CHANGE_CONTACT_US_STATUS_REQUEST = 'CHANGE_CONTACT_US_STATUS_REQUEST';
export const CHANGE_CONTACT_US_STATUS_SUCCESS = 'CHANGE_CONTACT_US_STATUS_SUCCESS';
export const CHANGE_CONTACT_US_STATUS_FAILURE = 'CHANGE_CONTACT_US_STATUS_FAILURE';

export const CONTACT_US_DETAILS_REQUEST = 'CONTACT_US_DETAILS_REQUEST';
export const CONTACT_US_DETAILS_SUCCESS = 'CONTACT_US_DETAILS_SUCCESS';
export const CONTACT_US_DETAILS_FAILURE = 'CONTACT_US_DETAILS_FAILURE';

export const ADD_CONTACT_US_REPLY_REQUEST = 'ADD_CONTACT_US_REPLY_REQUEST';
export const ADD_CONTACT_US_REPLY_SUCCESS = 'ADD_CONTACT_US_REPLY_SUCCESS';
export const ADD_CONTACT_US_REPLY_FAILURE = 'ADD_CONTACT_US_REPLY_FAILURE';

export const ContactUsAction = {

    /**
     * Action for list Contact Us page
     * @param {Object} params - The params which are used for the api
     * @author Sarath
     * @created_at 10 June 2022
     */

    listContactUS: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ContactUsServices.listContactUs(params)
            .then(
                response => { return dispatch(success(request, response)); },
                error => { return dispatch(failure(request, error)); }
            );
        };

        function request(request) { return { type: CONTACT_US_REQUEST, request: request }}
        function success(request, response ) { return { type: CONTACT_US_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }}
        function failure(request, error) { return { type: CONTACT_US_FAILURE, request: request, data: error.data, message: error.message, errors: error.errors, status: error.status, status_code: error.status_code }}
    },

    /**
     * Action for change contact us status
     * @param {Object} params  The params which are used for the api
     * @author Sarath
     * @created_at 15 June 2022
     */

    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ContactUsServices.changeStatus(params)
            .then(
                response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); }
            );
        };

        function request(request) { return { type: CHANGE_CONTACT_US_STATUS_REQUEST, request: request}}
        function success(request, response) { return { type: CHANGE_CONTACT_US_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }}
        function failure(request, error) { return { type: CHANGE_CONTACT_US_STATUS_FAILURE, request: request, message: error.message, data: error.data, errors: error.errors, status: error.status, status_code: error.status_code }}
    },

    /**
     * Action to get contact us details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.contact_us_id
     * @author Jasin 
     * @created_at 07 July 2022 
    */
    contactUsDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ContactUsServices.contactUsDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CONTACT_US_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: CONTACT_US_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CONTACT_US_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to add reply
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 22 June 2022
    */
    addReply: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ContactUsServices.addReply(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_CONTACT_US_REPLY_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_CONTACT_US_REPLY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_CONTACT_US_REPLY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },
}