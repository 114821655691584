import { SignInServices } from "../services";

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';

export const SignInAction = {

    /**
     * Action for sign in 
     * @param {Object} params - The params which are used for registration api.
     * @param {string} params.email - Email of the admin user
     * @param {string} params.password - Password of the admin user
     * @author Akshay 
     * @created_at 15 June 2022
     */
    signIn: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SignInServices.signIn(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SIGNIN_REQUEST, request: request } }
        function success(request, response) { return { type: SIGNIN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SIGNIN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for sign out
     * @param {Object} params - The params which are used for registration api.
     * @author Akshay 
     * @created_at 15 June 2022
     */
    signOut: () => {
        return dispatch => {
            return SignInServices.signOut()
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };

    }
};
