
module.exports = {
    /**
     * function to validate the promo create or update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 28 May 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'title': {
                errors.title = '';
                if (value === '' || value === '<p></p>\n')
                    errors.title = 'Enter poster title.';
                break;
            } case 'description': {
                errors.description = '';
                if (value === '' || value === '<p></p>\n')
                    errors.description = 'Enter poster description.';
                break;
            } case 'primary_color': {
                errors.primary_color = '';
                if (value === '')
                    errors.primary_color = 'Select primary color.';
                break;
            } case 'secondary_color': {
                errors.secondary_color = '';
                if (value === '')
                    errors.secondary_color = 'Select secondary color.';
                break;
            } case 'text_color': {
                errors.text_color = '';
                if (value === '')
                    errors.text_color = 'Select text color.';
                break;
            } case 'poster_image': {
                errors.poster_image = '';
                if (value === '' || value === [] || typeof value === 'undefined') {
                    errors.poster_image = 'Select poster image. ';
                } else if (typeof value !== 'string') {
                    let extension = (value.name).split('.').pop();
                    extension = extension.toLowerCase();
                    let size = value.size;

                    if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                        errors.poster_image = 'Please choose a valid file format.';
                    } else if (size >= 2000000) {
                        errors.poster_image = 'Selected promo sample file size exceeded.File size must be below 2 MB.';
                    }
                }
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}