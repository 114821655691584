import { apiServices } from '../../_helpers';

export const SupportCenterServices = {

    /**
     * Service for List Support Center
     * @param {Object} params - The params which are used for the api
     * @author Sarath 
     * @created_at 22 June 2022
     */

    listSupportCenter: (params) => {
        return apiServices.post('admin/support-center/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for List Support Center
     * @param {Object} params - The params which are used for the api
     * @author Sarath 
     * @created_at 22 June 2022
     */
    ticketDetails: (params) => {
        return apiServices.post('admin/support-center/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service for List Support Center
     * @param {Object} params - The params which are used for the api
     * @author Sarath 
     * @created_at 22 June 2022
     */
    addReply: (params) => {
        return apiServices.post('admin/support-center/reply/add', params)
            .then((response) => { return response; })
    },

    /**
     * Service for change Support Center status
     * @param {Object} params - The params which are used for the api
     * @author Naveen 
     * @created_at 21 June 2022
    */
    changeStatus: (params) => {
        return apiServices.put('/admin/support-center/change-status', params)
            .then((response) => { return response; })

    }
}
