module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Jasin
     * @created_at 14 June 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter your plan name.';
                break;
            } case 'category': {
                errors.category = '';
                if (value === '')
                    errors.category = 'Select category.';
                break;
            } case 'number_of_coupons': {
                errors.number_of_coupons = '';
                if (value === '' && data.category !== 'giveaway')
                    errors.number_of_coupons = 'Enter number of coupons.';
                break;
            } case 'cost': {
                errors.cost = '';
                if (value === '')
                    errors.cost = 'Enter cost.';
                break;
            } case 'discount': {
                errors.discount = '';
                if (value === '' && data.discounted_cost)
                    errors.discount = 'Enter discount.';
                break;
            } case 'discounted_cost': {
                errors.discounted_cost = '';
                if (value === '' && data.discount)
                    errors.discounted_cost = 'Enter discounted cost.';
                break;
            } case 'description': {
                errors.description = '';
                if (value === '')
                    errors.description = 'Enter description.';
                break;
            }  case 'duration': {
                errors.duration = '';
                if (value === '' && data.category === 'giveaway')
                    errors.duration = 'Enter duration.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}