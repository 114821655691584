module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author 
     * @created_at 15 June 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {

            case 'user_type': {
                errors.user_type = '';
                if (value === '')
                    errors.user_type = 'Select User Type';
                break;
            } case 'created_by': {
                errors.created_by = '';
                if (value === '')
                    errors.created_by = 'Select User Name';
                break;
            } case 'message': {
                errors.message = '';
                if (value === '')
                    errors.message = 'Enter Message';
                break;
            }default: {
                errors[name] = '';
                break;
            }

        }
        return errors;
    }
}