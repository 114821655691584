import React, { useState } from 'react'
import { FilterAlt } from '@mui/icons-material'
import { Box, Grid, Stack } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { PostAuthRoutes } from '../../routes'
import { Card, CardContent, CardHeader } from '../../_components/card'
import { GeneralButton, RouteLink } from '../../_components/controls'
import { FormSearchInput } from '../../_components/form'
import { UpdateStatusModal } from '../../_components/modal'
import { PermissionAction } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { PermissionDataTable } from '.'
import { PermissionFilter } from './permission-filter'
import { AbilityCan } from '../../_helpers/permission/AbilityCan'

function Permission() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    const [showPermissionFilterModal, setShowPermissionFilterModal] = useState(false);
    const [payload, setPayload] = useState({ limit: 100, page: 1, filter: { status: searchParams.get('status') ?? '', filter_parent: searchParams.get('filter_parent') ?? '', filter_brand_category: searchParams.get('filter_brand_category') ?? '' }, filter_portal: searchParams.get('filter_portal') ?? '', search: searchParams.get('q') ?? '' });

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const changeStatus = (params) => dispatch(PermissionAction.changePermissionStatus(params));

    /**
     * function to open/close update permission Filter model
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update permission
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'permission_id': id });
    }

    /**
     * function to open/close permission filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const permissionFilterModal = () => {
        setShowPermissionFilterModal(!showPermissionFilterModal);
    }

    /**
     * function to apply the selected filter options on the permission list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the permission filter modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the permission list
     * @param {String} text Search keyword used
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('permission').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='admin_permission_create' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <RouteLink to={PostAuthRoutes('create_permission').path} label={<GeneralButton label={PostAuthRoutes('create_permission').name} />} />
                        </AbilityCan>
                        <AbilityCan I='admin_permission_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='admin_permission_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => permissionFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <PermissionFilter show={showPermissionFilterModal} closeModal={permissionFilterModal} applyFilter={applyFilter} permissionFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>

                            <PermissionDataTable statusUpdateModal={statusUpdateModal} filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />

        </React.Fragment>

    )
}

export { Permission }