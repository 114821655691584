import { apiServices } from '../../_helpers';

export const TestimonialServices = {

    /**
     * Service to list Testimonials
     * @param {Object} params - The params which are used for the api
     * @author  
     * @created_at 15 June 2022
     */
    listTestimonial: (params) => {
        return apiServices.post('/admin/testimonial/list', params)
            .then((response) => { return response; })

    },

    /**
     * Service for Creating Testimonial
     * @param {Object} params - The params which are used for the api
     * @author  
     * @created_at 15 June 2022
     */
    createTestimonial: (params) => {
        return apiServices.post('/admin/testimonial/create', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

}
