import { apiServices } from '../../_helpers';

export const NewsletterSubscribersServices = {

    /**
     * Service to get newsletter subscribers list
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    getNewsletterSubscribersList: (params) => {
        return apiServices.post('admin/newsletter/subscribers', params)
            .then((response) => { return response; })
    },

};
