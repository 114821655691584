import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stack, Box } from '@mui/material'
import { CardHeader } from "../../../_components/card";
import { GeneralButton } from "../../../_components/controls/general-button/GeneralButton";
import { FilterAlt } from "@mui/icons-material";
import { StoryAction } from '../../../redux/actions/StoryAction'
import { useHistory, useLocation } from 'react-router-dom'
import { FormSearchInput } from '../../../_components/form';
import { FilterMenu } from "../../../_components/controls";
import queryString from 'query-string';
import { NoData } from "../../../_components/layout/no-data";
import { AbilityCan } from "../../../_helpers/permission/AbilityCan";
import { StoryCard, StoryCardLoader, StoryExport } from "../../story";
import { StoryFilter } from "../../story/filter";

function InfluencerStories() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(search);
    const influencerId = searchParams.get('id') // get influencer id from url

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { influencer_stories_loading: isLoading, influencer_stories: stories } = useSelector((state) => state.StoryReducer);

    const listInfluencerStories = (params) => dispatch(StoryAction.listInfluencerStories(params));

    /** Initialize and declare state */
    const [showStoryFilterModal, setShowStoryFilterModal] = useState(false);
    const [payload, setPayload] = useState({
            limit: 100, page: 1, filter: {
            status: searchParams.get('status') ?? '',
            brand: searchParams.get('brand') ?? '',
            date: searchParams.get('date') ?? '',
            influencer_id: searchParams.get('id') ?? '',
        }
    });

    useEffect(() => {
        getNext()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = payload.page;
        queryParam.id = influencerId;
        queryParam.idTab = 'stories';
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.date) queryParam.date = payload.filter.date; else delete queryParam.date;
        if (payload.filter.brand) queryParam.brand = payload.filter.brand; else delete queryParam.brand;

        history.push({ search: queryString.stringify(queryParam) })

        getNext() // Initially load participants list

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [influencerId, payload.filter, payload.search])

    /**
     * function to list Story
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {

        const result = await listInfluencerStories(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to open/close Story filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const storyFilterModal = () => {
        setShowStoryFilterModal(!showStoryFilterModal);
    }

    /**
     * function to apply the selected filter options on the Story list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the Story filter modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: {...filterData, influencer_id: influencerId }});
    }

    /**
     * function to apply the search key words on the Story list
     * @param {String} text Search keyword used
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='admin_stories_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='admin_stories_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => storyFilterModal()} />
                        </AbilityCan>
                        <AbilityCan I='admin_stories_export_csv' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FilterMenu>
                                <StoryExport payload={payload} />
                            </FilterMenu>
                        </AbilityCan>

                    </Stack>
                } />
                <StoryFilter show={showStoryFilterModal} closeModal={storyFilterModal} applyFilter={applyFilter} storyFilter={payload.filter} hideFilter={['influencer_id', 'influencer']}/>
                <InfiniteScroll dataLength={stories.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            stories.length > 0 && stories.map((story, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} key={i}>
                                        <StoryCard story={story} />
                                    </Grid>
                                );
                            })
                        }
                        {isLoading && <StoryCardLoader />}
                        {!isLoading && stories.length === 0 && <NoData content1={`No`} content2={`Story available`} minHeight={`55vh`} ></NoData>}
                    </Grid>
                </InfiniteScroll>
            </Box>
        </React.Fragment>
    )
}

export { InfluencerStories }