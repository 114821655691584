import {
	SIGNIN_REQUEST, SIGNIN_SUCCESS, SIGNIN_FAILURE
} from "../actions";

let user = localStorage.getItem('accessToken') ? (localStorage.getItem('accessToken')) : '';
const initialState = user ? { loggedIn: true, user, errors: {}, password_errors: {} } : { loggedIn: false, request: {}, user: {}, errors: {}, };

export function SignInReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Sign in redux */
		case SIGNIN_REQUEST: {
			return {
				loading: true,
				loggedIn: false,
				request: action.request,
				user: {}
			};
		}
		case SIGNIN_SUCCESS: {
			return {
				loading: false,
				loggedIn: true,
				user: action.data,
			};
		}
		case SIGNIN_FAILURE: {
			return {
				loading: false,
				loggedIn: false,
				user: {},
				errors: action.errors
			};
		}
		/** end::Sign in redux */

		default:
			return state;
	}
}
