import { apiServices } from '../../_helpers';


export const BrandServices = {

    /**
     * Service for list brands
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    listBrands: (params) => {
        return apiServices.post('/admin/brand', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change promo status
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
    */
    changeStatus: (params) => {
        return apiServices.put('/admin/brand/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for update brand
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @param {String} params.name
     * @param {String} params.insta_handle
     * @param {String} params.email
     * @param {String} params.mobile_number
     * @author Jasin
     * @created_at 15 April 2022
    */
    updateBrand: (params) => {
        return apiServices.put('admin/brand/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

    /**
     * Service for brand select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
    */
    brandSelectList: (params) => {
        return apiServices.post('admin/brand/brand-select-list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for brand details
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    brandDetails: (params) => {
        return apiServices.post('/admin/brand/details', params)
            .then((response) => { return response; })

    },

    /**
     * Service for brand csv
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 11 April 2022
     */
    brandCsv: (params) => {
        return apiServices.post('/admin/brand/export-csv', params)
            .then((response) => { return response; })

    },

    /**
     * Service for brand send influencer welcome message
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 11 April 2022
     */
    brandSendInfluencerWelcomeMessage: (params) => {
        return apiServices.post('/admin/brand/send-influencer-welcome-message', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change password
     * @param {Object} params - The params which are used for change password api.
     * @author
     * @created_at 10 July 2023
     */
    changeBrandPassword: (params) => {

        return apiServices.put('/admin/brand/change-password', params)
        .then((response) => { return response; })
    },

    /**
     * Service to delete brand
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 11 April 2022
     */
    deleteBrandProfile: (params) => {
        return apiServices.post('/admin/brand/delete', params)
            .then((response) => { return response; })

    },

    /**
     * Service to update logo
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 21 Aug 2023
     */
    updateBrandLogo: (params) => {
        return apiServices.post('/admin/brand/logo/update', params, { headers: { 'Content-Type': 'multipart/form-data' }} )
            .then((response) => { return response; })
    },

    /**
     * Service for list brand menu
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 26 Oct 2023
     */
    listBrandMenu: (params) => {
        return apiServices.post('/admin/brand/brand-menu', params)
            .then((response) => { return response; })

    },

    /**
	 * Service for Category select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 26 Oct 2023
	 */
	brandCategorySelectList: (params) => {
		return apiServices.post('/admin/brand/menu-category-select-list', params).then((response) => {
			return response;
		});
	},

    /**
     * Service for to enable whats app
     * @param {Object} params - The params which are used for the api
     * @author Naveen k
     * @created_at 13 Jun 2024
    */
    changeBrandWhatsApp: (params) => {
        return apiServices.put('/admin/brand/change-whats-app', params)
            .then((response) => { return response; })
    },

    /**
     * Service for to enable whats app
     * @param {Object} params - The params which are used for the api
     * @author Naveen k
     * @created_at 19 Jun 2024
    */
    changeBrandSms: (params) => {
        return apiServices.put('/admin/brand/change-sms', params)
            .then((response) => { return response; })
    },
};
