import { Event } from '@mui/icons-material'
import { Box, CardMedia, Chip, Divider, Grid, Stack, Card, CardContent, Typography } from '@mui/material'
import React from 'react'

function BrandMenuCard({ brandMenu }) {

	return (
		<Card style={{ height: '100%' }}>
			<Grid container spacing={2} display="flex" alignItems="center">
				<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
					<Box style={{ width: `100%` }}>
						<CardMedia
							component={'img'}
							src={brandMenu.image}
							onError={(e) => {
								e.target.src = brandMenu.error_image_url;
							}}
							sx={{ position: 'relative', padding: 0, margin: 0, width: '100%', height: '900%', borderTopRightRadius: 0, borderBottomRightRadius: { xs: 0, sm: 0, md: 4 }, borderBottomLeftRadius: { xs: 0, sm: 0, md: 4 } }}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
					<CardContent>
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
							<Typography variant="h5" sx={{ cursor: `pointer` }}>{`${brandMenu.title}`}</Typography>
							<Box>
								<Chip size="small" label={brandMenu.status === 'active' ? 'Active' : 'Inactive'} color={brandMenu.status === 'active' ? 'success' : 'error'} />
							</Box>
						</Box>
						<Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{`${brandMenu.description}`}</Typography>
						<Divider />
						<Stack spacing={1}>
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="body2">{`Category : `}</Typography>
								<Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${brandMenu.category}`}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Non Veg : `}</Typography>
								<Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${brandMenu.is_non === true ? ' Yes ' : ' No '}`}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Price : `}</Typography>
								<Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${brandMenu.price}`}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Is Countable : `}</Typography>
								<Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${typeof brandMenu.is_countable === 'undefined' || brandMenu.is_countable ? 'Yes' : 'No'}`}</Typography>
							</Box>
							{(typeof brandMenu.is_countable !== 'undefined' && !brandMenu.is_countable) && <Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Item type : `}</Typography>
								<Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${brandMenu.item_type}`}</Typography>
							</Box>}
						</Stack>
						<Divider />
						<Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
							<Box display="flex" alignItems="center" flexShrink={0}>
								<Typography variant="body2">
									<Event /> {`Available from : `}
								</Typography>
							</Box>
							<Typography variant="h6">{`${new Date(brandMenu.available_time_start).toLocaleString()}`}</Typography>
						</Box>
						<Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
							<Box display="flex" alignItems="center" flexShrink={0}>
								<Typography variant="body2">
									<Event /> {`Available Till : `}
								</Typography>
							</Box>
							<Typography variant="h6">{`${new Date(brandMenu.available_time_end).toLocaleString()}`}</Typography>
						</Box>
					</CardContent>
				</Grid>
			</Grid>
		</Card>
	);
}

export { BrandMenuCard };