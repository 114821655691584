import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleInputChange, validateForm } from "../../../../_helpers";
import { Validate } from "./Validate";
import { FormPassword } from "../../../../_components/form";
import { Grid } from "@mui/material";
import { BrandAction } from "../../../../redux/actions";
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from "../../../../_components/modal";

// Initialize form input values to null
const inputs = { new_password: '', confirm_password: '' };

function ChangeBrandPassword({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    
    const params = new URLSearchParams(search);
    const brandId = params.get('brandId'); // story id from url

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /** Initialize values from props */
    const { show, closeModal } = props;

    /** Redux actions and state */
    const { change_brand_password_loading: isLoading } = useSelector((state) => state.BrandReducer);
    const changeBrandPassword = (params) => dispatch(BrandAction.changeBrandPassword(params));

    /**
     * function to close wallet model
     * @author
     * @created_at 10 July 2023
     */
    const onClose = () => {
        closeModal(false);
    }

    /**
     * function to handle input changes
     * @param object e input object with name and value
     * @author
     * @created_at 10 July 2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(Validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author
     * @created_at 10 July 2023
     */
    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = Validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        data['brand_id'] = brandId;
        setData({ ...data });
        dispatchAction(formData);

    }

    const dispatchAction = async (formData) => {
        changeBrandPassword(data).then((result) => {
            closeModal(false);
        });
    }

    return (
        <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth='sm'>
            <form onSubmit={handleSubmit} noValidate >
                <ConfzModalHeader id="changePassword" onClose={onClose} title={`Change Password`} />
                <ConfzModalBody>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormPassword
                                tabIndex={1}
                                label='New password'
                                name='new_password'
                                value={data.new_password}
                                error={action.isSubmitted && errors.new_password ? errors.new_password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormPassword
                                tabIndex={1}
                                label='Re-enter password'
                                name='confirm_password'
                                value={data.confirm_password}
                                error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <ConfzModalFooter justifyContent={'center'}>
                        <ConfzModalFooterSaveBtn tabIndex={4} text='Confirm' loading={isLoading}/>
                        <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClose} />
                    </ConfzModalFooter>
                </ConfzModalBody>
            </form>
        </ConfzModal>
    )
}

export { ChangeBrandPassword };
