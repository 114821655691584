import { GlobalAction } from ".";
import { FaqServices } from "../services/FaqServices";

export const FAQ_REQUEST = 'FAQ_REQUEST';
export const FAQ_SUCCESS = 'FAQ_SUCCESS';
export const FAQ_FAILURE = 'FAQ_FAILURE';

export const FAQ_CREATE_REQUEST = 'FAQ_CREATE_REQUEST';
export const FAQ_CREATE_SUCCESS = 'FAQ_CREATE_SUCCESS';
export const FAQ_CREATE_FAILURE = 'FAQ_CREATE_FAILURE';

export const UPDATE_FAQ_REQUEST = 'UPDATE_FAQ_REQUEST';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAILURE = 'UPDATE_FAQ_FAILURE';

export const CHANGE_FAQ_STATUS_REQUEST = 'CHANGE_FAQ_STATUS_REQUEST';
export const CHANGE_FAQ_STATUS_SUCCESS = 'CHANGE_FAQ_STATUS_SUCCESS';
export const CHANGE_FAQ_STATUS_FAILURE = 'CHANGE_FAQ_STATUS_FAILURE';

export const FAQ_DETAILS_REQUEST = 'FAQ_DETAILS_REQUEST';
export const FAQ_DETAILS_SUCCESS = 'FAQ_DETAILS_SUCCESS';
export const FAQ_DETAILS_FAILURE = 'FAQ_DETAILS_FAILURE';

export const DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAILURE = 'DELETE_FAQ_FAILURE';

export const SORT_FAQ_REQUEST = 'SORT_FAQ_REQUEST';
export const SORT_FAQ_SUCCESS = 'SORT_FAQ_SUCCESS';
export const SORT_FAQ_FAILURE = 'SORT_FAQ_FAILURE';

export const FaqAction = {

    /**
     * Action for list FAQ
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 10 Jan 2023
     */
    listFaq: (params) => {

        return dispatch => {
            dispatch(request(params));
            return FaqServices.listFaq(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: FAQ_REQUEST, request: request } }
        function success(request, response) { return { type: FAQ_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: FAQ_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating FAQ
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 10 Jan 2023
     */
    createFaq: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FaqServices.createFaq(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: FAQ_CREATE_REQUEST, request: request } }
        function success(request, response) { return { type: FAQ_CREATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: FAQ_CREATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for update FAQ
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 10 Jan 2023
    */
    updateFaq: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FaqServices.updateFaq(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_FAQ_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_FAQ_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_FAQ_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for deleting FAQ
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 10 Jan 2023
    */
    deleteFaq: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FaqServices.deleteFaq(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_FAQ_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_FAQ_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_FAQ_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to change FAQ status 
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 12 Jan 2023
     */
    changeFaqStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FaqServices.changeFaqStatus(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_FAQ_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_FAQ_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_FAQ_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for Sort FAQ
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 16 Jan 2023
    */
    sortFaq: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FaqServices.sortFaq(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };
        function request(request) { return { type: SORT_FAQ_REQUEST, request: request } }
        function success(request, response) { return { type: SORT_FAQ_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SORT_FAQ_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /** 
     * Action to get FAQ details
     * @param { Object } params - The params which are used for the api
     * @param { String } req.body.faq_id
     * @author  Naveen
     * @created_at 13 Jan 2023
    */
    faqDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FaqServices.faqDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };
        function request(request) { return { type: FAQ_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: FAQ_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: FAQ_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}