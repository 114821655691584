import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import Box from '../../_components/box'
import { DashboardAction } from "../../../src/redux/actions";
import { useDispatch } from "react-redux";
import { InfluencerCard, BrandCard, PromoCard, StoryCard, TotalWalletCardCard, TotalMonthWalletCardCard } from './dashboard-cards';
import { TopInfluencerGraph, TopInfluencersByFollowers, TopBrandsByFollowers, TopBrandsGraph } from './';



function Dashboard() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    //** Redux actions and state */
    const getTopInfluencersByFollowers = (params) => dispatch(DashboardAction.getTopInfluencersByFollowers(params));
    const getTopInfluencersByStories = (params) => dispatch(DashboardAction.getTopInfluencersByStories(params));
    const getTopBrandsByFollowers = (params) => dispatch(DashboardAction.getTopBrandsByFollowers(params));
    const getTopBrandsByStories = (params) => dispatch(DashboardAction.getTopBrandsByStories(params));

    useEffect(() => {
        getTopInfluencersByFollowers();
        getTopInfluencersByStories();
        getTopBrandsByFollowers();
        getTopBrandsByStories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box py={3}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <InfluencerCard />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <BrandCard />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <PromoCard />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <StoryCard />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TotalWalletCardCard />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TotalMonthWalletCardCard />
                </Grid>
                <Grid container item xs={12} md={12} lg={12} spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <TopInfluencerGraph />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TopInfluencersByFollowers />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} >
                        <TopBrandsGraph />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TopBrandsByFollowers />
                    </Grid>
                </Grid>
            </Grid>
        </Box>


    )
}

export { Dashboard }
