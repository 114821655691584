import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PostAuthRoutes } from "../../../../routes";
import { APP_NAME, handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from ".";
import { CancelButton, SubmitButton } from "../../../../_components/controls";
import { FormBrowseFile, FormColorInput, FormRichTextEditor } from "../../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../../_components/card";
import { Box, Grid, Stack } from "@mui/material";
import { PromoAction } from "../../../../redux/actions";

// Initialize form input values to null
const inputs = { promo_id: '', title: '', description: '', poster_image: '', primary_color: { r: 18, g: 66, b: 222, a: 1 }, secondary_color: { r: 255, g: 255, b: 255, a: 1 }, text_color: { r: 0, g: 0, b: 0, a: 1 } };

function UpdatePromoPoster() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const promoId = params.get('pId'); // plan promo from url
    const { poster } = location.state ?? {};

    /** Redux actions */
    const updatePromoPoster = (params) => dispatch(PromoAction.updatePromoPoster(params));

    /** Redux reducer states */
    const { update_promo_poster_loading: isLoading } = useSelector((state) => state.PromoReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, 'primary_color': '', secondary_color: '', text_color: '' });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isPosterLoading, setIsPosterLoading] = useState(true);

    useEffect(() => {
        if (poster && poster.title) {
            setData({
                promo_id: poster.promo_id,
                title: poster.title,
                description: poster.description,
                primary_color: ((poster.primary_color) ? (poster.primary_color) : (inputs.primary_color)),
                secondary_color: ((poster.secondary_color) ? (poster.secondary_color) : (inputs.secondary_color)),
                text_color: ((poster.text_color) ? (poster.text_color) : (inputs.text_color)),
                poster_image: poster.poster_image,
            });
            setIsPosterLoading(false);
        } else {
            setData({
                ...data,
                promo_id: promoId,
            });
            setIsPosterLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poster, promoId])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle create/update promo form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        formData.append("promo_id", data.promo_id);
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("primary_color", JSON.stringify(data.primary_color));
        formData.append("secondary_color", JSON.stringify(data.secondary_color));
        formData.append("text_color", JSON.stringify(data.text_color));
        formData.append("poster_image", data.poster_image);
        dispatchUpdatePromoPosterAction(formData);
    }

    /**
     * function to dispatch create/update promotion api
     * @param {Object} formData - The params which are used for the api
     * @param {string} formData.promo_id - Promo id which used for update promo
     * @param {string} formData.name - Promo name
     * @param {string} formData.description - Promo description
     * @param {string} formData.promo_type - Promo type chosen form the select box
     * @param {string} formData.file - Promo sample file
     * @param {string} formData.promo_start_date - Promo start date
     * @param {string} formData.promo_end_date - Promo end date
     * @param {string} formData.number_of_promos - Maximum Promos can post the promo
     * @param {string} formData.payment_method - Promo payment method
     * @param {string} formData.promo_cost_per_influencer - Promo cost per influencer
     * @param {string} formData.coupon_file - Promo coupon code csv file uploaded
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const dispatchUpdatePromoPosterAction = async (formData) => {
        const isCreated = await updatePromoPoster(formData);
        if (isCreated && isCreated.status === 1) {
            history.push({
                pathname: PostAuthRoutes('promo_details').path,
                search: `?pId=${promoId}`
            });
        } else {
            setErrors(isCreated.errors);
        }
    }

    /**
     * function to cancel promo create or update
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const cancelPromo = () => {
        history.push({
            pathname: PostAuthRoutes('promo_details').path,
            search: `?pId=${promoId}`
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('update_poster_details').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={PostAuthRoutes('update_poster_details').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                            <Card >
                                {(!isPosterLoading) && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormRichTextEditor
                                                tabIndex={1}
                                                label='Title'
                                                name='title'
                                                value={data.title}
                                                error={action.isSubmitted && (errors.title ? errors.title : '')}
                                                onChange={handleChange}
                                                autoFocus={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormRichTextEditor
                                                tabIndex={2}
                                                label='Description'
                                                name='description'
                                                value={data.description}
                                                error={action.isSubmitted && (errors.description ? errors.description : '')}
                                                onChange={handleChange}
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormColorInput
                                                label="Primary Color"
                                                name="primary_color"
                                                value={data.primary_color}
                                                onChange={handleChange}
                                                error={action.isSubmitted && (errors.primary_color ? errors.primary_color : '')}
                                            />
                                            <FormColorInput
                                                label="Secondary Color"
                                                name="secondary_color"
                                                value={data.secondary_color}
                                                onChange={handleChange}
                                                error={action.isSubmitted && (errors.secondary_color ? errors.secondary_color : '')}
                                            />
                                            <FormColorInput
                                                label="Text Color"
                                                name="text_color"
                                                value={data.text_color}
                                                onChange={handleChange}
                                                error={action.isSubmitted && (errors.text_color ? errors.text_color : '')}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormBrowseFile
                                                tabIndex={10}
                                                name="poster_image"
                                                error={(action.isSubmitted && (errors.poster_image)) ? (errors.poster_image) : ''}
                                                placeholder="your poster image"
                                                value={data.poster_image}
                                                onChange={handleChange}
                                                acceptType=".png,.jpg,.jpeg"
                                                info="Allowed Format: png, jpg, jpeg | Allowed Maximum Size: 2 MB | Suggested Aspect Ratio: 1080 x 1920"
                                            />
                                        </Grid>

                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label='Update' loading={isLoading} tabIndex={11} />
                                                <CancelButton onClick={cancelPromo} tabIndex={12} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { UpdatePromoPoster };
