import { apiServices } from '../../_helpers';

export const ApprovalServices = {

    /**
     * Service to list plans
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    listApprovals: (params) => {
        return apiServices.post('/admin/approval/list', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get approval request
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    approvalDetails: (params) => {
        return apiServices.post('admin/approval/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get approval request
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    planUpdateApprove: (params) => {
        return apiServices.post('admin/approval/plan-approve', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get approval request
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    planUpdateReject: (params) => {
        return apiServices.post('admin/approval/plan-reject', params)
            .then((response) => { return response; })
    },
};
