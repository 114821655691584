import { StoryServices } from "../services/StoryServices";

export const STORIES_REQUEST = 'STORIES_REQUEST';
export const STORIES_SUCCESS = 'STORIES_SUCCESS';
export const STORIES_FAILURE = 'STORIES_FAILURE';

export const INFLUENCER_STORIES_REQUEST = 'INFLUENCER_STORIES_REQUEST';
export const INFLUENCER_STORIES_SUCCESS = 'INFLUENCER_STORIES_SUCCESS';
export const INFLUENCER_STORIES_FAILURE = 'INFLUENCER_STORIES_FAILURE';

export const STORY_DETAIL_REQUEST = 'STORY_DETAIL_REQUEST';
export const STORY_DETAIL_SUCCESS = 'STORY_DETAIL_SUCCESS';
export const STORY_DETAIL_FAILURE = 'STORY_DETAIL_FAILURE';

export const STORY_TIMELINE_REQUEST = 'STORY_TIMELINE_REQUEST';
export const STORY_TIMELINE_SUCCESS = 'STORY_TIMELINE_SUCCESS';
export const STORY_TIMELINE_FAILURE = 'STORY_TIMELINE_FAILURE';

export const STORY_PROMO_DETAILS_REQUEST = 'STORY_PROMO_DETAILS_REQUEST';
export const STORY_PROMO_DETAILS_SUCCESS = 'STORY_PROMO_DETAILS_SUCCESS';
export const STORY_PROMO_DETAILS_FAILURE = 'STORY_PROMO_DETAILS_FAILURE';

export const EXPORT_STORY_REQUEST = 'EXPORT_STORY_REQUEST';
export const EXPORT_STORY_SUCCESS = 'EXPORT_STORY_SUCCESS';
export const EXPORT_STORY_FAILURE = 'EXPORT_STORY_FAILURE';

export const StoryAction = {


    /**
    * Action for list story
    * @param {Object} params - The params which are used for the api
    * @author 
    * @created_at 15 June 2022
    */
    listStory: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StoryServices.listStory(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: STORIES_REQUEST, request: request } }
        function success(request, response) { return { type: STORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORIES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action to get story details 
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
    */
    listInfluencerStories: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StoryServices.listInfluencerStories(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: INFLUENCER_STORIES_REQUEST, request: request } }
        function success(request, response) { return { type: INFLUENCER_STORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INFLUENCER_STORIES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function to get stories details using sId
     * @param {Object} params - The params which are used for the api
     * @return view
     * @author Jasin
     * @created_at 15 June 2022 
     */
    getStoryDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StoryServices.getStoryDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: STORY_DETAIL_REQUEST, request } }
        function success(request, response) { return { type: STORY_DETAIL_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORY_DETAIL_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },
    /**
     * function to get stories timeline
     * @param {Object} params - The params which are used for the api
     * @return view
     * @author Jasin
     * @created_at 15 June 2022
    */
    getStoryTimeline: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StoryServices.getStoryTimeline(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: STORY_TIMELINE_REQUEST, request } }
        function success(request, response) { return { type: STORY_TIMELINE_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORY_TIMELINE_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action fo get promo details from stories
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
    */
    getPromoDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StoryServices.getPromoDetails(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: STORY_PROMO_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: STORY_PROMO_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORY_PROMO_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to export story csv
    * @param {Object} params - The params which are used for the api
    * @author Jasin
    * @created_at 15 June 2022
    */
    exportStoryCsv: (params) => {

        return dispatch => {
            dispatch(request(params));
            return StoryServices.exportStoryCsv(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: EXPORT_STORY_REQUEST } }
        function success(response) { return { type: EXPORT_STORY_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: EXPORT_STORY_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
}