import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { PreAuthRoutes } from '../../../routes';

import { FormPassword } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';
import { RouteLink } from '../../../_components/controls/route-link';
import { ShortLogo } from '../../../_components/logo/short-logo';
import Box from '../../../_components/box';
import { Grid, Typography } from '@mui/material';

import { ResetPasswordStyle as useStyles, validate } from '.'
import { ForgotPasswordAction } from '../../../redux/actions';
import { SweetAlert } from '../../../_layouts/sweet-alert';

// Initialize form input values to null
const inputs = { password: '', confirm_password: '', email: '', forgot_password_token: '' };

function ResetPassword({ ...props }) {

    /** Initialize plugins and variables */
    let { search } = useLocation();
    const params = new URLSearchParams(search);
    const email = params.get('email'); // email from url
    const forgotPasswordToken = params.get('token'); // token from url

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { reset_password_loading: isLoading, reset_password_errors: resetPasswordErrors } = useSelector((state) => state.ForgotPasswordReducer);
    const resetPassword = (params) => dispatch(ForgotPasswordAction.resetPassword(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setData({ ...data, 'forgot_password_token': forgotPasswordToken, 'email': email });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, forgotPasswordToken])

    useEffect(() => {
        setErrors({ ...resetPasswordErrors });
    }, [resetPasswordErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Jasin
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Jasin
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchResetPasswordAction();
    }

    /**
     * function to dispatch send password reset link
     * @author Akshay N
     * @created_at 14 June 2022
     */
    async function dispatchResetPasswordAction() {
        const isPasswordChanged = await resetPassword(data);
        if (isPasswordChanged.status === 1) {
            SweetAlert.fire({
                title: 'Password Changed',
                text: "Password changed successfully",
                icon: 'success',
                confirmButtonText: 'Ok!',
                reverseButtons: true,
                showCancelButton: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    let redirectPath = PreAuthRoutes('login').path;
                    history.push(redirectPath);
                }
            })
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('reset_password').name}`}</title>
            </Helmet>
            <PreSignIn style={{ maxWidth: `430px` }}>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} >
                            <Box variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mt={-8} p={2} mb={4} textAlign="center" >
                                <Box >
                                    <ShortLogo width="50" height="50" />
                                </Box>
                                <Typography variant="h5" fontWeight="medium">{`${PreAuthRoutes('reset_password').name}`}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPassword
                                tabIndex={1}
                                label='Password'
                                name='password'
                                value={data.password}
                                error={action.isSubmitted && errors.password ? errors.password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPassword
                                tabIndex={2}
                                label='Re-enter password'
                                name='confirm_password'
                                value={data.confirm_password}
                                error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.actionBox} mt={2}>
                        <RouteLink to={PreAuthRoutes('login').path} label={PreAuthRoutes('login').name} sx={{ width: '20%' }} />
                        <SubmitButton label={'Change Password'} fullWidth loading={isLoading} />
                    </Box>
                </form>

            </PreSignIn>
        </React.Fragment>
    );
}

export { ResetPassword };
