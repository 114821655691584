import React, { useState, useEffect } from 'react'
import { Chip, Grid, Stack } from '@mui/material';
import { ConfzModal, ConfzModalBody, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../_components/modal';
import { handleInputChange } from '../../../_helpers';
import { FormSelect, FormDateRangePicker } from '../../../_components/form';

// Initialize form input values to null
const inputs = { date: '', startDate: '', endDate: '', status: '' };

// Declare filter status select options
const filterStatus = [
    { value: 'replied', label: 'Replied' },
    { value: 'not-replied', label: 'Not-Replied' }
]

function ContactUsFilter({ ...props }) {

    /** Initialize and declare state */
    const { contactUsFilter, show, closeModal, applyFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        setData({ status: contactUsFilter.status, date: contactUsFilter.date, startDate: contactUsFilter.startDate, endDate: contactUsFilter.endDate })

        let filtered = {};
        Object.keys(contactUsFilter).forEach(function (item, i) {
            if (contactUsFilter[item])
                filtered[item] = contactUsFilter[item];
        });
        setFilteredData({ ...filtered });
    }, [contactUsFilter])

    /**
     * function to close ContactUs filter modal
     * @param {string} status Entered status on ContactUs filter modal
     * @param {string} month Entered month on ContactUs filter modal 
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const onClose = () => {
        setData({ status: '', date: '', startDate: '', endDate: '' });
        closeModal(false);
    }

    /**
     * function to clear ContactUs filter modal
     * @param {string} status Entered status on ContactUs filter modal
     * @param {string} month Entered month on ContactUs filter modal 
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const onClear = () => {
        setData({ status: '', date: '', startDate: '', endDate: '' });
        applyFilter({ status: '', date: '', startDate: '', endDate: '' });
        closeModal(false);
    }

    /**
     * Function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Sarath
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        closeModal(false)
        applyFilter(data);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Jasin
     * @created_at 14 June 2022 
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data })
    }

    /**
     * function to clear all data entered on ContactUs filter modal
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });

    }

    return (
        <React.Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction='row' spacing={1}>
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        return <Chip keys={1} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id='contactUsFilter' onClose={onClose} title="Filter" />
                    <ConfzModalBody>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    className="col-md-6"
                                    value={data.status}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <FormDateRangePicker
                                    tabIndex={2}
                                    label='Created On'
                                    name='date'
                                    placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                    onChange={handleChange}
                                    value={data.date}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}  >
                                    <ConfzModalFooterSaveBtn tabIndex={4} text="Filter" />
                                    <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClear} text="Clear" />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ConfzModalBody>
                </form>
            </ConfzModal>
        </React.Fragment>
    );
}

export { ContactUsFilter }