
import {
    CONTACT_US_REQUEST, CONTACT_US_SUCCESS, CONTACT_US_FAILURE,  
    CHANGE_CONTACT_US_STATUS_REQUEST, CHANGE_CONTACT_US_STATUS_SUCCESS, CHANGE_CONTACT_US_STATUS_FAILURE,
    CONTACT_US_DETAILS_REQUEST, CONTACT_US_DETAILS_SUCCESS, CONTACT_US_DETAILS_FAILURE,
    ADD_CONTACT_US_REPLY_REQUEST, ADD_CONTACT_US_REPLY_SUCCESS, ADD_CONTACT_US_REPLY_FAILURE,

} from '../actions'

const initialState = {
    contact_us_loading: false, contact_us: [], contact_us_errors: {},
    change_contact_us_status_loading: false, change_contact_us_status_errors: {},
    page: 1, per_page: 100, total_pages: 0, 
    contact_us_details_loading: false, contact_us_details: {}, contact_us_details_errors: {},
    add_contact_us_reply_loading: false, add_contact_us_reply_errors: [],

};

export function ContactUsReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List Contact Us */
        case CONTACT_US_REQUEST: {
            return {
                ...state,
                contact_us_loading: true,
                contact_us_errors: {}
            };
        }
        case CONTACT_US_SUCCESS: {

            let newArray = [...state.contact_us]; 

            if (action.data.page === 1 ) {
                newArray = []
            }
  
            action.data.contactUs.forEach((payload) => {
                const contactIndex = newArray.findIndex(contact_us => contact_us._id === payload._id);
                if (contactIndex !== -1) {
                    newArray[contactIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                };
            });

            return {
                ...state,
                contact_us_loading: false,
                contact_us: action.data.contactUs,
                contact_us_errors: {},
                per_page: action.data.per_page,
                page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }
        case CONTACT_US_FAILURE: {
            return {
                ...state,
                contact_us_loading: false,
                contact_us_errors: action.errors,
                contact_us: []
                
            };
        }
        /** end::List Contact Us */

        /** begin::Change contact us status redux */
        case CHANGE_CONTACT_US_STATUS_REQUEST: {
            return {
                ...state,
                change_contact_us_status_loading : true,
                change_contact_us_status_errors: {}
            };
        }
        case CHANGE_CONTACT_US_STATUS_SUCCESS: {
              
            let statusArray = [...state.contact_us];      
            const contactIndex = statusArray.findIndex(contact_us => contact_us._id === action.data._id );

            if (contactIndex !== -1) {
                statusArray[contactIndex].status = action.data.status;
            }

            return {
                ...state,
                change_contact_us_status_loading: false,
                change_contact_us_status_errors: {},
                contact_us: statusArray
            };
        }        
        case CHANGE_CONTACT_US_STATUS_FAILURE: {
            return {
                ...state,
                change_contact_us_status_loading: false,
                change_contact_us_status_errors: action.errors
            };
        }
         /** end:: Change contact us status redux */

        /** begin::Contact us details redux */
        case CONTACT_US_DETAILS_REQUEST: {
            return {
                ...state,
                contact_us_details_loading: true,
                contact_us_details_errors: {}
            };
        }
        case CONTACT_US_DETAILS_SUCCESS: {

            return {
                ...state,
                contact_us_details_loading: false,
                contact_us_details: action.data,
                contact_us_details_errors: {}
            };
        }
        case CONTACT_US_DETAILS_FAILURE: {
            return {
                ...state,
                contact_us_details_loading: false,
                contact_us_details_errors: action.errors
            };
        }
        /** end::ticket details redux */

        /** begin::Add reply redux */
        case ADD_CONTACT_US_REPLY_REQUEST: {
            return {
                ...state,
                add_contact_us_reply_loading: true,
                add_contact_us_reply_errors: {}
            };
        }
        case ADD_CONTACT_US_REPLY_SUCCESS: {
            let newArray = state.contact_us_details;

            let replies = newArray.replies;
            replies = replies.concat(action.data);
            newArray.replies = replies

            return {
                ...state,
                add_contact_us_reply_loading: false,
                add_contact_us_reply_errors: {},
                ticket_details: newArray,

            };
        }
        case ADD_CONTACT_US_REPLY_FAILURE: {
            return {
                ...state,
                add_contact_us_reply_loading: false,
                add_contact_us_reply_errors: action.errors
            };
        }
        /** end::Add reply redux */
        default:
            return state;
    }
}
