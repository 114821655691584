import React, { useState } from 'react'
import { FilterAlt } from '@mui/icons-material'
import { Box, Grid, Stack } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { PostAuthRoutes } from '../../routes'
import { Card, CardContent, CardHeader } from '../../_components/card'
import { GeneralButton, RouteLink } from '../../_components/controls'
import { FormSearchInput } from '../../_components/form'
import { UpdateStatusModal } from '../../_components/modal'
import { BrandCostAction } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { BrandCostDataTable, BrandCostFilter, DefaultBrandPrice } from '.'

function BrandCost() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '', cost_id: '' });

    const [showBrandCostFilterModal, setShowBrandCostFilterModal] = useState(false);
    const [payload, setPayload] = useState({ limit: 100, page: 1, filter: { status: searchParams.get('status') ?? '' }, search: searchParams.get('q') ?? '' });

    /** Redux actions and state */
    const changeStatus = (params) => dispatch(BrandCostAction.changeStatus(params));

    /**
     * function to open/close update Brand Cost Filter model
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status, item, cost_id }) => {
        setStatusData({ id: id, status: status, item: '', cost_id });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update Bran dCost 
     * @param {string} null 
     * @return view
     * @author
     * @created_at 21 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'brand_id': id, cost_id: statusData.cost_id });
    }

    /**
     * function to open/close Brand Cost filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 21 June 2022
     */
    const brandCostFilterModal = () => {
        setShowBrandCostFilterModal(!showBrandCostFilterModal);
    }

    /**
     * function to apply the selected filter options on the Brand Cost list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the Brand Cost filter modal
     * @return view
     * @author 
     * @created_at 21 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the permission list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('brand_cost').name} action={
                    <Stack spacing={1} direction="row">
                        <RouteLink to={PostAuthRoutes('create_brand_cost').path} label={<GeneralButton label={PostAuthRoutes('create_brand_cost').name} />} />
                        <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        <GeneralButton label={<FilterAlt />} onClick={() => brandCostFilterModal()} />
                    </Stack>
                } />
            </Box>
            <BrandCostFilter show={showBrandCostFilterModal} closeModal={brandCostFilterModal} applyFilter={applyFilter} brandCostFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <DefaultBrandPrice />
                            <BrandCostDataTable statusUpdateModal={statusUpdateModal} filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />

        </React.Fragment>

    )
}

export { BrandCost }