import { apiServices } from '../../_helpers';


export const ForgotPasswordServices = {

	/**
	 * Service for send password reset link 
	 * @param {Object} params - The params which are used for change password api.
	 * @param {string} params.email - Email of the admin user
	 * @author Akshay 
	 * @created_at 15 April 2022
	 */
	sendPasswordResetLink: (params) => {

		return apiServices.post('/admin/send-password-reset-link', params)
			.then((response) => { return response; })
	},

	/**
	 * Service for send password reset link 
	 * @param {Object} params - The params which are used for change password api.
	 * @param {string} params.email - Email of the admin user
	 * @author Jasin
	 * @created_at 15 April 2022
	 */
	resetPassword: (params) => {

		return apiServices.post('/admin/reset-password', params)
			.then((response) => { return response; })
	},

}
