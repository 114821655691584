import React, { useState } from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { BrandWelcomeMessageModal } from "./BrandWelcomeMessageModal";
import { useSelector } from "react-redux";

function BrandInfluencerWelcomeMessage({ ...props }) {
    const [showModal, setShowModal] = useState(false);


    /** Redux actions and state */
    const { brand_send_influencer_welcome_message: isSendMessage } = useSelector((state) => state.BrandReducer);

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        updateSendMessage();
    }

    /**
     * function to close plans update modal
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const closeModal = () => {
        setShowModal(!showModal);
    }

    /**
     * function to create wallet
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const updateSendMessage = () => {
        setShowModal(!showModal);
    }
    return (
        <React.Fragment>
            <FormGroup>
                <FormControlLabel labelPlacement='start' control={<Switch checked={isSendMessage} onChange={handleChange} />} label={`Do you want to ${(isSendMessage === false) ? 'send' : 'disable'}  Ad Run welcome Instagram message to the influencer?`} />
            </FormGroup>

            <BrandWelcomeMessageModal show={showModal} closeModal={closeModal} />

        </React.Fragment>
    )
}

export { BrandInfluencerWelcomeMessage };
