import React from 'react';
import { Box, Card, Grid, Skeleton } from '@mui/material';

function NotificationsCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 10; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}  {...props}>
                <Card style={{ marginBottom: 30 }}>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: `100%`, padding: 0 }}>
                        <Skeleton variant="rectangular" height="100px" width="100%" sx={{ borderRadius: 3 }} />
                    </Box>
                </Card>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { NotificationsCardLoader };