import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, Container, Grid } from "@mui/material";
import { PostAuthRoutes } from "../../../routes";
import { ContactUsDetailsCard, AddReply, ContactUsReplies } from "./";
import { CardHeader } from "../../../_components/card";
import { ContactUsAction } from "../../../redux/actions";

function ContactUsDetails({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const contactUsId = params.get('cId'); // plan promo from url

    /** Redux actions and state */
    const { contact_us_details_loading: isLoading, contact_us_details } = useSelector((state) => state.ContactUsReducer);
    const contactUsDetails = (params) => dispatch(ContactUsAction.contactUsDetails(params));

    useEffect(() => {
        contactUsDetails({ contact_us_id: contactUsId });
        //eslint-disable-next-line
    }, [contactUsId])

    return (
        <React.Fragment>
            <CardHeader title={PostAuthRoutes('contact_us_details').name} />
            {!isLoading && Object.keys(contact_us_details).length > 0 &&
                <Container>
                    <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <ContactUsDetailsCard contact_us_details = {contact_us_details}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Card sx={{ mb: 1, minHeight: `710px` }}>
                                <CardContent sx={{ p: `2rem !important` }}>
                                    <ContactUsReplies />
                                    <AddReply />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            }
        </React.Fragment>
    );
}

export { ContactUsDetails }