import { FilterAlt } from '@mui/icons-material'
import { Box, Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PostAuthRoutes } from '../../routes'
import { CardHeader, Card, CardContent } from '../../_components/card'
import { FormSearchInput } from '../../_components/form'
import { GeneralButton } from '../../_components/controls'
import { TestimonialDataTable } from './'
import { TestimonialFilter } from './testimonial-filter'
import { AbilityCan } from '../../_helpers/permission/AbilityCan'

function Testimonial() {

    /** Initialize plugins and variables */
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    let history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [showTestimonialFilterModal, setShowTestimonialFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: {
            status: searchParams.get('status') ?? '',
        },
        search: searchParams.get('q') ?? ''
    });

    /**
     * function to create Testimonial
     * @param {string} null
     * @return view
     * @author
     * @created_at 15 June 2022
     */
    function createTestimonial() {
        history.push({
            pathname: PostAuthRoutes('create_testimonial').path,

        });
    }

    /**
     * function to open/close Testimonial filter modal
     * @param {string} null
     * @return view
     * @author Naveen
     * @created_at 15 June 2022
     */
    const testimonialFilterModal = () => {
        setShowTestimonialFilterModal(!showTestimonialFilterModal);
    }

    /**
     * function to apply the selected filter options on the testimonial list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the testimonial filter modal
     * @return view
     * @author Naveen
     * @created_at 15 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the testimonial list
     * @param {String} text Search keyword used
     * @return view
     * @author Naveen
     * @created_at 15 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('testimonial').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='admin_testimonials_create' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment>Create</React.Fragment>} onClick={() => createTestimonial()} />
                        </AbilityCan>
                        <AbilityCan I='admin_testimonials_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='admin_testimonials_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment><FilterAlt /></React.Fragment>} onClick={() => testimonialFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <TestimonialFilter show={showTestimonialFilterModal} closeModal={testimonialFilterModal} applyFilter={applyFilter} testimonialFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <TestimonialDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </React.Fragment>

    )

}

export { Testimonial }