import { apiServices } from '../../_helpers';

export const BrandCostServices = {

    /**
     * Service for List brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
     */

    listBrandCost: (params) => {
        return apiServices.post('admin/brand-cost/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for add brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
     */
    addBrandCost: (params) => {
        return apiServices.put('admin/brand-cost/add', params)
            .then((response) => { return response; })
    },

    /**
     * Service for update brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
     */
    updateBrandCost: (params) => {
        return apiServices.put('admin/brand-cost/update', params)
            .then((response) => { return response; })
    },

    /**
     * Service for change status brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
     */
    changeStatus: (params) => {
        return apiServices.put('admin/brand-cost/change-status', params)
            .then((response) => { return response; })
    },

    /**
     * Service for add brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
    */
    addDefaultPrice: (params) => {
        return apiServices.post('admin/master/create', params)
            .then((response) => { return response; })
    },

    /**
     * Service for update brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
    */
    updateDefaultPrice: (params) => {
        return apiServices.put('admin/master/update', params)
            .then((response) => { return response; })
    },

    /**
     * Service for update brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
    */
    getDefaultPriceDetails: (params) => {
        return apiServices.post('admin/master/get-master', params)
            .then((response) => { return response; })
    },
}
