import React from 'react';
import { Box, CardContent, Card, Grid, Skeleton } from '@mui/material';

function ChangePasswordCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 1; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i} style={{ paddingTop: '10px', height: `470px` }} {...props}>
                <Card style={{ height: `100%` }}>
                    <CardContent style={{ position: "relative", height: `350px`, top: 0, right: 0, left: 0, bottom: 0 }}>
                        <Grid container spacing={0} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
                                <Box style={{ paddingTop: 60, minHeight: `10px`, paddingLeft: `30px`, marginBottom: 50 }}>
                                    <Skeleton animation="wave" height={40} width="50%" style={{ marginBottom: 0 }} />
                                </Box>
                                <CardContent style={{ paddingTop: 0, minHeight: `90px`, paddingBottom: 90 }} >
                                    <Skeleton animation="wave" height={60} width="90%" style={{ marginBottom: 1, marginLeft: 30 }} />
                                    <Skeleton animation="wave" height={60} width="90%" style={{ marginBottom: 1, marginLeft: 30 }} />
                                    <Skeleton animation="wave" height={60} width="90%" style={{ marginBottom: 1, marginLeft: 30 }} />
                                    <Skeleton animation="wave" height={60} width="90%" style={{ marginBottom: 1, marginLeft: 30 }} />
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                <CardContent style={{ paddingTop: `60px`, minHeight: `120px`, paddingLeft: `30px` }} >
                                    <Skeleton variant="rectangular" animation="wave" height={300} width="100%" style={{ marginBottom: 5, borderRadius: 8 }} />
                                </CardContent>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { ChangePasswordCardLoader };