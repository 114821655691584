import React, { useEffect, useState } from "react";
import { CardHeader as PageHeader } from "../../../_components/card";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PostAuthRoutes } from "../../../routes";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { CouponCodeCard } from "../../coupon-codes";
import { GiveawayAction } from "../../../redux/actions";
import { GiveawayCard, GiveawayCardLoader } from "../giveaway-card";
import { GiveawayWinnerCard } from "../giveaways-winner-card";
import { NoData } from "../../../_components/layout";
import { Tabs } from "../../../_components/tabs";
import { GiveawayCouponCodes } from "../giveaway-coupon-codes";
import { GiveawayEntries } from "./giveaway-entries";


function GiveawayDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const giveawayId = params.get('gId'); // plan promo from url

    /** Redux actions and state */
    const { brand_giveaway_details_loading: isLoading, brand_giveaway_details: giveaway_details } = useSelector((state) => state.GiveawayReducer);
    // const { brand_giveaway_coupon_codes: coupon_codes} = useSelector((state) => state.GiveawayReducer);
    const { giveaway_winner_loading: isWinnerLoading, giveaway_winner } = useSelector((state) => state.GiveawayReducer);
    const giveawayDetails = (params) => dispatch(GiveawayAction.giveawayDetails(params));
    const listBrandGiveawayCouponCodes = (params) => dispatch(GiveawayAction.listBrandGiveawayCouponCodes(params));
    const getGiveawayWinner = (params) => dispatch(GiveawayAction.getGiveawayWinner(params));
    const getRunningGiveaways = (params) => dispatch(GiveawayAction.getRunningGiveaways(params));

    /** Initialize and declare state */
    const [couponCodesPayload, setCouponCodesPayload] = useState({ limit: 100, page: 1 });

    useEffect(() => {
        giveawayDetails({ giveaway_id: giveawayId });
        getGiveawayWinner({ giveaway_id: giveawayId });

        //eslint-disable-next-line
    }, [giveawayId])

    useEffect(() => {

        getNextGiveawayCouponCodes() // Initially load participants list
        getRunningGiveaways();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to list giveaways
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNextGiveawayCouponCodes = async () => {
        let giveawayCouponCodesResult = await listBrandGiveawayCouponCodes({ giveaway_id: giveawayId, ...couponCodesPayload });
        if (giveawayCouponCodesResult.status)
            setCouponCodesPayload({ ...couponCodesPayload, page: couponCodesPayload.page + 1 });
    }


    // function CouponCodeTabPanel() {
    //     return (
    //         <InfiniteScroll dataLength={coupon_codes.length} next={getNextGiveawayCouponCodes} hasMore={true} >
    //             <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
    //                 {
    //                     coupon_codes.length > 0 && coupon_codes.map((coupon_code, i) => {
    //                         return <Grid key={i} item xs={12} sm={12} md={3} lg={3} xl={4}><CouponCodeCard {...coupon_code} height='170px' isClickable={true} hasAction={false} canChangeStatus={false} /></Grid>;
    //                     })
    //                 }
    //             </Grid>
    //         </InfiniteScroll>
    //     )
    // }

    function GiveawayDetailsTabPanel() {
        return (
            <React.Fragment>
                {(!isLoading && Object.keys(giveaway_details).length > 0) &&
                    <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                        <GiveawayWinnerCard isLoading={isWinnerLoading} giveaway_winner={giveaway_winner} />
                        <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                            <Box sx={{ maxWidth: `1000px` }}>
                                <GiveawayCard giveaway={giveaway_details} hasAction={false} />
                            </Box>
                        </Box>
                    </Grid>
                }
                {(isLoading) && (
                    <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                        <GiveawayCardLoader count={1} />
                    </Grid>
                )}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <PageHeader title={PostAuthRoutes('giveaway_details').name} />
            {!isLoading && Object.keys(giveaway_details).length === 0 && <NoData content1={`No`} content2={`Promo available`} minHeight={`55vh`} ></NoData>}
            <Tabs
                groupName={'gaTab'}
                data={[
                    { slug: 'giveaway', label: "Giveaway", component: <GiveawayDetailsTabPanel /> },
                    { slug: 'entries', label: "Giveaway Entries", component: <GiveawayEntries /> },
                    { slug: 'codes', label: "Coupon Codes", component: <GiveawayCouponCodes />, disabled: ((Object.keys(giveaway_details).length > 0 && (giveaway_details.send_coupon_code !== 'no')) ? false : true) }
                ]}
            />
        </React.Fragment>
    );
}

export { GiveawayDetails }