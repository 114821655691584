import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContactUsAction } from '../../redux/actions';
import { PostAuthRoutes } from "../../routes";
import queryString from 'query-string';
import { ButtonGroup, Chip, Button } from '@mui/material';
import { DataTable } from '../../_components/data-table/DataTable';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import { useHistory } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { AbilityCan } from '../../_helpers/permission/AbilityCan'


function ContactUsDataTable({ statusUpdateModal, filterAction, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { per_page, total, contact_us_loading: isLoading, contact_us } = useSelector((state) => state.ContactUsReducer);
    const listContactUS = (params) => dispatch(ContactUsAction.listContactUS(params));

    /** Initialize and declare state */
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [search] = useState('');
    const [filter, setFilter] = useState({ filter_status: '' });
    const [resetPagination, setResetPagination] = useState(0);
    const filterData = props.filterData;
    const limit = useState(100);

    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: "Name", selector: (row, i) => (row.name), sortField: 'name', sortable: true, center: true, width: '13%' },
        { name: "Insta Handle", selector: (row, i) => (row.insta_handle), sortField: 'subject', sortable: true, center: true, width: '13%' },
        { name: "Email", selector: (row, i) => (row.email), sortField: 'description', sortable: true, center: true, width: '13%' },
        { name: "Mobile Number", selector: (row, i) => (row.mobile_number), sortField: 'mobile_number', sortable: 'true', center: true, width: '13%' },
        { name: "Message", selector: (row, i) => (row.message), sortField: 'message', sortable: true, center: true, width: '23%' },
        { name: "Created Date", selector: (row, i) => (row.created_at.slice(0, 10)), sortField: 'created_at', sortable: true, center: true, width: '10%' },
        { name: "Status", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '10%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author
     * @created_at 14 June 2022
     */
    const StatusRow = ({ row }) => {

        function handleDetailsClick(row) {
            let redirectPath = `${PostAuthRoutes('contact_us_details').path}?cId=${row._id}`;
            history.push(redirectPath);
        }

        /**
         * function to change status of the row
         * @param {string} row._id - Id of row
         * @param {string} row.status - status of row
         * @return json
         * @author
         * @created_at 14 June 2022
         */
        function handleChangeStatusClick(row) {
            setTimeout(() => {
                statusUpdateModal({ id: row._id, status: row.status, item: '' });
            }, 100);
        }

        let status = <Chip label={row.status} className="badge-status capitalize" />
        if (row.status === 'replied')
            status = <Chip label={row.status} color="success" className="badge-status capitalize" />
        else if (row.status === 'not-replied')
            status = <Chip label={row.status} color="error" className="badge-status capitalize" />

        let statusChange = (

            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='admin_contact_us_details' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => handleDetailsClick(row)}> <Visibility /></Button>
                </AbilityCan>
                <AbilityCan I='admin_contact_us_change_status' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => handleChangeStatusClick(row)}> <ChangeCircleIcon /></Button>
                </AbilityCan>
            </ButtonGroup>
        )
        return <React.Fragment>{status}{statusChange} </React.Fragment>
    }

    useEffect(() => {

        var queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `${PostAuthRoutes('contact_us').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        listContactUS({ page, search, sort, filter, limit });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, sort, filter])

    useEffect(() => {
        if (filterAction) {
            setResetPagination(!resetPagination)
            setPage(1);
            setFilter({ filter_status: filterData.filter_status })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterAction])

    /**
     * function to sort columns
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={contact_us}
            total={total}
            per_page={per_page}
            setPage={page => setPage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />

    )
}
export { ContactUsDataTable }