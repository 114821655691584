import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from "react-helmet";

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { PostAuthRoutes, PreAuthRoutes } from '../../../routes';

import { FormInput, FormPassword } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';
import { RouteLink } from '../../../_components/controls/route-link';

import { Grid, Typography } from '@mui/material';
import { SignInAction } from '../../../redux/actions';
import { ShortLogo } from '../../../_components/logo';
import Box from '../../../_components/box';
import { SignInStyle, validate } from '.'

// Initialize form input values to null
const inputs = { email: '', password: '' };

function SignIn() {

    /** Initialize plugins and variables */
    const classes = SignInStyle();
    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { loading: isLoading, errors: signinErrors } = useSelector((state) => state.SignInReducer);
    const signIn = (params) => dispatch(SignInAction.signIn(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...signinErrors });
    }, [signinErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSignInAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     * @created_at 14 June 2022
     */
    async function dispatchSignInAction() {
        const isSignin = await signIn(data);
        if (isSignin.status === 1) {
            let redirectPath = PostAuthRoutes('home').path;
            history.push(redirectPath);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('login').name}`}</title>
            </Helmet>
            <PreSignIn >
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} >
                            <Box variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mt={-8} p={2} mb={4} textAlign="center" >
                                <Box >
                                    <ShortLogo height="50" width="50" />
                                </Box>
                                <Typography variant="h5" fontWeight="medium">{`${PreAuthRoutes('login').name}`}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormInput
                                tabIndex={1}
                                label='Email'
                                name='email'
                                value={data.email}
                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                onChange={handleChange}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPassword
                                tabIndex={1}
                                label='Password'
                                name='password'
                                value={data.password}
                                error={action.isSubmitted && errors.password ? errors.password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="left" className={classes.forgotPasswordAction}>
                        <RouteLink to={PreAuthRoutes('forgot_password').path} label={PreAuthRoutes('forgot_password').name} />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.actionBox}>
                        <SubmitButton label={'Log In'} fullWidth loading={isLoading} />
                    </Box>
                </form>
            </PreSignIn>
        </React.Fragment>
    );
}

export { SignIn };
