import React, { useEffect, useState } from 'react'
import { FormLabel } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";
import PropTypes from 'prop-types'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { useStyles } from './FormColorInputStyle';

function FormColorInput({ label, name, value, className, onChange, error, info, ...props }) {

    const classes = useStyles();

    const [color, setColor] = useState({ r: '241', g: '112', b: '19', a: '1' });
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    useEffect(() => {
        setColor({ ...value });
    }, [value])

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            swatch: {
                padding: '5px',
                marginLeft: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '3',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });




    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (e) => {
        let target = {
            'name': name,
            'value': e.rgb,
            'type': 'color'
        }
        setColor({ ...e.rgb })
        onChange({ target })

    };


    return (

        <FormControl fullWidth className={classes.formControl}  >
            <FormLabel htmlFor={name} className={classes.label}>{label}</FormLabel>
            <span className={` ml-auto`}>
                    <div style={styles.swatch} onClick={handleClick}>
                        <div style={styles.color} />
                    </div>
                    {displayColorPicker ? <div style={styles.popover}>
                        <div style={styles.cover} onClick={handleClose} />
                        <SketchPicker color={color} onChange={handleChange} />
                    </div> : null}

                </span>
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>

    )
}

FormColorInput.defaultProps = {
    className: "",
}

FormColorInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
}

export { FormColorInput }








