import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PostAuthRoutes } from "../../../routes";
import { handleInputChange, validateForm, APP_NAME } from "../../../_helpers";

import Box from "../../../_components/box";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormInput, FormSelect } from "../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { RoleAction } from "../../../redux/actions";
import { validate } from "./validate";

// Initialize form input values to null
const inputs = { name: '', portal: '' };

// Declare portal type values
const portalType = [
	{ value: 'admin', label: 'Admin' },
	{ value: 'brand', label: 'Brand' },
	{ value: 'influencer', label: 'Influencer' },
];

function CreateRole() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Role id and details on update page */
    const location = useLocation();
    const { role } = location.state ?? {};

    /** Redux actions and state */
    const createRole = (params) => dispatch(RoleAction.createRole(params));
    const UpdateRole = (params) => dispatch(RoleAction.UpdateRole(params));
    const { create_role_loading: isLoading, create_role_errors: createRoleErrors } = useSelector((state) => state.RoleReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...createRoleErrors });
    }, [createRoleErrors])

    /**
     * function to handle input changes and modify the value
     * @author
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    useEffect(() => {
        if (role && role._id) {
            setData({
				role_id: role._id,
				name: role.name,
				portal: role.portal
			});
        }
    }, [role])

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        const formData = {
            "role_id": data.role_id,
            "name": data.name,
            "portal": data.portal
        }

        addRole(formData);
    }

    /**
     * function to create/update role
     * @author
     * @created_at 14 June 2022
     */
    async function addRole(formData) {
        const isCreated = role && role._id ? await UpdateRole(formData) : await createRole(formData)
        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('role').path;
            history.push(redirectPath);
        }

    }

    /**
     * function to cancel create/update role
     * @author
     * @created_at 14 June 2022
     */
    function cancelRole() {
        let redirectPath = PostAuthRoutes('role').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${role && role._id ? PostAuthRoutes('update_role').name : PostAuthRoutes('create_role').name}`}</title>
            </Helmet>
            <CardHeader title={role && role._id ? PostAuthRoutes('update_role').name : PostAuthRoutes('create_role').name} />
            <Box py={3} paddingLeft='350px'>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ width: '70%' }}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Card >
                                <CardContent >
                                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                                        <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                            <Grid container spacing={3}>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormInput
                                                        tabIndex={4}
                                                        label='Name'
                                                        name='name'
                                                        value={data.name}
                                                        error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                                        onChange={handleChange}
                                                        maxLength={250}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormSelect
                                                        tabIndex={3}
                                                        label={`Portal`}
                                                        name={`portal`}
                                                        placeholder={`Select portal`}
                                                        onChange={handleChange}
                                                        data={portalType}
                                                        error={action.isSubmitted && errors.portal ? errors.portal : ''}
                                                        className="col-md-6"
                                                        value={data.portal}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={role && role._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={6} />
                                                <CancelButton onClick={cancelRole} tabIndex={12} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )

}

export { CreateRole };