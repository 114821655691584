import { apiServices } from '../../_helpers';

export const StoryServices = {

  /**
   * Service for list Story
   * @param {Object} params - The params which are used for the api
   * @author  
   * @created_at 15 June 2022
   */
  listStory: (params) => {
    return apiServices.post('/admin/story', params)
      .then((response) => { return response; })

  },
  /**
   * Service to fetch story details
   * @param {Object} params - The params which are used for the api
   * @author  
   * @created_at 15 June 2022
   */
  listInfluencerStories: (params) => {
    return apiServices.post('admin/influencer/stories', params)
      .then((response) => { return response; })
  },
  /**
    * Service for get story details using sId
    * @param {Object} params - The params which are used for the api
    * @author Jasin 
    * @created_at 15 June 2022
  */
  getStoryDetails: (params) => {
    return apiServices.post('/admin/story/detail', params)
      .then((response) => { return response; })
  },

  /**
    * Service for get story timeline
    * @param {Object} params - The params which are used for the api
    * @author Jasin 
    * @created_at 15 June 2022
  */
  getStoryTimeline: (params) => {
    return apiServices.post('/admin/story/timeline', params)
      .then((response) => { return response; })
  },

  /**
    * Service to get promo details from stories
    * @param {Object} params - The params which are used for the api
    * @author Jasin 
    * @created_at 15 June 2022
  */
  getPromoDetails: (params) => {
    return apiServices.post('/admin/story/promo-details', params)
      .then((response) => { return response; })

  },

  /**
    * Service to export story CSV
    * @param {Object} params - The params which are used for the api
    * @author Jasin 
    * @created_at 15 June 2022
  */
  exportStoryCsv: (params) => {
    return apiServices.post('/admin/story/export', params)
      .then((response) => { return response; })

  },
}