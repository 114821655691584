import { apiServices } from '../../_helpers';


export const AdvertisementServices = {

    /**
     * Service for list Advertisement
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    listAdvertisement: (params) => {
        return apiServices.post('/admin/advertisement', params)
            .then((response) => { return response; })

    },

    /**
     * Service for Creating Advertisement
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    createAdvertisement: (params) => {

        return apiServices.post('/admin/advertisement/create', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /**
     * Service for change influencer status
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
    */
    changeStatus: (params) => {
        return apiServices.put('/admin/advertisement/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for update advertisement
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
    */
    updateAdvertisement: (params) => {
        return apiServices.put('admin/advertisement/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

    /**
     * Service for deleting an advertisement
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
    */
    deleteAdvertisement: (params) => {
        return apiServices.put('admin/advertisement/delete', params)
            .then((response) => { return response; })
    },
}