module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 14 June 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = {};
        switch (name) {
            case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid';
                if (value === '')
                    errors.email = 'Enter an email address';
                break;
            } case 'password': {
                errors.password = value.length < 6 ? 'Password must be 6 characters long.' : '';
                if (value === '')
                    errors.password = 'Enter password.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}