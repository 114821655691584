import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { APP_NAME, handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from ".";
import Box from "../../../../_components/box";
import { CancelButton, SubmitButton } from "../../../../_components/controls";
import {FormInput } from "../../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { BrandCategoryAction } from "../../../../redux/actions";
import { PostAuthRoutes } from "../../../../routes";

// Initialize form input values to null
const inputs = { brand_category_id: '', brand_category: '' };

function CreateBrandCategory() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Brand id and details on update page */
    const location = useLocation();
    const { brand_category } = location.state ?? {};

    /** Redux actions and state */
    const { add_brand_category_loading: isLoading, add_brand_category_errors: addBrandCategoryErrors } = useSelector((state) => state.BrandCategoryReducer);
    const addBrandCategory = (params) => dispatch(BrandCategoryAction.addBrandCategory(params));
    const updateBrandCategory = (params) => dispatch(BrandCategoryAction.updateBrandCategory(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetBrandCategoryLoading, setBrandCategoryLoading] = useState(true);


    useEffect(() => {
        setErrors({ ...addBrandCategoryErrors });
    }, [addBrandCategoryErrors])

    useEffect(() => {
        if (brand_category && brand_category._id) {
            setData({
                brand_category_id: brand_category._id,
                brand_category: brand_category.brand_category
            });
            setBrandCategoryLoading(false);
        } else {
            setBrandCategoryLoading(false);
        }
    }, [brand_category])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle update brand form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchAddBrandCostAction(data);
    }

    /**
     * function to dispatch update brand api
     * @author Jasin
     * @created_at 14 June 2022
     */
    async function dispatchAddBrandCostAction(formData) {
        const isCreated = brand_category && brand_category._id ? await updateBrandCategory(formData) : await addBrandCategory(formData);

        if (isCreated && isCreated.status === 1) {
            let redirectPath = `${PostAuthRoutes('brand_category').path}`;
            history.push(redirectPath);
        }
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function cancelBrandCategory() {
        let redirectPath = `${PostAuthRoutes('brand_category').path}`;
        history.push(redirectPath);
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${brand_category && brand_category._id ? PostAuthRoutes('update_brand_category').name : PostAuthRoutes('create_brand_category').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={brand_category&& brand_category._id ? PostAuthRoutes('update_brand_category').name : PostAuthRoutes('create_brand_category').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" display={`flex`} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card >
                                {!isSetBrandCategoryLoading && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Brand Category'
                                                name='brand_category'
                                                value={data.brand_category}
                                                error={action.isSubmitted && errors.brand_category ? errors.brand_category : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={brand_category && brand_category._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={2} />
                                                <CancelButton onClick={cancelBrandCategory} tabIndex={3} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreateBrandCategory };
