import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Helmet } from "react-helmet";

import { GlobalAction, UserAction } from '../../../redux/actions';

import { Menu } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { ShortLogo } from '../../../_components/logo/short-logo';
import { styled } from '@mui/styles';

import { getPostComponentNameByPath } from '../../../routes';
import { AccountMenu, ThemeMode } from '.'
import { useContext } from 'react';
import { AppNameContext } from '../../../_contexts';
import Box from '../../../_components/box';
import { Notifications } from './notifications';
import { FormSearchInput } from '../../../_components/form';

const StyledAppBar = styled(({ ...props }) => (<AppBar {...props} />))`
        zIndex: 1200;
`;

function Header() {

    const dispatch = useDispatch();
    const location = useLocation();
    const appName = useContext(AppNameContext);

    const setDrawer = (params) => dispatch(GlobalAction.setDrawer(params));
    const { isDrawerOpen: open } = useSelector((state) => state.GlobalReducer);

    const [pageTitle, setPageTitle] = useState('Loading')

    const toggleDrawer = () => {
        setDrawer(!open);
    };



    useEffect(() => {
        setPageTitle(getPostComponentNameByPath(location.pathname));
    }, [location])

    useEffect(() => {
        dispatch(UserAction.getUserDetails())
        dispatch(UserAction.getUserPermission())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${appName} | ${pageTitle}`}</title>
            </Helmet>
            <StyledAppBar elevation={1} position="sticky" >
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} >
                        <Menu />
                    </IconButton>
                    <ShortLogo style={{ marginLeft: 10 }} height="30" />
                    <FormSearchInput />
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <ThemeMode />
                        <Notifications />
                        <AccountMenu />
                    </Box>
                </Toolbar>
            </StyledAppBar>
        </React.Fragment>
    )
}



export { Header };

