import React, { Component } from 'react'
import './content-loader.scss';

class ContentLoader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            height: ((this.props.height) ? (this.props.height + 'px') : 0),
            width: ((this.props.width) ? (this.props.width) : 100) + '%',
            display: (this.props.type) ? (this.props.type) : 'block',
            rows: (this.props.rows) ? parseInt(this.props.rows) : 1,
            round: (this.props.isRound) ? (this.props.isRound) : false,
        };
    }

    render() {
        var style = {
            height: this.state.height,
            display: this.state.display,
            width: this.state.width,
            marginRight: `10px`
        }

        if (this.state.round) {
            style.borderRadius = '50%';
            style.width = this.state.height
        }

        const rows = this.state.rows;
        return (
            [...Array(rows)].map((elementInArray, index) => (
                <span className="block load-animate mb-2" key={index} style={style}></span>
            ))
        );
    }
}
export { ContentLoader };
