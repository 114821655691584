import { MoreVert } from '@mui/icons-material';
import { Button, Menu } from '@mui/material';
import React, { useState } from 'react'

function FilterMenu({ children }) {

    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Button onClick={handleClick} variant="contained" color="primary">
                <MoreVert />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{ elevation: 0, sx: { overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 }, '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 } } }}
            >
                {children}
            </Menu>
        </React.Fragment>
    )
}

export { FilterMenu }