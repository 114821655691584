import { Box, Chip, Stack, Card, CardContent, Typography, CardHeader } from '@mui/material'

function EmailLogDetailCard({ emailLog, ...props }) {

  /** Initialize and declare variables */
  const { brand_id, created_at, mail_type, receiver, receiver_type, status } = emailLog

  return (
    <Card>
      <CardHeader action={
        <Box>
          <Stack direction="row" display={`flex`} alignItems={`center`} justifyContent={`center`} spacing={1}>
            <Chip size='small' label={status === 'success' ? 'Success' : 'Failed'} color={status === 'success' ? 'success' : 'error'} />
          </Stack>
        </Box>
      }></CardHeader>
      <CardContent>
        <Stack spacing={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography variant="body2">{`Receiver : `}</Typography>
            <Chip label={`${receiver}`} size="small" />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography variant="body2">{`Receiver Type : `}</Typography>
            <Chip label={`${receiver_type}`} size="small" />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography variant="body2">{`Mail Type : `}</Typography>
            <Chip label={`${mail_type}`} size="small" />
          </Box>
          {brand_id && brand_id.name &&
            <Box display="flex" justifyContent="space-between" alignItems="center" >
              <Typography variant="body2">{`Brand : `}</Typography>
              <Chip label={`${brand_id.name}`} size="small" />
            </Box>
          }
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography variant="body2">{`Created At : `}</Typography>
            <Chip label={`${created_at}`} size="small" />
          </Box>

        </Stack>
      </CardContent>
    </Card>
  )
}

export { EmailLogDetailCard }