import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Chip } from '@mui/material';
import { CurrencyRupee } from '@mui/icons-material';
import { DataTable } from '../../../../_components/data-table/DataTable';
import { WalletAction } from '../../../../redux/actions';

const CurrencySymbol = ({symbol}) => {
    return (
        <> {symbol ? <span dangerouslySetInnerHTML={{ __html: symbol }} /> : <CurrencyRupee />} </>
    );
}

function WalletHistoryDataTable({ limit, page, currency, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const searchParams = new URLSearchParams(search);
    const brandId = searchParams.get('brandId'); // brand id from url

    /** Redux actions and state */
    const { per_page, total, wallet_history_loading: isLoading, wallet_history } = useSelector((state) => state.WalletReducer);
    const listWalletHistory = (params) => dispatch(WalletAction.listWalletHistory(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ page, limit, brandId });

    const columns = [
		{ name: '#', selector: (row, i) => row._id, sortField: '_id', center: true, width: '10%', cell: (row, i) => per_page * (payload.page - 1) + (i + 1) },
		{ name: <>Amount ( <CurrencySymbol symbol={currency.html} /> )</>, selector: (row, i) => row.amount, sortField: 'name', center: true, sortable: true, width: '30%' },
		{ name: 'Created At', selector: (row, i) => row.created_at, sortField: 'created_at', sortable: true, center: true, width: '30%' },
		{ name: 'Status', selector: (row, i) => row.status, sortField: 'status', center: true, sortable: true, width: '30%', cell: (row) => <StatusRow row={row} /> },
	];

    /**
     * function to display status value on rows
     * @created_at 09 June 2023
     */
    const StatusRow = ({ row }) => {

        let status = <Chip label={row.status} className="badge-status capitalize" />;
        if (row.status === 'Success')
            status = <Chip label={row.status} color="success" className="badge-status capitalize" />;
        else if (row.status === 'Failed')
            status = <Chip label={row.status} color="error" className="badge-status capitalize" />;
        else
            status = <Chip label={row.status} color="info" className="badge-status capitalize" />;

        return <React.Fragment>{status}</React.Fragment>;

    }

    useEffect(() => {
        setPayload({ ...payload, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit])

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.page])

    /**
     * function to fetch wallet history
     * @return json
     */
    const getData = async () => {
        await listWalletHistory(payload);
    }

    /**
     * function to sort columns
     * @created_at 09 May 2023
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @created_at 09 May 2023
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @created_at 09 May 2023
     */

    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={wallet_history}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
            handleSelect={handleSelect}
        />
    )
}

export { WalletHistoryDataTable };