import { SupportCenterServices } from "../services";
import { GlobalAction } from "./GlobalAction";


export const SUPPORT_CENTER_REQUEST = 'SUPPORT_CENTER_REQUEST';
export const SUPPORT_CENTER_SUCCESS = 'SUPPORT_CENTER_SUCCESS';
export const SUPPORT_CENTER_FAILURE = 'SUPPORT_CENTER_FAILURE';

export const TICKET_DETAILS_REQUEST = 'TICKET_DETAILS_REQUEST';
export const TICKET_DETAILS_SUCCESS = 'TICKET_DETAILS_SUCCESS';
export const TICKET_DETAILS_FAILURE = 'TICKET_DETAILS_FAILURE';

export const ADD_REPLY_REQUEST = 'ADD_REPLY_REQUEST';
export const ADD_REPLY_SUCCESS = 'ADD_REPLY_SUCCESS';
export const ADD_REPLY_FAILURE = 'ADD_REPLY_FAILURE';

export const CHANGE_SUPPORT_CENTER_STATUS_REQUEST = 'CHANGE_SUPPORT_CENTER_STATUS_REQUEST';
export const CHANGE_SUPPORT_CENTER_STATUS_SUCCESS = 'CHANGE_SUPPORT_CENTER_STATUS_SUCCESS';
export const CHANGE_SUPPORT_CENTER_STATUS_FAILURE = 'CHANGE_SUPPORT_CENTER_STATUS_FAILURE';

export const SupportCenterAction = {

    /**
     * Action for list Support Center
     * @param {Object} params - The params which are used for the api
     * @author Sarath
     * @created_at 15 June 2022 
     */

    listSupportCenter: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SupportCenterServices.listSupportCenter(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: SUPPORT_CENTER_REQUEST, request: request } }
        function success(request, response) { return { type: SUPPORT_CENTER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SUPPORT_CENTER_FAILURE, request: request, data: error.data, message: error.message, errors: error.errors, status: error.status, status_code: error.status_code } }
    },

    /**
     * Action to get ticket details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.ticket_id
     * @author Jasin 
     * @created_at 15 June 2022 
    */
    ticketDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SupportCenterServices.ticketDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: TICKET_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: TICKET_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: TICKET_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to add reply
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 22 June 2022
    */
    addReply: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SupportCenterServices.addReply(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_REPLY_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_REPLY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_REPLY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for change support center status
     * @param {Object} params - The params which are used for the api
     * @author Naveen 
     * @created_at 21 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SupportCenterServices.changeStatus(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_SUPPORT_CENTER_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_SUPPORT_CENTER_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_SUPPORT_CENTER_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}