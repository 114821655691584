import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import Box from "../../../_components/box";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlanAction } from "../../../redux/actions";
import { PostAuthRoutes } from "../../../routes";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormInput, FormSelect } from "../../../_components/form";
import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";


// Initialize form input values to null
const inputs = { plan_id: '', name: '', number_of_coupons: '', description: '', cost: '', category: '', discount: '', discounted_cost: '', duration: '' };

// Declare Plan Category select options
const PlanCategory = [
    { value: 'main', label: 'Main' },
    { value: 'add_on', label: 'Add-on' },
    { value: 'giveaway', label: 'Giveaway' }
]

// Declare Plan Duration select options
const PlanDuration = [
    { value: '1', label: '1 Day' },
    { value: '2', label: '2 Days' },
    { value: '3', label: '3 Days' },
    { value: '4', label: '4 Days' },
    { value: '5', label: '5 Days' },
    { value: '6', label: '6 Days' },
    { value: '7', label: '7 Days' }
]

function CreatePlan() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Plan id and details on update page */
    const location = useLocation();
    const { plan } = location.state ?? {};

    /** Redux actions and state */
    const { create_plans_loading: isLoading, create_plans_errors: createPlanErrors } = useSelector((state) => state.PlanReducer);
    const createPlan = (params) => dispatch(PlanAction.createPlan(params));
    const updatePlan = (params) => dispatch(PlanAction.updatePlan(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetPlanLoading, setPlanLoading] = useState(true);

    useEffect(() => {
        setErrors({ ...createPlanErrors });
    }, [createPlanErrors])

    useEffect(() => {
        if (plan && plan._id) {
            setData({
                plan_id: plan._id,
                name: plan.name,
                number_of_coupons: (plan.number_of_coupons && plan.category !=='giveaway') ? `${plan.number_of_coupons}` : '',
                description: plan.description,
                cost: `${plan.cost}`,
                category: plan.category,
                discount: plan.discount ? `${plan.discount}` : '',
                discounted_cost: plan.discounted_cost ? `${plan.discounted_cost}` : '',
                duration: (plan.duration && plan.category ==='giveaway') ? `${plan.duration}` : '',
            });
            setPlanLoading(false);
        } else {
            setPlanLoading(false);
        }
    }, [plan])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Jasin
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle create plan form submit action
     * @param {object} e form object
     * @author Jasin
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchCreatePlanAction(data);
    }

    /**
     * function to dispatch create/update plan api
     * @author Jasin
     * @created_at 14 June 2022
     */
    async function dispatchCreatePlanAction(data) {
        const isCreated = plan && plan._id ? await updatePlan(data) : await createPlan(data);
        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('plans').path;
            history.push(redirectPath);
        }
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Jasin
     * @created_at 14 June 2022
     */
    function cancelPlan() {
        let redirectPath = PostAuthRoutes('plans').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${plan && plan._id ? PostAuthRoutes('update_plans').name : PostAuthRoutes('create_plans').name}`}</title>
            </Helmet>
            <Box >
                <CardHeader title={plan && plan._id ? PostAuthRoutes('update_plans').name : PostAuthRoutes('create_plans').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card >
                                {!isSetPlanLoading && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Plan name'
                                                name='name'
                                                value={data.name}
                                                error={action.isSubmitted && errors.name ? errors.name : ''}
                                                onChange={handleChange}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={2}
                                                label={`Category`}
                                                name={`category`}
                                                placeholder={`Select font`}
                                                onChange={handleChange}
                                                data={PlanCategory}
                                                className="col-md-6"
                                                value={data.category}
                                                error={(action.isSubmitted && (errors.category)) ? (errors.category) : ''}
                                            />
                                        </Grid>
                                        {data.category !== 'giveaway' &&
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormInput
                                                    tabIndex={3}
                                                    label='Number of coupons (per Month)'
                                                    name='number_of_coupons'
                                                    value={data.number_of_coupons}
                                                    error={action.isSubmitted && errors.number_of_coupons ? errors.number_of_coupons : ''}
                                                    onChange={handleChange}
                                                    maskType={`digit-only`}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Cost (in Rupees)'
                                                name='cost'
                                                value={data.cost}
                                                error={action.isSubmitted && errors.cost ? errors.cost : ''}
                                                onChange={handleChange}
                                                maskType={`digit-with-one-dot`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={3}
                                                label='Discount'
                                                name='discount'
                                                value={data.discount}
                                                error={action.isSubmitted && errors.discount ? errors.discount : ''}
                                                onChange={handleChange}
                                                maskType={`digit-with-one-dot`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Discounted Cost (in Rupees)'
                                                name='discounted_cost'
                                                value={data.discounted_cost}
                                                error={action.isSubmitted && errors.discounted_cost ? errors.discounted_cost : ''}
                                                onChange={handleChange}
                                                maskType={`digit-with-one-dot`}
                                            />
                                        </Grid>
                                        {data.category === 'giveaway' && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={2}
                                                label={`Duration`}
                                                name={`duration`}
                                                placeholder={`Select duration`}
                                                onChange={handleChange}
                                                data={PlanDuration}
                                                className="col-md-6"
                                                value={data.duration}
                                                error={(action.isSubmitted && (errors.duration)) ? (errors.duration) : ''}
                                            />
                                        </Grid>}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={5}
                                                label='Description'
                                                name='description'
                                                value={data.description}
                                                error={action.isSubmitted && errors.description ? errors.description : ''}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={250}
                                                minRows={2}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={plan && plan._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={6} />
                                                <CancelButton onClick={cancelPlan} tabIndex={7} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreatePlan };
