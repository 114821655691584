import { LogServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const LOG_REQUEST = 'LOG_REQUEST';
export const LOG_SUCCESS = 'LOG_SUCCESS';
export const LOG_FAILURE = 'LOG_FAILURE';

export const LOG_DETAILS_REQUEST = 'LOG_DETAILS_REQUEST';
export const LOG_DETAILS_SUCCESS = 'LOG_DETAILS_SUCCESS';
export const LOG_DETAILS_FAILURE = 'LOG_DETAILS_FAILURE';

export const DELETE_ALL_LOG_REQUEST = 'DELETE_ALL_LOG_REQUEST';
export const DELETE_ALL_LOG_SUCCESS = 'DELETE_ALL_LOG_SUCCESS';
export const DELETE_ALL_LOG_FAILURE = 'DELETE_ALL_LOG_FAILURE';

export const LogAction = {

    /**
     * Action for list Logs
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 06 Feb 2024
     */
    listLogs: (params) => {
        return dispatch => {
            dispatch(request(params));
            return LogServices.listLogs(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: LOG_REQUEST, request: request } }
        function success(request, response) { return { type: LOG_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: LOG_FAILURE, request: request, data: error.data, message: error.message, errors: error.errors, status: error.status, status_code: error.status_code } }
    },

    /**
     * Action to get log details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.log_id
     * @author Naveen
     * @created_at 06 Feb 2024
    */
    logDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return LogServices.logDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: LOG_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: LOG_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: LOG_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for deleting all logs
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 07 Feb 2024
    */
     deleteAllLogs: (params) => {
        return dispatch => {
            dispatch(request(params));
            return LogServices.deleteAllLogs(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_ALL_LOG_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_ALL_LOG_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_ALL_LOG_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
}