import {
    INFLUENCER_REQUEST, INFLUENCER_SUCCESS, INFLUENCER_FAILURE,
    CHANGE_INFLUENCER_STATUS_REQUEST, CHANGE_INFLUENCER_STATUS_SUCCESS, CHANGE_INFLUENCER_STATUS_FAILURE,
    INFLUENCER_DETAILS_REQUEST, INFLUENCER_DETAILS_SUCCESS, INFLUENCER_DETAILS_FAILURE,
    INFLUENCER_SELECT_LIST_REQUEST, INFLUENCER_SELECT_LIST_SUCCESS, INFLUENCER_SELECT_LIST_FAILURE,
    EXPORT_INFLUENCER_REQUEST, EXPORT_INFLUENCER_SUCCESS, EXPORT_INFLUENCER_FAILURE,
    DELETE_INFLUENCER_PROFILE_REQUEST, DELETE_INFLUENCER_PROFILE_SUCCESS, DELETE_INFLUENCER_PROFILE_FAILURE,
} from "../actions";

const initialState = {
    influencers_loading: false, influencers: [], influencers_errors: {},
    influencer_details_loading: false, influencer_details: {}, influencer_details_errors: {},
    influencer_select_list_loading: false, influencer_select_list: [], influencer_select_list_errors: {},
    export_influencer_loading: false, export_influencer: [], export_influencer_errors: {},
    delete_influencer_profile_loading: false, delete_influencer_profile_errors: {},
};

export function InfluencerReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List Influencer redux */
        case INFLUENCER_REQUEST: {
            return {
                ...state,
                influencers_loading: true,
                influencers_errors: {}
            };
        }
        case INFLUENCER_SUCCESS: {

            let newArray = [...state.influencers]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }

            action.data.influencers.forEach((payload) => {
                const influencerIndex = newArray.findIndex(influencer => influencer._id === payload._id);
                if (influencerIndex !== -1) {
                    newArray[influencerIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });


            return {
                ...state,
                influencers_loading: false,
                influencers: newArray,
                influencers_errors: {}
            };

        }
        case INFLUENCER_FAILURE: {
            return {
                ...state,
                influencers_loading: false,
                influencers: [],
                influencers_errors: action.errors
            };
        }

        /** end::List influencer coupon codes redux */

        /** begin::Change influencer status redux */
        case CHANGE_INFLUENCER_STATUS_REQUEST: {
            return {
                ...state,
                change_influencer_status_loading: true,
                change_influencer_status_errors: {}
            };
        }
        case CHANGE_INFLUENCER_STATUS_SUCCESS: {
            let influencerArray = [...state.influencers];
            let influencerDetailObject = state.influencer_details

            const influencerIndex = influencerArray.findIndex(influencers => influencers._id === action.data._id);

            if (influencerIndex !== -1) {
                influencerArray[influencerIndex].status = action.data.status;
            }

            if (influencerDetailObject.id === action.data.id) {
                influencerDetailObject.status = action.data.status
            }

            return {
                ...state,
                change_influencer_status_loading: false,
                change_influencer_status_errors: {},
                influencers: influencerArray
            };
        }
        case CHANGE_INFLUENCER_STATUS_FAILURE: {
            return {
                ...state,
                change_influencer_status_loading: false,
                change_influencer_status_errors: action.errors
            };
        }
        /** end::Change influencer status redux */

        /** begin::influencer details redux */
        case INFLUENCER_DETAILS_REQUEST: {
            return {
                ...state,
                influencer_details_loading: true,
                influencer_details_errors: {}
            };
        }
        case INFLUENCER_DETAILS_SUCCESS: {
            return {
                ...state,
                influencer_details_loading: false,
                influencer_details: action.data,
                influencer_details_errors: {}
            };
        }
        case INFLUENCER_DETAILS_FAILURE: {
            return {
                ...state,
                influencer_details_loading: false,
                influencer_details_errors: action.errors
            };
        }
        /** end::influencer details redux */

        /** begin::Influencer select list redux */
        case INFLUENCER_SELECT_LIST_REQUEST: {
            return {
                ...state,
                influencer_select_list_loading: true,
                influencer_select_list_errors: {}
            };
        }
        case INFLUENCER_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                influencer_select_list_loading: false,
                influencer_select_list_errors: {},
                influencer_select_list: action.data

            };

        }
        case INFLUENCER_SELECT_LIST_FAILURE: {
            return {
                ...state,
                influencer_select_list_loading: false,
                influencer_select_list_errors: action.errors,
                influencer_select_list: []
            };
        }
        /** end::Brands select list redux */

        /** begin::Export influencer redux*/
        case EXPORT_INFLUENCER_REQUEST: {
            return {
                ...state,
                export_influencer_loading: true,
                export_influencer_errors: {}
            };
        }
        case EXPORT_INFLUENCER_SUCCESS: {

            return {
                ...state,
                export_influencer_loading: false,
                export_influencer: action.data,
                export_influencer_errors: {}
            };

        }
        case EXPORT_INFLUENCER_FAILURE: {
            return {
                ...state,
                export_influencer_loading: false,
                export_influencer: [],
                export_influencer_errors: action.errors,
            };
        }
        /** end::Export influencer redux*/

        /** begin::delete influencer redux */
        case DELETE_INFLUENCER_PROFILE_REQUEST: {
            return {
                ...state,
                delete_influencer_profile_loading: true,
                delete_influencer_profile_errors: {}
            };
        }
        case DELETE_INFLUENCER_PROFILE_SUCCESS: {

            return {
                ...state,
                delete_influencer_profile_loading: false,
                delete_influencer_profile_errors: {}
            };
        }
        case DELETE_INFLUENCER_PROFILE_FAILURE: {
            return {
                ...state,
                delete_influencer_profile_loading: false,
                delete_influencer_profile_errors: action.errors
            };
        }
        /** end::delete influencer redux */

        default:
            return state;
    }
}