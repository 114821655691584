import { AddBox, CheckBox, CheckBoxOutlineBlank, ChevronRight, ExpandMore, Folder, FolderOpen, IndeterminateCheckBox, InsertDriveFile } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Grid, Stack, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoleAction } from '../../../redux/actions';
import { PostAuthRoutes } from '../../../routes';
import { useHistory, useLocation } from "react-router-dom";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import { CardHeader as PageHeader } from "../../../_components/card";
import { CancelButton, SubmitButton } from '../../../_components/controls';
import { NoData } from '../../../_components/layout/no-data';

/**
  * 
  * @author Jasin 
  */
function AssignPermission({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const roleId = params.get('rId')

    /** Redux actions and state */
    const assignPermission = (params) => dispatch(RoleAction.assignPermission(params));
    const getAssignedPermissions = (params) => dispatch(RoleAction.getAssignedPermissions(params));
    const { assigned_permissions_loading: isLoading, assigned_permissions, all_permissions } = useSelector((state) => state.RoleReducer);

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [roleStatus, setRoleStatus] = useState('');

    function getNodeIds(data) {
        let ids = [];

        data?.forEach(({ value, children }) => {
            ids = [...ids, value, ...getNodeIds(children)];
        });

        return ids;
    }

    useEffect(() => {
        getAssignedPermissions({ show_checkbox: true, role_id: roleId })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId])

    useEffect(() => {
        setExpanded(getNodeIds(all_permissions));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [all_permissions])

    useEffect(() => {
        if (Object.keys(assigned_permissions).length > 0) {
            setChecked(assigned_permissions.permissions);
            setRoleName(assigned_permissions.name);
            setRoleStatus(assigned_permissions.status);
        }
    }, [assigned_permissions])

    function handleSubmit(e) {
        e.preventDefault();
        dispatchAssignPermissionAction(checked);
    }

    async function dispatchAssignPermissionAction(formData) {
        await assignPermission({ permissions: checked, role_id: roleId })
    }

    function cancelAssignPermission() {
        let redirectPath = PostAuthRoutes('role').path;
        history.push(redirectPath);
    }
    return (
        <React.Fragment>
            {!isLoading && all_permissions.length === 0 && <NoData content1={`No`} content2={`Assigned Permissions available`} minHeight={`55vh`} ></NoData>}
            {(!isLoading && all_permissions.length > 0) &&
                <React.Fragment>
                    <PageHeader title={PostAuthRoutes('assign_permission').name} />
                    <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ width: '48%' }}>
                            <Card>
                                <CardHeader title={roleName} action={
                                    <React.Fragment>
                                        <Chip size='small' label={roleStatus === 'active' ? 'Active' : 'Inactive'} color={roleStatus === 'active' ? 'success' : 'error'} />
                                    </React.Fragment>
                                } />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Card>
                                <form onSubmit={handleSubmit} noValidate>

                                    <CardContent sx={{ lineHeight: '2' }}>
                                        <Grid container spacing={3} justifyContent="center" alignItems="center" >
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                {(!isLoading && all_permissions.length > 0) &&
                                                    <CheckboxTree
                                                        nodes={all_permissions}
                                                        checked={checked}
                                                        expanded={expanded}
                                                        onCheck={(checked) => setChecked(checked)}
                                                        onExpand={(expanded) => setExpanded(expanded)}

                                                        icons={{
                                                            check: <CheckBox fontSize="inherit" />,
                                                            uncheck: <CheckBoxOutlineBlank fontSize="inherit" />,
                                                            halfCheck: <CheckBox fontSize="inherit" />,
                                                            expandClose: <ChevronRight fontSize="inherit" />,
                                                            expandOpen: <ExpandMore fontSize="inherit" />,
                                                            expandAll: <AddBox fontSize="inherit" />,
                                                            collapseAll: <IndeterminateCheckBox fontSize="inherit" />,
                                                            parentClose: <Folder fontSize="inherit" />,
                                                            parentOpen: <FolderOpen fontSize="inherit" />,
                                                            leaf: <InsertDriveFile fontSize="inherit" />
                                                        }}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={12} lg={12} xl={12}>
                                                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                    <SubmitButton label={'Assign Permissions'} loading={isLoading} tabIndex={6} />
                                                    <CancelButton onClick={cancelAssignPermission} tabIndex={12} />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </form>
                            </Card>
                        </Grid>
                    </Grid>

                </React.Fragment>}
        </React.Fragment>
    )
}

export { AssignPermission };

