import { apiServices } from '../../_helpers';

export const LogServices = {

    /**
     * Service for List Logs
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 06 Feb 2024
     */
    listLogs: (params) => {
        return apiServices.post('admin/log', params)
            .then((response) => { return response; })
    },

    /**
     * Service for get Log details
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 06 Feb 2024
     */
    logDetails: (params) => {
        return apiServices.post('admin/log/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service for deleting all logs
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 07 Feb 2024
    */
    deleteAllLogs: (params) => {
        return apiServices.delete('admin/log/delete-all', params)
            .then((response) => { return response; })
    },
}