import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { APP_NAME } from '../../../_helpers';
import { PreAuthRoutes } from '../../../routes';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { Grid, Skeleton, Typography } from '@mui/material';
import Box from '../../../_components/box';
import { ShortLogo } from '../../../_components/logo/short-logo';
import { RegisterAction } from '../../../redux/actions';

function ConfirmEmail() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const { key: verificationString } = useParams();

    /** Redux actions and state */
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});

    /** Initialize and declare state */

    useEffect(() => {
        dispatch(RegisterAction.confirmEmail(verificationString)).then((result) => {
            setIsLoading(false);
            setData(result.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('confirm_email').name}`}</title>
            </Helmet>
            <PreSignIn>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} >
                        <Box variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mt={-8} p={2} mb={4} textAlign="center" >
                            <Box>
                                <ShortLogo width="50" height="50" />
                            </Box>
                            <Typography variant="h5" fontWeight="medium">{`${PreAuthRoutes('confirm_email').name}`}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <Typography variant="h4">{isLoading ? <Skeleton /> : data.heading}</Typography>
                        <Typography variant="body">{isLoading ? <Skeleton /> : data.description}</Typography>
                    </Grid>
                </Grid>
            </PreSignIn>
        </React.Fragment>
    )
}

export { ConfirmEmail }