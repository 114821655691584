import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { CurrencyRupee } from '@mui/icons-material'
import Box from '../../../../_components/box'
import { StatisticsWidget } from '../../../../_components/widgets'
import { WalletHistoryDataTable } from '.'
import { useSelector } from 'react-redux'

const CurrencySymbol = ({symbol}) => {
    return (
        <> {symbol ? <span dangerouslySetInnerHTML={{ __html: symbol }} /> : <CurrencyRupee />} </>
    );
}

function BrandWallet({ currency, ...props }) {

    /** Initialize and declare state */
    const [payload] = useState({ limit: 10, page: 1 });

    const { brand_wallet_total } = useSelector((state) => state.WalletReducer);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
                <Box mb={1.5}>
                    <StatisticsWidget
                        color="dark"
                        icon={<CurrencySymbol symbol={currency.html} />}
                        title="Current Balance"
                        count={brand_wallet_total}
                        percentage={{
                            label: `Your active balance is ${brand_wallet_total} ${currency.name}`,
                        }}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <WalletHistoryDataTable limit={payload.limit} page={payload.page} currency={currency} />
            </Grid>
        </Grid>
    )
}

export { BrandWallet }