import React, { useEffect } from 'react'
import Box from '../../../_components/box'
import { StatisticsWidget } from '../../../_components/widgets'
import { useDispatch, useSelector } from "react-redux";
import { DashboardAction } from "../../../../src/redux/actions";

function StoryCard() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    //** Redux actions and state */
    const { story_count_details } = useSelector((state) => state.DashboardReducer);
    const storyCount = (params) => dispatch(DashboardAction.storyCount(params));

    /** Initialize and declare state */
    const { story_count, story_percentage } = story_count_details;

    useEffect(() => {

        storyCount();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box mb={1.5}>
            <StatisticsWidget
                color="success"
                icon="weekend"
                title="Stories"
                count={story_count}
                percentage={{
                    color: `${story_percentage > 0 ? 'success' : 'error'}`,
                    amount: `${parseFloat(story_percentage).toFixed(2)}%`,
                    label: "than last month",
                }}
            />
        </Box>
    )
}
export { StoryCard }