import { apiServices } from '../../_helpers';

export const RoleServices = {

    /**
     * Service to get all Roles
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 15 June 2022
    */
    listRole: (params) => {
        return apiServices.post('admin/role/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for Creating Roles
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    createRole: (params) => {
        return apiServices.post('/admin/role/create', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /**
     * Service for update role
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
    */
    updateRole: (params) => {
        return apiServices.put('admin/role/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

    /**
     * Service for change Role status
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
    */
    changeStatus: (params) => {
        return apiServices.put('/admin/role/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for role select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    roleSelectList: (params) => {
        return apiServices.post('admin/role/role-select-list', params)
            .then((response) => { return response; })
    },


    /**
     * Service to assign permission
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    assignPermission: (params) => {
        return apiServices.post('admin/role/assign-permission', params)
            .then((response) => { return response; })
    },


    /**
     * Service to get assigned permissions
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    getAssignedPermissions: (params) => {
        return apiServices.post('admin/role/get-assigned-permissions', params)
            .then((response) => { return response; })
    },
}