import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { validate } from ".";
import { PlanAction } from "../../../redux/actions";
import { PostAuthRoutes } from "../../../routes";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormInput } from "../../../_components/form";
import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";

// Initialize form input values
const inputs = { value: '', slug: 'plan_installation_cost', master_id: '' };

function PlanInstallationCost({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /** Redux actions and state */
    const { add_installation_cost_loading: isLoading, add_installation_cost_errors: addInstallationCostErrors, get_installation_cost_loading: installationCostLoading, installation_cost } = useSelector((state) => state.PlanReducer);
    const addIntsallationCost = (params) => dispatch(PlanAction.addIntsallationCost(params));
    const updateIntsallationCost = (params) => dispatch(PlanAction.updateIntsallationCost(params));
    const getInstallationCost = (params) => dispatch(PlanAction.getInstallationCost(params));

    useEffect(() => {
        setErrors({ ...addInstallationCostErrors });
    }, [addInstallationCostErrors])

    /**
    * function to handle input changes and modify the value
    * @param {string} e.name input name
    * @param {string} e.value input value
    * @author Akshay N
    * @created_at 21 June 2022 
    */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    useEffect(() => {
        getInstallationCost({ slug: data.slug });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!installationCostLoading && Object.keys(installation_cost).length > 0) {
            setData({ ...data, value: installation_cost.value, master_id: installation_cost._id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [installation_cost])

    /**
    * function to handle update brand form submit action
    * @param {object} e form object
    * @author Akshay N
    * @created_at 21 June 2022 
    */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchInstallationCostAction(data);
    }

    /**
     * function to update or add installation cost
     * @return view
     * @author Akshay N
     * @created_at 21 June 2022
     */
    const dispatchInstallationCostAction = async (formData) => {
        const isCreated = (installation_cost && installation_cost._id) ? await updateIntsallationCost(formData) : await addIntsallationCost(formData);
        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('plans').path;
            history.push(redirectPath);
        }
    }

    function cancelInstallationCost() {
        let redirectPath = PostAuthRoutes('plans').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${installation_cost && installation_cost._id ? PostAuthRoutes('update_installation_cost').name : PostAuthRoutes('create_installation_cost').name}`}</title>
            </Helmet>
            <Box >
                <CardHeader title={installation_cost && installation_cost._id ? PostAuthRoutes('update_installation_cost').name : PostAuthRoutes('create_installation_cost').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card >
                                <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Installation Cost'
                                                name='value'
                                                value={data.value}
                                                error={action.isSubmitted && errors.value ? errors.value : ''}
                                                onChange={handleChange}
                                                autoFocus={true}
                                                maskType={`digit-with-one-dot`}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={installation_cost && installation_cost._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={2} />
                                                <CancelButton onClick={cancelInstallationCost} tabIndex={10} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    );
}

export { PlanInstallationCost }