import { Box, Button, Grid,  Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandCostAction } from "../../../redux/actions";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormInput } from "../../../_components/form";
import { handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";

// Initialize form input values
const inputs = { value: '', slug: 'default_coupon_price', master_id: '', giveaway_value: '', giveaway_slug: 'default_giveaway_coupon_price',giveaway_master_id: '' };

function DefaultBrandPrice({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [giveawayData, setGiveawayData] = useState({ value: '', slug: 'default_giveaway_coupon_price', master_id: '' });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [showForm, setShowForm] = useState(false);

    /** Redux actions and state */
    const { add_default_price_loading: isLoading, add_default_price_errors: addDefaultPriceErrors, get_default_price_loading: defaultPriceLoading, default_price, default_giveaway_price } = useSelector((state) => state.BrandCostReducer);
    const addDefaultPrice = (params) => dispatch(BrandCostAction.addDefaultPrice(params));
    const updateDefaultPrice = (params) => dispatch(BrandCostAction.updateDefaultPrice(params));
    const getDefaultPriceDetails = (params) => dispatch(BrandCostAction.getDefaultPriceDetails(params));

    useEffect(() => {
        setErrors({ ...addDefaultPriceErrors });
    }, [addDefaultPriceErrors])

    /**
    * function to handle input changes and modify the value
    * @param {string} e.name input name
    * @param {string} e.value input value
    * @author Akshay N
    * @created_at 21 June 2022 
    */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    useEffect(() => {
        getDefaultPriceDetails({ slug: data.slug });
        getDefaultPriceDetails({ slug: data.giveaway_slug });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!defaultPriceLoading && Object.keys(default_price).length > 0) {
            setData({ ...data, 
                value: default_price.value, 
                master_id: default_price._id
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [default_price])

    useEffect(() => {
        if (!defaultPriceLoading && Object.keys(default_giveaway_price).length > 0) {
            setData({ ...data, 
                giveaway_value: default_giveaway_price.value, 
                giveaway_master_id: default_giveaway_price._id
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [default_giveaway_price])

    useEffect(() => {
        if (!defaultPriceLoading && Object.keys(data).length > 0) {
            setGiveawayData({ ...giveawayData, 
                value: data.giveaway_value, 
                master_id: data.giveaway_master_id
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    /**
    * function to handle update brand form submit action
    * @param {object} e form object
    * @author Akshay N
    * @created_at 21 June 2022 
    */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchDefaultPriceAction(data);
    }

    /**
     * function to update or add default price
     * @return view
     * @author Akshay N
     * @created_at 21 June 2022
     */
    const dispatchDefaultPriceAction = async (formData) => {
        console.log(giveawayData, 'giveawayData');
        const isCreated = (default_price && default_price._id) ? await updateDefaultPrice(formData) : await addDefaultPrice(formData);
        if (isCreated.status === 1) {
            const isGiveawayPriceCreated = (default_giveaway_price && default_giveaway_price._id) ? await updateDefaultPrice(giveawayData) : await addDefaultPrice(giveawayData);
            if (isGiveawayPriceCreated.status === 1) {
                setShowForm(false);
            }
        }
    }
    /**
     * function to cancel default price
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 21 June 2022
     */
    const cancelDefaultPrice = () => {
        setShowForm(false);
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} direction="row" sx={{ p: `30px` }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={`flex`} justifyContent="center" alignItems="center" >
                    {(!showForm && Object.keys(default_price).length === 0) &&
                        <Box mb={2}>
                            <Stack spacing={1} direction="row" >
                                <Button defaultPriceLoading variant="contained" color={"primary"} onClick={() => setShowForm(true)}>Add Default Coupon Price</Button>
                            </Stack>
                        </Box>
                    }
                    {showForm &&
                        <Box mb={2}>
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Default Coupon Price'
                                            name='value'
                                            value={data.value}
                                            error={action.isSubmitted && errors.value ? errors.value : ''}
                                            onChange={handleChange}
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Default Giveaway Coupon Price'
                                            name='giveaway_value'
                                            value={data.giveaway_value}
                                            error={action.isSubmitted && errors.giveaway_value ? errors.giveaway_value : ''}
                                            onChange={handleChange}
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} display={`flex`} justifyContent="center" alignItems="center">
                                        <Stack direction="row" spacing={1}  >
                                            <SubmitButton label='Save' tabIndex={2} />
                                            <CancelButton onClick={cancelDefaultPrice} tabIndex={3} loading={isLoading} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    }
                    {(!showForm && (Object.keys(default_price).length > 0 || Object.keys(default_giveaway_price).length > 0)) &&
                        <Box mb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Stack direction="column" display={`flex`} alignItems={`center`} justifyContent={`center`} >
                                        <Typography variant="h1">{`${default_price.value} /-`} </Typography>
                                        <Typography variant="h6"> {`Default Coupon Price `} </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Stack direction="column" display={`flex`} alignItems={`center`} justifyContent={`center`} >
                                        <Typography variant="h1">{`${default_giveaway_price.value ?? 0} /-`} </Typography>
                                        <Typography variant="h6"> {`Default Giveaway Coupon Price `} </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={12} xl={12} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                    <Button variant="text" onClick={() => setShowForm(true)}>Click here to update the default coupon price.</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export { DefaultBrandPrice }