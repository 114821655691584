import {
    APPROVALS_REQUEST, APPROVALS_SUCCESS, APPROVALS_FAILURE,
    APPROVAL_DETAILS_REQUEST, APPROVAL_DETAILS_SUCCESS, APPROVAL_DETAILS_FAILURE,
    PLAN_UPDATE_APPROVE_REQUEST, PLAN_UPDATE_APPROVE_SUCCESS, PLAN_UPDATE_APPROVE_FAILURE,
    PLAN_UPDATE_REJECT_REQUEST, PLAN_UPDATE_REJECT_SUCCESS, PLAN_UPDATE_REJECT_FAILURE
} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    approvals_loading: false, approvals: [], approvals_errors: {},
    approval_details_loading: false, approval: {}, approval_details_errors: {},
    plan_approve_or_reject_loading: false, plan_approve_or_reject_errors: {},
};

export function ApprovalReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Plans list redux */
        case APPROVALS_REQUEST: {
            return {
                ...state,
                approvals_loading: true,
                approvals_errors: {}
            };
        }
        case APPROVALS_SUCCESS: {

            let newArray = [...state.approvals]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.approvalRequests.forEach((payload) => {
                const planIndex = newArray.findIndex(approvals => approvals._id === payload._id);
                if (planIndex !== -1) {
                    newArray[planIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                approvals_loading: false,
                approvals: newArray,
                approvals_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case APPROVALS_FAILURE: {
            return {
                ...state,
                approvals_loading: false,
                approvals_errors: action.errors,
                approvals: []
            };
        }
        /** end::Plans list redux */


        /** begin::approval details redux */
        case APPROVAL_DETAILS_REQUEST: {
            return {
                ...state,
                approval_details_loading: true,
                approval_details_errors: {}
            };
        }
        case APPROVAL_DETAILS_SUCCESS: {
            return {
                ...state,
                approval_details_loading: false,
                approval: action.data,
                approval_details_errors: {}
            };
        }
        case APPROVAL_DETAILS_FAILURE: {
            return {
                ...state,
                approval_details_loading: false,
                approval_details_errors: action.errors
            };
        }
        /** end::approval details redux */

        /** begin::approval details redux */
        case PLAN_UPDATE_APPROVE_REQUEST: {
            return {
                ...state,
                plan_approve_or_reject_loading: true,
                plan_approve_or_reject_errors: {}
            };
        }
        case PLAN_UPDATE_APPROVE_SUCCESS: {
            let newArray = state.approval; //making a new array

            if (newArray._id === action.data._id) {
                newArray.status = action.data.status
            }

            return {
                ...state,
                plan_approve_or_reject_loading: false,
                approval: newArray,
                plan_approve_or_reject_errors: {}
            };
        }
        case PLAN_UPDATE_APPROVE_FAILURE: {
            return {
                ...state,
                plan_approve_or_reject_loading: false,
                plan_approve_or_reject_errors: action.errors
            };
        }
        /** end::approval details redux */

        /** begin::approval details redux */
        case PLAN_UPDATE_REJECT_REQUEST: {
            return {
                ...state,
                plan_approve_or_reject_loading: true,
                plan_approve_or_reject_errors: {}
            };
        }
        case PLAN_UPDATE_REJECT_SUCCESS: {

            let newArray = state.approval; //making a new array

            if (newArray._id === action.data._id) {
                newArray.status = action.data.status
            }

            return {
                ...state,
                plan_approve_or_reject_loading: false,
                approval: newArray,
                plan_approve_or_reject_errors: {}
            };
        }
        case PLAN_UPDATE_REJECT_FAILURE: {
            return {
                ...state,
                plan_approve_or_reject_loading: false,
                plan_approve_or_reject_errors: action.errors
            };
        }
        /** end::approval details redux */
        default:
            return state;
    }
}
