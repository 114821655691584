import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SupportCenterAction } from '../../redux/actions/SupportCenterAction';
import { Card, CardContent, CardHeader } from '../../_components/card';
import { PostAuthRoutes } from '../../routes';
import { GeneralButton } from '../../_components/controls/general-button/GeneralButton';
import { FilterAlt } from '@mui/icons-material';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { SupportCenterDataTable } from './SupportCenterDataTable';
import { SupportCenterFilter } from './support-center-filter';
import { FormSearchInput } from '../../_components/form';
import { UpdateStatusModal } from '.'
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function SupportCenter(params) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const listSupportCenter = (params) => dispatch(SupportCenterAction.listSupportCenter(params));
    const changeStatus = (params) => dispatch(SupportCenterAction.changeStatus(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    const [showSupportCenterFilterModal, setShowSupportCenterFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { status: searchParams.get('status') ?? '', date: searchParams.get('date') ?? '' },
        search: searchParams.get('search') ?? ''
    });

    useEffect(() => {
        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = payload.page;
        if (payload.search) queryParam.search = payload.search; else delete queryParam.search;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.date) queryParam.date = payload.filter.date; else delete queryParam.date;
        let pageUrl = `${PostAuthRoutes('support_center').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        getNext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])


    /**
     * function to list SupportCenter
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {
        const result = await listSupportCenter(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to open/close SupportCenter filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const supportCenterFilterModal = () => {
        setShowSupportCenterFilterModal(!showSupportCenterFilterModal);
    }

    /**
     * function to open/close SupportCenter status Update modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 21 June 2022
     */
    const statusUpdateModal = ({ id, status, remark }) => {
        setStatusData({ id: id, status: status, remark: remark });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update support center status
     * @param {string} id - Id of the data which we need to update.
     * @return view
     * @author
     * @created_at 21 June 2022
     */
    const updateStatusAction = async ({ id, status, remark }) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'support_center_id': id, status, remark });
    }

    /**
     * function to apply the selected filter options on the Support center list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the Support center filter modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to apply the search key words on the Support center list
     * @param {String} text Search keyword used
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('support_center').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='admin_support_center_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='admin_support_center_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => supportCenterFilterModal()} />
                            </AbilityCan>
                        </Stack>
                    }
                />
                <SupportCenterFilter show={showSupportCenterFilterModal} applyFilter={applyFilter} closeModal={supportCenterFilterModal} supportCenterFilter={payload.filter} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardContent>
                                <SupportCenterDataTable statusUpdateModal={statusUpdateModal} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </React.Fragment>
    )
}

export { SupportCenter }