import {
    PLANS_REQUEST, PLANS_SUCCESS, PLANS_FAILURE,
    ADD_PLAN_REQUEST, ADD_PLAN_SUCCESS, ADD_PLAN_FAILURE, 
    UPDATE_PLAN_REQUEST, UPDATE_PLAN_SUCCESS, UPDATE_PLAN_FAILURE, 
    PLAN_DETAILS_REQUEST, PLAN_DETAILS_SUCCESS, PLAN_DETAILS_FAILURE,
    CHANGE_PLAN_STATUS_REQUEST, CHANGE_PLAN_STATUS_SUCCESS, CHANGE_PLAN_STATUS_FAILURE,
    ADD_INSTALLATION_COST_REQUEST, ADD_INSTALLATION_COST_SUCCESS, ADD_INSTALLATION_COST_FAILURE,
    UPDATE_INSTALLATION_COST_REQUEST, UPDATE_INSTALLATION_COST_SUCCESS, UPDATE_INSTALLATION_COST_FAILURE,
    GET_INSTALLATION_COST_REQUEST, GET_INSTALLATION_COST_SUCCESS, GET_INSTALLATION_COST_FAILURE,
    GIVEAWAY_PLANS_REQUEST, GIVEAWAY_PLANS_SUCCESS, GIVEAWAY_PLANS_FAILURE,

} from "../actions";

const initialState = {
    plans_loading: false, plans: [], plans_errors: {},
    create_plans_loading: false, create_plans: {}, create_plans_errors: {},
    plan_details_loading: false, plan_details: {}, plan_details_errors: {},
    brand_costs_loading: false, brand_costs: [], brand_costs_errors: [],
    get_installation_cost_loading: false, get_installation_cost_errors: {}, installation_cost: {},
    add_installation_cost_loading: false, add_installation_cost_errors: {},
    giveaway_plans_loading: false, giveaway_plans: [], giveaway_plans_errors: {},
};

export function PlanReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Plans list redux */
        case PLANS_REQUEST: {
            return {
                ...state,
                plans_loading: true,
                plans_errors: {}
            };
        }
        case PLANS_SUCCESS: {

            let newArray = [...state.plans]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.plans.forEach((payload) => {
                const planIndex = newArray.findIndex(plans => plans._id === payload._id);
                if (planIndex !== -1) {
                    newArray[planIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                plans_loading: false,
                plans: newArray,
                plans_errors: {}
            };

        }
        case PLANS_FAILURE: {
            return {
                ...state,
                plans_loading: false,
                plans_errors: action.errors,
                plans: [],
            };
        }
        /** end::Plans list redux */

        /** begin::Create plans redux */
        case ADD_PLAN_REQUEST: {
            return {
                ...state,
                create_plans_loading: true,
                create_plans_errors: {}
            };
        }
        case ADD_PLAN_SUCCESS: {
            let planArray = [...state.plans];
            const planIndex = planArray.findIndex(plans => plans._id === action.data._id);
            if (planIndex !== -1) {
                planArray[planIndex] = action.data;
            }
            return {
                ...state,
                plans: planArray,
                create_plans_loading: false,
                create_plans: action.data,
                create_plans_errors: {}
            };
        }
        case ADD_PLAN_FAILURE: {
            return {
                ...state,
                create_plans_loading: false,
                create_plans_errors: action.errors
            };
        }
        /** end::Create plans redux */

        /** begin::Update plans redux */
        case UPDATE_PLAN_REQUEST: {
            return {
                ...state,
                create_plans_loading: true,
                create_plans_errors: {}
            };
        }
        case UPDATE_PLAN_SUCCESS: {
            let planArray = [...state.plans];
            const planIndex = planArray.findIndex(plans => plans._id === action.data._id);

            if (planIndex !== -1) {
                planArray[planIndex] = action.data;
            }
        
            return {
                ...state,
                plans: planArray,
                create_plans_loading: false,
                create_plans: action.data,
                create_plans_errors: {}
            };
        }
        case UPDATE_PLAN_FAILURE: {
            return {
                ...state,
                create_plans_loading: false,
                create_plans_errors: action.errors
            };
        }
        /** end::Update plans redux */

        /** begin::plan details redux */
        case PLAN_DETAILS_REQUEST: {
            return {
                ...state,
                plan_details_loading: true,
                plan_details_errors: {}
            };
        }
        case PLAN_DETAILS_SUCCESS: {
            return {
                ...state,
                plan_details_loading: false,
                plan_details: action.data,
                plan_details_errors: {}
            };
        }
        case PLAN_DETAILS_FAILURE: {
            return {
                ...state,
                plan_details_loading: false,
                plan_details_errors: action.errors
            };
        }
        /** end::plan details redux */

        /** begin::Change brand status redux */
        case CHANGE_PLAN_STATUS_REQUEST: {
            return {
                ...state,
                change_plan_status_loading: true,
                change_plan_status_errors: {}
            };
        }
        case CHANGE_PLAN_STATUS_SUCCESS: {   
            let planArray = [...state.plans];
            const planIndex = planArray.findIndex(plans => plans._id === action.data._id);

            if (planIndex !== -1) {
                planArray[planIndex].status = action.data.status;
            }
            return {
                ...state,
                change_plan_status_loading: false,
                change_plan_status_errors: {},
                brands: planArray
            };
        }
        case CHANGE_PLAN_STATUS_FAILURE: {
            return {
                ...state,
                change_plan_status_loading: false,
                change_plan_status_errors: action.errors
            };
        }
        /** end::Change brands status redux */

        /** begin::Add installation cost redux */
        case ADD_INSTALLATION_COST_REQUEST: {
            return {
                ...state,
                add_installation_cost_loading: true,
                add_installation_cost_errors: {}
            };
        }
        case ADD_INSTALLATION_COST_SUCCESS: {
            return {
                ...state,
                add_installation_cost_loading: false,
                installation_cost: action.data,
                add_installation_cost_errors: {},
            };
        }
        case ADD_INSTALLATION_COST_FAILURE: {
            return {
                ...state,
                add_installation_cost_loading: false,
                add_installation_cost_errors: action.errors
            };
        }
        /** end::Add installation cost redux */

        /** begin::Add installation cost redux */
        case UPDATE_INSTALLATION_COST_REQUEST: {
            return {
                ...state,
                add_installation_cost_loading: true,
                add_installation_cost_errors: {}
            };
        }
        case UPDATE_INSTALLATION_COST_SUCCESS: {
            return {
                ...state,
                add_installation_cost_loading: false,
                installation_cost: action.data,
                add_installation_cost_errors: {},
            };
        }
        case UPDATE_INSTALLATION_COST_FAILURE: {
            return {
                ...state,
                add_installation_cost_loading: false,
                add_installation_cost_errors: action.errors
            };
        }
        /** end::Add installation cost redux */

        /** begin::Add installation cost redux */
         case GET_INSTALLATION_COST_REQUEST: {
            return {
                ...state,
                get_installation_cost_loading: true,
                get_installation_cost_errors: {}
            };
        }
        case GET_INSTALLATION_COST_SUCCESS: {
            return {
                ...state,
                get_installation_cost_loading: false,
                get_installation_cost_errors: {},
                installation_cost: action.data,
            };
        }
        case GET_INSTALLATION_COST_FAILURE: {
            return {
                ...state,
                get_installation_cost_loading: false,
                get_installation_cost_errors: action.errors
            };
        }
        /** end::Add installation cost redux */

                /** begin::Giveaway Plans list redux */
        case GIVEAWAY_PLANS_REQUEST: {
            return {
                ...state,
                giveaway_plans_loading: true,
                giveaway_plans_errors: {}
            };
        }
        case GIVEAWAY_PLANS_SUCCESS: {

            let newArray = [...state.giveaway_plans]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.plans.forEach((payload) => {
                const planIndex = newArray.findIndex(giveaway_plans => giveaway_plans._id === payload._id);
                if (planIndex !== -1) {
                    newArray[planIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                giveaway_plans_loading: false,
                giveaway_plans: newArray,
                giveaway_plans_errors: {}
            };

        }
        case GIVEAWAY_PLANS_FAILURE: {
            return {
                ...state,
                giveaway_plans_loading: false,
                giveaway_plans_errors: action.errors,
                giveaway_plans: [],
            };
        }
        /** end::Giveaway Plans list redux */
        default:
            return state;
    }
}
