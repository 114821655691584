import {
    GIVEAWAYS_REQUEST, GIVEAWAYS_SUCCESS, GIVEAWAYS_FAILURE,
    CHANGE_GIVEAWAY_STATUS_REQUEST, CHANGE_GIVEAWAY_STATUS_SUCCESS, CHANGE_GIVEAWAY_STATUS_FAILURE,
    BRAND_GIVEAWAY_DETAILS_REQUEST, BRAND_GIVEAWAY_DETAILS_SUCCESS, BRAND_GIVEAWAY_DETAILS_FAILURE,
    BRAND_GIVEAWAY_COUPON_CODES_REQUEST, BRAND_GIVEAWAY_COUPON_CODES_SUCCESS, BRAND_GIVEAWAY_COUPON_CODES_FAILURE,
    GIVEAWAY_WINNER_REQUEST, GIVEAWAY_WINNER_SUCCESS, GIVEAWAY_WINNER_FAILURE,
    GIVEAWAY_ENTRIES_REQUEST, GIVEAWAY_ENTRIES_SUCCESS, GIVEAWAY_ENTRIES_FAILURE,
    GIVEAWAY_REDEEM_COUPON_REQUEST, GIVEAWAY_REDEEM_COUPON_SUCCESS, GIVEAWAY_REDEEM_COUPON_FAILURE,  
    CREATE_GIVEAWAY_REQUEST, CREATE_GIVEAWAY_SUCCESS, CREATE_GIVEAWAY_FAILURE,
    UPDATE_GIVEAWAY_REQUEST, UPDATE_GIVEAWAY_SUCCESS, UPDATE_GIVEAWAY_FAILURE,  
    GIVEAWAY_DATES_REQUEST, GIVEAWAY_DATES_SUCCESS, GIVEAWAY_DATES_FAILURE,
    GET_RUNNING_GIVEAWAYS_REQUEST, GET_RUNNING_GIVEAWAYS_SUCCESS, GET_RUNNING_GIVEAWAYS_FAILURE,

} from "../actions";

const initialState = {
    giveaways_loading: false, giveaways: [], giveaways_errors: {},
    change_giveaway_status_loading: false, change_giveaway_status_errors: {},
    brand_giveaway_details_loading: false, brand_giveaway_details: {}, brand_giveaway_details_errors: {},
    brand_giveaway_coupon_codes_loading: false, brand_giveaway_coupon_codes: [], brand_giveaway_coupon_codes_errors: {},
    giveaway_winner_loading: false, giveaway_winner: [], giveaway_winner_errors: {},
    giveaway_entries_loading: false, giveaway_entries: [], giveaway_entries_errors: {},
    giveaway_redeem_coupon_loading: false, giveaway_redeem_coupon: [], giveaway_redeem_coupon_errors: {},
    create_giveaway_loading: false, create_giveaway: {}, create_giveaway_errors: {},
    giveaway_dates_loading: false, giveaway_dates: [], giveaway_dates_errors: {},
    running_giveaways_loading: false, running_giveaways: [], running_giveaways_errors: {},

};

export function GiveawayReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List brand giveaways redux */
        case GIVEAWAYS_REQUEST: {
            return {
                ...state,
                giveaways_loading: true,
                giveaways_errors: {}
            };
        }
        case GIVEAWAYS_SUCCESS: {

            let newArray = [...state.giveaways]; //making a new array
            let runningGiveawaysArray = [...state.running_giveaways];
            
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.giveaways.forEach((payload) => {
                let activeToday = false;                
                
                if(runningGiveawaysArray && runningGiveawaysArray.length > 0) {
                    const giveawayIndex = runningGiveawaysArray.findIndex(giveaway => (giveaway._id === payload._id && (giveaway.category === 'giveaway')) );
                    activeToday = (giveawayIndex !== -1) ? true : false;
                }

                const giveawayIndex = newArray.findIndex(giveaways => giveaways._id === payload._id);

                if (giveawayIndex !== -1) {
                    newArray[giveawayIndex] = payload;
                    newArray[giveawayIndex].active_today = activeToday;
                } else {
                    newArray = newArray.concat({ ...payload, active_today: activeToday });
                }
            });

            return {
                ...state,
                giveaways_loading: false,
                giveaways: newArray,
                giveaways_errors: {}
            };

        }
        case GIVEAWAYS_FAILURE: {
            return {
                ...state,
                giveaways_loading: false,
                giveaways_errors: action.errors,
                giveaways: []
            };
        }
        /** end::List brand giveaways redux */

        /** begin::Change giveaway status redux */
        case CHANGE_GIVEAWAY_STATUS_REQUEST: {
            return {
                ...state,
                change_giveaway_status_loading: true,
                create_giveaway_errors: {}
            };
        }
        case CHANGE_GIVEAWAY_STATUS_SUCCESS: {
            let giveawayArray = [...state.giveaways];
            const giveawayIndex = giveawayArray.findIndex(giveaways => giveaways._id === action.data._id);
            if (giveawayIndex !== -1) {
                giveawayArray[giveawayIndex].status = action.data.status;
            }
            return {
                ...state,
                change_giveaway_status_loading: false,
                change_giveaway_status_errors: {},
                giveaways: giveawayArray
            };
        }
        case CHANGE_GIVEAWAY_STATUS_FAILURE: {
            return {
                ...state,
                change_giveaway_status_loading: false,
                change_giveaway_status_errors: action.errors
            };
        }
        /** end::Change giveaway status redux */

        /** begin::giveaway details redux */
        case BRAND_GIVEAWAY_DETAILS_REQUEST: {
            return {
                ...state,
                brand_giveaway_details_loading: true,
                brand_giveaway_details_errors: {}
            };
        }
        case BRAND_GIVEAWAY_DETAILS_SUCCESS: {
            let runningGiveawaysArray = [...state.running_giveaways];

            if (runningGiveawaysArray && runningGiveawaysArray.length > 0) {
                const giveawayIndex = runningGiveawaysArray.findIndex(giveaway => (giveaway._id === action.data._id && (giveaway.category === 'giveaway')) );
                action.data.active_today = (giveawayIndex !== -1) ? true : false;
            }
            
            return {
                ...state,
                brand_giveaway_details_loading: false,
                brand_giveaway_details: action.data,
                brand_giveaway_details_errors: {}
            };
        }
        case BRAND_GIVEAWAY_DETAILS_FAILURE: {
            return {
                ...state,
                brand_giveaway_details_loading: false,
                brand_giveaway_details_errors: action.errors
            };
        }

        case BRAND_GIVEAWAY_COUPON_CODES_REQUEST: {
            return {
                ...state,
                brand_giveaway_coupon_codes_loading: true,
                brand_giveaway_coupon_codes_errors: {}
            };
        }
        case BRAND_GIVEAWAY_COUPON_CODES_SUCCESS: {

            let newArray = [...state.brand_giveaway_coupon_codes]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.coupon_codes.forEach((payload) => {
                const couponCodeIndex = newArray.findIndex(coupon_code_data => coupon_code_data.coupon_code === payload.coupon_code);
                if (couponCodeIndex !== -1) {
                    newArray[couponCodeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                brand_giveaway_coupon_codes_loading: false,
                brand_giveaway_coupon_codes: newArray,
                brand_giveaway_coupon_codes_errors: {}
            };

        }
        case BRAND_GIVEAWAY_COUPON_CODES_FAILURE: {
            return {
                ...state,
                brand_giveaway_coupon_codes: [],
                brand_giveaway_coupon_codes_loading: false,
                brand_giveaway_coupon_codes_errors: action.errors
            };
        }
        /** end::giveaway details redux */

        /** begin::Get giveaway winner redux */
        case GIVEAWAY_WINNER_REQUEST: {
            return {
                ...state,
                giveaway_winner_loading: true,
                giveaway_winner_errors: {}
            };
        }
        case GIVEAWAY_WINNER_SUCCESS: {

            const newData = action.data;
            return {
                ...state,
                giveaway_winner: newData,
                giveaway_winner_loading: false,
                giveaway_winner_errors: {}
            };
        }
        case GIVEAWAY_WINNER_FAILURE: {
            return {
                ...state,
                giveaway_winner_loading: false,
                giveaway_winner_errors: action.errors
            };
        }
        /** end::Get giveaway winner redux */

        /** begin::Giveaway entries list redux */
        case GIVEAWAY_ENTRIES_REQUEST: {
            return {
                ...state,
                giveaway_entries_loading: true,
                giveaway_entries_errors: {}
            };
        }
        case GIVEAWAY_ENTRIES_SUCCESS: {

            let newArray = [...state.giveaway_entries]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.giveaway_entries.forEach((payload) => {
                const couponCodeIndex = newArray.findIndex(data => data._id === payload._id);
                if (couponCodeIndex !== -1) {
                    newArray[couponCodeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                giveaway_entries_loading: false,
                giveaway_entries: newArray,
                giveaway_entries_errors: {}
            };

        }
        case GIVEAWAY_ENTRIES_FAILURE: {
            return {
                ...state,
                giveaway_entries: [],
                giveaway_entries_loading: false,
                giveaway_entries_errors: action.errors
            };
        }
        /** end::Giveaway entries list redux */

        /** begin::Giveaway Redeem coupon redux */
        case GIVEAWAY_REDEEM_COUPON_REQUEST: {
            return {
                ...state,
                giveaway_redeem_coupon_loading: true,
                giveaway_redeem_coupon_errors: {}
            };
        }
        case GIVEAWAY_REDEEM_COUPON_SUCCESS: {
            let couponArray = [...state.brand_giveaway_coupon_codes];
            const couponIndex = couponArray.findIndex(coupons => coupons._id === action.data._id && coupons.coupon_code === action.data.coupon_code);
            if (couponIndex !== -1) {
                couponArray[couponIndex].status = action.data.status;
                couponArray[couponIndex].redeemed_at = action.data.redeemed_at;
            }

            return {
                ...state,
                giveaway_redeem_coupon_loading: false,
                brand_giveaway_coupon_codes: couponArray,
                giveaway_redeem_coupon_errors: {}
            };

        }
        case GIVEAWAY_REDEEM_COUPON_FAILURE: {
            return {
                ...state,
                giveaway_redeem_coupon: [],
                giveaway_redeem_coupon_loading: false,
                giveaway_redeem_coupon_errors: action.errors
            };
        }
        /** end::Giveaway Redeem coupon redux */

        /** begin::Create giveaway redux */
        case CREATE_GIVEAWAY_REQUEST: {
            return {
                ...state,
                create_giveaway_loading: true,
                create_giveaway_errors: {}
            };
        }
        case CREATE_GIVEAWAY_SUCCESS: {

            const newData = [action.data];
            return {
                ...state,
                giveaways: (state.giveaways.length > 0) ? (newData.concat(state.giveaways)) : newData,
                create_giveaway_loading: false,
                create_giveaway: action.data,
                create_giveaway_errors: {}
            };
        }
        case CREATE_GIVEAWAY_FAILURE: {
            return {
                ...state,
                create_giveaway_loading: false,
                create_giveaway_errors: action.errors
            };
        }
        /** end::Create giveaway redux */

        /** begin::Update giveaway redux */
        case UPDATE_GIVEAWAY_REQUEST: {
            return {
                ...state,
                create_giveaway_loading: true,
                create_giveaway_errors: {}
            };
        }
        case UPDATE_GIVEAWAY_SUCCESS: {
            let giveawayArray = [...state.giveaways];
            const giveawayIndex = giveawayArray.findIndex(giveaways => giveaways._id === action.data._id);
            if (giveawayIndex !== -1) {
                giveawayArray[giveawayIndex] = action.data;
            }

            return {
                ...state,
                giveaways: giveawayArray,
                create_giveaway_loading: false,
                create_giveaway: action.data,
                create_giveaway_errors: {}
            };
        }
        case UPDATE_GIVEAWAY_FAILURE: {
            return {
                ...state,
                create_giveaway_loading: false,
                create_giveaway_errors: action.errors
            };
        }

        
        /** begin::Giveaway dates list redux */
        case GIVEAWAY_DATES_REQUEST: {
            return {
                ...state,
                giveaway_dates_loading: true,
                giveaway_dates_errors: {}
            };
        }
        case GIVEAWAY_DATES_SUCCESS: {

            return {
                ...state,
                giveaway_dates_loading: false,
                giveaway_dates: action.data,
                giveaway_dates_errors: {}
            };

        }
        case GIVEAWAY_DATES_FAILURE: {
            return {
                ...state,
                giveaway_dates: [],
                giveaway_dates_loading: false,
                giveaway_dates_errors: action.errors
            };
        }
        /** end::Giveaway dates list redux */

                /** begin::running promos redux*/
        case GET_RUNNING_GIVEAWAYS_REQUEST: {
            return {
                ...state,
                running_giveaways_loading: true,
                running_giveaways_errors: {}
            };
        }
        case GET_RUNNING_GIVEAWAYS_SUCCESS: {

            let newArray = [...state.giveaways]; //making a new array
            let newDetailsArray = state.brand_giveaway_details; //making a new array

            newArray.forEach(payload => {
                const giveawayIndex = action.data.findIndex(giveaway => ((giveaway._id === payload._id) && (giveaway.category === 'giveaway')));

                if (giveawayIndex !== -1) {
                    payload.active_today = true;
                } else {
                    payload.active_today = false;
                }
            });

            if (newDetailsArray && Object.keys(newDetailsArray).length > 0) {

                const giveawayIndex = action.data.findIndex(giveaway => ((giveaway._id === newDetailsArray._id) && (giveaway.category === 'giveaway')));

                if (giveawayIndex !== -1) {
                    newDetailsArray.active_today = true;
                } else {
                    newDetailsArray.active_today = false;
                }
            }

            return {
                ...state,
                running_giveaways_loading: false,
                running_giveaways: action.data,
                running_giveaways_errors: {},
                giveaways: newArray,
                brand_giveaway_details: newDetailsArray
            };

        }
        case GET_RUNNING_GIVEAWAYS_FAILURE: {
            return {
                ...state,
                running_giveaways: [],
                running_giveaways_loading: false,
                running_giveaways_errors: action.errors
            };
        }
        /** end::running promos redux */

        /** end::Update giveaway redux */
        default:
            return state;
    }
}
