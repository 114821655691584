import { Email, Instagram, Phone } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, CardHeader, Chip, Divider, Grid, Typography } from '@mui/material'
import React from 'react'

function ContactUsDetailsCard({ contact_us_details }) {

    return (
        <Card>
            <Grid container spacing={2} display="flex" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CardHeader
                            avatar={<Avatar aria-label="recipe"> R </Avatar>}
                            title={contact_us_details.name}
                            subheader={contact_us_details.created_at}
                            action={<Chip label={contact_us_details.status.toUpperCase()} color={contact_us_details.status === 'active' ? 'success':'error'} />}
                        />
                        <CardContent sx={{ minHeight: `740px`, pt: 4 }}>
                            <Divider/>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2"><Instagram /> {`Instagram Handle : `}</Typography>
                                <Typography variant="h6">{`@${contact_us_details.insta_handle}`}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2"><Email /> {`Email : `}</Typography>
                                <Typography variant="h6">{`${contact_us_details.email}`}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2"><Phone /> {`Mobile Number : `}</Typography>
                                <Typography variant="h6">{`${contact_us_details.mobile_number}`}</Typography>
                            </Box>
                            <Divider/>

                            <Typography variant="body2">{`${contact_us_details.message}`}</Typography>
                        </CardContent>
                    </Grid>
            </Grid>
        </Card>
    )
}

export { ContactUsDetailsCard }