import {
    STORIES_REQUEST, STORIES_SUCCESS, STORIES_FAILURE,
    INFLUENCER_STORIES_REQUEST, INFLUENCER_STORIES_SUCCESS, INFLUENCER_STORIES_FAILURE,
    STORY_DETAIL_REQUEST, STORY_DETAIL_SUCCESS, STORY_DETAIL_FAILURE,
    STORY_TIMELINE_REQUEST, STORY_TIMELINE_SUCCESS, STORY_TIMELINE_FAILURE,
    STORY_PROMO_DETAILS_REQUEST, STORY_PROMO_DETAILS_SUCCESS, STORY_PROMO_DETAILS_FAILURE,
    EXPORT_STORY_REQUEST, EXPORT_STORY_SUCCESS, EXPORT_STORY_FAILURE,
} from "../actions";

const initialState = {
    stories_loading: false, stories: [], stories_errors: {},
    influencer_stories_loading: false, influencer_stories: [], influencer_stories_errors: {},
    story_detail_loading: true, story_detail: {}, story_detail_errors: {},
    story_timeline_loading: true, story_timeline: [], story_timeline_errors: {},
    story_promo_loading: false, story_promo: {}, story_promo_errors: {},
    export_story_loading: false, export_story: [], export_story_errors: {},
};

export function StoryReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::story List redux */
        case STORIES_REQUEST: {
            return {
                ...state,
                stories_loading: true,
                stories_errors: {}
            };
        }
        case STORIES_SUCCESS: {

            let newArray = [...state.stories]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.stories.forEach((payload) => {
                const storyIndex = newArray.findIndex(story => story._id === payload._id);
                if (storyIndex !== -1) {
                    newArray[storyIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                stories_loading: false,
                stories: newArray,
                stories_errors: {}
            };

        }
        case STORIES_FAILURE: {
            return {
                ...state,
                stories_loading: false,
                stories_errors: action.errors,
                stories: [],
            };
        }

        /** end::List story redux */

        /** begin::story details redux */
        case INFLUENCER_STORIES_REQUEST: {
            return {
                ...state,
                influencer_stories_loading: true,
                influencer_stories_errors: {}
            };
        }
        case INFLUENCER_STORIES_SUCCESS: {

            let newArray = [...state.influencer_stories]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }

            action.data.stories.forEach((payload) => {
                const storyIndex = newArray.findIndex(story => story._id === payload._id);
                if (storyIndex !== -1) {
                    newArray[storyIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                influencer_stories_loading: false,
                influencer_stories: newArray,
                influencer_stories_errors: {}
            };

        }
        case INFLUENCER_STORIES_FAILURE: {
            return {
                ...state,
                influencer_stories_loading: false,
                influencer_stories_errors: action.errors,
                influencer_stories: []
            };
        }
        /** end::story details redux */

        /** begin::story details by sId redux */
        case STORY_DETAIL_REQUEST: {
            return {
                ...state,
                story_detail_loading: true,
                story_detail_errors: {}
            };
        }
        case STORY_DETAIL_SUCCESS: {

            return {
                ...state,
                story_detail_loading: false,
                story_detail: action.data,
                story_detail_errors: {}
            };

        }
        case STORY_DETAIL_FAILURE: {
            return {
                ...state,
                story_detail_loading: false,
                story_detail_errors: action.errors
            };
        }
        /** end::Story details by sId redux */

        /** begin::Story timeline redux */
        case STORY_TIMELINE_REQUEST: {
            return {
                ...state,
                story_timeline_loading: true,
                story_timeline_errors: {}
            };
        }
        case STORY_TIMELINE_SUCCESS: {

            return {
                ...state,
                story_timeline_loading: false,
                story_timeline: action.data,
                story_timeline_errors: {}
            };

        }
        case STORY_TIMELINE_FAILURE: {
            return {
                ...state,
                story_timeline_loading: false,
                story_timeline_errors: action.errors
            };
        }
        /** end::Story timeline redux */

        /** begin::Promo details redux of stories*/
        case STORY_PROMO_DETAILS_REQUEST: {
            return {
                ...state,
                story_promo_loading: true,
                story_promo_errors: {}
            };
        }
        case STORY_PROMO_DETAILS_SUCCESS: {

            return {
                ...state,
                story_promo_loading: false,
                story_promo: action.data,
                story_promo_errors: {}
            };

        }
        case STORY_PROMO_DETAILS_FAILURE: {
            return {
                ...state,
                story_promo_loading: false,
                story_promo_errors: action.errors,
            };
        }
        /** end::Promo details redux of stories */

        /** begin::Export story redux*/
        case EXPORT_STORY_REQUEST: {
            return {
                ...state,
                export_story_loading: true,
                export_story_errors: {}
            };
        }
        case EXPORT_STORY_SUCCESS: {

            return {
                ...state,
                export_story_loading: false,
                export_story: action.data,
                export_story_errors: {}
            };

        }
        case EXPORT_STORY_FAILURE: {
            return {
                ...state,
                export_story_loading: false,
                export_story: [],
                export_story_errors: action.errors,
            };
        }
        /** end::Export story redux*/
        default:
            return state;
    }
}