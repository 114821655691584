import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { PromoAction } from '../../../redux/actions';
import { CardHeader } from '../../../_components/card';
import { GeneralButton } from '../../../_components/controls';
import { PostAuthRoutes } from '../../../routes';
import { PromoPosterCard, PromoPosterCardLoader } from './promos-poster-card';

function PromosPosterDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const promoId = searchParams.get('pId'); // promo id from url

    
    /** Redux actions and state */
    const { promo_poster_loading: isLoading, promo_poster } = useSelector((state) => state.PromoReducer);
    const getPromoPoster = (params) => dispatch(PromoAction.getPromoPoster(params));

    useEffect(() => {
        getPromoPoster({ promo_id: promoId });

        //eslint-disable-next-line
    }, [promoId])

    /**
     * function to create wallet
     * @return view
     * @author Jasin 
     * @created_at 30 May 2022
     */
    const updatePoster = () => {
        history.push({
            pathname: PostAuthRoutes('update_poster_details').path,
            search: `?pId=${promoId}`,
            state: { poster: promo_poster },
        });
    }

    return (
        <React.Fragment>
            <CardHeader action={
                <Stack spacing={1} direction="row">
                    <GeneralButton label={'Update Poster Details'} onClick={updatePoster}/>
                </Stack>
            } />

            {(!isLoading && Object.keys(promo_poster).length > 0) &&
                <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                    <Box sx={{ maxWidth: `800px` }}>
                        <PromoPosterCard promo_poster={promo_poster} />
                    </Box>
                </Box>
            }
            {(isLoading) &&
                <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                    <Box sx={{ maxWidth: `800px` }}>
                        <PromoPosterCardLoader count={1} />
                    </Box>
                </Box>
            }
            
        </React.Fragment>
    )
}

export { PromosPosterDetails }
