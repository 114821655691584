module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author 
     * @created_at 14 June 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {
            case 'advertisement_type': {
                errors.advertisement_type = '';
                if (value === '')
                    errors.advertisement_type = 'Select advertisement type';
                break;
            } case 'description': {
                errors.description = '';
                if (value === '')
                    errors.description = 'Enter description';
                break;
            } case 'advertisement_picture': {
                errors.advertisement_picture = '';
                if (value === '' || value === [] || typeof value === 'undefined') {
                    errors.advertisement_picture = 'Select advertisement image . ';
                } else if (typeof value !== 'string') {
                    let extension = (value.name).split('.').pop();
                    extension = extension.toLowerCase();
                    let size = value.size;
                    if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                        errors.advertisement_picture = 'Please choose a valid image format.';
                    } else if (size >= 2000000) {
                        errors.advertisement_picture = 'Select advertisement image size exceed. ';
                    }
                }
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}