module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 14 June 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'brand_id': {
                errors.brand_id = '';
                if (value === '')
                    errors.brand_id = 'Select brand.';
                break;
            } case 'cost': {
                errors.cost = '';
                if (value === '')
                    errors.cost = 'Enter cost.';
                break;
            }  case 'date_range': {
                errors.date_range = '';
                if (value === '')
                    errors.date_range = 'Enter date range.';
                break;
            } case 'giveaway_coupon_cost': {
                errors.giveaway_coupon_cost = '';
                if (value === '')
                    errors.giveaway_coupon_cost = 'Enter giveaway coupon cost.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}