import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Stack } from '@mui/material'
import { PostAuthRoutes } from '../../routes'
import { Card, CardContent, CardHeader } from '../../_components/card'
import { GeneralButton } from '../../_components/controls'
import { DeleteModal } from '../../_components/modal/modals/DeleteModal';
import { LogAction } from '../../redux/actions';
import { LogsDataTable } from '.'
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function Logs() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux reducer states */
    const { user } = useSelector((state) => state.UserReducer);
    const deleteAllLogs = (params) => dispatch(LogAction.deleteAllLogs(params));

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ limit: 100, page: 1, });
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    /**
     * function to open/close logs delete modal
     * @return view
     * @author Naveen
     * @created_at 07 Feb 2024
     */
    const logsDeleteModal = async () => {
        setShowDeleteModal(!showDeleteModal)
    }

    /**
     * function to fetch giveaway list
     * @param {Object} payload - The params which are used for the api
     * @return json
     * @author Naveen
     * @created_at 07 Feb 2024
     */
    const logsAllDeleteAction = async () => {
        setShowDeleteModal(!showDeleteModal)
        await deleteAllLogs({})
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('log').name}
                    action={
                            <Stack spacing={1} direction="row" alignItems={`center`} >
                                <AbilityCan I='admin_log_delete_all' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    <GeneralButton label='Clear All Logs' onClick={() => logsDeleteModal()} />
                                </AbilityCan>
                            </Stack>
                    }
                />
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <LogsDataTable limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <DeleteModal show={showDeleteModal} closeModal={logsDeleteModal} data={{id: " "}} deleteModal={logsAllDeleteAction} />
        </React.Fragment>
    )
}

export { Logs }