import { CurrencyRupee } from "@mui/icons-material";
import { Box, Card, CardContent, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PlanAction } from "../../../redux/actions";
import { PostAuthRoutes } from "../../../routes";
import { CardHeader } from "../../../_components/card";
import { NoData } from "../../../_components/layout/no-data";

function PlanDetails({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const planId = params.get('planId'); // plan id from url

    /** Redux actions and state */
    const { plan_details_loading: isLoading, plan_details } = useSelector((state) => state.PlanReducer);
    const planDetails = (params) => dispatch(PlanAction.planDetails(params));

    useEffect(() => {
        planDetails({ plan_id: planId });

        //eslint-disable-next-line
    }, [planId])

    return (
        <React.Fragment>
            {!isLoading && plan_details.length === 0 && <NoData content1={`No`} content2={`Plans available`} minHeight={`55vh`} ></NoData>}
            {(!isLoading && Object.keys(plan_details).length > 0) &&
                <React.Fragment>
                    <CardHeader title={PostAuthRoutes('plan_details').name} />
                    <Grid container >
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                            <Card>
                                <CardContent>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
                                        <Typography variant="h5">{`${plan_details.name}`}</Typography>
                                        <Box>
                                            <Chip size='small' label={plan_details.status === 'active' ? 'Active' : 'Inactive'} color={plan_details.status === 'active' ? 'success' : 'error'} />
                                        </Box>
                                    </Box>

                                    <Stack spacing={2} direction="column" display={`flex`} alignItems={`center`} justifyContent={`center`} py={10}>
                                        <Box style={{ minHeight: '80px' }}>
                                            {plan_details.discounted_cost && <Typography variant="h2" style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, fontWeight: 500, textDecorationLine: 'line-through' }}><CurrencyRupee style={{ marginRight: '-5px' }} />{`${plan_details.cost}`}</Typography>}
                                            {plan_details.discount && <Chip label={`${plan_details.discount}% of discount`} size="small" />}
                                        </Box>
                                        <Typography variant="d1" style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, fontWeight: 600 }}><CurrencyRupee style={{ marginRight: '-20px' }} />{`${plan_details.discounted_cost ?? plan_details.cost}`}</Typography>
                                        {plan_details.category !== 'giveaway' && <Chip label={`${plan_details.number_of_coupons} coupons`} size="small" />}
                                    </Stack>

                                    <Divider />

                                    <Stack spacing={1}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                                            <Typography variant="body2">{`Category : `}</Typography>
                                            <Chip label={`${plan_details.category}`} style={{ textTransform: `capitalize` }}size="small" />
                                        </Box>
                                        {plan_details.category === 'giveaway' && <Box display="flex" justifyContent="space-between" alignItems="center" >
                                            <Typography variant="body2">{`Duration : `}</Typography>
                                            <Chip label={`${plan_details.duration} ${plan_details.duration > '1' ? 'Days' : 'Day'}`} style={{ textTransform: `capitalize` }} size="small" />
                                        </Box>}
                                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                                            <Typography variant="body2">{`Created On : `}</Typography>
                                            <Chip label={`${plan_details.created_at}`} size="small" />
                                        </Box>
                                    </Stack>
                                    <Divider />
                                    <Box style={{ minHeight: `80px` }}>
                                        <Typography variant="body2">{`${plan_details.description}`}</Typography>
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}

export { PlanDetails }