import { apiServices } from '../../_helpers';

export const EmailLogServices = {

    /**
     * Service to list email log
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    listEmailLogs: (params) => {
        return apiServices.post('/admin/email-log/list', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get email log details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    emailLogDetails: (params) => {
        return apiServices.post('admin/email-log/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service to resend email
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    ResendMail: (params) => {
        return apiServices.post('admin/email-log/resend', params)
            .then((response) => { return response; })
    },
};
