import {
    BRAND_LAST_INVOICE_REQUEST, BRAND_LAST_INVOICE_SUCCESS, BRAND_LAST_INVOICE_FAILURE,
    INVOICE_REQUEST, INVOICE_SUCCESS, INVOICE_FAILURE
} from "../actions";

const initialState = {
    brand_last_invoice_loading: false, brand_last_invoice: [], brand_last_invoice_errors: {},
    page: 1, per_page: 100, total: 0, total_pages: 0,
    invoices_loading: false, invoices: [], invoices_errors: {}
};

export function InvoiceReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::brand last invoice details redux */
        case BRAND_LAST_INVOICE_REQUEST: {
            return {
                ...state,
                brand_last_invoice_loading: true,
                brand_last_invoice_errors: {}
            };
        }
        case BRAND_LAST_INVOICE_SUCCESS: {
            return {
                ...state,
                brand_last_invoice_loading: false,
                brand_last_invoice: action.data,
                brand_last_invoice_errors: {}
            };
        }
        case BRAND_LAST_INVOICE_FAILURE: {
            return {
                ...state,
                brand_last_invoice_loading: false,
                brand_last_invoice_errors: action.errors
            };
        }
        /** end::brand last invoice details redux */

        /** begin::Plans list redux */
        case INVOICE_REQUEST: {
            return {
                ...state,
                invoices_loading: true,
                invoices_errors: {}
            };
        }
        case INVOICE_SUCCESS: {

            let newArray = [...state.invoices]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.invoices.forEach((payload) => {
                const invoiceIndex = newArray.findIndex(invoice => invoice._id === payload._id);
                if (invoiceIndex !== -1) {
                    newArray[invoiceIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                invoices_loading: false,
                invoices: action.data.invoices,
                invoices_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case INVOICE_FAILURE: {
            return {
                ...state,
                invoices_loading: false,
                invoices_errors: action.errors,
                invoices: [],
            };
        }
        /** end::Plans list redux */

        default:
            return state;
    }
}
