import { apiServices } from '../../_helpers';

export const BrandCategoryServices = {

    /**
     * Service for List brand category
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */

    listBrandCategory: (params) => {
        return apiServices.post('admin/brand-category/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for add brand category
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    addBrandCategory: (params) => {
        return apiServices.put('admin/brand-category/add', params)
            .then((response) => { return response; })
    },

    /**
     * Service for update brand category
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    updateBrandCategory: (params) => {
        return apiServices.put('admin/brand-category/update', params)
            .then((response) => { return response; })
    },

    /**
     * Service for change status brand category
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    changeStatus: (params) => {
        return apiServices.put('admin/brand-category/change-status', params)
            .then((response) => { return response; })
    },

    /**
     * Service for brand select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
    */
    brandCategorySelectList: (params) => {
        return apiServices.post('admin/brand-category/select-list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for brand category details
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 26 Oct 2023
     */
    brandCategoryDetails: (params) => {
        return apiServices.post('/admin/brand-category/details', params)
            .then((response) => { return response; })

    },
}
