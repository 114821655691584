import {
    EMAIL_LOGS_REQUEST, EMAIL_LOGS_SUCCESS, EMAIL_LOGS_FAILURE,
    EMAIL_LOG_DETAILS_REQUEST, EMAIL_LOG_DETAILS_SUCCESS, EMAIL_LOG_DETAILS_FAILURE,
    EMAIL_LOG_RESEND_REQUEST, EMAIL_LOG_RESEND_SUCCESS, EMAIL_LOG_RESEND_FAILURE
} from "../actions";

const initialState = {
    page: 1, per_page: 100, total: 0, total_pages: 0,
    email_logs_loading: false, email_logs: [], email_logs_errors: {},
    email_log_details_loading: false, email_log: {}, email_log_details_errors: {},
    email_log_resend_loading: false, email_log_resend_errors: {},
};

export function EmailLogReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Plans list redux */
        case EMAIL_LOGS_REQUEST: {
            return {
                ...state,
                email_logs_loading: true,
                email_logs_errors: {}
            };
        }
        case EMAIL_LOGS_SUCCESS: {

            let newArray = [...state.email_logs]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.logs.forEach((payload) => {
                const planIndex = newArray.findIndex(email_logs => email_logs._id === payload._id);
                if (planIndex !== -1) {
                    newArray[planIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                email_logs_loading: false,
                email_logs: action.data.logs,
                email_logs_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case EMAIL_LOGS_FAILURE: {
            return {
                ...state,
                email_logs_loading: false,
                email_logs_errors: action.errors,
                email_logs: []
            };
        }
        /** end::Plans list redux */


        /** begin::email log details redux */
        case EMAIL_LOG_DETAILS_REQUEST: {
            return {
                ...state,
                email_log_details_loading: true,
                email_log_details_errors: {}
            };
        }
        case EMAIL_LOG_DETAILS_SUCCESS: {
            return {
                ...state,
                email_log_details_loading: false,
                email_log: action.data,
                email_log_details_errors: {}
            };
        }
        case EMAIL_LOG_DETAILS_FAILURE: {
            return {
                ...state,
                email_log_details_loading: false,
                email_log_details_errors: action.errors
            };
        }
        /** end::email log details redux */

        /** begin::email log details redux */
        case EMAIL_LOG_RESEND_REQUEST: {
            return {
                ...state,
                email_log_resend_loading: true,
                email_log_resend_errors: {}
            };
        }
        case EMAIL_LOG_RESEND_SUCCESS: {
            let newArray = state.email_log; //making a new array 
            let newArrayList = state.email_logs; //making a new array                

            if (newArrayList) {
                const brandIndex = newArrayList.findIndex(email_logs => email_logs._id === action.data._id);
                if (brandIndex !== -1) {
                    newArrayList[brandIndex].status = action.data.status;
                    newArrayList[brandIndex].response = action.response;
                }
            }

            if (newArray && (newArray._id === action.data._id)) {
                newArray.status = action.data.status;
                newArray.response = action.data.response;
            }

            return {
                ...state,
                email_log_resend_loading: false,
                email_log: newArray,
                email_logs: newArrayList,
                email_log_resend_errors: {}
            };
        }
        case EMAIL_LOG_RESEND_FAILURE: {
            return {
                ...state,
                email_log_resend_loading: false,
                email_log_resend_errors: action.errors
            };
        }
        /** end::email log details redux */

        default:
            return state;
    }
}
