import { apiServices } from '../../_helpers';


export const DashboardServices = {
    /**
     * Service to get influencer count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    influencerCount: (params) => {
        return apiServices.post('/admin/influencer/count', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get brand count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    brandCount: (params) => {
        return apiServices.post('/admin/brand/count', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get promo count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    promoCount: (params) => {
        return apiServices.post('/admin/promo/count', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get story count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    storyCount: (params) => {
        return apiServices.post('/admin/story/count', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get top influencers by followers count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    getTopInfluencersByFollowers: (params) => {
        return apiServices.post('/admin/influencer/top-influencers-by-followers', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get top influencers by stories count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    getTopInfluencersByStories: (params) => {
        return apiServices.post('/admin/influencer/top-influencers-by-stories', params)
            .then((response) => { return response; })
    },

    /**
     * 
     * Service to get top brands by followers count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    getTopBrandsByFollowers: (params) => {
        return apiServices.post('/admin/brand/top-brands-by-followers', params)
            .then((response) => { return response ; })
    },

    /**
     * 
     * Service to get top brands by stories count
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    getTopBrandsByStories: (params) => {
        return apiServices.post('/admin/brand/top-brands-by-stories', params)
            .then((response) => { return response; })
    },


}