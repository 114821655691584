import { RegisterServices } from "../services";

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const RegisterAction = {

    /**
    * Action for register admin
    * @param {Object} params - The params which are used for registration api.
    * @param {string} params.name - Name of the admin 
    * @param {string} params.email - Email of the admin 
    * @param {string} params.password - Password entered by the admin 
    * @param {string} params.confirm_password - Confirm password entered by the admin 
    * @author Jasin 
    * @created_at 15 June 2022
    */
    register: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RegisterServices.register(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: REGISTER_REQUEST, request: request } }
        function success(request, response) { return { type: REGISTER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: REGISTER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};



