import React from 'react';
import { Box, Card, Grid, Skeleton } from '@mui/material';

function StoryCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 12; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} key={i}  {...props}>
                <Card style={{ padding: 0 }}>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: `100%`, padding: 0 }}>
                        <Skeleton variant="rectangular" width={230} height={380} sx={{ borderRadius: 3 }} />
                    </Box>
                </Card>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { StoryCardLoader };
