import { apiServices } from '../../_helpers';

export const InvoiceServices = {

    /**
     * Service to get brand last invoice
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    generateInvoice: (params) => {
        return apiServices.post('admin/invoice/generate', params)
            .then((response) => { return response; })
    },
    /**
     * Service to get brand last invoice
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    brandLastInvoices: (params) => {
        return apiServices.post('admin/invoice/brand-last-invoice', params)
            .then((response) => { return response; })
    },
    /**
     * Service to get all invoices
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
    */
    listInvoices: (params) => {
        return apiServices.post('admin/invoice/list', params)
            .then((response) => { return response; })
    },
};
