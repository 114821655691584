
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    signinAction: {
        marginTop: theme.spacing(1)
    },
    actionBox: {
        marginTop: theme.spacing(2)
    }
}))

export { useStyles };