import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ApprovalAction, InvoiceAction } from '../../../redux/actions';
import { PostAuthRoutes } from '../../../routes'
import { CardHeader } from '../../../_components/card';
import { ApprovalActionDetailCard, LastInvoiceDetailCard } from './';

function ApprovalsAction() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const requestId = params.get('request'); // request id from url

    /** Redux actions and state */
    const { approval_loading: isLoading, approval } = useSelector((state) => state.ApprovalReducer);
    const { brand_last_invoice } = useSelector((state) => state.InvoiceReducer);
    const approvalDetails = (params) => dispatch(ApprovalAction.approvalDetails(params));
    const brandLastInvoices = (params) => dispatch(InvoiceAction.brandLastInvoices(params));

    /** Initialize and declare state */
    const [filter] = useState({ requested_by: 'brand' });

    useEffect(() => {
        approvalDetails({ request_id: requestId, filter: filter });
        brandLastInvoices({ request_id: requestId, number_of_invoice: 3 })
        //eslint-disable-next-line
    }, [requestId])

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('approvals_action').name} />
            </Box>
            <Grid container spacing={2}>
                <Grid item container xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" >
                        {(!isLoading && approval) &&
                            <ApprovalActionDetailCard approval={approval} requestId={requestId} />
                        }
                    </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} md={9} lg={9} xl={9} spacing={2} direction="row" justifyContent="left" >
                    {
                        brand_last_invoice.length > 0 && brand_last_invoice.map((brandLastInvoice, i) => {
                            return (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={i}><LastInvoiceDetailCard brandLastInvoice={brandLastInvoice} /></Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export { ApprovalsAction }