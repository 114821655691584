import {
    ADD_BRAND_CATEGORY_REQUEST, ADD_BRAND_CATEGORY_SUCCESS, ADD_BRAND_CATEGORY_FAILURE,
    UPDATE_BRAND_CATEGORY_REQUEST, UPDATE_BRAND_CATEGORY_SUCCESS, UPDATE_BRAND_CATEGORY_FAILURE,
    CHANGE_BRAND_CATEGORY_STATUS_REQUEST, CHANGE_BRAND_CATEGORY_STATUS_SUCCESS, CHANGE_BRAND_CATEGORY_STATUS_FAILURE,
    BRAND_CATEGORY_REQUEST, BRAND_CATEGORY_SUCCESS, BRAND_CATEGORY_FAILURE,
    BRAND_CATEGORY_SELECT_LIST_REQUEST, BRAND_CATEGORY_SELECT_LIST_SUCCESS, BRAND_CATEGORY_SELECT_LIST_FAILURE,
    BRAND_CATEGORY_DETAILS_REQUEST, BRAND_CATEGORY_DETAILS_SUCCESS, BRAND_CATEGORY_DETAILS_FAILURE,

} from "../actions";

const initialState = {
    add_brand_category_loading: false, add_brand_category_errors: {},
    change_brand_category_status_loading: false, change_brand_category_status_errors: {},
    brand_category_loading: false, brand_category: [], brand_category_errors: [],
    brand_category_select_list_loading: false, brand_category_select_list: [], brand_category_select_list_errors: {},
    brand_category_details_loading: false, brand_category_details: {}, brand_category_details_errors: {},
};

export function BrandCategoryReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Create Permission redux */
        case ADD_BRAND_CATEGORY_REQUEST: {
            return {
                ...state,
                add_brand_category_loading: true,
                add_brand_category_errors: {}
            };
        }
        case ADD_BRAND_CATEGORY_SUCCESS: {
            return {
                ...state,
                add_brand_category_loading: false,
                add_brand_category_errors: {},
            };
        }
        case ADD_BRAND_CATEGORY_FAILURE: {
            return {
                ...state,
                add_brand_category_loading: false,
                add_brand_category_errors: action.errors
            };
        }
        /** end::Create Permission redux */

         /** begin::Update Permission redux */
         case UPDATE_BRAND_CATEGORY_REQUEST: {
            return {
                ...state,
                add_brand_category_loading: true,
                add_brand_category_errors: {}
            };
        }
        case UPDATE_BRAND_CATEGORY_SUCCESS: {
            return {
                ...state,
                add_brand_category_loading: false,
                add_brand_category_errors: {},
            };
        }
        case UPDATE_BRAND_CATEGORY_FAILURE: {
            return {
                ...state,
                add_brand_category_loading: false,
                add_brand_category_errors: action.errors
            };
        }
        /** end::Update Permission redux */

        /** begin::Change Permission status redux */
         case CHANGE_BRAND_CATEGORY_STATUS_REQUEST: {
            return {
                ...state,
                change_brand_category_status_loading: true,
                change_brand_category_status_errors: {}
            };
        }
        case CHANGE_BRAND_CATEGORY_STATUS_SUCCESS: {
            let newArray = [...state.brand_category];
            const brandCategoryIndex = newArray.findIndex(brand_category => (brand_category._id === action.data._id));

            if (brandCategoryIndex !== -1) {
                newArray[brandCategoryIndex].status = action.data.status;
            }

            return {
                ...state,
                change_brand_category_status_loading: false,
                change_brand_category_status_errors: {},
                brand_category: newArray
            };
        }
        case CHANGE_BRAND_CATEGORY_STATUS_FAILURE: {
            return {
                ...state,
                change_brand_category_status_loading: false,
                change_brand_category_status_errors: action.errors
            };
        }
        /** end::Change Permission status redux */

        /** begin::Permissions list redux */
        case BRAND_CATEGORY_REQUEST: {
            return {
                ...state,
                brand_category_loading: true,
                brand_category_errors: {}
            };
        }
        case BRAND_CATEGORY_SUCCESS: {
            let newArray = [...state.brand_category]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.brand_category.forEach((payload) => {
                const brandCategoryIndex = newArray.findIndex(brand_category => (brand_category._id === payload._id));
                if (brandCategoryIndex !== -1) {
                    newArray[brandCategoryIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                brand_category_loading: false,
                brand_category: newArray,
                brand_category_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }
        case BRAND_CATEGORY_FAILURE: {
            return {
                ...state,
                brand_category_loading: false,
                brand_category: [],
                brand_category_errors: action.errors
            };
        }
        /** end::Users list redux */

        /** begin::Brand category select list redux */
        case BRAND_CATEGORY_SELECT_LIST_REQUEST: {
            return {
                ...state,
                brand_category_select_list_loading: true,
                brand_category_select_list_errors: {}
            };
        }
        case BRAND_CATEGORY_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                brand_category_select_list_loading: false,
                brand_category_select_list_errors: {},
                brand_category_select_list: action.data

            };

        }
        case BRAND_CATEGORY_SELECT_LIST_FAILURE: {
            return {
                ...state,
                brand_category_select_list_loading: false,
                brand_category_select_list_errors: action.errors,
                brand_category_select_list: []
            };
        }
        /** end::Brand category select list redux */

        /** begin::brand category details redux */
        case BRAND_CATEGORY_DETAILS_REQUEST: {
            return {
                ...state,
                brand_category_details_loading: true,
                brand_category_details_errors: {}
            };
        }
        case BRAND_CATEGORY_DETAILS_SUCCESS: {

            return {
                ...state,
                brand_category_details_loading: false,
                brand_category_details: action.data,
                brand_category_details_errors: {}
            };
        }
        case BRAND_CATEGORY_DETAILS_FAILURE: {
            return {
                ...state,
                brand_category_details: {},
                brand_category_details_loading: false,
                brand_category_details_errors: action.errors
            };
        }
        /** end::brand category details redux */

        default:
            return state;
    }
}
