import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { StoryAction } from '../../../redux/actions/StoryAction'
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";


function StoryExport({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const csvLink = useRef();

    /** Redux actions and state */
    const { export_story_loading: isLoading, export_story } = useSelector((state) => state.StoryReducer);
    const exportStoryCsv = (params) => dispatch(StoryAction.exportStoryCsv(params));

    /** Initialize values from props */
    const { payload } = props;

    /** Initialize and declare state */
    const [isExporting, setIsExporting] = useState(false)
    const [exportDisabled, setExportDisabled] = useState(true)

    const headers = [
        { label: "#", key: "sl" },
        { label: "Promo", key: "promo_details.name" },
        { label: "Promo Start Date", key: "promo_details.promo_start_date" },
        { label: "Promo End Date", key: "promo_details.promo_end_date" },
        { label: "Used Promos Count", key: "promo_infuencer_count" },
        { label: "Brand", key: "brand_details.name" },
        { label: "Influencer", key: "influencer_details.name" },
        { label: "Posted On", key: "created_at" },
        { label: "Verification Completed On", key: "coupon.completed_at" },
        { label: "Status", key: "status" },
        { label: "Verification Percentage", key: "current_checkpoint" },
        { label: "Coupon Code", key: "coupon.coupon_code" },
        { label: "Tags", key: "tags_string" },
    ];

    /**
     * function to export csv file
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    const getStories = () => {
        if (!exportDisabled) {
            setExportDisabled(true)
            setIsExporting(true);
            exportStoryCsv(payload);
        }
    }

    useEffect(() => {
        if (isExporting && !isLoading) {
            csvLink.current.link.click();
            setIsExporting(false);
            setExportDisabled(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [export_story])

    useEffect(() => {
        if (csvLink.current) {
            setExportDisabled(false)
        }
    }, [csvLink])

    return (

        <React.Fragment>
            <MenuItem onClick={(e) => { e.stopPropagation(); getStories() }} disabled={exportDisabled} key={1}>
                <ListItemIcon>
                    <Download fontSize="small" />
                </ListItemIcon>
                Export to CSV
            </MenuItem>
            <MenuItem key={2} style={{ display: `none` }}>
                <CSVLink data={export_story} filename="stories.csv" asyncOnClick={true} headers={headers} ref={csvLink}>Download</CSVLink>
            </MenuItem>
        </React.Fragment>

    )
}

export { StoryExport }