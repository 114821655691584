import React, { useEffect } from 'react'
import Box from '../../../_components/box'
import { StatisticsWidget } from '../../../_components/widgets'
import { useDispatch, useSelector } from "react-redux";
import { DashboardAction } from "../../../../src/redux/actions";

function BrandCard() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    //** Redux actions and state */
    const { brand_count_details } = useSelector((state) => state.DashboardReducer);
    const brandCount = (params) => dispatch(DashboardAction.brandCount(params));

    /** Initialize and declare state */
    const { brand_count, brand_percentage } = brand_count_details;

    useEffect(() => {

        brandCount();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box mb={1.5}>
            <StatisticsWidget
                color="primary"
                icon="weekend"
                title="Brands"
                count={brand_count}
                percentage={{
                    color: `${brand_percentage > 0 ? 'success' : 'error'}`,
                    amount: `${parseFloat(brand_percentage).toFixed(2)}%`,
                    label: "than last month",
                }}
            />
        </Box>
    )
}
export { BrandCard }