import { Box, CardMedia } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';

// Declare story card style
const StyledCardMedia = styled(({ ...props }) => (<CardMedia {...props} />))`
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
`;

function StoryMediaCard({ thumbnailUrl, thumbnailUrlError, ...props }) {
    return (
        <Box style={{ width: `100%` }}>
            <StyledCardMedia component={"img"} src={thumbnailUrl} onError={e => { e.target.src = thumbnailUrlError }}  {...props} />
        </Box>
    )
}

export { StoryMediaCard }