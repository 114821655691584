import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../../_components/box'
import { StatisticsWidget } from '../../../_components/widgets'
import { WalletAction } from "../../../../src/redux/actions";

function TotalMonthWalletCardCard() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { wallet_total } = useSelector((state) => state.WalletReducer);
    const totalWallet = (params) => dispatch(WalletAction.totalWallet(params));

    useEffect(() => {
        totalWallet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // /**
    //  * function to fetch total amount in wallet
    //  * @return json
    //  */
    // const getData = async () => {
    //     await totalWallet();
    // }

    return (
        <Box mb={1.5}>
            <StatisticsWidget
                color="dark"
                icon="₹"
                title="Total Amount This Month"
                count={`${isNaN(wallet_total.totalWalletMonthCount) ? 0 : wallet_total.totalWalletMonthCount} /-`}
                percentage={{
                    color: `${wallet_total.percentage > 0 ? 'success' : 'error'}`,
                    amount: `${isNaN(wallet_total.percentage) ? 0 : Math.trunc(wallet_total.percentage)}%`,
                    label: `than last month`,
                }}
            />
        </Box>
    )
}
export { TotalMonthWalletCardCard }