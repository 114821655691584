import React from 'react'
import PropTypes from 'prop-types'
import { DialogActions, Stack } from '@mui/material'

function ConfzModalFooter({ className, children }) {
    return (
        <DialogActions className={`${className}`}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                {children}
            </Stack>
        </DialogActions>
    )
}


ConfzModalFooter.defaultProps = {
    className: ""
}


ConfzModalFooter.propTypes = {
    className: PropTypes.string,
}

export { ConfzModalFooter }
