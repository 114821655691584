import { Card, CardContent, CardMedia, Stack, Typography, Grid } from '@mui/material';
import React, { useState } from 'react'
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn } from '../../../_components/modal'
import { FormInput, FormSelect } from '../../../_components/form'
import questinMarkSvg from './question-mark.svg'
import { handleInputChange, validateForm } from "../../../_helpers";
import { validate } from "./validate";

// Initialize form input values to null
const inputs = { status: '', remark: '' };

// Declare status select options
const statusSelect = [
    { value: 'closed', label: 'Closed' },
    { value: 'resolved', label: 'Resolved' },
    { value: 'hold', label: 'Hold' }
]

function UpdateStatusModal({ show, showLoader, data, closeModal, updateStatus }) {

    /** Initialize and declare state */
    const [supportCenterStatusData, setSupportCenterStatusData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    /**
     * function to close update modal
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const onClose = () => {
        closeModal(false);
    }

    /**
     * function to handel submit entered values
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        updateStatus({ id: data.id, status: supportCenterStatusData.status, remark: supportCenterStatusData.remark })
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) })
        data[name] = value;
        setSupportCenterStatusData({ ...data });
    }

    return (
        <ConfzModal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ConfzModalBody style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>

                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `10px`, minWidth: `100%` }} spacing={3}>
                                <Typography variant='h3'>Are You Sure?</Typography>

                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `100px`, minWidth: `60%` }} >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={1}
                                                label={`Status`}
                                                name={`status`}
                                                placeholder={`Select status`}
                                                error={action.isSubmitted && errors.status ? errors.status : ''}
                                                onChange={handleChange}
                                                data={statusSelect}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Remark'
                                                name='remark'
                                                value={supportCenterStatusData.remark}
                                                error={(action.isSubmitted && (errors.remark)) ? (errors.remark) : ''}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={25}
                                                minRows={1}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>

                            </Stack>

                        </CardContent>
                    </ConfzModalBody>
                    <ConfzModalFooter style={{ padding: 0 }}>
                        <ConfzModalFooterSaveBtn tabIndex={3} text={'Update'} isProcessing={action.isProcessing} />
                        <ConfzModalFooterCloseBtn tabIndex={4} onClose={onClose} />
                    </ConfzModalFooter>
                </form>
            </Card>
        </ConfzModal>
    )
}

export { UpdateStatusModal }
