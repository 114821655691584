import React from 'react'
import './style.scss';

function TableLoader() {
    return (
        <div className="group">
            <div className="bigSqr">
                <div className="square first"></div>
                <div className="square second"></div>
                <div className="square third"></div>
                <div className="square fourth"></div>
            </div>
            <div className="text">Sit back and relax. </div>
            <div className="text-quote">It's not about having time. It's about making time.</div>
        </div>
    )
}

export { TableLoader };
