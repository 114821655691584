import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PostAuthRoutes } from "../../../routes";
import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";

import Box from "../../../_components/box";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormInput, FormSelect } from "../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { TestimonialAction } from "../../../redux/actions";
import { BrandAction } from "../../../redux/actions";
import { InfluencerAction } from "../../../redux/actions";
import { validate } from "./validate";

// Initialize form input values to null
const inputs = { created_by: '', user_type: '', message: '' };

// Declare user type select options
const userType = [
    { value: 'brand', label: 'Brand' },
    { value: 'influencer', label: 'Influencer' }
]

function CreateTestimonial() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const createTestimonial = (params) => dispatch(TestimonialAction.createTestimonial(params));
    const { create_testimonial_loading: isLoading, create_testimonial_errors: createTestimonialErrors } = useSelector((state) => state.TestimonialReducer);

    const { brands_select_list } = useSelector((state) => state.BrandReducer);
    const brandSelectList = (params) => dispatch(BrandAction.brandSelectList(params));

    const { influencer_select_list } = useSelector((state) => state.InfluencerReducer);
    const influencerSelectList = (params) => dispatch(InfluencerAction.influencerSelectList(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        brandSelectList();
        influencerSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setErrors({ ...createTestimonialErrors });
    }, [createTestimonialErrors])

    /**
     * function to handle input changes and modify the value
     * @author  
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  
     * @created_at 14 June 2022 
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        const formData = {
            "user_type": data.user_type,
            "created_by": data.created_by,
            "message": data.message,
        }

        addTestimonial(formData);
    }

    /**
     * function to create Testimonial
     * @author  
     * @created_at 14 June 2022 
     */
    async function addTestimonial(formData) {
        const isCreated = await createTestimonial(formData)
        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('testimonial').path;
            history.push(redirectPath);
        }

    }

    /**
     * function to cancel create testimonial
     * @author  
     * @created_at 15 June 2022 
     */
    function cancelTestimonial() {
        let redirectPath = PostAuthRoutes('testimonial').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('create_testimonial').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={PostAuthRoutes('create_testimonial').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" display={`flex`} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                            <Card >
                                <CardContent >
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={2}
                                                label={`User Type`}
                                                name={`user_type`}
                                                placeholder={`Select User Type`}
                                                onChange={handleChange}
                                                data={userType}
                                                error={action.isSubmitted && errors.user_type ? errors.user_type : ''}
                                                className="col-md-6"
                                                value={data.user_type}
                                            />
                                        </Grid>
                                        {(data.user_type) ?
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormSelect
                                                    tabIndex={2}
                                                    label={(data.user_type === "brand") ? `Select Brand` : `Select Influencer`}
                                                    name={`created_by`}
                                                    placeholder={(data.user_type === "brand") ? `Select Brand` : `Select Influencer`}
                                                    onChange={handleChange}
                                                    data={(data.user_type === "brand") ? brands_select_list : influencer_select_list}
                                                    error={action.isSubmitted && errors.created_by ? errors.created_by : ''}
                                                    className="col-md-6"
                                                    value={data.created_by}
                                                />
                                            </Grid> : ''
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Message'
                                                name='message'
                                                value={data.message}
                                                error={action.isSubmitted && errors.message ? errors.message : ''}
                                                onChange={handleChange}
                                                autoFocus={true}
                                                multiline
                                                maxLength={250}
                                                minRows={3}
                                            />
                                        </Grid>

                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={'Create'} loading={isLoading} tabIndex={4} />
                                                <CancelButton onClick={cancelTestimonial} tabIndex={7} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreateTestimonial };