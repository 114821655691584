import {
    ADVERTISEMENT_REQUEST, ADVERTISEMENT_SUCCESS, ADVERTISEMENT_FAILURE,
    ADVERTISEMENT_CREATE_REQUEST, ADVERTISEMENT_CREATE_SUCCESS, ADVERTISEMENT_CREATE_FAILURE,
    CHANGE_ADVERTISEMENT_STATUS_REQUEST, CHANGE_ADVERTISEMENT_STATUS_SUCCESS, CHANGE_ADVERTISEMENT_STATUS_FAILURE,
    UPDATE_ADVERTISEMENT_REQUEST, UPDATE_ADVERTISEMENT_SUCCESS, UPDATE_ADVERTISEMENT_FAILURE,
    DELETE_ADVERTISEMENT_REQUEST, DELETE_ADVERTISEMENT_SUCCESS, DELETE_ADVERTISEMENT_FAILURE,
} from "../actions";

const initialState = {
    advertisements_loading: false, advertisements: [], advertisements_errors: {},
    create_advertisements_loading: false, create_advertisements_errors: {},
    change_advertisement_status_loading: false, change_advertisement_status_errors: {}
};

export function AdvertisementReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List admin advertisement redux */
        case ADVERTISEMENT_REQUEST: {
            return {
                ...state,
                advertisements_loading: true,
                advertisements_errors: {}
            };
        }
        case ADVERTISEMENT_SUCCESS: {

            let newArray = [...state.advertisements]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.advertisement.forEach((payload) => {
                const advertisementIndex = newArray.findIndex(advertisement => advertisement._id === payload._id);
                if (advertisementIndex !== -1) {
                    newArray[advertisementIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                advertisements_loading: false,
                advertisements: newArray,
                advertisements_errors: {}
            };

        }
        case ADVERTISEMENT_FAILURE: {
            return {
                ...state,
                advertisements_loading: false,
                advertisements_errors: action.errors,
                advertisements:[]
            };
        }

        /** end::List advertisement coupon codes redux */

        /** begin::Create admin advertisement redux */
        case ADVERTISEMENT_CREATE_REQUEST: {
            return {
                ...state,
                create_advertisements_loading: true,
                create_advertisements_errors: {}
            };
        }
        case ADVERTISEMENT_CREATE_SUCCESS: {

            let advertisementArray = [...state.advertisements]; //making a new array
            const advertisementIndex = advertisementArray.findIndex(advertisement => advertisement._id === action.data._id);

            if (advertisementIndex !== -1) {
                advertisementArray[advertisementIndex].status = action.data.status;
            }

            return {
                ...state,
                create_advertisements_loading: false,
                advertisements: advertisementArray,
                create_advertisements_errors: {}
            };

        }
        case ADVERTISEMENT_CREATE_FAILURE: {
            return {
                ...state,
                create_advertisements_loading: false,
                create_advertisements_errors: action.errors
            };
        }

        /** end::Create advertisement coupon codes redux */

        /** begin::Change advertisement status redux */
        case CHANGE_ADVERTISEMENT_STATUS_REQUEST: {
            return {
                ...state,
                change_advertisement_status_loading: true,
                change_advertisement_status_errors: {}
            };
        }
        case CHANGE_ADVERTISEMENT_STATUS_SUCCESS: {

            let advertisementArray = [...state.advertisements]; //making a new array
            if (action.data.page === 1) {
                advertisementArray = []
            }
            action.data.forEach((payload) => {
                const advertisementIndex = advertisementArray.findIndex(advertisements => advertisements._id === payload._id);
                if (advertisementIndex !== -1) {
                    advertisementArray[advertisementIndex] = payload;
                } else {
                    advertisementArray = advertisementArray.concat(payload);
                }
            });

            return {
                ...state,
                change_advertisement_status_loading: false,
                advertisements: advertisementArray,
                change_advertisement_status_errors: {}
            };
        }
        case CHANGE_ADVERTISEMENT_STATUS_FAILURE: {
            return {
                ...state,
                change_advertisement_status_loading: false,
                change_advertisement_status_errors: action.errors
            };
        }
        /** end::Change advertisement status redux */

        /** begin::Update advertisement redux */
        case UPDATE_ADVERTISEMENT_REQUEST: {
            return {
                ...state,
                update_advertisement_loading: true,
                update_advertisement_errors: {}
            };
        }
        case UPDATE_ADVERTISEMENT_SUCCESS: {
            let advertisementArray = [...state.advertisements];
            const advertisementIndex = advertisementArray.findIndex(advertisements => advertisements._id === action.data._id);
            if (advertisementIndex !== -1) {
                advertisementArray[advertisementIndex] = action.data;
            }

            return {
                ...state,
                advertisements: advertisementArray,
                update_advertisement_loading: false,
                update_advertisement: action.data,
                update_advertisement_errors: {}
            };
        }
        case UPDATE_ADVERTISEMENT_FAILURE: {
            return {
                ...state,
                update_advertisement_loading: false,
                update_advertisement_errors: action.errors
            };
        }
        /** end::Update advertisement redux */

        /** begin::Delete advertisement redux */
        case DELETE_ADVERTISEMENT_REQUEST: {
            return {
                ...state,
                delete_advertisement_loading: true,
                delete_advertisement_errors: {}
            };
        }
        case DELETE_ADVERTISEMENT_SUCCESS: {
            let advertisementArray = [...state.advertisements];

            const AdvertisementFilter = advertisementArray.filter(advertisements => advertisements._id !== action.data._id)

            return {
                ...state,
                advertisements: AdvertisementFilter,
                delete_advertisement_loading: false,
                delete_advertisement: action.data,
                delete_advertisement_errors: {},
            };
        }
        case DELETE_ADVERTISEMENT_FAILURE: {
            return {
                ...state,
                delete_advertisement_loading: false,
                delete_advertisement_errors: action.errors
            };
        }
        /** end::Delete advertisement redux */

        default:
            return state;

    }
}