import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, Stack } from '@mui/material'
import { FilterAlt } from '@mui/icons-material'
import queryString from 'query-string';
import { GiveawayAction } from '../../../redux/actions';
import { CardHeader } from '../../../_components/card';
import { FormSearchInput } from '../../../_components/form';
import { GeneralButton } from '../../../_components/controls';
import { CouponCodeCard, CouponCodeCardLoader } from '../../coupon-codes';
import { GiveawayCouponCodesFilter } from '.'

function GiveawayCouponCodes() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const giveawayId = searchParams.get('gId'); // giveaway id from url

    /** Initialize and declare state */
    const [showCouponFilterModal, setShowCouponFilterModal] = useState(false);
    const [payload, setPayload] = useState({ giveaway_id: giveawayId, limit: 100, page: 1, filter: { status: searchParams.get('couponStatus') ?? '' }, search: searchParams.get('couponsQuery') ?? '' });


    /** Redux actions and state */
    const { brand_giveaway_coupon_codes_loading: isLoading, brand_giveaway_coupon_codes: coupon_codes } = useSelector((state) => state.GiveawayReducer);
    const listBrandGiveawayCouponCodes = (params) => dispatch(GiveawayAction.listBrandGiveawayCouponCodes(params));

    useEffect(() => {

        const queryParam = {};
        for (var key of searchParams.keys()) {
            const element = searchParams.get(key);

            if (element === "" || element === null) {
                delete queryParam[key];
            }
            else {
                queryParam[key] = element;
            }
        }

        queryParam.gId = giveawayId;
        if (payload.search) queryParam.couponsQuery = payload.search; else delete queryParam.couponsQuery;
        if (payload.filter.status) queryParam.couponStatus = payload.filter.status; else delete queryParam.couponStatus;

        history.push({ search: `?${queryString.stringify(queryParam)}` })

        getNext();
        //eslint-disable-next-line
    }, [giveawayId, payload.filter, payload.search])

    /**
     * function to fetch giveaway coupon codes list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @return json
     * @author 
     * @created_at 30 May 2022
     */
    const getNext = async () => {
        const result = await listBrandGiveawayCouponCodes(payload);
        if (result.status)
            setPayload({ ...payload, page: payload.page + 1 });
    }

    /**
     * function to open/close filter modal
     * @param {string} null
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const couponFilterModal = () => {
        setShowCouponFilterModal(!showCouponFilterModal);
    }

    /**
     * function to apply filter data to payload
     * @param {string} null
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <CardHeader
                action={(
                    <Stack spacing={1} direction="row">
                        <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        <GeneralButton label={<FilterAlt />} onClick={() => couponFilterModal()} />
                    </Stack>
                )}
            />
            <GiveawayCouponCodesFilter show={showCouponFilterModal} closeModal={couponFilterModal} applyFilter={applyFilter} couponFilter={payload.filter} />
            <InfiniteScroll dataLength={coupon_codes.length} next={getNext} hasMore={true}>
                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                    {
                        coupon_codes.length > 0 && coupon_codes.map((coupon_code, i) => {
                            return <Grid key={i} item xs={12} sm={12} md={3} lg={3} xl={3}><CouponCodeCard category={'giveaway'} canRedeem={true} promo={{_id: giveawayId}} {...coupon_code} height='170px' isClickable={true} hasAction={true} /></Grid>;
                        })
                    }
                    {(!isLoading && coupon_codes.length === 0) && 
                        <CouponCodeCardLoader />
                    }
                </Grid>
            </InfiniteScroll>
        </React.Fragment>
    )
}

export { GiveawayCouponCodes }
