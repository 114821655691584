import { Box, Typography, Grid, CardMedia } from '@mui/material'
import React, { useRef } from 'react'
import { Card, CardContent } from '../../../../_components/card'
import QRCode from "react-qr-code";

function PromoPosterCard({ promo_poster }) {

    const componentRef = useRef();

    /** Initialize values from promo props */
    const { title, description, poster_image, poster_image_error, logo, logo_error, promo_url } = promo_poster


    return (
        <Card style={{ height: '100%' }}>
            <Grid container spacing={1} display="flex" alignItems="stretch" style={{ height: '100%' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <CardContent>
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} mb={1} mt={2}>
                            <Typography variant="h6">Mention Us In Your Story</Typography>
                            <Typography variant="h4">GET EXCITING</Typography>
                            <Typography variant="h4">OFFERS</Typography>
                        </Box>
                    </CardContent>
                </Grid>

                <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
                    <CardMedia component={"img"} src={poster_image} style={{ position: "relative", padding: 0, margin: 0, width: "100%", height: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onError={e => { e.target.src = poster_image_error }} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} lg={7} xl={7} pr={2}>
                    <CardContent>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
                            <Typography variant="h5" dangerouslySetInnerHTML={{ __html: title }} />
                        </Box>
                        <Box>
                            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: description }} />
                        </Box>
                    </CardContent>
                </Grid>

                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} container justifyContent="flex-end">
                        <CardContent>
                            <div ref={componentRef}>
                                <QRCode value={`${promo_url}`} size={130} />
                            </div>
                        </CardContent>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <CardContent>
                            <CardMedia component={"img"} src={logo} style={{ position: "relative", padding: 0, margin: 0, width: "40%", height: '40%' }} onError={e => { e.target.src = logo_error }} />
                        </CardContent>
                    </Grid>
                </Grid>

            </Grid>
        </Card>
    )
}

export { PromoPosterCard }