/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from "../../base/colors";
import borders from "../../base/borders";

// Material Dashboard 2 PRO React helper functions
import rgba from "../../../functions/rgba";

const { secondary } = colors;
const { borderRadius } = borders;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      position: "relative",
      // backgroundColor: grey[100],
      borderRadius: borderRadius.sx,
      minHeight: "unset",
      // padding: pxToRem(4),

      "& .Mui-selected": {
        color: `${secondary.main} !important`,
      },
      "& .Mui-disabled": {
        color: `${rgba('#fff', 0.25)} !important`,
        cursor: `not-allowed !important`
      },
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      // height: "100%",
      // borderRadius: borderRadius.xs,
      backgroundColor: secondary.main,
      // boxShadow: tabsBoxShadow.indicator,
      transition: "all 500ms ease",


    },
  },
};
