import { GlobalAction } from ".";
import { AdvertisementServices } from "../services/AdvertisementServices";

export const ADVERTISEMENT_REQUEST = 'ADVERTISEMENT_REQUEST';
export const ADVERTISEMENT_SUCCESS = 'ADVERTISEMENT_SUCCESS';
export const ADVERTISEMENT_FAILURE = 'ADVERTISEMENT_FAILURE';

export const ADVERTISEMENT_CREATE_REQUEST = 'ADVERTISEMENT_CREATE_REQUEST';
export const ADVERTISEMENT_CREATE_SUCCESS = 'ADVERTISEMENT_CREATE_SUCCESS';
export const ADVERTISEMENT_CREATE_FAILURE = 'ADVERTISEMENT_CREATE_FAILURE';

export const CHANGE_ADVERTISEMENT_STATUS_REQUEST = 'CHANGE_ADVERTISEMENT_STATUS_REQUEST';
export const CHANGE_ADVERTISEMENT_STATUS_SUCCESS = 'CHANGE_ADVERTISEMENT_STATUS_SUCCESS';
export const CHANGE_ADVERTISEMENT_STATUS_FAILURE = 'CHANGE_ADVERTISEMENT_STATUS_FAILURE';

export const UPDATE_ADVERTISEMENT_REQUEST = 'UPDATE_ADVERTISEMENT_REQUEST';
export const UPDATE_ADVERTISEMENT_SUCCESS = 'UPDATE_ADVERTISEMENT_SUCCESS';
export const UPDATE_ADVERTISEMENT_FAILURE = 'UPDATE_ADVERTISEMENT_FAILURE';

export const DELETE_ADVERTISEMENT_REQUEST = 'DELETE_ADVERTISEMENT_REQUEST';
export const DELETE_ADVERTISEMENT_SUCCESS = 'DELETE_ADVERTISEMENT_SUCCESS';
export const DELETE_ADVERTISEMENT_FAILURE = 'DELETE_ADVERTISEMENT_FAILURE';

export const AdvertisementAction = {

    /**
     * Action for list Advertisement
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
     listAdvertisement: (params) => {
        
        return dispatch => {
            dispatch(request(params));
            return AdvertisementServices.listAdvertisement(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: ADVERTISEMENT_REQUEST, request: request } }
        function success(request, response) { return { type: ADVERTISEMENT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADVERTISEMENT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating Advertisement
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    createAdvertisements: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AdvertisementServices.createAdvertisement(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: CHANGE_ADVERTISEMENT_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: ADVERTISEMENT_CREATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADVERTISEMENT_CREATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for change influencer status
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
     changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AdvertisementServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_ADVERTISEMENT_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_ADVERTISEMENT_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_ADVERTISEMENT_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update advertisement
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
    */
     updateAdvertisement: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AdvertisementServices.updateAdvertisement(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_ADVERTISEMENT_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_ADVERTISEMENT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_ADVERTISEMENT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for deleting an advertisement
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
    */
     deleteAdvertisement: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AdvertisementServices.deleteAdvertisement(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_ADVERTISEMENT_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_ADVERTISEMENT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_ADVERTISEMENT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }
}