import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { InfluencerAction } from '../../../redux/actions/InfluencerAction'
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

function InfluencerExport({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const csvLink = useRef();

    /** Redux actions and state */
    const { export_influencer_loading: isLoading, export_influencer } = useSelector((state) => state.InfluencerReducer);
    const exportInfluencerCsv = (params) => dispatch(InfluencerAction.exportInfluencerCsv(params));

    /** Initialize values from props */
    const { payload } = props;

    /** Initialize and declare state */
    const [isExporting, setIsExporting] = useState(false)
    const [exportDisabled, setExportDisabled] = useState(true)

    const headers = [
        { label: "#", key: "inI" },
        { label: "Name", key: "insta_profile.name" },
        { label: "Email", key: "email" },
        { label: "Insta handle", key: "insta_handle" },
        { label: "Followers count", key: "insta_profile.followers_count" },
        { label: "Mobile number", key: "mobile_number" },
        { label: "Posted On", key: "created_at" },
        { label: "Status", key: "status" },
    ];

    /**
     * function to export csv file
     * @param {string} null
     * @author Naveen
     * @created_at 17 June 2022 
     */
    const getInfluencer = () => {
        if (!exportDisabled) {
            setExportDisabled(true)
            setIsExporting(true);
            exportInfluencerCsv(payload);
        }
    }

    useEffect(() => {
        if (isExporting && !isLoading) {
            csvLink.current.link.click();
            setIsExporting(false);
            setExportDisabled(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [export_influencer])

    useEffect(() => {
        if (csvLink.current) {
            setExportDisabled(false)
        }
    }, [csvLink])

    return (

        <React.Fragment>
            <MenuItem onClick={(e) => { e.stopPropagation(); getInfluencer() }} disabled={exportDisabled} key={1}>
                <ListItemIcon>
                    <Download fontSize="small" />
                </ListItemIcon>
                Export to CSV
            </MenuItem>
            <MenuItem key={2} style={{ display: `none` }}>
                <CSVLink data={export_influencer} filename="influencer.csv" asyncOnClick={true} headers={headers} ref={csvLink}>Download</CSVLink>
            </MenuItem>
        </React.Fragment>

    )

}

export { InfluencerExport }