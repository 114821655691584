
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    formControl: {
        borderRadius: `50%`,
    },
    label: {
        padding: `10px`,
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: `0.5px`
    },
    input: {
        fontSize: 14,
        width: 'auto',
        borderRadius: `.25rem`,
    },
    info: {
        marginLeft: `0!important`,
        fontSize: 10,
        marginTop: 0,
        padding: 0,
        color: theme.palette.text.secondary,
    }
}))

export { useStyles };