import {
    TESTIMONIAL_REQUEST, TESTIMONIAL_SUCCESS, TESTIMONIAL_FAILURE,
    TESTIMONIAL_CREATE_REQUEST, TESTIMONIAL_CREATE_SUCCESS, TESTIMONIAL_CREATE_FAILURE,
} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    testimonial_loading: false, testimonial: [], testimonial_errors: {},
    create_testimonial_loading: false, create_testimonial_errors: {},
};

export function TestimonialReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Testimonial list redux */
        case TESTIMONIAL_REQUEST: {
            return {
                ...state,
                testimonial_loading: true,
                testimonial_errors: {}
            };
        }
        case TESTIMONIAL_SUCCESS: {

            let newArray = [...state.testimonial]; //making a new array

            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.testimonial.forEach((payload) => {
                const testimonialIndex = newArray.findIndex(testimonial => testimonial._id === payload._id);
                if (testimonialIndex !== -1) {
                    newArray[testimonialIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                testimonial_loading: false,
                testimonial: newArray,
                testimonial_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case TESTIMONIAL_FAILURE: {
            return {
                ...state,
                testimonial_loading: false,
                testimonial_errors: action.errors,
                testimonial: []
            };
        }
        /** end::testimonial list redux */

        /** begin::Create admin testimonial redux */
        case TESTIMONIAL_CREATE_REQUEST: {
            return {
                ...state,
                create_testimonial_loading: true,
                create_testimonial_errors: {}
            };
        }
        case TESTIMONIAL_CREATE_SUCCESS: {

            let testimonialArray = [...state.testimonial]; //making a new array
            const testimonialIndex = testimonialArray.findIndex(testimonials => testimonials._id === action.data._id);

            if (testimonialIndex !== -1) {
                testimonialArray[testimonialIndex].status = action.data.status;
            }

            return {
                ...state,
                create_testimonial_loading: false,
                testimonial: testimonialArray,
                create_testimonial_errors: {}
            };

        }
        case TESTIMONIAL_CREATE_FAILURE: {
            return {
                ...state,
                create_testimonial_loading: false,
                create_testimonial_errors: action.errors
            };
        }

        /** end::Create testimonial redux */

        default:
            return state;
    }
}