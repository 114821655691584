import { GlobalAction } from ".";
import { InfluencerServices } from "../services/InfluencerServices";

export const INFLUENCER_REQUEST = 'INFLUENCER_REQUEST';
export const INFLUENCER_SUCCESS = 'INFLUENCER_SUCCESS';
export const INFLUENCER_FAILURE = 'INFLUENCER_FAILURE';

export const CHANGE_INFLUENCER_STATUS_REQUEST = 'CHANGE_INFLUENCER_STATUS_REQUEST';
export const CHANGE_INFLUENCER_STATUS_SUCCESS = 'CHANGE_INFLUENCER_STATUS_SUCCESS';
export const CHANGE_INFLUENCER_STATUS_FAILURE = 'CHANGE_INFLUENCER_STATUS_FAILURE';

export const INFLUENCER_DETAILS_REQUEST = 'INFLUENCER_DETAILS_REQUEST';
export const INFLUENCER_DETAILS_SUCCESS = 'INFLUENCER_DETAILS_SUCCESS';
export const INFLUENCER_DETAILS_FAILURE = 'INFLUENCER_DETAILS_FAILURE';

export const INFLUENCER_SELECT_LIST_REQUEST = 'INFLUENCER_SELECT_LIST_REQUEST';
export const INFLUENCER_SELECT_LIST_SUCCESS = 'INFLUENCER_SELECT_LIST_SUCCESS';
export const INFLUENCER_SELECT_LIST_FAILURE = 'INFLUENCER_SELECT_LIST_FAILURE';

export const EXPORT_INFLUENCER_REQUEST = 'EXPORT_INFLUENCER_REQUEST';
export const EXPORT_INFLUENCER_SUCCESS = 'EXPORT_INFLUENCER_SUCCESS';
export const EXPORT_INFLUENCER_FAILURE = 'EXPORT_INFLUENCER_FAILURE';

export const DELETE_INFLUENCER_PROFILE_REQUEST = 'DELETE_INFLUENCER_PROFILE_REQUEST';
export const DELETE_INFLUENCER_PROFILE_SUCCESS = 'DELETE_INFLUENCER_PROFILE_SUCCESS';
export const DELETE_INFLUENCER_PROFILE_FAILURE = 'DELETE_INFLUENCER_PROFILE_FAILURE';

export const InfluencerAction = {
    /**
     * Action for list influencer
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    listInfluencer: (params) => {

        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.listInfluencer(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: INFLUENCER_REQUEST, request: request } }
        function success(request, response) { return { type: INFLUENCER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INFLUENCER_FAILURE, request: request, message: error.message, data: error.data, errors: error.errors, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change influencer status
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_INFLUENCER_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_INFLUENCER_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_INFLUENCER_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get Influencer details
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
    */
    influencerDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.influencerDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: INFLUENCER_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: INFLUENCER_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INFLUENCER_DETAILS_FAILURE, request: request, message: error.message, data: error.data, errors: error.errors, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for influencer select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 15 June 2022
    */
    influencerSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.influencerSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: INFLUENCER_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: INFLUENCER_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INFLUENCER_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to export influencer csv
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
    */
    exportInfluencerCsv: (params) => {

        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.exportInfluencerCsv(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: EXPORT_INFLUENCER_REQUEST } }
        function success(response) { return { type: EXPORT_INFLUENCER_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: EXPORT_INFLUENCER_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to delete influencer
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 08 Mar 2024
    */
    deleteInfluencerProfile: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.deleteInfluencerProfile(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_INFLUENCER_PROFILE_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_INFLUENCER_PROFILE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_INFLUENCER_PROFILE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}