import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, FormHelperText } from '@mui/material';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import { styled } from '@mui/styles';
import { FormMultipleDatePickerStyle as useStyles } from '.';
import moment  from 'moment';
import { startOfDay } from 'date-fns';

/**
 * Declare default value of input properties 
 */
const defaultProps = {
    className: "",
    isReadOnly: false,
    maxLength: 255,
    maskType: ''
};


const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "selected"
})(({ theme, selected }) => ({
    ...(selected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark
        },
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%"
    })
}));
  
function FormMultipleDatePicker({ label, name, placeholder, className, onChange, tabIndex, error, maskType, minDate = null, maxDate=null, value, info, disablePast=false, disableDates, disableUnchosenDates=false, ...props }) {

    const [val, setVal] = React.useState((value && value.length > 0) ? value : []);
    const classes = useStyles();

    const findIndexDate = (dates, date) => {
        const dateTime = new Date(date).getTime();
        return dates.findIndex((item) => new Date(item).getTime() === dateTime);
    };

    const findDate = (dates, date) => {
        const dateTime = date.getTime();
        return dates.find((item) => new Date(item).getTime() === dateTime);
    };

    const handleChange = (e) => {

        if (e) {
            const array = [...val];

            const date = startOfDay(new Date(e));
            const isoDate = date.toISOString();
    
            const index = findIndexDate(array, isoDate);
            if (index >= 0) {
                array.splice(index, 1);
            } else {
                array.push(isoDate);
            }
            
            array.sort();
            onChange({ target: { type: `date_time`, name: name, value: array } });
            setVal(array);
        }
    }
    
    function isDisabled(date) {
        if (disableUnchosenDates) {
            const dateTime = new Date(date).getTime();
            return !(val.find((item) => new Date(item).getTime() === dateTime));

        } else if (disableDates && disableDates.length > 0) {

            return disableDates.some(disabledDate =>
                new Date(date).getTime() === new Date(disabledDate).getTime()
            );
        }
        return false;

    }
        
    const renderPickerDay = (date, selectedDates, pickersDayProps) => {
        if (!val) {
            return <PickersDay {...pickersDayProps} />;
        }
    
        const selected = findDate(val, date);
    
        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                selected={selected !== undefined}
            />
        );
    };

    const displayDate = () => {
        if (!val || val.length === 0) {
            return '';
        }
      
        const startDate = new Date(val[0]);
        const endDate = new Date(val[val.length - 1]);
                
        return `${moment(startDate).format('DD/MM/yyyy')} - ${moment(endDate).format('DD/MM/yyyy')}`;
    };

    return (

        <FormControl fullWidth className={`${className}`} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    onChange={handleChange}
                    label={label}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputFormat="dd/MM/yyyy - dd/MM/yyyy"
                    renderInput={({
                        ref,
                        inputProps,
                        ...params
                    }) => (
                        <TextField inputRef={ref} {...params} {...inputProps} value={displayDate()}/>)
                    }
                    disablePast ={disablePast}
                    shouldDisableDate = {isDisabled}
                    renderDay={renderPickerDay}
                    closeOnSelect={false}
                />
            </LocalizationProvider>
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>

    )
}


FormMultipleDatePicker.defaultProps = defaultProps;

export { FormMultipleDatePicker }