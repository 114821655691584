module.exports = {
    /**
     * function to validate the registration form inputs
     * @author Naveen
     * @created_at 10 Jan 2023
     */

    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {

            case 'question': {
                errors.question = '';
                if (value === '')
                    errors.question = 'Enter Question';
                break;
            } case 'answer': {
                errors.answer = '';
                if (value === '')
                    errors.answer = 'Enter Answer';
                break;
            } default: {
                errors[name] = '';
                break;
            }

        }
        return errors;
    }
}