import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Chip, Grid, Stack } from '@mui/material';
import { BrandAction } from '../../../../../redux/actions';
import { FormSelect } from '../../../../../_components/form';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../../../_components/modal';
import { handleInputChange } from '../../../../../_helpers';

// Initialize form input values to null
const inputs = { category: '' };

function BrandMenuFilter({ ...props }) {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
    const brandId = searchParams.get('brandId'); // brand id from url

	/** Redux actions and state */
	const { brand_menu_select_list } = useSelector((state) => state.BrandReducer);
	const brandCategorySelectList = (params) => dispatch(BrandAction.brandCategorySelectList(params));

	/** Initialize and declare state */
	const { show, closeModal, applyFilter, brandMenuFilter } = props;
	const [data, setData] = useState({ ...inputs });
	const [filteredData, setFilteredData] = useState({});

	useEffect(() => {
		brandCategorySelectList({brand_id: brandId});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setData({ category: brandMenuFilter.category });

		let filtered = {};
		Object.keys(brandMenuFilter).forEach(function (item, i) {
			if (brandMenuFilter[item] && !brandMenuFilter['requested_by']) filtered[item] = brandMenuFilter[item];
		});
		setFilteredData({ ...filtered });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brandMenuFilter]);

	/**
	 * function to close brand menu filter modal
	 * @param {string} category Entered category on brand menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const onClose = () => {
		setData({ category: '' });
		closeModal(false);
	};

	/**
	 * function to clear brand menu filter modal
	 * @param {string} category Entered category on brand menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const onClear = () => {
		setData({ category: '' });
		applyFilter({ category: '' });
		closeModal(false);
	};

	/**
	 * function to handle input changes and modify the value
	 * @param {string} e.name input name
	 * @param {string} e.value input value
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function handleChange(e) {
		const { name, value } = handleInputChange(e);
		data[name] = value;
		setData({ ...data });
	}

	/**
	 * function to handle submit entered values
	 * @param {object} e form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function handleSubmit(e) {
		e.preventDefault();
		applyFilter(data);
		closeModal(false);
	}

	/**
	 * function to clear all data entered on brand menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const clearFilterItem = (item) => {
		const clearItem = { ...data };
		clearItem[item] = '';
		setData({ ...clearItem });
		applyFilter({ ...clearItem });
	};

	return (
		<React.Fragment>
			{Object.keys(filteredData).length > 0 && (
				<Grid container mb={2}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Stack direction="row" spacing={1}>
							{Object.keys(filteredData).map((item, i) => {
								if (item === 'category') {
									const category = brand_menu_select_list.find(function (category) {
										return category.value === filteredData[item];
									});
									return <Chip key={i} label={category.label} onDelete={() => clearFilterItem(item)} />;
								} else {
									return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />;
								}
							})}
						</Stack>
					</Grid>
				</Grid>
			)}
			<ConfzModal isDialogOpen={show} onClose={onClose} maxWidth="xs">
				<form onSubmit={handleSubmit} noValidate>
					<ConfzModalHeader id="brandMenuFilter" onClose={onClose} title="Filter" />
					<ConfzModalBody>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormSelect tabIndex={1} label={`Category`} name={`category`} placeholder={`Select category`} onChange={handleChange} data={brand_menu_select_list} value={data.category} />
							</Grid>
						</Grid>
					</ConfzModalBody>
					<ConfzModalFooter>
						<ConfzModalFooterSaveBtn tabIndex={4} text="Filter" />
						<ConfzModalFooterCloseBtn tabIndex={3} onClose={onClear} text="Clear" />
					</ConfzModalFooter>
				</form>
			</ConfzModal>
		</React.Fragment>
	);
}

export { BrandMenuFilter };
