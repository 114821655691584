import {
    CREATE_WALLET_REQUEST, CREATE_WALLET_SUCCESS, CREATE_WALLET_FAILURE,
    WALLET_HISTORY_REQUEST, WALLET_HISTORY_SUCCESS, WALLET_HISTORY_FAILURE,
    WALLET_TOTAL_REQUEST, WALLET_TOTAL_SUCCESS, WALLET_TOTAL_FAILURE,
} from "../actions";

const initialState = {
    page: 1, per_page: 10, total: 0, total_pages: 0,
    create_brand_wallet_loading: false, create_brand_wallet_errors: {},
    wallet_history_loading: false, wallet_history: [], wallet_history_errors: {},
    wallet_total_loading: false, wallet_total: [], wallet_total_errors: {},
    brand_wallet_total_loading: false, brand_wallet_total: 0, brand_wallet_total_errors: {},
};

export function WalletReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Create wallet redux */
        case CREATE_WALLET_REQUEST: {
            return {
                ...state,
                create_brand_wallet_loading: true,
                create_brand_wallet_errors: {}
            };
        }
        case CREATE_WALLET_SUCCESS: {
            return {
                ...state,
                create_brand_wallet_loading: false,
                brand_wallet_total: action.data.current_balance,
                create_brand_wallet_errors: {}
            };

        }
        case CREATE_WALLET_FAILURE: {
            return {
                ...state,
                create_brand_wallet_loading: false,
                create_brand_wallet_errors: action.errors,
            };
        }
        /** end::Create wallet redux */
        /** begin::Wallet history list redux */
        case WALLET_HISTORY_REQUEST: {
            return {
                ...state,
                wallet_history_loading: true,
                wallet_history_errors: {}
            };
        }
        case WALLET_HISTORY_SUCCESS: {

            return {
                ...state,
                wallet_history_loading: false,
                wallet_history: action.data.wallets,
                wallet_history_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case WALLET_HISTORY_FAILURE: {
            return {
                ...state,
                wallet_history_loading: false,
                wallet_history_errors: action.errors,
                wallet_history: [],
            };
        }
        /** end::Wallet history list redux */

        /** begin::Wallet amount total redux */
        case WALLET_TOTAL_REQUEST: {
            return {
                ...state,
                wallet_total_loading: true,
                wallet_total_errors: {}
            };
        }
        case WALLET_TOTAL_SUCCESS: {

            return {
                ...state,
                wallet_total_loading: false,
                wallet_total: action.data,
                wallet_total_errors: {},
            };

        }
        case WALLET_TOTAL_FAILURE: {
            return {
                ...state,
                wallet_total_loading: false,
                wallet_total_errors: action.errors,
                wallet_total: [],
            };
        }
        /** end::Wallet amount total redux */

        case "BRAND_WALLET_TOTAL": {

			return {
                ...state,
				brand_wallet_total: action.data,
			};
		}

        default:
            return state;
    }
}
