import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import { DataTable } from '../../_components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { LogAction } from '../../redux/actions';
import { Button, ButtonGroup, Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PostAuthRoutes } from '../../routes';
import { Visibility } from '@mui/icons-material';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function LogsDataTable({ limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { per_page, total, log_loading: isLoading, log, delete_all_logs } = useSelector((state) => state.LogReducer);
    const listLogs = (params) => dispatch(LogAction.listLogs(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ page, limit });

    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '6%', cell: (row, i) => ((per_page * (payload.page - 1)) + (i + 1)) },
        { name: 'Method', selector: (row, i) => (row.method), sortField: 'method', sortable: true, center: true, width: '8%' },
        { name: 'URL', selector: (row, i) => (row.url), sortField: 'url', sortable: true, wrap: true, width: '50%' },
        { name: 'Response Time', selector: (row, i) => (`${row.responseTime} ms`), sortField: 'responseTime', sortable: true, center: true, width: '12%' },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', sortable: true, center: true, width: '12%' },
        { name: 'Status Code', selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '12%', cell: row => (<StatusRow row={row} />) },
    ];

    useEffect(() => {
        setPayload({ page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit])

    useEffect(() => {

        const queryParam = {};
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        history.push({
            pathname: PostAuthRoutes('log').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.page, delete_all_logs])

    /**
     * functions on status rows
     * @param {Object} row
     * @return json
     * @author Naveen
     * @created_at 06 Feb 2024
     */
    const StatusRow = ({ row }) => {

        const statusColor = row.status >= 400 && row.status < 500 ? 'error' : row.status >= 500 && row.status < 600 ? 'warning' : row.status >= 200 && row.status < 300 ? 'success' : 'default';
        /**
         * function to display log details of the row
         * @param {string} id - Id of the data which we need to display
         * @return json
         * @author Naveen
         * @created_at 06 Feb 2024
         */
        const logDetails = (row) => {
            history.push({
                pathname: PostAuthRoutes('log_details').path,
                search: `?lId=${row._id}`
            });
        }

        let status = <Chip label={row.status} className="badge-status" color={statusColor}/>;

        let statusHover = (
            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='admin_log_details' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => logDetails(row)}> <Visibility /></Button>
                </AbilityCan>
            </ButtonGroup>
        )

        return (
            <React.Fragment>
                {status}{statusHover}
            </React.Fragment>
        )

    }

    /**
     * Function to fetch log list
     * @return json
     * @author Naveen
     * @created_at 06 Feb 2024
     */
    const getData = async () => {
        await listLogs(payload);
    }

    /**
     * Function to sort columns
     * @return view
     * @author
     * @created_at 06 Feb 2024
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * Function to handle page
     * @param {String} page - Page number to load
     * @return view
     * @author Naveen
     * @created_at 06 Feb 2024
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={log}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
        />
    )
}

export { LogsDataTable };