import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import Box from '../../../_components/box';

import { PostAuthMenuRoutes } from '../../../routes';
import { SideBarStyle as useStyles } from './'
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';


function SideBar() {

    const classes = useStyles();
    const location = useLocation();

    /** Redux actions and state */
    const { isDrawerOpen } = useSelector((state) => state.GlobalReducer);
    const { user } = useSelector((state) => state.UserReducer);

    const menuRoutes = Object.values(PostAuthMenuRoutes());


    return (
        <Drawer variant="permanent" open={isDrawerOpen} className={classes.drawer} >
            <Box className={`${classes.listMenuWrapper} ${isDrawerOpen ? `drawer-open` : ``}`} >
                <List  >
                    {menuRoutes.map((route, index) => {

                        var splitPathName = location.pathname.split('/');
                        var parentPathName = splitPathName.slice(0, 2).join('/');

                        return (
                            <React.Fragment key={index}>
                                <AbilityCan key={index} I={route.menuPermission} passThrough={((!route.menuPermission) || (user.role_slug === 'developer')) ? true : false}>
                                    {route.hasSeparation && <Divider sx={{ margin: `5px 0` }} />}
                                    <ListItem button component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ((parentPathName === route.path) ? 'active' : '')}>
                                        <ListItemIcon>
                                            <route.menuIcon className={classes.menuIcon} />
                                        </ListItemIcon>
                                        <ListItemText className={`menu-name ${isDrawerOpen ? `show-menu-name` : `hide-menu-name`}`} >{route.name}</ListItemText>
                                    </ListItem>
                                </AbilityCan>
                            </React.Fragment>
                        )
                    })}
                </List>
            </Box>
        </Drawer>
    )
}

export { SideBar };

