import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";

import Box from "../../../_components/box";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormInput, FormSelect } from "../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { BrandAction, BrandCategoryAction } from "../../../redux/actions";
import { PostAuthRoutes } from "../../../routes";

// Initialize form input values to null
const inputs = { brand_id: '', name: '', insta_handle: '', email: '', mobile_number: '', brand_category: ''};

function BrandUpdate() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Brand id and details on update page */
    const location = useLocation();
    const { brand, redirectPathUrl } = location.state ?? {};

    /** Redux actions and state */
    const { update_brands_loading: isLoading, update_brands_errors: createBrandErrors } = useSelector((state) => state.BrandReducer);
    const { brand_category_select_list } = useSelector((state) => state.BrandCategoryReducer);
    const updateBrand = (params) => dispatch(BrandAction.updateBrand(params));
    const brandCategorySelectList = (params) => dispatch(BrandCategoryAction.brandCategorySelectList(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetBrandLoading, setBrandLoading] = useState(true);
    const [isSetBrandCategoryLoading, setBrandCategoryLoading] = useState(true);

    useEffect(() => {
        brandCategorySelectList().then((result) => {
            setBrandCategoryLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setErrors({ ...createBrandErrors });
    }, [createBrandErrors])

    useEffect(() => {
        if (brand && brand._id) {
            setData({
                brand_id: brand._id,
                name: brand.name,
                insta_handle: brand.insta_handle,
                email: brand.email,
                mobile_number: brand.mobile_number,
                brand_category: brand.brand_category ? brand.brand_category : ''
            });
            setBrandLoading(false);
        } else {
            setBrandLoading(false);
        }
    }, [brand])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle update brand form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdateBrandAction(data);
    }

    /**
     * function to dispatch update brand api
     * @author Jasin
     * @created_at 14 June 2022 
     */
    async function dispatchUpdateBrandAction(formData) {
        const isCreated = await updateBrand(formData);
        if (isCreated && isCreated.status === 1) {
            if (redirectPathUrl) {
                history.push(redirectPathUrl);
            } else {
                let redirectPath = `${PostAuthRoutes('brands').path}`;
                history.push(redirectPath);
            }
        }
    }

    /**
     * function cancel update and direct back to brand page
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 14 June 2022  
     */
    function cancelBrand() {
        let redirectPath = `${PostAuthRoutes('brands').path}`;
        history.push(redirectPath);
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('update_brands').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={PostAuthRoutes('update_brands').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" display={`flex`} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card >
                                {(!isSetBrandLoading && !isSetBrandCategoryLoading) && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Name'
                                                name='name'
                                                value={data.name}
                                                error={action.isSubmitted && errors.name ? errors.name : ''}
                                                onChange={handleChange}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={2}
                                                label='Instagram Handle'
                                                name='insta_handle'
                                                value={data.insta_handle}
                                                error={action.isSubmitted && errors.insta_handle ? errors.insta_handle : ''}
                                                onChange={handleChange}
                                                maskType={`insta_handle`}
                                                inputProps={{ "autoCapitalize": "none" }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={3}
                                                label='Email'
                                                name='email'
                                                value={data.email}
                                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Mobile Number'
                                                name='mobile_number'
                                                value={data.mobile_number}
                                                error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                                onChange={handleChange}
                                                maskType={`digit-only`}
                                                inputProps={{ maxLength: 12 }}
                                                autoComplete={`mobile_number`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={5}
                                                label={`Brand Category`}
                                                name={`brand_category`}
                                                placeholder={`Select brand category`}
                                                onChange={handleChange}
                                                data={brand_category_select_list}
                                                className="col-md-6"
                                                value={data.brand_category}
                                                error={action.isSubmitted && errors.brand_category ? errors.brand_category : ''}                                                
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={'Update'} loading={isLoading} tabIndex={11} />
                                                <CancelButton onClick={cancelBrand} tabIndex={12} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { BrandUpdate };
