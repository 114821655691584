import React, { useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import { PostAuthRoutes } from '../../routes'
import { FilterAlt } from '@mui/icons-material'
import { Card, CardContent, CardHeader } from '../../_components/card'
import { GeneralButton } from '../../_components/controls'
import { FormSearchInput } from '../../_components/form'
import { UpdateStatusModal } from '../../_components/modal/modals/UpdateStatusModal';
import { RoleAction } from '../../redux/actions'
import { RoleDataTable } from '.'
import { useHistory, useLocation } from 'react-router'
import { RoleFilter } from './role-filter'
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function Role() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const changeStatus = (params) => dispatch(RoleAction.changeStatus(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '' });
    const [showRoleFilterModal, setShowRoleFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { status: searchParams.get('status') ?? '', portal: searchParams.get('portal') ?? '' },
        search: searchParams.get('q') ?? ''
    });

    /**
     * function to open/close status update modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status }) => {
        setStatusData({ id: id, status: status });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update role status
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'role_id': id });
    }

    /**
     * function to create Role
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    function createRole() {
        history.push({
            pathname: PostAuthRoutes('create_role').path,

        });
    }

    /**
     * function to open/close Role filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const roleFilterModal = () => {
        setShowRoleFilterModal(!showRoleFilterModal);
    }

    /**
     * function to apply the selected filter options on the role list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the role filter modal
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the role list
     * @param {String} text Search keyword used
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('role').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='admin_role_create' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment>Create</React.Fragment>} onClick={() => createRole()} />
                        </AbilityCan>
                        <AbilityCan I='admin_role_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='admin_role_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => roleFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <RoleFilter show={showRoleFilterModal} closeModal={roleFilterModal} applyFilter={applyFilter} roleFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <RoleDataTable statusUpdateModal={statusUpdateModal} filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </React.Fragment>

    )

}

export { Role }