import {
    ADD_BRAND_COST_REQUEST, ADD_BRAND_COST_SUCCESS, ADD_BRAND_COST_FAILURE,
    UPDATE_BRAND_COST_REQUEST, UPDATE_BRAND_COST_SUCCESS, UPDATE_BRAND_COST_FAILURE,
    CHANGE_BRAND_COST_STATUS_REQUEST, CHANGE_BRAND_COST_STATUS_SUCCESS, CHANGE_BRAND_COST_STATUS_FAILURE,
    BRAND_COST_REQUEST, BRAND_COST_SUCCESS, BRAND_COST_FAILURE,
    ADD_DEFAULT_PRICE_REQUEST, ADD_DEFAULT_PRICE_SUCCESS, ADD_DEFAULT_PRICE_FAILURE,
    UPDATE_DEFAULT_PRICE_REQUEST, UPDATE_DEFAULT_PRICE_SUCCESS, UPDATE_DEFAULT_PRICE_FAILURE,
    GET_DEFAULT_PRICE_REQUEST, GET_DEFAULT_PRICE_SUCCESS, GET_DEFAULT_PRICE_FAILURE,
} from "../actions";

const initialState = {
    add_brand_cost_loading: false, add_brand_cost_errors: {},
    change_brand_cost_status_loading: false, change_brand_cost_status_errors: {},
    brand_costs_loading: false, brand_costs: [], brand_costs_errors: [],
    get_default_price_loading: false, get_default_price_errors: {}, default_price: {}, default_giveaway_price: {},
    add_default_price_loading: false, add_default_price_errors: {},
};

export function BrandCostReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Create Permission redux */
        case ADD_BRAND_COST_REQUEST: {
            return {
                ...state,
                add_brand_cost_loading: true,
                add_brand_cost_errors: {}
            };
        }
        case ADD_BRAND_COST_SUCCESS: {
            return {
                ...state,
                add_brand_cost_loading: false,
                add_brand_cost_errors: {},
            };
        }
        case ADD_BRAND_COST_FAILURE: {
            return {
                ...state,
                add_brand_cost_loading: false,
                add_brand_cost_errors: action.errors
            };
        }
        /** end::Create Permission redux */

         /** begin::Update Permission redux */
         case UPDATE_BRAND_COST_REQUEST: {
            return {
                ...state,
                add_brand_cost_loading: true,
                add_brand_cost_errors: {}
            };
        }
        case UPDATE_BRAND_COST_SUCCESS: {
            return {
                ...state,
                add_brand_cost_loading: false,
                add_brand_cost_errors: {},
            };
        }
        case UPDATE_BRAND_COST_FAILURE: {
            return {
                ...state,
                add_brand_cost_loading: false,
                add_brand_cost_errors: action.errors
            };
        }
        /** end::Update Permission redux */

        /** begin::Change Permission status redux */
         case CHANGE_BRAND_COST_STATUS_REQUEST: {
            return {
                ...state,
                change_brand_cost_status_loading: true,
                change_brand_cost_status_errors: {}
            };
        }
        case CHANGE_BRAND_COST_STATUS_SUCCESS: {
            let newArray = [...state.brand_costs];
            const brandCostIndex = newArray.findIndex(brand_costs => (brand_costs._id === action.data._id && brand_costs.cost._id === action.data.cost._id));

            if (brandCostIndex !== -1) {
                newArray[brandCostIndex].cost.status = action.data.cost.status;
            }

            return {
                ...state,
                change_brand_cost_status_loading: false,
                change_brand_cost_status_errors: {},
                brand_costs: newArray
            };
        }
        case CHANGE_BRAND_COST_STATUS_FAILURE: {
            return {
                ...state,
                change_brand_cost_status_loading: false,
                change_brand_cost_status_errors: action.errors
            };
        }
        /** end::Change Permission status redux */

        /** begin::Permissions list redux */
        case BRAND_COST_REQUEST: {
            return {
                ...state,
                brand_costs_loading: true,
                brand_costs_errors: {}
            };
        }
        case BRAND_COST_SUCCESS: {
            let newArray = [...state.brand_costs]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.brand_costs.forEach((payload) => {
                const brandCostIndex = newArray.findIndex(brand_costs => (brand_costs._id === payload._id && brand_costs.cost._id === payload.cost._id));
                if (brandCostIndex !== -1) {
                    newArray[brandCostIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                brand_costs_loading: false,
                brand_costs: newArray,
                brand_costs_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }
        case BRAND_COST_FAILURE: {
            return {
                ...state,
                brand_costs_loading: false,
                brand_costs: [],
                brand_costs_errors: action.errors
            };
        }
        /** end::Users list redux */

        /** begin::Add default price redux */
        case ADD_DEFAULT_PRICE_REQUEST: {
            return {
                ...state,
                add_default_price_loading: true,
                add_default_price_errors: {}
            };
        }
        case ADD_DEFAULT_PRICE_SUCCESS: {
            
            let defaultBrandPrice = state.default_price;
            let defaultGiveawayPrice = state.default_giveaway_price;

            if (action.data.slug === 'default_coupon_price') {
                defaultBrandPrice = action.data;
            } else if (action.data.slug === 'default_giveaway_coupon_price') {
                defaultGiveawayPrice = action.data;
            }


            return {
                ...state,
                add_default_price_loading: false,
                default_price: defaultBrandPrice,
                default_giveaway_price: defaultGiveawayPrice,
                add_default_price_errors: {},
            };
        }
        case ADD_DEFAULT_PRICE_FAILURE: {
            return {
                ...state,
                add_default_price_loading: false,
                add_default_price_errors: action.errors
            };
        }
        /** end::Add default price redux */

        /** begin::Add default price redux */
        case UPDATE_DEFAULT_PRICE_REQUEST: {
            return {
                ...state,
                add_default_price_loading: true,
                add_default_price_errors: {}
            };
        }
        case UPDATE_DEFAULT_PRICE_SUCCESS: {
            let defaultBrandPrice = state.default_price;
            let defaultGiveawayPrice = state.default_giveaway_price;

            if (action.data.slug === 'default_coupon_price') {
                defaultBrandPrice = action.data;
            } else if (action.data.slug === 'default_giveaway_coupon_price') {
                defaultGiveawayPrice = action.data;
            }

            return {
                ...state,
                add_default_price_loading: false,
                default_price: defaultBrandPrice,
                default_giveaway_price: defaultGiveawayPrice,
                add_default_price_errors: {},
            };
        }
        case UPDATE_DEFAULT_PRICE_FAILURE: {
            return {
                ...state,
                add_default_price_loading: false,
                add_default_price_errors: action.errors
            };
        }
        /** end::Add default price redux */

        /** begin::Add default price redux */
         case GET_DEFAULT_PRICE_REQUEST: {
            return {
                ...state,
                get_default_price_loading: true,
                get_default_price_errors: {}
            };
        }
        case GET_DEFAULT_PRICE_SUCCESS: {
            let defaultBrandPrice = state.default_price;
            let defaultGiveawayPrice = state.default_giveaway_price;

            if (action.data.slug === 'default_coupon_price') {
                defaultBrandPrice = action.data;
            } else if (action.data.slug === 'default_giveaway_coupon_price') {
                defaultGiveawayPrice = action.data;
            }


            return {
                ...state,
                get_default_price_loading: false,
                get_default_price_errors: {},
                default_price: defaultBrandPrice,
                default_giveaway_price: defaultGiveawayPrice,
            };
        }
        case GET_DEFAULT_PRICE_FAILURE: {
            return {
                ...state,
                get_default_price_loading: false,
                get_default_price_errors: action.errors
            };
        }
        /** end::Add default price redux */

        default:
            return state;
    }
}
