
import { useEffect } from 'react';
import { cookieServices } from '../../_helpers';
import { useHistory } from 'react-router';

import { Grid } from '@mui/material';

import { CopyRight, useStyles } from '.'
import Box from '../../_components/box';
import { Card } from '../../_components/card/card/Card';

import image from "../../_theme/images/bg-sign-in-basic.jpeg";
import { PostAuthRoutes } from '../../routes';

const PreSignIn = ({ children, ...props }) => {

    let history = useHistory();

    useEffect(() => {

        if (cookieServices.get('accessToken')) {
            let redirectPath = PostAuthRoutes('home').path;
            history.push(redirectPath);
        }

    }, [history])

    const classes = useStyles();
    return (
        <Box justifyContent="center" alignItems="center" position="absolute" width="100%" minHeight="100vh" sx={{ backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) => image && `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${image})` }} >
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.preSignInWrapper}>
                <Grid item >
                    <Card>
                        <Box display="flex" alignItems="center">
                            <Box className={classes.preSignInWrapperItemInner} {...props}>
                                {children}
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <CopyRight />
        </Box>
    )
}


export { PreSignIn }
