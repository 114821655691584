import { GlobalAction } from ".";
import { GiveawayServices } from "../services";

export const GIVEAWAYS_REQUEST = 'GIVEAWAYS_REQUEST';
export const GIVEAWAYS_SUCCESS = 'GIVEAWAYS_SUCCESS';
export const GIVEAWAYS_FAILURE = 'GIVEAWAYS_FAILURE';

export const CHANGE_GIVEAWAY_STATUS_REQUEST = 'CHANGE_GIVEAWAY_STATUS_REQUEST';
export const CHANGE_GIVEAWAY_STATUS_SUCCESS = 'CHANGE_GIVEAWAY_STATUS_SUCCESS';
export const CHANGE_GIVEAWAY_STATUS_FAILURE = 'CHANGE_GIVEAWAY_STATUS_FAILURE';

export const BRAND_GIVEAWAY_DETAILS_REQUEST = 'BRAND_GIVEAWAY_DETAILS_REQUEST';
export const BRAND_GIVEAWAY_DETAILS_SUCCESS = 'BRAND_GIVEAWAY_DETAILS_SUCCESS';
export const BRAND_GIVEAWAY_DETAILS_FAILURE = 'BRAND_GIVEAWAY_DETAILS_FAILURE';

export const BRAND_GIVEAWAY_COUPON_CODES_REQUEST = 'BRAND_GIVEAWAY_COUPON_CODES_REQUEST';
export const BRAND_GIVEAWAY_COUPON_CODES_SUCCESS = 'BRAND_GIVEAWAY_COUPON_CODES_SUCCESS';
export const BRAND_GIVEAWAY_COUPON_CODES_FAILURE = 'BRAND_GIVEAWAY_COUPON_CODES_FAILURE';

export const GIVEAWAY_WINNER_REQUEST = 'GIVEAWAY_WINNER_REQUEST';
export const GIVEAWAY_WINNER_SUCCESS = 'GIVEAWAY_WINNER_SUCCESS';
export const GIVEAWAY_WINNER_FAILURE = 'GIVEAWAY_WINNER_FAILURE';

export const GIVEAWAY_ENTRIES_REQUEST = 'GIVEAWAY_ENTRIES_REQUEST';
export const GIVEAWAY_ENTRIES_SUCCESS = 'GIVEAWAY_ENTRIES_SUCCESS';
export const GIVEAWAY_ENTRIES_FAILURE = 'GIVEAWAY_ENTRIES_FAILURE';

export const GIVEAWAY_REDEEM_COUPON_REQUEST = 'GIVEAWAY_REDEEM_COUPON_REQUEST';
export const GIVEAWAY_REDEEM_COUPON_SUCCESS = 'GIVEAWAY_REDEEM_COUPON_SUCCESS';
export const GIVEAWAY_REDEEM_COUPON_FAILURE = 'GIVEAWAY_REDEEM_COUPON_FAILURE';

export const CREATE_GIVEAWAY_REQUEST = 'CREATE_GIVEAWAY_REQUEST';
export const CREATE_GIVEAWAY_SUCCESS = 'CREATE_GIVEAWAY_SUCCESS';
export const CREATE_GIVEAWAY_FAILURE = 'CREATE_GIVEAWAY_FAILURE';

export const UPDATE_GIVEAWAY_REQUEST = 'UPDATE_GIVEAWAY_REQUEST';
export const UPDATE_GIVEAWAY_SUCCESS = 'UPDATE_GIVEAWAY_SUCCESS';
export const UPDATE_GIVEAWAY_FAILURE = 'UPDATE_GIVEAWAY_FAILURE';

export const GIVEAWAY_DATES_REQUEST = 'GIVEAWAY_DATES_REQUEST';
export const GIVEAWAY_DATES_SUCCESS = 'GIVEAWAY_DATES_SUCCESS';
export const GIVEAWAY_DATES_FAILURE = 'GIVEAWAY_DATES_FAILURE';

export const GET_RUNNING_GIVEAWAYS_REQUEST = 'GET_RUNNING_GIVEAWAYS_REQUEST';
export const GET_RUNNING_GIVEAWAYS_SUCCESS = 'GET_RUNNING_GIVEAWAYS_SUCCESS';
export const GET_RUNNING_GIVEAWAYS_FAILURE = 'GET_RUNNING_GIVEAWAYS_FAILURE';

export const GiveawayAction = {

    /**
     * Action for list brand giveaways
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    listBrandGiveaways: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.listBrandGiveaways(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAYS_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAYS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAYS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change giveaway status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N 
     * @created_at 13 Jan 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_GIVEAWAY_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_GIVEAWAY_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_GIVEAWAY_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
    /**
     * Action to get giveaway details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin 
    */
    giveawayDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.giveawayDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_GIVEAWAY_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_GIVEAWAY_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_GIVEAWAY_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list promo coupon codes
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     */
    listBrandGiveawayCouponCodes: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.listBrandGiveawayCouponCodes(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: BRAND_GIVEAWAY_COUPON_CODES_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_GIVEAWAY_COUPON_CODES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_GIVEAWAY_COUPON_CODES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

     /**
     * Action for get giveaway winner
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @param {String} req.body.giveaway_message
     * @author  Jasin
     * @created_at 17 Jan 2023
    */
    getGiveawayWinner: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.getGiveawayWinner(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_WINNER_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_WINNER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_WINNER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for get giveaway entries
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author  Jasin
     * @created_at 17 Jan 2023
    */
    listGiveawayEntries: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.listGiveawayEntries(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_ENTRIES_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_ENTRIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_ENTRIES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for giveaway redeem coupon
    * @param {Object} params - The params which are used for registration api.
    * @author  
    * @created_at 30 May 2022
    */
    giveawayRedeemCoupon: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.giveawayRedeemCoupon(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_REDEEM_COUPON_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_REDEEM_COUPON_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_REDEEM_COUPON_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

     /**
     * Action to create giveaway
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.name
     * @param {String} req.body.description
     * @param {String} req.body.start_date
     * @param {String} req.body.end_date
     * @param {String} req.body.thank_you_message
     * @param {Object} req.files.sample_story
     * @author  Jasin
     * @created_at 13 Jan 2023
     */
    createGiveaway: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.createGiveaway(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CREATE_GIVEAWAY_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_GIVEAWAY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_GIVEAWAY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for update giveaway
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.name
     * @param {String} req.body.description
     * @param {String} req.body.start_date
     * @param {String} req.body.end_date
     * @param {String} req.body.thank_you_message
     * @param {Object} req.files.sample_story
     * @author  Jasin
     * @created_at 13 Jan 2023
    */
    updateGiveaway: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.updateGiveaway(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_GIVEAWAY_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_GIVEAWAY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_GIVEAWAY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

     /**
     * Action for get giveaway dates
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author  Jasin
     * @created_at 17 Jan 2023
    */
    getGiveawayDates: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.getGiveawayDates(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_DATES_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_DATES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_DATES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to get running promos
    * @param {Object} params - The params which are used for the api
    * @author Jasin
    * @created_at 30 May 2022
    */
    getRunningGiveaways: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.getRunningGiveaways(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GET_RUNNING_GIVEAWAYS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_RUNNING_GIVEAWAYS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_RUNNING_GIVEAWAYS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};
