import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from "../../../../_components/modal";
import { BrandAction } from "../../../../redux/actions";

// Initialize form input values to null
const inputs = { brand_id: '' };

function BrandWelcomeMessageModal({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    
    const params = new URLSearchParams(search);
    const brandId = params.get('brandId'); // story id from url

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });

    /** Initialize values from props */
    const { show, closeModal } = props;

    /** Redux actions and state */
    const { brand_send_influencer_message_loadind: isLoading, brand_send_influencer_welcome_message: isSendMessage } = useSelector((state) => state.BrandReducer);
    const brandSendInfluencerWelcomeMessage = (params) => dispatch(BrandAction.brandSendInfluencerWelcomeMessage(params));

    /**
     * function to close wallet model
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const onClose = () => {
        closeModal(false);
    }


    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {

        data['brand_id'] = brandId;
        setData({ ...data });

        dispatchAction();

    }

    const dispatchAction = async () => {
        brandSendInfluencerWelcomeMessage(data).then((result) => {
            closeModal(false);
        });
    }

    return (
        <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth='sm'>
            <form onSubmit={handleSubmit} noValidate >
                <ConfzModalHeader id="sendMessage" onClose={onClose} title='' />
                <ConfzModalBody>
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to ${(isSendMessage===false) ? 'send' : 'disable' } Ad Run welcome Instagram message to the influencer?`}</p>
                    <ConfzModalFooter justifyContent={'center'}>
                        <ConfzModalFooterSaveBtn tabIndex={4} text='Confirm' onClick={handleSubmit} loading={isLoading}/>
                        <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClose} />
                    </ConfzModalFooter>
                </ConfzModalBody>
            </form>
        </ConfzModal>
    )
}

export { BrandWelcomeMessageModal };
