import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../../_components/box'
import { StatisticsWidget } from '../../../_components/widgets'
import { WalletAction } from "../../../../src/redux/actions";

function TotalWalletCardCard() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { wallet_total } = useSelector((state) => state.WalletReducer);
    const totalWallet = (params) => dispatch(WalletAction.totalWallet(params));

    useEffect(() => {
        totalWallet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box mb={1.5}>
            <StatisticsWidget
                color="dark"
                icon="₹"
                title="Total Amount"
                count={`${wallet_total.totalWalletCount} /-`}
                percentage={{
                    label: `Credited ${wallet_total.totalWalletCount} rupees`,
                }}
            />
        </Box>
    )
}
export { TotalWalletCardCard }