import { makeStyles } from "@mui/styles";
import { DRAWER_WIDTH } from "../../../_helpers";

const useStyles = makeStyles((theme) => ({
    drawer: {
        "& .MuiPaper-root": {
            marginTop: theme.spacing(8),
            border: `none`,
            height: `calc(100vh - ${theme.spacing(8)})`,
            "&:hover": {
                '& .menu-name': {
                    display: `block !important`
                },
                '& .MuiBox-root': {
                    height: `100%`
                },
                '& .MuiList-root': {
                    // backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                    width: `${DRAWER_WIDTH}px !important`,
                    height: `100%`
                }
            },
            "& .MuiListItem-root": {
                "&:last-child": {
                    marginBottom: `20px !important`
                }
            }
        },
        "& .MuiListItemIcon-root": {
            minWidth: theme.spacing(2),
            padding: theme.spacing(2),
            fontSize: `20px !important`
        },
        "& .MuiListItemText-root": {
            marginTop: 0,
            marginBottom: 0,
            color: `text`,
        },
        '& .MuiTypography-root': {
            fontSize: `14px`,
            paddingRight: `15px`
        },
        '& .show-menu-name': {
            display: `block !important`
        },
        '& .hide-menu-name': {
            display: `none !important`
        },
        '& .drawer-open': {
            // backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            width: `${DRAWER_WIDTH}px !important`,
            height: `100%`
        }
    },
    listMenuWrapper: {
        // paddingTop: theme.spacing(7)
    },
    menuIcon: {
        fontSize: `20px !important`
    }

}))

export { useStyles };

