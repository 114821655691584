import { Instagram, MoreVert, Email, Phone, CheckCircle, HomeRounded, AttachMoney, CurrencyRupee, AddCircle } from '@mui/icons-material'
import { Box, CardHeader, CardMedia, Chip, Grid, IconButton, Menu, MenuItem, Stack, Typography, Badge } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { PostAuthRoutes } from '../../../routes'
import { Card, CardContent } from '../../../_components/card'
import { AbilityCan } from '../../../_helpers/permission/AbilityCan'
import { UpdateLogoModal } from './update-logo';

const useStyles = makeStyles((theme) => ({
    fullSizeIcon: {
        border: '3px solid #333'
    },
    circularBadge: {
        borderRadius: '50%', // Ensures the Badge stays circular
        cursor: 'pointer',
    },
}));

const CurrencySymbol = ({symbol, style}) => {
    return (
        <span style={style}> {symbol ? <span dangerouslySetInnerHTML={{ __html: symbol }} /> : <CurrencyRupee />} </span>
    );
}

function BrandCard({ brand, statusUpdateModal, whatsAppUpdateModal, smsUpdateModal, redirectUrl, height = `100%`, actions = { detail: true, update: true, change_status: true, change_whats_app: true, change_sms: true } }) {

    /** Initialize values from brand props */
    const { _id, status, name, email, email_verify_status, mobile_number, mobile_verify_status, insta_handle, insta_profile, billing_address, plan, whats_app, sms, registration_slug } = brand;
    const classes = useStyles();

    /** Initialize and declare state */
    const { brand_wallet_total } = useSelector((state) => state.WalletReducer);

    const [statusData, setStatusData] = useState({ id: brand._id, status: brand.status, item: '' });
    const [whatsAppData, setWhatsAppData] = useState({ id: brand._id, whatsApp: brand.whats_app, item: '' });
    const [smsData, setSmsData] = useState({ id: brand._id, sms: brand.sms, item: '' });
    const [showUpdateLogoModal, setShowUpdateLogoModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let history = useHistory();

    const { user } = useSelector((state) => state.UserReducer);

    useEffect(() => {
        setStatusData({ id: _id, status: status, item: name })
        setWhatsAppData({ id: _id, whatsApp: whats_app, item: name })
        setSmsData({ id: _id, sms: sms, item: name })
    }, [_id, status, name, whats_app])

    /**
     * function to handle click menu
     * @param {object} event form object
     * @author
     * @created_at 14 June 2022
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close menu
     * @param {string} null
     * @author
     * @created_at 14 June 2022
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to direct to brand update page
     * @param {string} null
     * @author
     * @created_at 14 June 2022
     */
    function handleUpdateClick() {
        setAnchorEl(null);
        history.push({ pathname: PostAuthRoutes('update_brands').path, state: { brand: brand, redirectPathUrl: (redirectUrl) ? redirectUrl : '' } });
    }

    /**
     * function to change brand status
     * @param {string} null
     * @author
     * @created_at 14 June 2022
     */
    function handleStatusChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }

    /**
     * function to change brand status
     * @param {string} null
     * @author
     * @created_at 14 June 2022
     */
    function handleWhatsAppChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            whatsAppUpdateModal(whatsAppData);
        }, 100);
    }

    /**
     * function to change brand status
     * @param {string} null
     * @author
     * @created_at 14 June 2022
     */
    function handleSmsChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            smsUpdateModal(smsData);
        }, 100);
    }

    /**
     * function to direct to Brand details page
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    function handleDetailsClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('brand_details').path,
            search: `?brandId=${_id}`
        });
    }

    /**
     * function to open/close FAQ update logo modal
     * @return view
     * @author Naveen
     * @created_at 21 Aug 2023
     */
    const updateLogoModal = async () => {
        setShowUpdateLogoModal(!showUpdateLogoModal)
    }

    return (
        <Card sx={{ height: height, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
            <CardHeader
                sx={{ width: `100%` }}
                action={
                    <Box>
                        <Stack direction="row" display={`flex`} alignItems={`center`} justifyContent={`center`} spacing={1}>
                            {(plan && plan.name) && <Chip size='small' icon={<CurrencySymbol symbol={brand.currency.html} style={{ marginLeft: '8px' }}/>} label={`${brand_wallet_total}`} color='primary' />}
                            {(plan && plan.name) && <Chip size='small' icon={<AttachMoney />} label={plan.name} color='primary' />}
                            <Chip size='small' label={status === 'active' ? 'Active' : 'Inactive'} color={status === 'active' ? 'success' : 'error'} />
                            <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                            <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                                <AbilityCan I='admin_brand_details' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    {actions.detail && <MenuItem onClick={handleDetailsClick}>{`Details`}</MenuItem>}
                                </AbilityCan>
                                <AbilityCan I='admin_add_update_brand' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    {actions.update && <MenuItem onClick={handleUpdateClick}>{`Update`}</MenuItem>}
                                </AbilityCan>
                                <AbilityCan I='admin_brand_change_status' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    {actions.change_status && <MenuItem onClick={handleStatusChangeClick}>{status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>}
                                </AbilityCan>
                                <AbilityCan I='admin_brand_change_whats_app' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    {actions.change_whats_app && <MenuItem onClick={handleWhatsAppChangeClick}>{whats_app === 'active' ? 'Disable WhatsApp' : 'Enable WhatsApp'}</MenuItem>}
                                </AbilityCan>
                                <AbilityCan I='admin_brand_change_sms' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    {actions.change_sms && <MenuItem onClick={handleSmsChangeClick}>{sms === 'active' ? 'Disable SMS' : 'Enable SMS'}</MenuItem>}
                                </AbilityCan>
                            </Menu>
                        </Stack>
                    </Box>
                }>
            </CardHeader>
            <CardContent style={{ width: `100%` }}>
                <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`} sx={{ width: `100%`, marginLeft: 0 }}>
                    <Badge onClick={() => updateLogoModal()} overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<AddCircle fontSize='medium' />} classes={{ badge: classes.circularBadge }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `200px` }}>
                                <CardMedia height={`100%`} component={"img"} src={insta_profile.profile_picture_url} onError={e => { e.target.src = insta_profile.profile_picture_url_error }} />
                            </Box>
                        </Grid>
                    </Badge>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems={`center`} justifyContent={`center`}>
                        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                            <Box style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: '70%' }}>
                                <Typography noWrap variant="h5" mb={1} onClick={handleDetailsClick} sx={{ cursor: `pointer` }}>{`${name}`}</Typography>
                            </Box>
                            {registration_slug === 'facebook_registration' && <Chip variant="outlined" size='small' label={insta_profile.is_private ? 'Private' : 'Public'} color={insta_profile.is_private ? 'secondary' : 'primary'} />}
                        </Stack>
                        {insta_handle && <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Instagram />&nbsp;{`@${insta_handle}`}</Typography>}
                        {email ? <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Email />&nbsp;{`${email}`}&nbsp;{email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='light' size='small' />}</Typography> : <Typography variant="h6" align='center' >&nbsp;</Typography>}
                        {mobile_number ? <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Phone />&nbsp;{`${mobile_number}`}&nbsp;{mobile_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='light' size='small' />}</Typography> : <Typography variant="h6" align='center' >&nbsp;</Typography>}
                        {billing_address &&
                            <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><HomeRounded />&nbsp;{`${billing_address.address}`}, {`${billing_address.city}`}-{`${billing_address.pincode}`}&nbsp;</Typography>
                        }
                    </Grid>
                    <Grid container spacing={1} mt={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Box sx={{ backgroundColor: 'info.main', width: `100%` }}  >
                                <Typography variant="h6" color={`white`} align={`center`}>{insta_profile.followers_count}</Typography>
                                <Typography variant="body2" color={`white`} align={`center`}>{`Followers`}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <UpdateLogoModal show={showUpdateLogoModal} closeModal={updateLogoModal} brandId={brand._id} />
                </Grid>
            </CardContent>
        </Card>
    )
}

export { BrandCard }