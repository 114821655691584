import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { GlobalReducer, SignInReducer, RegisterReducer, UserReducer, InfluencerReducer, ForgotPasswordReducer, BrandReducer, PromoReducer, PlanReducer, ApprovalReducer, AdvertisementReducer, InvoiceReducer, StoryReducer, DashboardReducer, NotificationReducer, EmailLogReducer, RoleReducer, PermissionReducer, SupportCenterReducer, ContactUsReducer, TestimonialReducer, BrandCostReducer, FaqReducer, GiveawayReducer, NewsletterSubscribersReducer, WalletReducer, BrandCategoryReducer, LogReducer } from './reducers';

const loggerMiddleware = createLogger({ predicate: () => process.env.NODE_ENV !== 'production' });

const rootReducer = combineReducers({ GlobalReducer, SignInReducer, InfluencerReducer, RegisterReducer, UserReducer, ForgotPasswordReducer, BrandReducer, PromoReducer, PlanReducer, ApprovalReducer, AdvertisementReducer, InvoiceReducer, StoryReducer, DashboardReducer, NotificationReducer, EmailLogReducer, RoleReducer, PermissionReducer, SupportCenterReducer, ContactUsReducer, TestimonialReducer, BrandCostReducer, FaqReducer, GiveawayReducer, NewsletterSubscribersReducer, WalletReducer, BrandCategoryReducer, LogReducer })

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);