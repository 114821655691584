import { BrandCostServices } from "../services";
import { GlobalAction } from "./GlobalAction";


export const BRAND_COST_REQUEST = 'BRAND_COST_REQUEST';
export const BRAND_COST_SUCCESS = 'BRAND_COST_SUCCESS';
export const BRAND_COST_FAILURE = 'BRAND_COST_FAILURE';

export const ADD_BRAND_COST_REQUEST = 'ADD_BRAND_COST_REQUEST';
export const ADD_BRAND_COST_SUCCESS = 'ADD_BRAND_COST_SUCCESS';
export const ADD_BRAND_COST_FAILURE = 'ADD_BRAND_COST_FAILURE';

export const UPDATE_BRAND_COST_REQUEST = 'UPDATE_BRAND_COST_REQUEST';
export const UPDATE_BRAND_COST_SUCCESS = 'UPDATE_BRAND_COST_SUCCESS';
export const UPDATE_BRAND_COST_FAILURE = 'UPDATE_BRAND_COST_FAILURE';

export const CHANGE_BRAND_COST_STATUS_REQUEST = 'CHANGE_BRAND_COST_STATUS_REQUEST';
export const CHANGE_BRAND_COST_STATUS_SUCCESS = 'CHANGE_BRAND_COST_STATUS_SUCCESS';
export const CHANGE_BRAND_COST_STATUS_FAILURE = 'CHANGE_BRAND_COST_STATUS_FAILURE';

export const ADD_DEFAULT_PRICE_REQUEST = 'ADD_DEFAULT_PRICE_REQUEST';
export const ADD_DEFAULT_PRICE_SUCCESS = 'ADD_DEFAULT_PRICE_SUCCESS';
export const ADD_DEFAULT_PRICE_FAILURE = 'ADD_DEFAULT_PRICE_FAILURE';

export const UPDATE_DEFAULT_PRICE_REQUEST = 'UPDATE_DEFAULT_PRICE_REQUEST';
export const UPDATE_DEFAULT_PRICE_SUCCESS = 'UPDATE_DEFAULT_PRICE_SUCCESS';
export const UPDATE_DEFAULT_PRICE_FAILURE = 'UPDATE_DEFAULT_PRICE_FAILURE';

export const GET_DEFAULT_PRICE_REQUEST = 'GET_DEFAULT_PRICE_REQUEST';
export const GET_DEFAULT_PRICE_SUCCESS = 'GET_DEFAULT_PRICE_SUCCESS';
export const GET_DEFAULT_PRICE_FAILURE = 'GET_DEFAULT_PRICE_FAILURE';

export const BrandCostAction = {

    /**
     * Action for list brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */

    listBrandCost: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.listBrandCost(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: BRAND_COST_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_COST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_COST_FAILURE, request: request, data: error.data, message: error.message, errors: error.errors, status: error.status, status_code: error.status_code } }
    },

    /**
     * Action to add brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    addBrandCost: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.addBrandCost(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_BRAND_COST_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_BRAND_COST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_BRAND_COST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to update brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    updateBrandCost: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.updateBrandCost(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_BRAND_COST_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_BRAND_COST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_BRAND_COST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for change brand cost status
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_BRAND_COST_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_BRAND_COST_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_BRAND_COST_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to add brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    addDefaultPrice: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.addDefaultPrice(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_DEFAULT_PRICE_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_DEFAULT_PRICE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_DEFAULT_PRICE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to update brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    updateDefaultPrice: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.updateDefaultPrice(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_DEFAULT_PRICE_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_DEFAULT_PRICE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_DEFAULT_PRICE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to get price details
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    getDefaultPriceDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.getDefaultPriceDetails(params)
                .then(
                    response => { return dispatch(success(params, response)) },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_DEFAULT_PRICE_REQUEST, request: request } }
        function success(request, response) { return { type: GET_DEFAULT_PRICE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_DEFAULT_PRICE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

}