import React from 'react'
import { Button, CircularProgress } from '@mui/material'

function SubmitButton({ label = 'Submit', tabIndex, loading = false, disabled = false, ...props }) {
    return (
        <Button
            type="submit"
            variant="contained"
            color={"primary"}
            tabIndex={tabIndex}
            disabled={loading || disabled}
            startIcon={loading ? (<CircularProgress size={14} />) : ('')}
            {...props}
        >
            {label}
        </Button>
    )
}

export { SubmitButton }
