import { Send } from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { EmailLogAction } from "../../../redux/actions";
import { PostAuthRoutes } from "../../../routes";
import { CardHeader } from "../../../_components/card";
import { GeneralButton } from "../../../_components/controls";
import { EmailLogDetailCard, EmailLogRequestCard } from "./";

function EmailLogDetails({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const logId = params.get('lId'); // plan id from url

    /** Redux actions and state */
    const { email_log_loading: isLoading, email_log, email_log_resend_loading: isResendLoading } = useSelector((state) => state.EmailLogReducer);
    const emailLogDetails = (params) => dispatch(EmailLogAction.emailLogDetails(params));
    const ResendMail = (params) => dispatch(EmailLogAction.ResendMail(params));

    useEffect(() => {
        emailLogDetails({ log_id: logId });

        //eslint-disable-next-line
    }, [logId])

    /**
     * function to resend mail
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    const resendMail = () => {
        ResendMail({ log_id: logId })
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('email_log_details').name} action={
                    <Stack spacing={2} direction="row">
                        <GeneralButton label={<React.Fragment><Send /> Resend Mail</React.Fragment>} onClick={() => resendMail()} disabled={isResendLoading} />
                    </Stack>
                } />
            </Box>
            <Grid container spacing={2}>
                <Grid item container xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" >
                        {(!isLoading && Object.keys(email_log).length > 0) &&
                            <EmailLogDetailCard emailLog={email_log} />
                        }
                    </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} md={9} lg={9} xl={9}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" >
                        {(!isLoading && Object.keys(email_log).length > 0) &&
                            <EmailLogRequestCard emailLog={email_log} />
                        }
                    </Grid>
                </Grid>

            </Grid>
        </React.Fragment>

    );
}

export { EmailLogDetails }