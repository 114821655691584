import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandAction } from "../../../redux/actions";
import { FormDatePicker, FormSelect } from "../../../_components/form";
import { ConfzModal, ConfzModalBody, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from "../../../_components/modal";
import { handleInputChange } from "../../../_helpers";

// Initialize form input values to null
const inputs = { filter_status: '', filter_brand: '', filter_month: '' };

// Declare filter status select options
const filterStatus = [
    { value: 'not_paid', label: 'Not Paid' },
    { value: 'paid', label: 'Paid' },
    { value: 'pending', label: 'Pending' }
]

function InvoiceFilter({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { brands_select_list } = useSelector((state) => state.BrandReducer);
    const brandSelectList = (params) => dispatch(BrandAction.brandSelectList(params));

    /** Initialize and declare state */
    const { show, closeModal, applyFilter, invoiceFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});


    /**
     * function to close invoice filter modal
     * @param {string} status Entered status on invoice filter modal
     * @param {string} month Entered month on invoice filter modal 
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const onClose = () => {
        setData({ filter_status: '', filter_brand: '', filter_month: '' });
        closeModal(false);
    }

    /**
     * function to clear invoice filter modal
     * @param {string} status Entered status on invoice filter modal
     * @param {string} month Entered month on invoice filter modal 
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const onClear = () => {
        setData({ filter_status: '', filter_brand: '', filter_month: '' });
        applyFilter({ filter_status: '', filter_brand: '', filter_month: '' });
        closeModal(false);
    }

    useEffect(() => {
        brandSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (show === true) {
            setData({ filter_status: invoiceFilter.filter_status, filter_brand: invoiceFilter.filter_brand, filter_month: invoiceFilter.filter_month })
        }

        let filtered = {};
        Object.keys(invoiceFilter).forEach(function (item, i) {
            if (invoiceFilter[item]) {
                filtered[item] = invoiceFilter[item];
                if (item === 'filter_status') {
                    let status = filterStatus.filter(function (obj) { return obj.value === invoiceFilter[item] })
                    filtered[item] = status[0].label
                }
                return filtered[item];
            }
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, invoiceFilter])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Jasin
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  
     * @created_at 14 June 2022 
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);

    }

    /**
     * function to clear all data entered on invoice filter modal
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const clearFilterItem = async (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });
    }

    return (
        <React.Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={1}>
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        if (item === 'status') {
                                            const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                            return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />
                                        } else if (item === 'filter_brand') {
                                            const brand = brands_select_list.find(function (brand) { return brand.value === filteredData[item]; });
                                            return <Chip key={i} label={brand.label} onDelete={() => clearFilterItem(item)} />
                                        } else if (item === 'requested_for') {
                                            return filteredData[item] = ''
                                        }
                                        return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />

                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="invoiceFilter" onClose={onClose} title='Filter' />
                    <ConfzModalBody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`filter_status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    className="col-md-6"
                                    value={data.filter_status}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={2}
                                    label={`Brands`}
                                    name={`filter_brand`}
                                    placeholder={`Select brand`}
                                    onChange={handleChange}
                                    data={brands_select_list}
                                    className="col-md-6"
                                    value={data.filter_brand}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormDatePicker
                                    tabIndex={2}
                                    label='Month'
                                    name='filter_month'
                                    value={data.filter_month}
                                    placeholder="Year and Month"
                                    onChange={handleChange}
                                    views={['month', 'year']}
                                    inputFormat='MMMM yyyy'
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                    <ConfzModalFooterSaveBtn tabIndex={4} text='Filter' />
                                    <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClear} text='Clear' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ConfzModalBody>
                </form>
            </ConfzModal>
        </React.Fragment>
    );
}

export { InvoiceFilter }