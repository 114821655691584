import { AccessTime } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

function SortFaqCard({ faqs }) {

    /** Initialize values from activity_log props */
    const { question, created_at } = faqs;

    return (
        <Card>
            <CardContent sx={{ mt: 2 }}>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <Typography variant="body2">{`${question}`}</Typography>
                        <Typography variant="body2" sx={{ fontSize: 10 }}> <AccessTime /> {created_at}</Typography>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )

}

export { SortFaqCard }