import React from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

function ConfzModalHeader({ id, title, onClose }) {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant={`h5`}>{title}</Typography>
                <IconButton aria-label="close" onClick={() => onClose()}   >
                    <Close />
                </IconButton>
            </Stack>
        </DialogTitle>
    )
}


ConfzModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
}

export { ConfzModalHeader }
