
module.exports = {
    /**
     * function to validate the brand logo update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author 
     * @created_at 21 Aug 2023
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'file': {
                errors.file = '';
                if (value === '' || value === [] || typeof value === 'undefined') {
                    errors.file = 'Select logo. ';
                } else if (typeof value !== 'string') {
                    let extension = (value.name).split('.').pop();
                    extension = extension.toLowerCase();
                    let size = value.size;

                    if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                        errors.file = 'Please choose a valid file format.';
                    } else if (size >= 2000000) {
                        errors.file = 'Selected promo sample file size exceeded.File size must be below 2 MB.';
                    }
                }
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}