import {
    LOG_REQUEST, LOG_SUCCESS, LOG_FAILURE,
    LOG_DETAILS_REQUEST, LOG_DETAILS_SUCCESS, LOG_DETAILS_FAILURE,
    DELETE_ALL_LOG_REQUEST, DELETE_ALL_LOG_SUCCESS, DELETE_ALL_LOG_FAILURE,
} from "../actions"

const initialState = {
    page: 1, per_page: 20, total: 0, total_pages: 0,
    log_loading: false, log: [], log_errors: {},
    log_details_loading: false, log_details: {}, log_details_errors: {},
    delete_all_logs_loading: false, delete_all_logs: {}, delete_all_logs_errors: {},
};

export function LogReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::List Support Center redux */
        case LOG_REQUEST: {
            return {
                ...state,
                log_loading: true,
                log_errors: {}
            };
        }
        case LOG_SUCCESS: {

            let newArray = [...state.log]; //making a new array

            if (action.data.page === 1) {
                newArray = []
            }

            action.data.logs.forEach((payload) => {
                const logIndex = newArray.findIndex(log => log._id === payload._id);
                if (logIndex !== -1) {
                    newArray[logIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                log_loading: false,
                log: action.data.logs,
                log_errors: {},
                page: action.data.per_page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }
        case LOG_FAILURE: {
            return {
                ...state,
                log: [],
                log_loading: false,
                log_errors: action.errors,
            }
        }
        /** end::List Logs redux */

        /** begin::Log details redux */
        case LOG_DETAILS_REQUEST: {
            return {
                ...state,
                log_details_loading: true,
                log_details_errors: {}
            };
        }
        case LOG_DETAILS_SUCCESS: {

            let newArray = [...state.log]; //making a new array

            if (action.data.page === 1) {
                newArray = []
            }

            const logDetailsIndex = newArray.findIndex(log_details => log_details._id === action.data._id);

            if (logDetailsIndex !== -1) {
                newArray[logDetailsIndex] = action.data;
            } else {
                newArray = newArray.concat(action.data);
            }

            return {
                ...state,
                log_details_loading: false,
                log_details: action.data,
                log_details_errors: {}
            };
        }
        case LOG_DETAILS_FAILURE: {
            return {
                ...state,
                log_details_loading: false,
                log_details_errors: action.errors
            };
        }
        /** end::Log details redux */

        /** begin::Delete all logs redux */
        case DELETE_ALL_LOG_REQUEST: {
            return {
                ...state,
                delete_all_logs_loading: true,
                delete_all_logs_errors: {}
            };
        }
        case DELETE_ALL_LOG_SUCCESS: {

            return {
                ...state,
                delete_all_logs_loading: false,
                delete_all_logs: action.data,
                delete_all_logs_errors: {},
            };
        }
        case DELETE_ALL_LOG_FAILURE: {
            return {
                ...state,
                delete_all_logs_loading: false,
                delete_all_logs_errors: action.errors
            };
        }
        /** end::Delete all logs redux */

        default:
            return state;
    }
}