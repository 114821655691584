module.exports = {
    /**
     * function to validate the change password component
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 14 June 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {
            case 'current_password': {
                errors.current_password = value.length < 6 ? 'Current password must be 6 characters long.' : '';
                if (value === '')
                    errors.current_password = 'Enter current password.';
                break;
            } case 'new_password': {
                const confirmPassword = data.confirm_password ?? '';
                errors.new_password = value.length < 6 ? 'Password must be 6 characters long.' : '';
                if (confirmPassword !== '' && confirmPassword === value)
                    errors.confirm_password = '';
                if (value === '')
                    errors.password = 'Enter password.';
                break;
            } case 'confirm_password': {
                const password = data.new_password ?? '';
                errors.confirm_password = value.length < 6 ? 'Password must be 6 characters long.' : '';
                if (password !== '' && password !== value)
                    errors.confirm_password = 'Confirm password should match with the password.';
                if (value === '')
                    errors.confirm_password = 'Re-enter your password.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}