import React, { useEffect } from "react";
import { LogAction } from "../../../redux/actions";
import { CardHeader, Card, CardContent } from "../../../_components/card";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PostAuthRoutes } from "../../../routes";
import { Grid, Typography, Box, Chip, Divider, Stack } from "@mui/material";
import { NoData } from "../../../_components/layout/no-data";

function LogDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const logId = params.get('lId'); // log id from url

    /** Redux actions and state */
    const { log_details_loading: isLoading, log_details } = useSelector((state) => state.LogReducer);
    const logDetails = (params) => dispatch(LogAction.logDetails(params));

    const statusColor = log_details.status >= 400 && log_details.status < 500 ? 'error' : log_details.status >= 500 && log_details.status < 600 ? 'warning' : log_details.status >= 200 && log_details.status < 300 ? 'success' : 'default';
    const statusLabel = log_details.status >= 400 && log_details.status < 500 ? 'Client Error' : log_details.status >= 500 && log_details.status < 600 ? 'Server Error' : log_details.status >= 200 && log_details.status < 300 ? 'Success' : 'Error Unknown';

    useEffect(() => {
        logDetails({ log_id: logId });

        //eslint-disable-next-line
    }, [logId])

    return (
		<React.Fragment>
			{!isLoading && Object.keys(log_details).length === 0 && <NoData content1={`No`} content2={`log available`} minHeight={`55vh`}></NoData>}
			{!isLoading && Object.keys(log_details).length > 0 && (
				<React.Fragment>
					<CardHeader title={PostAuthRoutes('log_details').name} />
					<Box paddingLeft="300px">
						<Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ width: '80%' }}>
							<Grid item xs={12} sm={12} md={8} lg={12} xl={12}>
								<Card>
									<CardContent>
										<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
											<Typography variant="h5">{`${statusLabel}`}</Typography>
											<Box>
												<Chip size="small" label={log_details.status} color={statusColor} />
											</Box>
										</Box>
										<Stack spacing={1}>
											<Box display="flex" justifyContent="space-between" alignItems="center">
												<Typography variant="body2">{`Method : `}</Typography>
												<Chip label={`${log_details.method}`} style={{ textTransform: `capitalize` }} size="small" />
											</Box>
											<Box display="flex" justifyContent="space-between" alignItems="center">
												<Typography variant="body2">{`URL : `}</Typography>
												<Chip label={`'${log_details.url}'`} size="small" />
											</Box>
											<Box display="flex" justifyContent="space-between" alignItems="center">
												<Typography variant="body2">{`Response Time : `}</Typography>
												<Chip label={`${log_details.responseTime} ms`} size="small" />
											</Box>
											<Box display="flex" justifyContent="space-between" alignItems="center">
												<Typography variant="body2">{`Created On : `}</Typography>
												<Chip label={`${log_details.created_at}`} size="small" />
											</Box>
										</Stack>
										<Divider />
										<Box style={{ minHeight: `80px`, overflow: 'auto' }}>
											<Typography variant="overline">Request Body:</Typography>
											<Typography variant="subtitle2" sx={{ whiteSpace: 'pre-wrap', paddingLeft: '40px' }}>{`${JSON.stringify(log_details.requestBody, null, 2)}`}</Typography>
										</Box>
										<Divider />
										<Box style={{ minHeight: `80px`, overflow: 'auto' }}>
											<Typography variant="overline">Response Body:</Typography>
											<Typography variant="subtitle2" sx={{ whiteSpace: 'pre-wrap', paddingLeft: '40px' }}>{`${JSON.stringify(log_details.responseBody, null, 2)}`}</Typography>
										</Box>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Box>
				</React.Fragment>
			)}
		</React.Fragment>
	);

}

export { LogDetails }