import { GlobalAction } from ".";
import { BrandCostServices, PlanServices } from "../services";

export const PLANS_REQUEST = 'PLANS_REQUEST';
export const PLANS_SUCCESS = 'PLANS_SUCCESS';
export const PLANS_FAILURE = 'PLANS_FAILURE';

export const ADD_PLAN_REQUEST = 'ADD_PLAN_REQUEST';
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS';
export const ADD_PLAN_FAILURE = 'ADD_PLAN_FAILURE';

export const UPDATE_PLAN_REQUEST = 'UPDATE_PLAN_REQUEST';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE';

export const PLAN_DETAILS_REQUEST = 'PLAN_DETAILS_REQUEST';
export const PLAN_DETAILS_SUCCESS = 'PLAN_DETAILS_SUCCESS';
export const PLAN_DETAILS_FAILURE = 'PLAN_DETAILS_FAILURE';

export const CHANGE_PLAN_STATUS_REQUEST = 'CHANGE_PLAN_STATUS_REQUEST';
export const CHANGE_PLAN_STATUS_SUCCESS = 'CHANGE_PLAN_STATUS_SUCCESS';
export const CHANGE_PLAN_STATUS_FAILURE = 'CHANGE_PLAN_STATUS_FAILURE';

export const ADD_INSTALLATION_COST_REQUEST = 'ADD_INSTALLATION_COST_REQUEST';
export const ADD_INSTALLATION_COST_SUCCESS = 'ADD_INSTALLATION_COST_SUCCESS';
export const ADD_INSTALLATION_COST_FAILURE = 'ADD_INSTALLATION_COST_FAILURE';

export const UPDATE_INSTALLATION_COST_REQUEST = 'UPDATE_INSTALLATION_COST_REQUEST';
export const UPDATE_INSTALLATION_COST_SUCCESS = 'UPDATE_INSTALLATION_COST_SUCCESS';
export const UPDATE_INSTALLATION_COST_FAILURE = 'UPDATE_INSTALLATION_COST_FAILURE';

export const GET_INSTALLATION_COST_REQUEST = 'GET_INSTALLATION_COST_REQUEST';
export const GET_INSTALLATION_COST_SUCCESS = 'GET_INSTALLATION_COST_SUCCESS';
export const GET_INSTALLATION_COST_FAILURE = 'GET_INSTALLATION_COST_FAILURE';

export const GIVEAWAY_PLANS_REQUEST = 'GIVEAWAY_PLANS_REQUEST';
export const GIVEAWAY_PLANS_SUCCESS = 'GIVEAWAY_PLANS_SUCCESS';
export const GIVEAWAY_PLANS_FAILURE = 'GIVEAWAY_PLANS_FAILURE';

export const PlanAction = {

    /**
     * Action for list plans
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    listPlans: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.listPlans(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PLANS_REQUEST, request: request } }
        function success(request, response) { return { type: PLANS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PLANS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to create plan
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.name
     * @param {String} req.body.number_of_coupons
     * @param {String} req.body.description
     * @param {String} req.body.cost
     * @param {String} req.body.category
     * @author Jasin 
     * @created_at 15 June 2022
    */
    createPlan: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.createPlan(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_PLAN_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_PLAN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_PLAN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to create plan
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.plan_id
     * @param {String} req.body.name
     * @param {String} req.body.number_of_coupons
     * @param {String} req.body.description
     * @param {String} req.body.cost
     * @param {String} req.body.category
     * @author Jasin 
     * @created_at 15 June 2022
    */
    updatePlan: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.updatePlan(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_PLAN_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_PLAN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_PLAN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get plan details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.plan_id
     * @author Jasin 
     * @created_at 15 June 2022
    */
    planDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.planDetails(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: PLAN_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: PLAN_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PLAN_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change plan status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.plan_id
     * @author Jasin 
     * @created_at 15 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PLAN_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PLAN_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PLAN_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to add installation cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 04 January 2023
    */
    addIntsallationCost: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.addDefaultPrice(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_INSTALLATION_COST_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_INSTALLATION_COST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_INSTALLATION_COST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to update brand cost
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    updateIntsallationCost: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.updateDefaultPrice(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_INSTALLATION_COST_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_INSTALLATION_COST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_INSTALLATION_COST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to get price details
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
    */
    getInstallationCost: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandCostServices.getDefaultPriceDetails(params)
                .then(
                    response => { return dispatch(success(params, response)) },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_INSTALLATION_COST_REQUEST, request: request } }
        function success(request, response) { return { type: GET_INSTALLATION_COST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_INSTALLATION_COST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for list giveaway plans
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    listGiveawayPlans: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.listGiveawayPlans(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_PLANS_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_PLANS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_PLANS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};
