import React, { useEffect } from 'react'
import Box from '../../../_components/box'
import { StatisticsWidget } from '../../../_components/widgets'
import { useDispatch, useSelector } from "react-redux";
import { DashboardAction } from "../../../../src/redux/actions";

function PromoCard() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    //** Redux actions and state */
    const { promo_count_details } = useSelector((state) => state.DashboardReducer);
    const promoCount = (params) => dispatch(DashboardAction.promoCount(params));

    /** Initialize and declare state */
    const { promo_count, promo_percentage } = promo_count_details;

    useEffect(() => {

        promoCount();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box mb={1.5}>
            <StatisticsWidget
                color="secondary"
                icon="weekend"
                title="Promos"
                count={promo_count}
                percentage={{
                    color: `${promo_percentage > 0 ? 'success' : 'error'}`,
                    amount: `${parseFloat(promo_percentage).toFixed(2)}%`,
                    label: "than last month",
                }}
            />
        </Box>
    )
}
export { PromoCard }