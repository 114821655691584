import React from 'react'
import { styled } from '@mui/material/styles';
import { CardContent as MuiCardContent } from '@mui/material'

const StyledMuiCardContent = styled(({ ...props }) => (<MuiCardContent {...props} />))`
        padding: ${props => props.theme.spacing(4)};
        &:last-child {
            paddingBottom:${props => props.theme.spacing(4)};
        }
`;

function CardContent({ ...props }) {


    return (
        <StyledMuiCardContent  {...props}>{props.children}</StyledMuiCardContent>
    )
}

export { CardContent }
