import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

function CancelButton({ text, className, tabIndex, onClick, props }) {

    return (
        <Button className={className} type="button" variant="contained" color="secondary" tabIndex={tabIndex} onClick={() => onClick()} {...props} >
            {text}
        </Button>
    )
}


CancelButton.defaultProps = {
    className: "",
    text: "Cancel",
}


CancelButton.propTypes = {
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

export { CancelButton }
