import React, { useEffect } from 'react'
import { Box, Card, CardContent, CardMedia, Divider, Grid, Skeleton, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StoryAction } from '../../../../redux/actions';
import { PostAuthRoutes } from "../../../../routes";
import { useHistory } from 'react-router'

function StoryPromoDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    let history = useHistory();

    const params = new URLSearchParams(search);
    const storyId = params.get('sId'); // story id from url

    /** Redux reducer state */
    const { promo_loading: isLoading, story_promo: promo } = useSelector((state) => state.StoryReducer);

    /** Redux reducer actions */
    const getPromoDetails = (params) => dispatch(StoryAction.getPromoDetails(params));

    useEffect(() => {
        getPromoDetails({ story_id: storyId });
        //eslint-disable-next-line
    }, [storyId])

    /**
    * function to direct to Promo details page
    * @return view
    * @author
    * @created_at 27 June 2022
    */
    function handlePromoDetailsClick() {
        history.push({
            pathname: PostAuthRoutes('promo_details').path,
            search: `?pId=${promo._id}`
        });
    }

    /**
     * function to direct to Brand details page
     * @return view
     * @author
     * @created_at 27 June 2022
     */
    function handleBrandDetailsClick() {
        history.push({
            pathname: PostAuthRoutes('brand_details').path,
            search: `?brandId=${promo.brand._id}`
        });
    }

    return (
        <Card sx={{ padding: 6, height: "100%" }}>
            <Grid container>
                {!isLoading && Object.keys(promo).length > 0 &&
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                        <CardContent >
                            <Box>
                                <Typography variant={`h5`} onClick={handlePromoDetailsClick} sx={{ mb: 1, cursor: `pointer` }}>{promo.name}</Typography>
                                <Box style={{ minHeight: `60px` }}>
                                    <Typography variant={`subtitle2`} sx={{ fontWeight: 500, whiteSpace: 'pre-line' }}>{promo.description} Description</Typography>
                                </Box>
                                <Typography variant={`body2`} sx={{ fontWeight: 500 }} mt={2}>{`Mention @${promo.insta_handle}`}</Typography>
                                <Divider />
                                <Typography variant={`body2`} sx={{ fontWeight: 500 }} mt={2}>{`${promo.promo_start_date} - ${promo.promo_end_date}`}</Typography>
                                <Typography variant={`body2`} onClick={handleBrandDetailsClick} sx={{ fontWeight: 500, cursor: `pointer` }}>{`Brand name - ${promo.brand.name}`}</Typography>
                            </Box>
                        </CardContent>
                    </Grid>
                }
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                    {isLoading ? (
                        <Skeleton variant="rectangular" width={180} height={320} />
                    ) : (
                        <CardMedia component="img" image={promo.file} style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} alt={promo.name} onError={e => { e.target.src = promo.file_error }} />
                    )}
                </Grid>
            </Grid>
        </Card>
    )
}

export { StoryPromoDetails }