import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import { UserAction } from "../../../redux/actions";
import { Box, Grid } from '@mui/material';
import { ActivityLogCard } from './activity-log-card';
import { CardHeader } from '../../../_components/card/card-header/CardHeader';
import { PostAuthRoutes } from '../../../routes';
import { ActivityLogCardLoader } from ".";
import { NoData } from '../../../_components/layout/no-data';

function ActivityLog() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { activity_logs_loading: isLoading, activity_logs } = useSelector((state) => state.UserReducer);
    const getLoginActivityList = (params) => dispatch(UserAction.getActivityList(params));

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ limit: 100, page: 1 });

    useEffect(() => {
        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to list ActivityLogin
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {
        const result = await getLoginActivityList(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    return (
        <React.Fragment>
            <Box>
                {activity_logs.length > 0 && <CardHeader title={PostAuthRoutes('activity_log').name} />}
                <InfiniteScroll dataLength={activity_logs.length} next={getNext} hasMore={true}>
                    <Grid container spacing={1}  >
                        {
                            activity_logs.length > 0 && activity_logs.map((activity_log, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
                                        <ActivityLogCard activity_log={activity_log} />
                                    </Grid>
                                );
                            })
                        }
                        {!isLoading && activity_logs.length === 0 && <NoData content1={`No`} content2={`Activity logs available`} minHeight={`55vh`} description={`Stay tuned! All your activity will show up here.`} ></NoData>}
                        {isLoading && <ActivityLogCardLoader />}
                    </Grid>
                </InfiniteScroll>
            </Box>
        </React.Fragment>
    )
}

export { ActivityLog };
