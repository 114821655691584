import {
    PROMOS_REQUEST, PROMOS_SUCCESS, PROMOS_FAILURE,
    CHANGE_PROMO_STATUS_REQUEST, CHANGE_PROMO_STATUS_SUCCESS, CHANGE_PROMO_STATUS_FAILURE,
    UPDATE_PROMO_REQUEST, UPDATE_PROMO_SUCCESS, UPDATE_PROMO_FAILURE,
    PROMO_DETAILS_REQUEST, PROMO_DETAILS_SUCCESS, PROMO_DETAILS_FAILURE,
    PROMO_COUPON_CODES_REQUEST, PROMO_COUPON_CODES_SUCCESS, PROMO_COUPON_CODES_FAILURE,
    PROMOS_LIST_REQUEST, PROMOS_LIST_SUCCESS, PROMOS_LIST_FAILURE,
    PROMOS_STORY_REQUEST, PROMOS_STORY_SUCCESS, PROMOS_STORY_FAILURE,
    EXPORT_PROMO_REQUEST, EXPORT_PROMO_SUCCESS, EXPORT_PROMO_FAILURE,
    CHANGE_PROMO_FEATURE_REQUEST, CHANGE_PROMO_FEATURE_SUCCESS, CHANGE_PROMO_FEATURE_FAILURE,
    CHANGE_PROMOS_STATUS_REQUEST, CHANGE_PROMOS_STATUS_SUCCESS, CHANGE_PROMOS_STATUS_FAILURE,
    CHANGE_BRAND_PROMO_FEATURE_REQUEST, CHANGE_BRAND_PROMO_FEATURE_SUCCESS, CHANGE_BRAND_PROMO_FEATURE_FAILURE,
    REDEEM_COUPON_REQUEST, REDEEM_COUPON_SUCCESS, REDEEM_COUPON_FAILURE,    
    CREATE_PROMO_REQUEST, CREATE_PROMO_SUCCESS, CREATE_PROMO_FAILURE,
    GET_RUNNING_PROMOS_REQUEST, GET_RUNNING_PROMOS_SUCCESS, GET_RUNNING_PROMOS_FAILURE,
    UPDATE_PROMO_POSTER_REQUEST, UPDATE_PROMO_POSTER_SUCCESS, UPDATE_PROMO_POSTER_FAILURE,
    PROMO_POSTER_REQUEST, PROMO_POSTER_SUCCESS, PROMO_POSTER_FAILURE,


} from "../actions";

const initialState = {
    promos_loading: false, promos: [], promos_errors: {},
    promo_details_loading: false, promo_details: {}, promo_details_errors: {},
    coupon_codes_loading: false, coupon_codes: [], coupon_codes_errors: {},
    promo_list_loading: false, promo_list: [], promo_list_errors: {},
    promo_story_loading: false, promo_story_details: [], promo_story_errors: {},
    export_promo_loading: false, export_promo: [], export_promo_errors: {},
    redeem_coupon_loading: false, redeem_coupon: [], redeem_coupon_errors: {},
    create_promos_loading: false, create_promos: {}, create_promos_errors: {},
    running_promos_loading: false, running_promos: [], running_promos_errors: {},
    update_promo_poster_loading: false, promo_poster: {}, update_promo_poster_errors: {},
    promo_poster_loading: false, promo_poster_errors: {},
};

export function PromoReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List brand promos redux */
        case PROMOS_REQUEST: {
            return {
                ...state,
                promos_loading: true,
                promos_errors: {}
            };
        }
        case PROMOS_SUCCESS: {

            let newArray = [...state.promos]; //making a new array

            if (action.data.page === 1) {
                newArray = []
            }

            action.data.promos.forEach((payload) => {
                const promoIndex = newArray.findIndex(promos => promos._id === payload._id);
                if (promoIndex !== -1) {
                    newArray[promoIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                promos_loading: false,
                promos: newArray,
                promos_errors: {}
            };

        }
        case PROMOS_FAILURE: {
            return {
                ...state,
                promos_loading: false,
                promos_errors: action.errors,
                promos: [],
            };
        }
        /** end::List brand promos redux */

        /** begin::Change promo status redux */
        case CHANGE_PROMO_STATUS_REQUEST: {
            return {
                ...state,
                change_promo_status_loading: true,
                create_promos_errors: {}
            };
        }
        case CHANGE_PROMO_STATUS_SUCCESS: {
            let promoArray = [...state.promos];
            const promoIndex = promoArray.findIndex(promos => promos._id === action.data._id);
            if (promoIndex !== -1) {
                promoArray[promoIndex].status = action.data.status;
            }
            return {
                ...state,
                change_promo_status_loading: false,
                change_promo_status_errors: {},
                promos: promoArray
            };
        }
        case CHANGE_PROMO_STATUS_FAILURE: {
            return {
                ...state,
                change_promo_status_loading: false,
                change_promo_status_errors: action.errors
            };
        }
        /** end::Change promo status redux */

        /** begin::Update promo redux */
        case UPDATE_PROMO_REQUEST: {
            return {
                ...state,
                create_promos_loading: true,
                create_promos_errors: {}
            };
        }
        case UPDATE_PROMO_SUCCESS: {
            return {
                ...state,
                create_promos_loading: false,
                create_promos: action.data,
                create_promos_errors: {}
            };
        }
        case UPDATE_PROMO_FAILURE: {
            return {
                ...state,
                create_promos_loading: false,
                create_promos_errors: action.errors
            };
        }
        /** end::Update promo redux */

        /** begin::promo details redux */
        case PROMO_DETAILS_REQUEST: {
            return {
                ...state,
                promo_details_loading: true,
                promo_details_errors: {}
            };
        }
        case PROMO_DETAILS_SUCCESS: {
            
            let runningPromosArray = [...state.running_promos];

            if (runningPromosArray && runningPromosArray.length > 0 && action.data) {
                const promoIndex = runningPromosArray.findIndex(promo => (promo._id === action.data._id && (promo.category === 'promo')) );
                action.data.active_today = (promoIndex !== -1) ? true : false;

            }
            
            return {
                ...state,
                promo_details_loading: false,
                promo_details: action.data,
                promo_details_errors: {}
            };
        }
        case PROMO_DETAILS_FAILURE: {
            return {
                ...state,
                promo_details_loading: false,
                promo_details_errors: action.errors
            };
        }
        /** end::promo details redux */

        /** begin::List brand promos coupon codes redux */
        case PROMO_COUPON_CODES_REQUEST: {
            return {
                ...state,
                coupon_codes_loading: true,
                coupon_codes_errors: {}
            };
        }
        case PROMO_COUPON_CODES_SUCCESS: {

            let newArray = [...state.coupon_codes]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.coupon_codes.forEach((payload) => {
                const couponCodeIndex = newArray.findIndex(coupon_code_data => coupon_code_data.coupon_code === payload.coupon_code);
                if (couponCodeIndex !== -1) {
                    newArray[couponCodeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                coupon_codes_loading: false,
                coupon_codes: newArray,
                coupon_codes_errors: {}
            };

        }
        case PROMO_COUPON_CODES_FAILURE: {
            return {
                ...state,
                coupon_codes_loading: false,
                coupon_codes_errors: action.errors
            };
        }
        /** end::List brand promos coupon codes redux */

        /** begin::promo List redux */
        case PROMOS_LIST_REQUEST: {
            return {
                ...state,
                promo_list_loading: true,
                promo_list_errors: {}
            };
        }
        case PROMOS_LIST_SUCCESS: {

            let newArray = [...state.promo_list]; //making a new array
            let runningPromosArray = [...state.running_promos];
            
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.promo.forEach((payload) => {
                let activeToday = false;                

                if(runningPromosArray && runningPromosArray.length > 0) {
                    const promoIndex = runningPromosArray.findIndex(promo => (promo._id === payload._id && (promo.category === 'promo')) );
                    activeToday = (promoIndex !== -1) ? true : false;
                }

                const promoIndex = newArray.findIndex(promo => promo._id === payload._id);
                if (promoIndex !== -1) {
                    newArray[promoIndex] = payload;
                    newArray[promoIndex].active_today = activeToday;

                } else {
                    newArray = newArray.concat({ ...payload, active_today: activeToday });
                }
            });

                
            return {
                ...state,
                promo_list_loading: false,
                promo_list: newArray,
                promo_list_errors: {}
            };

        }
        case PROMOS_LIST_FAILURE: {
            return {
                ...state,
                promo_list_loading: false,
                promo_list_errors: action.errors,
                promo_list: [],
            };
        }
        /** end::List promo redux */

        /** begin::Promo story details redux */
        case PROMOS_STORY_REQUEST: {
            return {
                ...state,
                promo_story_loading: true,
                promo_story_errors: {}
            };
        }

        case PROMOS_STORY_SUCCESS: {
            let newArray = [...state.promo_story_details]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }

            action.data.story.forEach((payload) => {
                const storyIndex = newArray.findIndex(story => story._id === payload._id);
                if (storyIndex !== -1) {
                    newArray[storyIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                story_details_loading: false,
                promo_story_details: newArray,
                story_details_errors: {}
            };
        }

        case PROMOS_STORY_FAILURE: {
            return {
                ...state,
                promo_story_loading: false,
                promo_story_errors: action.errors,
                promo_story_details: []
            };
        }
        /** end::Promo story details redux */

        /** begin::Export promo redux*/
        case EXPORT_PROMO_REQUEST: {
            return {
                ...state,
                export_promo_loading: true,
                export_promo_errors: {}
            };
        }
        case EXPORT_PROMO_SUCCESS: {

            return {
                ...state,
                export_promo_loading: false,
                export_promo: action.data,
                export_promo_errors: {}
            };

        }
        case EXPORT_PROMO_FAILURE: {
            return {
                ...state,
                export_promo_loading: false,
                export_promo: [],
                export_promo_errors: action.errors,
            };
        }
        /** end::Export promo redux*/

        /** begin::Change promo feature redux */
        case CHANGE_PROMO_FEATURE_REQUEST: {
            return {
                ...state,
                change_promo_feature_loading: true,
                change_promo_feature_errors: {}
            };
        }
        case CHANGE_PROMO_FEATURE_SUCCESS: {

            let promoArray = [...state.promo_list];
            const promoIndex = promoArray.findIndex(promos => promos._id === action.data._id);

            if (promoIndex !== -1) {
                promoArray[promoIndex].feature = action.data.feature;
            }

            return {
                ...state,
                change_promo_feature_loading: false,
                change_promo_feature_errors: {},
                promos: promoArray
            };
        }
        case CHANGE_PROMO_FEATURE_FAILURE: {
            return {
                ...state,
                change_promo_feature_loading: false,
                change_promo_feature_errors: action.errors
            };
        }
        /** end::Change promo feature redux */

        /** begin::Change promos status redux */
        case CHANGE_PROMOS_STATUS_REQUEST: {
            return {
                ...state,
                change_promos_status_loading: true,
                change_promos_status_errors: {}
            };
        }
        case CHANGE_PROMOS_STATUS_SUCCESS: {
            let promosArray = [...state.promo_list];
            const promosIndex = promosArray.findIndex(promo => promo._id === action.data._id);

            if (promosIndex !== -1) {
                promosArray[promosIndex].status = action.data.status;
            }

            return {
                ...state,
                change_promos_status_loading: false,
                change_promos_status_errors: {},
                promos: promosArray
            };
        }
        case CHANGE_PROMOS_STATUS_FAILURE: {
            return {
                ...state,
                change_promos_status_loading: false,
                change_promos_status_errors: action.errors
            };
        }
        /** end::Change promos status redux */

        /** begin::Change brand promo feature redux */
        case CHANGE_BRAND_PROMO_FEATURE_REQUEST: {
            return {
                ...state,
                change_brand_promo_feature_loading: true,
                change_brand_promo_feature_errors: {}
            };
        }
        case CHANGE_BRAND_PROMO_FEATURE_SUCCESS: {

            let promoArray = [...state.promos];
            const promoIndex = promoArray.findIndex(promos => promos._id === action.data._id);

            if (promoIndex !== -1) {
                promoArray[promoIndex].feature = action.data.feature;
            }

            return {
                ...state,
                change_brand_promo_feature_loading: false,
                change_brand_promo_feature_errors: {},
                promos: promoArray
            };
        }
        case CHANGE_BRAND_PROMO_FEATURE_FAILURE: {
            return {
                ...state,
                change_brand_promo_feature_loading: false,
                change_brand_promo_feature_errors: action.errors
            };
        }
        /** end::Change brand promo feature redux */

        /** begin::Redeem coupon redux */
        case REDEEM_COUPON_REQUEST: {
            return {
                ...state,
                redeem_coupon_loading: true,
                redeem_coupon_errors: {}
            };
        }
        case REDEEM_COUPON_SUCCESS: {

            let couponArray = [...state.coupon_codes];

            const couponIndex = couponArray.findIndex(coupons => coupons._id === action.data._id && coupons.coupon_code === action.data.coupon_code);
            if (couponIndex !== -1) {
                couponArray[couponIndex].status = action.data.status;
                couponArray[couponIndex].redeemed_at = action.data.redeemed_at;
            }

            return {
                ...state,
                redeem_coupon_loading: false,
                coupon_codes: couponArray,
                redeem_coupon_errors: {}
            };

        }
        case REDEEM_COUPON_FAILURE: {
            return {
                ...state,
                redeem_coupon: [],
                redeem_coupon_loading: false,
                redeem_coupon_errors: action.errors
            };
        }
        /** end::Redeem coupons redux */

        /** begin::Create promo redux */
        case CREATE_PROMO_REQUEST: {
            return {
                ...state,
                create_promos_loading: true,
                create_promos_errors: {}
            };
        }
        case CREATE_PROMO_SUCCESS: {

            return {
                ...state,
                create_promos_loading: false,
                create_promos: action.data,
                create_promos_errors: {}
            };
        }
        case CREATE_PROMO_FAILURE: {
            return {
                ...state,
                create_promos_loading: false,
                create_promos_errors: action.errors
            };
        }
        /** end::Create promo redux */

                /** begin::running promos redux*/
        case GET_RUNNING_PROMOS_REQUEST: {
            return {
                ...state,
                running_promos_loading: true,
                running_promos_errors: {}
            };
        }
        case GET_RUNNING_PROMOS_SUCCESS: {

            let newArray = [...state.promo_list]; //making a new array
            let newDetailsArray = state.promo_details; //making a new array

            newArray.forEach(payload => {
                const promoIndex = action.data.findIndex(promo => ((promo._id === payload._id) && (promo.category === 'promo')));

                if (promoIndex !== -1) {
                    payload.active_today = true;
                } else {
                    payload.active_today = false;
                }
            });

            if (newDetailsArray && Object.keys(newDetailsArray).length > 0) {

                const promoIndex = action.data.findIndex(promo => ((promo._id === newDetailsArray._id) && (promo.category === 'promo')));

                if (promoIndex !== -1) {
                    newDetailsArray.active_today = true;
                } else {
                    newDetailsArray.active_today = false;
                }
            }


            return {
                ...state,
                running_promos_loading: false,
                running_promos: action.data,
                running_promos_errors: {},
                promo_list: newArray,
                promo_details: newDetailsArray
            };

        }
        case GET_RUNNING_PROMOS_FAILURE: {
            return {
                ...state,
                running_promos: [],
                running_promos_loading: false,
                running_promos_errors: action.errors
            };
        }
        /** end::running promos redux */

        /** begin::Update promo redux */
        case UPDATE_PROMO_POSTER_REQUEST: {
            return {
                ...state,
                update_promo_poster_loading: true,
                update_promo_poster_errors: {}
            };
        }
        case UPDATE_PROMO_POSTER_SUCCESS: {
            return {
                ...state,
                update_promo_poster_loading: false,
                update_promo_poster_errors: {}
            };
        }
        case UPDATE_PROMO_POSTER_FAILURE: {
            return {
                ...state,
                update_promo_poster_loading: false,
                update_promo_poster_errors: action.errors
            };
        }
        /** end::Update promo redux */

        /** begin::Get promo redux */
        case PROMO_POSTER_REQUEST: {
            return {
                ...state,
                promo_poster_loading: true,
                promo_poster_errors: {}
            };
        }
        case PROMO_POSTER_SUCCESS: {
            return {
                ...state,
                promo_poster_loading: false,
                promo_poster: action.data,
                promo_poster_errors: {}
            };
        }
        case PROMO_POSTER_FAILURE: {
            return {
                ...state,
                promo_poster_loading: false,
                promo_poster_errors: action.errors
            };
        }
        /** end::Get promo redux */
        default:
            return state;
    }
}
