
import {
    SUPPORT_CENTER_REQUEST, SUPPORT_CENTER_SUCCESS, SUPPORT_CENTER_FAILURE,
    TICKET_DETAILS_REQUEST, TICKET_DETAILS_SUCCESS, TICKET_DETAILS_FAILURE,
    ADD_REPLY_REQUEST, ADD_REPLY_SUCCESS, ADD_REPLY_FAILURE,
    CHANGE_SUPPORT_CENTER_STATUS_REQUEST, CHANGE_SUPPORT_CENTER_STATUS_SUCCESS, CHANGE_SUPPORT_CENTER_STATUS_FAILURE,

} from "../actions"

const initialState = {
    support_center_loading: false, support_center: [], support_center_errors: {},
    page: 1, per_page: 20, total: 0, total_pages: 0,
    ticket_details_loading: false, ticket_details: {}, ticket_details_errors: {},
    add_reply_loading: false, add_reply_errors: [],
    change_support_center_status_loading: false, change_support_center_status_errors: {},

};

export function SupportCenterReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List Support Center redux */
        case SUPPORT_CENTER_REQUEST: {
            return {
                ...state,
                support_center_loading: true,
                support_center_errors: {}
            };
        }
        case SUPPORT_CENTER_SUCCESS: {

            let newArray = [...state.support_center]; //making a new array

            if (action.data.page === 1) {
                newArray = []
            }
            action.data.supportCenter.forEach((payload) => {

                const support_centerIndex = newArray.findIndex(support_center => support_center._id === payload._id);
                if (support_centerIndex !== -1) {
                    newArray[support_centerIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                support_center_loading: false,
                support_center: action.data.supportCenter,
                support_center_errors: {},
                page: action.data.per_page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }
        case SUPPORT_CENTER_FAILURE: {
            return {
                ...state,
                support_center: [],
                support_center_loading: false,
                support_center_errors: action.errors,
            }
        }
        /** end::List Support Center redux */

        /** begin::ticket details redux */
        case TICKET_DETAILS_REQUEST: {
            return {
                ...state,
                ticket_details_loading: true,
                ticket_details_errors: {}
            };
        }
        case TICKET_DETAILS_SUCCESS: {

            let newArray = [...state.support_center]; //making a new array

            if (action.data.page === 1) {
                newArray = []
            }

            const ticketDetailsIndex = newArray.findIndex(ticket_details => ticket_details._id === action.data._id);

            if (ticketDetailsIndex !== -1) {
                newArray[ticketDetailsIndex] = action.data;
            } else {
                newArray = newArray.concat(action.data);
            }

            return {
                ...state,
                ticket_details_loading: false,
                ticket_details: action.data,
                ticket_details_errors: {}
            };
        }
        case TICKET_DETAILS_FAILURE: {
            return {
                ...state,
                ticket_details_loading: false,
                ticket_details_errors: action.errors
            };
        }
        /** end::ticket details redux */

        /** begin::Add reply redux */
        case ADD_REPLY_REQUEST: {
            return {
                ...state,
                add_reply_loading: true,
                add_reply_errors: {}
            };
        }
        case ADD_REPLY_SUCCESS: {
            let ticketArray = state.ticket_details;

            let replies = ticketArray.replies;
            replies = replies.concat(action.data);
            ticketArray.replies = replies

            return {
                ...state,
                add_reply_loading: false,
                add_reply_errors: {},
                ticket_details: ticketArray,

            };
        }
        case ADD_REPLY_FAILURE: {
            return {
                ...state,
                add_reply_loading: false,
                add_reply_errors: action.errors
            };
        }
        /** end::Add reply redux */

        /** begin::Change Support center status redux */
        case CHANGE_SUPPORT_CENTER_STATUS_REQUEST: {
            return {
                ...state,
                change_support_center_status_loading: true,
                change_support_center_status_errors: {}
            };
        }
        case CHANGE_SUPPORT_CENTER_STATUS_SUCCESS: {

            let supportCenterArray = [...state.support_center];
            let ticketDetails = state.ticket_details

            const supportCenterIndex = supportCenterArray.findIndex(support_center => support_center._id === action.data._id);

            if (supportCenterIndex !== -1) {
                supportCenterArray[supportCenterIndex].status = action.data.status;
            }

            if (ticketDetails && ticketDetails._id === action.data._id) {
                ticketDetails.status = action.data.status;
            }

            return {
                ...state,
                change_support_center_status_loading: false,
                change_support_center_status_errors: {},
                support_center: supportCenterArray,
                ticket_details: ticketDetails
            };
        }
        case CHANGE_SUPPORT_CENTER_STATUS_FAILURE: {
            return {
                ...state,
                change_support_center_status_loading: false,
                change_support_center_status_errors: action.errors
            };
        }
        /** end::Change Support center status redux */

        default:
            return state;

    }
}