/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import borders from "../../base/borders";
import boxShadows from "../../base/boxShadows";
import colors from "../../base/colors";
import rgba from "../../../functions/rgba";

const { black } = colors;
const { borderRadius } = borders;
const { xxl } = boxShadows;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: `${rgba(black.main, 0.8)}`
      },
    },
    paper: {
      padding: 30,
      boxShadow: xxl,
      borderRadius: borderRadius.lg
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};
