import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";
import Box from "../../../_components/box";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormInput, FormPassword, FormSelect } from "../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { RoleAction, UserAction } from "../../../redux/actions";
import { PostAuthRoutes } from "../../../routes";

// Initialize form input values to null
const inputs = { admin_id: '', name: '', email: '', password: '', confirm_password: '', role_id: '' };

function CreateUser() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** User id and details on update page */
    const location = useLocation();
    const { user } = location.state ?? {};

    /** Redux actions and state */
    const { add_user_loading: isLoading, add_user_errors: createUserErrors } = useSelector((state) => state.UserReducer);
    const { roles_select_list_loading: isRolesLoading, roles_select_list } = useSelector((state) => state.RoleReducer);
    const addUser = (params) => dispatch(UserAction.addUser(params));
    const updateUser = (params) => dispatch(UserAction.updateUser(params));
    const roleSelectList = (params) => dispatch(RoleAction.roleSelectList(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetUserLoading, setUserLoading] = useState(true);

    useEffect(() => {
        roleSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setErrors({ ...createUserErrors });
    }, [createUserErrors])

    useEffect(() => {
        if (user && user._id) {
            setData({
                admin_id: user._id,
                name: user.name,
                email: user.email,
                role_id: user.role_id,
            });
            setUserLoading(false);
        } else {
            setUserLoading(false);
        }
    }, [user])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle update brand form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdateUserAction(data);
    }

    /**
     * function to dispatch update brand api
     * @author Jasin
     * @created_at 14 June 2022
     */
    async function dispatchUpdateUserAction(formData) {
        const isCreated = user && user._id ? await updateUser(formData) : await addUser(formData);

        if (isCreated && isCreated.status === 1) {
            let redirectPath = `${PostAuthRoutes('user').path}`;
            history.push(redirectPath);
        }
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function cancelBrand() {
        let redirectPath = `${PostAuthRoutes('user').path}`;
        history.push(redirectPath);
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${user && user._id ? PostAuthRoutes('update_user').name : PostAuthRoutes('create_user').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={user && user._id ? PostAuthRoutes('update_user').name : PostAuthRoutes('create_user').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" display={`flex`} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card >
                                {!isSetUserLoading && !isRolesLoading && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Name'
                                                name='name'
                                                value={data.name}
                                                error={action.isSubmitted && errors.name ? errors.name : ''}
                                                onChange={handleChange}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={3}
                                                label={`Role`}
                                                name={`role_id`}
                                                placeholder={`Select role`}
                                                onChange={handleChange}
                                                data={roles_select_list}
                                                error={action.isSubmitted && errors.role_id ? errors.role_id : ''}
                                                className="col-md-6"
                                                value={data.role_id}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={2}
                                                label='Email'
                                                name='email'
                                                value={data.email}
                                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        {!(user && user._id) && <React.Fragment>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormPassword
                                                    tabIndex={3}
                                                    label='Password'
                                                    name='password'
                                                    value={data.password}
                                                    error={action.isSubmitted && errors.password ? errors.password : ''}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormPassword
                                                    tabIndex={4}
                                                    label='Re-enter password'
                                                    name='confirm_password'
                                                    value={data.confirm_password}
                                                    error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </React.Fragment>}
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={user && user._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={6} />
                                                <CancelButton onClick={cancelBrand} tabIndex={7} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreateUser };
