import { Grid, Stack } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { PostAuthRoutes } from '../../routes'
import { NewsletterSubscribersDataTable } from '.'
import { FormSearchInput } from '../../_components/form'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CardHeader, Card, CardContent } from '../../_components/card'
import { AbilityCan } from '../../_helpers/permission/AbilityCan'

function NewsletterSubscribers() {

    /** Initialize plugins and variables */
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ limit: 100, page: 1, search: searchParams.get('q') ?? '' });

    /** Redux reducer state */
    const { user } = useSelector((state) => state.UserReducer);

    /**
     * function to apply the search key words on the newsletter subscribers list
     * @param {String} text Search keyword used
     * @return view
     * @author
     * @created_at 31 March 2023
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('newsletter_subscribers').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`} >
                            <AbilityCan I='admin_newsletter_subscribers_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                        </Stack>
                    } />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <NewsletterSubscribersDataTable search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}

export { NewsletterSubscribers }
