import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent } from '@mui/material'

function ConfzModalBody({ className, children, ...props }) {
    return (
        <DialogContent sx={{ pt: `1rem !important` }} className={`modal-body ${className}`} {...props}>
            {children}
        </DialogContent>
    )
}


ConfzModalBody.defaultProps = {
    className: ""
}


ConfzModalBody.propTypes = {
    className: PropTypes.string,
}

export { ConfzModalBody }
