import React from 'react'
import { useLocation } from 'react-router';
import { Link, Route, Switch } from 'react-router-dom'

import { Container, Grid } from '@mui/material'
import { MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import Box from '../../_components/box';

import { PostAuthModuleMenuRoutes } from '../../routes'

function Profile() {

    /** Initialize plugins and variables */
    const menuRoutes = Object.values(PostAuthModuleMenuRoutes('profile'));

    const location = useLocation();

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Box sx={{ position: `fixed` }}>
                        <MenuList dense sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            {menuRoutes.map((route, index) => (
                                <MenuItem key={index} component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ``} sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                    <ListItemIcon>
                                        <route.menuIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{route.name}</ListItemText>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <Switch>
                        {menuRoutes.map((route, index) => (
                            <Route key={index} exact path={route.path} component={route.component} />
                        ))}
                    </Switch>
                </Grid>
            </Grid>
        </Container>
    )
}




export { Profile };
