import React from 'react'
import { Grid, Stack, Typography, CardMedia, Avatar } from '@mui/material'
import { Card, CardContent } from '../../../_components/card'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux';
import { CheckCircle, MailOutline } from '@mui/icons-material';
import { ProfileDetailsCardLoader } from ".";

function ProfileDetails() {

    /** Redux actions and state */
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);

    if (!isLoading)
        return (
            <React.Fragment>
                <Card>
                    <CardContent>
                        <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Avatar sx={{ width: 150, height: 150 }}> {user.short_name} </Avatar>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Typography variant="h4" color="primary">{user.name}</Typography>
                                </Stack>
                                <Typography variant="h6" display={`flex`} alignItems='center'><MailOutline color='primary' />&nbsp;{user.email}&nbsp;{user.email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='secondary' size='small' />}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    else
        return (
            <Card>
                <CardContent>
                    <ProfileDetailsCardLoader />
                </CardContent>
            </Card>
        )
}

export { ProfileDetails };
