import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn } from '../'
import questinMarkSvg from './question-mark.svg'

function UpdateStatusModal({ show, showLoader, data, closeModal, updateStatus }) {

    const [action] = useState({ isProcessing: false, isSubmitted: false });

    const onClose = () => {
        closeModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateStatus(data.id);
    }

    return (
        <ConfzModal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ConfzModalBody style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `165px` }}>
                                <Typography variant='h3'>Are You Sure?</Typography>
                                <Typography variant='body2'>{`Do you want to ${(data.status === 'active') ? 'deactivate' : 'activate'}  ${data.item}`}?</Typography>
                            </Stack>
                        </CardContent>
                    </ConfzModalBody>
                    <ConfzModalFooter style={{ padding: 0 }}>
                        <ConfzModalFooterSaveBtn tabIndex={3} text={`${(data.status === 'active') ? 'deactivate' : 'activate'} `} isProcessing={action.isProcessing} />
                        <ConfzModalFooterCloseBtn tabIndex={4} onClose={onClose} />
                    </ConfzModalFooter>
                </form>
            </Card>
        </ConfzModal>
    )
}

export { UpdateStatusModal }
