import React, { useEffect } from "react";
import { FaqAction } from "../../../redux/actions";
import { CardHeader as PageHeader, Card, CardContent } from "../../../_components/card";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Linkify from 'react-linkify';
import { PostAuthRoutes } from "../../../routes";
import { Grid, Typography, Box, Chip, Divider } from "@mui/material";
import { NoData } from "../../../_components/layout/no-data";

function FaqDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const faqId = params.get('fId'); // faq id from url

    /** Redux actions and state */
    const { faq_details_loading: isLoading, faq_details } = useSelector((state) => state.FaqReducer);
    const faqDetails = (params) => dispatch(FaqAction.faqDetails(params));

    useEffect(() => {
        faqDetails({ faq_id: faqId });

        //eslint-disable-next-line
    }, [faqId])

    return (
        <React.Fragment>
            {!isLoading && Object.keys(faq_details).length === 0 && <NoData content1={`No`} content2={`faq available`} minHeight={`55vh`} ></NoData>}
            {(!isLoading && Object.keys(faq_details).length > 0) &&
                <React.Fragment>
                    <PageHeader title={PostAuthRoutes('faq_details').name} />
                    <Box paddingLeft='300px'>
                        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ width: '80%' }}>
                            <Grid item xs={12} sm={12} md={8} lg={12} xl={12}>
                                <Card >
                                    <CardContent>
                                        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
                                                    <Typography variant="h5" sx={{ cursor: `pointer` }}>Question</Typography>
                                                    <Box>
                                                        <Chip size='small' label={faq_details.status === 'active' ? 'Active' : 'Inactive'} color={faq_details.status === 'active' ? 'success' : 'error'} />
                                                    </Box>
                                                </Box>
                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                    <a target="blank" href={decoratedHref} key={key}>
                                                        {decoratedText}
                                                    </a>
                                                )}>
                                                    <Typography style={{ whiteSpace: 'pre-line' }}  variant="body2" paddingTop={2} paddingBottom={1}>{`${faq_details.question}`}</Typography>
                                                </Linkify>
                                            </Grid>

                                            <Divider />

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <Typography variant="h5" sx={{ cursor: `pointer` }}>Answer</Typography>
                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                    <a target="blank" href={decoratedHref} key={key}>
                                                        {decoratedText}
                                                    </a>
                                                )}>
                                                    <Typography style={{ whiteSpace: 'pre-line' }}  variant="body2" paddingTop={2} paddingBottom={1}>{`${faq_details.answer}`}</Typography>
                                                </Linkify>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                    </Box>
                </React.Fragment>
            }
        </React.Fragment>
    )

}

export { FaqDetails }