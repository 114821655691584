import { Card, CardContent, CardHeader } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import theme from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';
import React from 'react';

function EmailLogRequestCard({ emailLog, ...props }) {

    /** Initialize and declare state */
    const { email_data, response } = emailLog;

    return (
        <React.Fragment>
            <Card sx={{ pb: 1 }}>
                <CardHeader title="API Request" />
                <CardContent>
                    {Object.keys(emailLog).length > 0 && <SyntaxHighlighter language="json" wrapLines={true} showLineNumbers={true} style={theme}>{email_data}</SyntaxHighlighter>}
                </CardContent>
                <CardHeader title="Response" style={{ paddingTop: 0 }} />
                <CardContent>
                    {Object.keys(emailLog).length > 0 && <SyntaxHighlighter language="json" wrapLines={true} showLineNumbers={true} style={theme}>{JSON.stringify(response, null, 2)} </SyntaxHighlighter>}
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export { EmailLogRequestCard }